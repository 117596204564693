import {
  Row,
} from '@devexpress/dx-react-grid';
import {
  MainPageContainer,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { convertToDate, useSMSBillingReport02 } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { SummaryItemComponent } from 'components/ReportsComponents/SummaryItemComponent';
import { GroupSummaryItemComponent } from 'components/ReportsComponents/GroupSummaryItemComponent';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { customizeCell, valuesConverter } from './helpers';
import { columns, dateColumnsName, defaultGroups, groupSummaryItems, options, summaryItems, tableColumnExtensions } from './constants';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { WorkProviderSearchForm } from 'components/ReportForms/WorkProviderSearchForm';
import { reportLogger } from '../reportLogger';
import { customizeCountSummaryCell } from '../helpers';


export function SMSBillingReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    endDate: urlParams.get('endDate'),
    startDate: urlParams.get('startDate'),
    workProvider: urlParams.get('workProvider'),
  };

  const queryEnabled =
    !!urlParamsValue.startDate &&
    !!urlParamsValue.endDate &&
    !!urlParamsValue.workProvider;

  const { reportData, refetchReport, isReportFetching } = useSMSBillingReport02(
    {
      endDate: urlParamsValue.endDate,
      startDate: urlParamsValue.startDate,
      workProvider: urlParamsValue.workProvider,
    },
    queryEnabled,
    handleError
  );

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) || null
  );
  const [workProvider, setWorkProvider] = useState<string | null>(
    urlParamsValue.workProvider || null
  );

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsValue.startDate, urlParamsValue.endDate]);

  return (
    <MainPageContainer isLoading={isReportFetching} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName="SMS Billing Report"
          infoMessage="Displays the SMS Billing Report for the date range provided"
        />
        <WorkProviderSearchForm
          handleSubmit={handleSubmit}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          isDisabled={isReportFetching}
          workProvider={workProvider}
          setWorkProvider={setWorkProvider}
          options={options}
        />
      </UpperInfoContainer>

      {!isReportFetching && rows?.length === 0 && (
        <NoReportDataMessage />
      )}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isReportFetching}
          disableDragAndDrop
          title="smsBillingReport"
          summaryItems={summaryItems}
          defaultGrouping={defaultGroups}
          DateTypeProvider={DateTypeProvider}
          dateColumnsName={dateColumnsName}
          customizeCellExport={customizeCell}
          groupSummaryItems={groupSummaryItems}
          headerTitleForSheet="SMS Billing Report"
          SummaryItemComponent={SummaryItemComponent}
          customizeSummaryCell={customizeCountSummaryCell}
          tableColumnExtensions={tableColumnExtensions}
          GroupSummaryItemComponent={GroupSummaryItemComponent}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.workProvider == workProvider
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate && !!workProvider)
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            workProvider: workProvider,
          },
          reportPath
        )
      );
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('SMS Billing Report'));
    setToast(true);
  }
}
