import styled from '@emotion/styled';
import { Heading } from 'components/Typography';
import { PageHeader } from 'components/PageLayout';
import { ReportTitleInfo } from 'components/ReportTitleInfo';
import { ReportHeaderProps } from './types';


export function ReportHeader({ reportName, infoMessage }: ReportHeaderProps) {
    return (
        <PageHeader>
            <Heading>
                <ReportTitleText>
                    <div>{reportName}</div>
                    <ReportTitleInfo message={infoMessage} title={reportName} />
                </ReportTitleText>
            </Heading>
        </PageHeader>
    );
}

const ReportTitleText = styled('div')`
  display: flex;
`;
