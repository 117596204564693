/** @jsxImportSource @emotion/react */

import { VehiclesSearchTable } from './VehiclesSearchTable';
import { VehicleTableHeader } from './types';
import { Repair, Repairs, SortOrder } from 'api/resources/models/AutoGenerated';

interface Props {
  loading: boolean;
  repairs?: Repairs;
  tableHeaders: VehicleTableHeader[];
  selected?: string;
  onSortClick: (id: string, sortOrder: SortOrder) => void;
  sortOrder?: SortOrder;
  itemsCount?: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange?: (count: number) => void;
  onRepairClick: (repair: Repair & { siteCode: string | null }) => void;
}

export function VehicleTable({
  loading,
  repairs,
  tableHeaders,
  selected,
  itemsCount,
  currentPage,
  onSortClick,
  sortOrder,
  onPageChange,
  onItemsPerPageChange,
  onRepairClick,
}: Props) {
  return (
    <VehiclesSearchTable
      headers={tableHeaders}
      data={repairs?.items}
      selected={selected}
      onSort={onSortClick}
      isLoading={loading}
      sortOrder={sortOrder}
      itemsCount={itemsCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      onRepairClick={onRepairClick}
    />
  );
}
