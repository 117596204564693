import { Row } from '@devexpress/dx-react-grid';
import { formatDateTime } from 'utils/helpers';
import { dateColumnsName } from './constants';

export const valuesConverter = (data: Row[]) => {
  return data.map((row) => {
    const formattedRow = { ...row };
    dateColumnsName.forEach((columnName) => {
      if (row[columnName]) {
        formattedRow[columnName] = formatDateTime(row[columnName]);
      }
    });
    return formattedRow;
  });
};