import { UserAccount as ApiUserAccount } from 'api/resources/models/AutoGenerated';
import dayjs from 'dayjs';
import { convertToDate } from 'pages/hooks';
import { UserAccount } from './types';

export const dateToString = (date: Date) =>
  dayjs(date).format('YYYY-MM-DDTHH:mm:ss');

export const getApiForm = (data: UserAccount): ApiUserAccount => ({
  ...data,
  expiryDate: data.expiryDate ? dateToString(data.expiryDate) : null,
  createdDate: data.createdDate ? dateToString(data.createdDate) : null,
  lastLoginDate: data.lastLoginDate ? dateToString(data.lastLoginDate) : null,
  dataAccessProfileName: data.dataAccessProfileName ?? '',
});

export const getFormData = (data: ApiUserAccount): UserAccount => ({
  ...data,
  expiryDate: data.expiryDate ? convertToDate(data.expiryDate) : null,
  createdDate: data.createdDate ? convertToDate(data.createdDate) : null,
  lastLoginDate: data.lastLoginDate ? convertToDate(data.lastLoginDate) : null,
});

