/** @jsxImportSource @emotion/react */

import { Container, GridItem } from 'components/Layout';
import { Heading, Text } from 'components/Typography';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styled from '@emotion/styled';
import { FormGroup, InputAdornment } from '@mui/material';
import { FormEvent, useMemo, useState, useEffect } from 'react';
import { useCompanies, useUserAccounts, BaseUserAccount } from 'pages/hooks';
import { usersTableHeaders } from './types';
import { DataGrid, getSortObject } from 'components/DataGrid';
import { createAdminUsersAccountsUrl, ROUTES } from 'core/routes';
import { useUserAccountParams } from './hooks';
import { SortObject } from 'components/types';
import { TextField } from 'components/TextField';
import { Checkbox } from 'components/Checkbox';
import {
  MainPageContainer,
  UpperInfoContainer,
  PageHeader,
  TableContainer,
} from 'components/PageLayout';
import { MobileRow } from './MobileRow';
import InfoText from 'components/InfoText';
import { Select, SelectOption } from 'components/Select';
import { DesktopButton, MobileButton } from './CreateButtons';
import { NO_USERS_DATA } from './constants';
import { BackButtonItem } from 'components/ReportsComponents/BackButton';
import { BACK_TO_ADMINISTRATION } from 'pages/constants';
import { SubmitButton } from './SubmitButton';
import { hasOnlySpaces } from 'utils/helpers';

export function UserAccounts({ history, location }: RouteComponentProps) {
  const urlParams = useUserAccountParams(location.search);
  const urlParamsValues = {
    sort: urlParams.sort,
    sortOrder: urlParams.sortOrder,
    searchTerm: urlParams.searchTerm,
    companyId: Number(urlParams.companyId),
    disabledAccounts: urlParams.disabledAccounts,
  };
  const { companies, isCompaniesLoading } = useCompanies({});

  const companyOptions = useMemo<SelectOption[]>(() => {
    const companiesList =
      companies?.map((e) => ({
        id: e.id,
        value: e.name ?? '',
        text: e.name ?? '',
      })) || [];
    return [
      {
        id: -1,
        value: 'All',
        text: 'All',
      },
      ...companiesList,
    ];
  }, [companies]);

  const [searchTerm, setSearchTerm] = useState(
    urlParamsValues.searchTerm ?? ''
  );
  const [selectedCompany, setSelectedCompany] = useState<
    SelectOption | undefined
  >(
    companyOptions?.find((e) => e.id == urlParamsValues?.companyId) ?? undefined
  );

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParamsValues.sort ?? '',
    sortOrder: urlParamsValues.sortOrder,
  });

  const [includeDisabledAccounts, setIncludeDisabledAccounts] = useState(
    urlParamsValues.disabledAccounts
  );

  const queryEnabled =
    !!urlParamsValues.searchTerm || urlParamsValues.companyId > 0;
  const { users, isUsersFetching } = useUserAccounts(
    {
      sort: urlParamsValues.sort,
      sortOrder: urlParamsValues.sortOrder,
      searchTerm: urlParamsValues.searchTerm,
      includeDisabledAccounts: urlParamsValues.disabledAccounts,
      companyId:
        urlParamsValues.companyId > 0 ? urlParamsValues.companyId : undefined,
    },
    queryEnabled
  );

  const loading = isUsersFetching || isCompaniesLoading;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  const isSearchDisabled = (hasOnlySpaces(searchTerm)) && (!selectedCompany || selectedCompany?.id === -1)
 
  return (
    <MainPageContainer isLoading={loading} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <BackButtonItem text={BACK_TO_ADMINISTRATION}/>
        <PageHeader>
          <Heading>Manage User Accounts</Heading>
        </PageHeader>

        <form onSubmit={handleSubmit}>
          <Container direction="row" alignItems="end">
            <InputButton md={3} sm={8} xs={10}>
              <TextItem>
                <Text fontSize="s">Search for Company</Text>
              </TextItem>
              <Select
                options={companyOptions}
                selectedId={selectedCompany?.id ?? undefined}
                onChange={(e) => setSelectedCompany(e)}
                variant="outlined"
              />
            </InputButton>
            <InputButton md={3} sm={8} xs={10}>
              <TextItem>
                <Text fontSize="s">Search for User</Text>
              </TextItem>
              <TextField
                className="searchbox"
                placeholder="Search"
                variant="outlined"
                value={searchTerm}
                fullWidth
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <EndInputAdornment position="end" onClick={() => setSearchTerm('')}>
                      <ClearIcon />
                    </EndInputAdornment>)
                }}
              />
            </InputButton>
            <GridItem md={1} sm={2} xs={2}>
              <SubmitButton isDisabled={isSearchDisabled}/>
            </GridItem>
            <DesktopButton/>
          </Container>
        </form>

        <GridItem>
          <FormGroup row>
            <Checkbox
              checked={includeDisabledAccounts}
              onChange={(e) =>
                setIncludeDisabledAccounts(Boolean(e.target.checked))
              }
              label="Include disabled accounts"
              color="primary"
            />
          </FormGroup>
        </GridItem>
      </UpperInfoContainer>

      <>
        {!loading && users && users.length === 0 && (
          <InfoText>{NO_USERS_DATA}</InfoText>
        )}

        <TableContainer direction="column">
          <DataGrid
            isLoading={loading}
            headers={usersTableHeaders}
            data={users}
            onSort={sort}
            sortObject={sortObject}
            onRowClick={rowClick}
            mobileRow={<MobileRow users={users} onClick={rowClick} />}
          />
        </TableContainer>
      </>
      <MobileButton/>
    </MainPageContainer>
  );

  function rowClick(row: BaseUserAccount) {
    const route = generatePath(ROUTES.userAccountEdit, {
      id: row.id,
    });
    history.push(route);
  }

  function sort(sortValue: string) {
    setSortObject((prevValue) => getSortObject(sortValue, prevValue));
  }

  function handleSubmit(event: FormEvent) {
    setSearchTerm(() => searchTerm.trim());
    event.preventDefault();
    setSortObject({});
    navigateIfPossible();
  }

  function navigateIfPossible() {
    history.replace(
      createAdminUsersAccountsUrl(
        searchTerm,
        sortObject.sortString ?? undefined,
        selectedCompany?.id?.toString?.(),
        sortObject.sortOrder,
        includeDisabledAccounts
      )
    );
  }
}

const TextItem = styled(GridItem)`
  padding-bottom: 12px;
`;

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const EndInputAdornment = styled(InputAdornment)`
  :hover {
    cursor: pointer;
  }
`;
