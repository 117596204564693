import { Column, TableColumnExtensions } from './types';

export const FEEDBACK_REPORT_TITLE = 'ActiveWeb Feedback Report';

export const FEEDBACK_REPORT_GRID_TITLE = 'activeWebFeedbackReport';

export const FEEDBACK_REPORT_MESSAGE =
  'Provides the detail of all Feedback entries for the chosen date range';

export const columns: Column[] = [
  { name: 'activeWebID', title: 'ActiveWebID' },
  { name: 'registration', title: 'Registration' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'userID', title: 'User ID' },
  { name: 'feedbackDate', title: 'Feedback Date' },
  { name: 'feedbackType', title: 'Feedback Type' },
  { name: 'feedbackComment', title: 'Feedback Comment' },
];

export const tableColumnExtensions: TableColumnExtensions[] = [
  { columnName: 'activeWebID', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'feedbackComment', wordWrapEnabled: true },
  {
    columnName: 'feedbackDate',
    wordWrapEnabled: true,
  },
  { columnName: 'feedbackType', wordWrapEnabled: true },
  { columnName: 'registration', wordWrapEnabled: true },
  { columnName: 'repairerName', wordWrapEnabled: true },
  { columnName: 'userID', wordWrapEnabled: true },
];

export const dateColumnsName = ['feedbackDate'];
