import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CycleTimeTable } from './CycleTimeTable';
import { useCycleTimeNetworkAverage } from 'pages/hooks';
import { cycleTimeHeaders } from './constants';
import { convertCycleTimeData } from './helpers';
import { CycleTimeResponse, TableProps } from './types';


export function NetworkAverage({ handleError }: TableProps) {
    const location = useLocation();

    const urlParamsValue = useMemo(() => {
        const urlParams = new URLSearchParams(location.search);

        return {
            startDate: urlParams.get('startDate'),
            endDate: urlParams.get('endDate'),
        }

    }, [location.search])

    const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate;

    const { networkAverageData, isDataFetching } = useCycleTimeNetworkAverage(
        urlParamsValue.startDate,
        urlParamsValue.endDate,
        queryEnabled,
        handleError
    );

    const [data, setData] = useState<Partial<CycleTimeResponse>[] | undefined>();
    useEffect(() => {
        if (networkAverageData) {
            const newData = convertCycleTimeData(Array(networkAverageData));
            return setData(newData)
        }
    }, [networkAverageData]);

    return (
        <>
            {queryEnabled ? (
                <CycleTimeTable
                    title={'Network Average'}
                    noDataMessage={`Sorry, we couldn't find any Network Average data.`}
                    headers={cycleTimeHeaders(false, 'Repairer')}
                    data={data}
                    isLoading={isDataFetching}
                />
            )
                : null
            }
        </>
    );
}