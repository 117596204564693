import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { AxiosError } from 'axios';
import PrimaryToast from 'components/PrimaryToast';
import { useUpdateCompany } from 'pages/hooks';
import React, { useState } from 'react';
import { CompaniesDetails } from './CompaniesDetails';
import { Company, convertToAPIData } from './form';

interface Props {
  company: Company;
}

export default function CompaniesEdit({ company }: Props) {
  const { updateCompany, isUpdating } = useUpdateCompany();
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  return (
    <React.Fragment>
      <CompaniesDetails
        company={company}
        onSubmit={onSubmit}
        isBusy={isUpdating}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </React.Fragment>
  );

  function onSubmit(data: Company) {
    updateCompany(convertToAPIData({ ...company, ...data }))
      .then(() => {
        setToastState({
          message: successMessages.companyUpdated,
          isOpen: true,
          severity: 'success',
        });
      })
      .catch((error: AxiosError) => handleError(error));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(_error: AxiosError) {
    setToastState({
      message: errorMessages.companyUpdated,
      isOpen: true,
      severity: 'error',
    });
  }
}
