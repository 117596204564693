export const successMessages = {
  accountProfileCreated: 'Account profile was created successfully',
  accountProfileUpdated: 'Account profile was updated successfully',
  companyCreated: 'Company successfully created',
  companyUpdated: 'Company successfully updated',
  addDataAccessCodes: (siteCode: string, formalCode: string) =>
    `Site code and Formal code pair ` +
    `(${siteCode}/${formalCode}) added successfully`,
  removeDataAccessCodes: (
    siteCodeOnSuccess: string,
    formalCodeOnSuccess: string
  ) =>
    `Site code and Formal code pair ` +
    `(${siteCodeOnSuccess}/${formalCodeOnSuccess}) removed successfully`,
  dataAccessProfilesUpdate: 'Data access profile successfully updated',
  dataAccessProfilesCreate: 'Data access profile was created successfully',
  repairDetailsUpdated: 'Repair details successfully updated',
  userCreation: (firstName: string, surname: string) =>
    `User ${firstName} ${surname} was created successfully`,
  userUpdate: 'User account successfully updated',
  userEnabled: 'User account enabled successfully',
  userDisabled: 'User account disabled successfully',
  userNotification: 'User notification email sent successfully',
  userPasswordReset: 'Password reset link sent successfully',
  userUnlocked: 'User account unlocked successfully',
  userSwitched: (name: string | null) =>
    `User account profile switched to ${name}`,
  workProviderAdded: 'Work Provider added successfully!',
  workProviderRemoved: 'Work Provider removed successfully!',
};
