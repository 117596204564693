import { CycleTimeHeader } from './types';

export const summaryTypeOptions = [
  {
    id: 1,
    value: '1',
    text: 'Repairer',
  },
  {
    id: 2,
    value: '2',
    text: 'Manufacturer',
  },
  {
    id: 3,
    value: '3',
    text: 'Stream',
  },
];

export const summaryTypeMap: { [key: string]: string } = {
  Repairer: '1',
  Manufacturer: '2',
  Stream: '3',
};

export const summaryTypeMapToName: { [key: string]: string } = {
  '1': 'Repairer',
  '2': 'Manufacturer',
  '3': 'Stream',
  '4': 'Detail',
};

export const cycleTimeHeaders: (
  isSummaryDetails: boolean,
  fullNameHeader: string
) => CycleTimeHeader[] = (isSummaryDetails, fullNameHeader) => {
  const claims = isSummaryDetails ? 'Total Claims' : 'Claims';
  const avgLabHrs = isSummaryDetails ? 'Average Hours' : 'Labour Hours';

  return [
    { id: 'fullName', label: fullNameHeader },
    { id: 'claims', label: claims },
    { id: 'avgLabHrs', label: avgLabHrs },
    { id: 'fnoL_Onsite', label: 'FNOL-OnSite' },
    { id: 'onSite_Started', label: 'OnSite-Started' },
    { id: 'started_Completed', label: 'Started-Completed' },
    { id: 'completed_Delivered', label: 'Completed-Delivered' },
    { id: 'key2Key', label: 'Key-to-Key' },
    { id: 'fnoL_Delivered', label: 'FNOL-Delivered' },
  ];
};

export const headerButtonsOptions = [
  { id: 'repairer', label: 'R', value: '1' },
  { id: 'manufacturer', label: 'M', value: '2' },
  { id: 'stream', label: 'S', value: '3' },
  { id: 'details', label: 'D', value: '4' },
];