import { SLAPerformance } from "api/resources/models/AutoGenerated";

export const sortReportData = (reportData: SLAPerformance[] | undefined) => {
    return reportData?.sort((a, b) => {
      const first = a.slaTitle.toUpperCase();
      const second = b.slaTitle.toUpperCase();
      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    })
      }
  