import styled from '@emotion/styled';
import MatButton, { ButtonProps } from '@mui/material/Button';
import { forwardRef, useEffect } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Text } from 'components/Typography';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Container } from './Layout';
import { MobileContainer } from 'core/Theming/Device';
import { usePageData } from 'core/PageData';

export interface Props {
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: React.MouseEventHandler<HTMLButtonElement> | VoidFunction;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  children?: React.ReactNode;
}

const MaterialButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }, ref) => (
    <MatButton ref={ref} disableFocusRipple {...props}></MatButton>
  )
);

MaterialButton.displayName = 'MaterialButton';

export function Button({
  variant = 'contained',
  onClick,
  children,
  color,
}: Props) {
  return (
    <MaterialButton variant={variant} onClick={onClick} color={color}>
      {children}
    </MaterialButton>
  );
}

export const PrimaryButton = styled(MaterialButton)`
  color: ${({ theme }) => theme.palette.primary.light};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.contact.button};
  border-radius: ${({ theme }) => theme.border.radius};
  cursor: pointer;
  text-transform: capitalize;
  :hover {
    background-color: ${({ theme }) => theme.palette.button.hover};
  }
  :active {
    background-color: ${({ theme }) => theme.palette.button.active};
    color: ${({ theme }) => theme.palette.button.activeText};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.palette.button.disabled};
    color: ${({ theme }) => theme.palette.primary.disabled};
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const SecondaryButton = styled(MaterialButton)`
  background: ${({ theme }) => theme.palette.button.light};
  border: ${({ theme }) => `2px solid ${theme.palette.primary.light}`};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.border.radius};
  text-transform: capitalize;

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.active};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.button.active};
    color: ${({ theme }) => theme.palette.button.activeText};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.button.disabled};
    color: ${({ theme }) => theme.palette.primary.disabled};
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const ImpersonatingButton = styled(MaterialButton)`
  color: ${({ theme }) => theme.palette.primary.light};
  align-items: center;
  justify-content: center;
  background-color: rgb(248, 68, 68);
  border-radius: ${({ theme }) => theme.border.radius};
  cursor: pointer;
  text-transform: capitalize;
  font-size: 14px;
  padding: 5px;
  margin-left: 15px;
  :hover {
    background-color: ${({ theme }) => theme.palette.messages.error};
  }
  :active {
    background-color: rgb(248, 68, 68);
    color: ${({ theme }) => theme.palette.button.activeText};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.palette.button.disabled};
    color: ${({ theme }) => theme.palette.primary.disabled};
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const UserMenuButton = styled(MaterialButton)`
  text-transform: capitalize;
  background-color: transparent;
`;

const StyledDiv = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  & svg {
    margin-right: ${({ theme }) => theme.margin.xs};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-top: ${({ theme }) => theme.margin.m};
  }
`;

const StyledCreateButton = styled(PrimaryButton)`
  min-width: 0;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const MobileBottomContainer = styled(Container)`
  position: fixed;
  padding: 12px 15px 12px 87px;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-left: 15px;
  }
`;

export function BackButton({ text }: { text: string }) {
  const { setPageData } = usePageData();
  const { goBack, location } = useHistory();
  const theme = useTheme();

  useEffect(() => {
    return () =>
      setPageData({
        previousPathName: location.pathname,
        previousSearch: location.search,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledDiv onClick={goBack}>
      <KeyboardBackspaceIcon htmlColor={theme.palette.background.dark} />
      <Text fontSize="xs">{text}</Text>
    </StyledDiv>
  );
}

// export function ErrorBackButton({ text, errorHandler }: { text: string, errorHandler: () => void }) {
//   const theme = useTheme();
//   return (
//     <StyledDiv onClick={errorHandler}>
//       <KeyboardBackspaceIcon htmlColor={theme.palette.background.dark} />
//       <Text fontSize="xs">{text}</Text>
//     </StyledDiv>
//   );
// }

export default function MobileBottomButton({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <MobileContainer>
      <MobileBottomContainer>{children}</MobileBottomContainer>
    </MobileContainer>
  );
}

export function CreateButton({ text, route }: { text: string; route: string }) {
  const history = useHistory();
  return (
    <Container justify="flex-end">
      <StyledCreateButton type="button" onClick={() => history.push(route)}>
        <Add />
        {text}
      </StyledCreateButton>
    </Container>
  );
}
