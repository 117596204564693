import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { convertToDate, useFeedbackReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { ReportProps } from '../hooks';
import {
  FEEDBACK_REPORT_GRID_TITLE,
  FEEDBACK_REPORT_MESSAGE,
  FEEDBACK_REPORT_TITLE,
  columns,
  dateColumnsName,
  tableColumnExtensions,
} from './constants';
import { AxiosError } from 'axios';
import { createReportURL } from 'core/routes';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportSearchForm } from 'components/ReportForms/ReportSearchForm';
import { customizeCell, valuesConverter } from './helpers';
import { reportLogger } from '../reportLogger';

export function FeedbackReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    startDate: urlParams.get('startDate'),
    endDate: urlParams.get('endDate'),
  };
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) ?? null
  );

  const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate;

  const { isReportFetching, refetchReport, reportData } = useFeedbackReport(
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    queryEnabled,
    handleError
  );

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const isBusy = isReportFetching;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsValue.startDate, urlParamsValue.endDate]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={FEEDBACK_REPORT_TITLE}
          infoMessage={FEEDBACK_REPORT_MESSAGE}
        />
        <ReportSearchForm
          endDate={endDate}
          isDisabled={isBusy}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleSubmit={handleSubmit}
        />
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          dateColumnsName={dateColumnsName}
          title={FEEDBACK_REPORT_GRID_TITLE}
          customizeCellExport={customizeCell}
          DateTypeProvider={DateTypeProvider}
          tableColumnExtensions={tableColumnExtensions}
          headerTitleForSheet={FEEDBACK_REPORT_TITLE}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.endDate == dateToString(endDate)
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate)
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
          },
          reportPath
        )
      );
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('ActiveWeb Feedback Report'));
    setToast(true);
  }
}
