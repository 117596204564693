import { Column } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import dayjs from 'dayjs';
import {
  useGetMeasureFuture30report,
  useGetMeasureRolling30Report,
  useGetRepairerGroup,
  useGetRepairerByGroupName,
  useGetStreams,
  convertToDate,
} from 'pages/hooks';
import { FormEvent, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate, formatDateTime } from 'utils/helpers';
import { Workbook } from 'exceljs';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import { ReportsLoader } from 'components/ReportsLoader';
import { SelectOption } from 'components/InputSelect';
import {
  RepairerMetaData,
  WorkProvider,
} from 'api/resources/models/AutoGenerated';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import {
  rollingTableColumnExtensions,
  tableColumnExtensions,
  REPAIRER_CAPACITY_REPORT,
  REPAIRER_CAPACITY_REPORT_GRID_HEADER,
  REPAIRER_CAPACITY_REPORT_GRID_TITLE,
  REPAIRER_CAPACITY_REPORT_MESSAGE,
} from './constants';
import { ReportFormRepairerCapacityReport } from 'components/ReportForms/ReportFormRepairerCapacityReport';
import { handleNewSheet } from './helper';
import { reportLogger } from '../reportLogger';

export function Rolling30DayReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const urlParams = {
    runDate: urlSearchParams.get('runDate'),
    repairGroup: urlSearchParams.get('repairGroup'),
    siteCode: urlSearchParams.get('siteCode'),
    streams: urlSearchParams.get('streams'),
  };

  // states
  const [runDate, setRunDate] = useState<Date | null>(
    convertToDate(urlParams?.runDate) ?? null
  );
  const [repairerGroup, setRepairerGroup] = useState<string | null>(
    urlParams.repairGroup ?? null
  );
  const [siteCode, setSiteCode] = useState<SelectOption[]>([]);
  const [streams, setStreams] = useState<SelectOption[]>([]);

  const queryEnabled =
    !!urlParams.runDate &&
    !!urlParams.repairGroup &&
    !!urlParams.streams &&
    !!urlParams.siteCode;

  //apis
  const { repairerGroupList, isRepairerGroupListLoading } = useGetRepairerGroup(
    (error) => handleError(error, 'isRepairerGroup')
  );
  const { streamsList, isStramsLoading } = useGetStreams(
    (error) => handleError(error, 'isStreamsList'),
    (list: WorkProvider[]) =>
      setStreams(
        list?.map((item, index) => {
          return {
            id: index,
            value: item.wpMasterId.toString(),
            text: item.wpDescription,
          };
        })
      )
  );
  const { siteCodes, isSiteCodesLoading } = useGetRepairerByGroupName(
    repairerGroup ?? '',
    repairerGroup !== null,
    (error) => handleError(error, 'isSiteCodes'),
    (list: RepairerMetaData[]) => {
      setSiteCode(
        list?.map((item, index) => {
          return {
            id: index,
            value: item.formalSiteCode,
            text: item.repairerName,
          };
        })
      );
    }
  );
  const { measureRolling30ReportData, isRollingReportFetching, refetchReport } =
    useGetMeasureRolling30Report(
      {
        runDate: urlParams.runDate,
        repairerGroup: urlParams.repairGroup,
        siteCodes: urlParams.siteCode,
        wpMasterIds: urlParams.streams,
        rollingMeasureCodes: '2,3,4,5',
      },
      queryEnabled,
      (error: AxiosError) => handleError(error, 'isReport')
    );
  const {
    measureFuture30ReportData,
    isReportFetching,
    refetchMeasureFuture30ReportData,
  } = useGetMeasureFuture30report(
    {
      runDate: urlParams.runDate,
      repairerGroup: urlParams.repairGroup,
      siteCodes: urlParams.siteCode,
      wpMasterIds: urlParams.streams,
      rollingMeasureCodes: '6,7',
    },
    queryEnabled,
    (error: AxiosError) => handleError(error, 'isReport')
  );

  //Loader
  const isBusy = isReportFetching || isRollingReportFetching;

  const isOptionsLoading =
    isRepairerGroupListLoading || isSiteCodesLoading || isStramsLoading;

  //Select options
  const repairerGroupOptions = repairerGroupList?.map((item, index) => {
    return { id: index, value: item.groupName, text: item.groupName };
  });
  const siteCodesOptions = siteCodes?.map((item, index) => {
    return { id: index, value: item.formalSiteCode, text: item.repairerName };
  });
  const streamsOptions = streamsList?.map((item, index) => {
    return {
      id: index,
      value: item.wpMasterId.toString(),
      text: item.wpDescription,
    };
  });

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParams.runDate,
    urlParams.repairGroup,
    urlParams.siteCode,
    urlParams.streams,
  ]);

  useEffect(() => {
    if (
      siteCode.length == 0 &&
      urlParams.siteCode != siteCode?.map((e) => e.value)?.join(',')
    )
      setSiteCode(
        siteCodesOptions?.filter((e) =>
          urlParams.siteCode?.split(',').includes(e.value)
        ) ?? []
      );
    if (
      streams.length == 0 &&
      urlParams.streams != streams?.map((e) => e.value)?.join(',')
    )
      setStreams(
        streamsOptions?.filter((e) =>
          urlParams.streams?.split(',').includes(e.value)
        ) || []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading]);

  //columns
  const rolling30Dayscolumns = useMemo(() => {
    const columnsList: Column[] = [];
    columnsList.push({ name: 'measureName', title: 'Measure Name' });
    [...Array(32)].forEach((_u, i) => {
      if (runDate)
        columnsList.push({
          name: `c${31 - i}`,
          title: dayjs(new Date(runDate))
            .subtract(31, 'days')
            .add(i, 'day')
            .format('DD MMM'),
        });
    });
    return columnsList;
  }, [runDate]);

  const measureFuture30ReportColumns = useMemo(() => {
    const columnsList: Column[] = [];
    columnsList.push({ name: 'measureName', title: 'Measure Name' });
    [...Array(32)].forEach((_u, i) => {
      if (runDate)
        columnsList.push({
          name: `c${i}`,
          title: dayjs(new Date(runDate)).add(i, 'day').format('DD MMM'),
        });
    });

    return columnsList;
  }, [runDate]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={REPAIRER_CAPACITY_REPORT}
          infoMessage={REPAIRER_CAPACITY_REPORT_MESSAGE}
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
          <ReportFormRepairerCapacityReport
            isBusy={isBusy}
            runDate={runDate}
            streams={streams}
            siteCode={siteCode}
            setRunDate={setRunDate}
            setStreams={setStreams}
            setSiteCode={setSiteCode}
            handleSubmit={handleSubmit}
            repairerGroup={repairerGroup}
            streamsOptions={streamsOptions}
            setRepairerGroup={setRepairerGroup}
            siteCodesOptions={siteCodesOptions}
            repairerGroupOptions={repairerGroupOptions}
          />
        )}
      </UpperInfoContainer>
      {!isReportFetching &&
        measureFuture30ReportData &&
        measureFuture30ReportData?.length === 0 &&
        measureRolling30ReportData &&
        measureRolling30ReportData?.length === 0 && <NoReportDataMessage />}
      <ReportGridContainer direction="column">
        <ReportGrid
          disableFiltering
          isLoading={isBusy}
          columns={rolling30Dayscolumns || []}
          tableColumnExtensions={rollingTableColumnExtensions}
          rows={measureRolling30ReportData}
          DateTypeProvider={DateTypeProvider}
          headerTitleForSheet={REPAIRER_CAPACITY_REPORT_GRID_HEADER}
          title={REPAIRER_CAPACITY_REPORT_GRID_TITLE}
          handleNewSheet={(workbook: Workbook) =>
            handleNewSheet({
              workbook,
              measureFuture30ReportData,
              measureFuture30ReportColumns,
            })
          }
          reportHeaderTagLine={`Report produced on ${formatDate(new Date())}`}
          disabledSorting
          disabledGrouping
          disableDragAndDrop
          disabledTableSelection
        />
      </ReportGridContainer>
      <ReportGridContainer direction="column">
        <ReportGrid
          disableFiltering
          isLoading={isBusy}
          columns={measureFuture30ReportColumns || []}
          tableColumnExtensions={tableColumnExtensions}
          rows={measureFuture30ReportData}
          DateTypeProvider={DateTypeProvider}
          headerTitleForSheet={REPAIRER_CAPACITY_REPORT_GRID_HEADER}
          title={REPAIRER_CAPACITY_REPORT_GRID_TITLE}
          reportHeaderTagLine={`Report produced on ${formatDateTime(
            new Date()
          )}`}
          disabledSorting
          disabledGrouping
          hideExportPanel
          disableDragAndDrop
          disabledTableSelection
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParams.runDate == dateToString(runDate) &&
      urlParams.repairGroup == repairerGroup &&
      urlParams.siteCode == siteCode?.map((e) => e.value)?.join(',') &&
      urlParams.streams == streams?.map((e) => e.value)?.join(',')
    ) {
      refetchReport();
      refetchMeasureFuture30ReportData();
    } else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (
      !!runDate &&
      !!repairerGroup &&
      siteCode?.length > 0 &&
      streams?.length > 0
    ) {
      history.replace(
        createReportURL(
          {
            runDate: dateToString(runDate),
            repairGroup: repairerGroup,
            siteCode: siteCode?.map((e) => e.value)?.join(','),
            streams: streams?.map((e) => e.value)?.join(','),
          },
          reportPath
        )
      );
    }
  }

  function handleError(
    error: AxiosError,
    type: 'isRepairerGroup' | 'isStreamsList' | 'isSiteCodes' | 'isReport'
  ) {
    reportLogger(error);
    let message = errorMessages.reports('Capacity Report');
    if (type == 'isRepairerGroup') message = errorMessages.repairersList;
    else if (type == 'isStreamsList') message = errorMessages.streamsList;
    else if (type == 'isSiteCodes') message = errorMessages.siteCodes;
    setErrorMessage(message);
    setToast(true);
  }
}

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  margin-bottom: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
