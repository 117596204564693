import { ExportPanel } from '@devexpress/dx-react-grid-material-ui';
import { ExportMenuAdditionalProps } from '../types';

export const ExportMenu = ({
  showAdditionalOptions, 
  onPDFExport,
  exportToCSV,
  hideExportToCSV,
  children,
  ...restProps
}: ExportPanel.MenuProps & ExportMenuAdditionalProps) => {
  return (
    <ExportPanel.Menu {...restProps}>
      {children}
      {!hideExportToCSV ? <ExportPanel.MenuItem
        text="Export all data (CSV)"
        onClick={() => exportToCSV()}
      /> : null}
      {showAdditionalOptions ? <ExportPanel.MenuItem
        text="Export all data (PDF)"
        onClick={() => onPDFExport()}
      /> : null}
    </ExportPanel.Menu>
  );
};
