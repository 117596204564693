import { getApiForm } from './form';
import { AxiosError } from 'axios';
import PrimaryToast from 'components/PrimaryToast';
import { ROUTES } from 'core/routes';
import { useDataAccessProfiles, useUpdateUserAccount } from 'pages/hooks';
import { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { UserDetails } from './UserDetails';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { UserAccount } from './types';

interface Props {
  user: UserAccount;
  toastHandler: () => boolean;
  blockNavigationHandler: (payload: boolean) => void;
}

export interface DataProfile {
  id: number;
  text: string | null;
  value?: string | null;
}

export function UserEdit({
  user,
  toastHandler,
  blockNavigationHandler,
}: Props) {
  const history = useHistory();
  const { dataAccessProfiles, isDataAccessProfilesLoading } =
    useDataAccessProfiles({ companyId: user?.companyId, enabled: true });
  const { updateUserAccount, isUpdating } = useUpdateUserAccount();
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  return (
    <>
      <UserDetails
        user={user}
        onSubmit={submit}
        toastHandler={toastHandler}
        dataAccessProfiles={dataAccessProfiles}
        blockNavigationHandler={blockNavigationHandler}
        dataAccessProfileClick={dataAccessProfileClick}
        isBusy={isDataAccessProfilesLoading || isUpdating}
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </>
  );

  function dataAccessProfileClick(dataProfile: DataProfile) {
    const route = generatePath(ROUTES.dataAccessProfileEdit, {
      id: dataProfile.id,
    });

    history.push(route);
  }

  function submit(data: UserAccount) {
    updateUserAccount(getApiForm({ ...user, ...data }))
      .then(() => {
        blockNavigationHandler(false);
        setToastState({
          message: successMessages.userUpdate,
          isOpen: true,
          severity: 'success',
        });
      })
      .catch((error: AxiosError) => handleError(error));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(_error: AxiosError) {
    setToastState({
      message: errorMessages.userUpdate,
      isOpen: true,
      severity: 'error',
    });
  }
}
