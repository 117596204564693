import styled from '@emotion/styled';
import { Text } from 'components/Typography';
import { LabelProps } from 'pages/ReportsDetails/Covea/types';

export const SpecialMarkerComponent = ({
  name,
  color,
}: {
  [key: string]: unknown;
}) => {
  return (
    <svg
      id={typeof name == 'string' ? name : ''}
      width="25"
      height="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="25"
        height="15"
        fill={typeof color == 'string' ? color : ''}
      />
    </svg>
  );
};

export const SpecialLabelComponent = ({ text }: LabelProps) => {
  return (
    <ChartLegendText noWrap fontSize="s" color="black">
      {text}
    </ChartLegendText>
  );
};

const ChartLegendText = styled(Text)`
  margin-left: 5px;
`;
