import styled from '@emotion/styled';
import { useState } from 'react';
import { Container, GridItem } from 'components/Layout';
import { TextField } from 'components/TextField';
import { Text } from 'components/Typography';
import { InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { Clear } from '@mui/icons-material';
import { PrimaryButton } from 'components/Button';
import {
  useAddWorkProviderFormalCodes,
  useGetAccountprofilesWorkProvider,
  useWorkProviders,
} from 'pages/hooks';
import { useParams } from 'react-router-dom';
import { AccountProfileParams } from 'core/routes';
import { logger } from 'core/logger';
import { GrayBackdrop, Loader } from 'components/Loader';
import PrimaryToast from 'components/PrimaryToast';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export function AccountProfileWorkProviders() {
  const { id } = useParams<AccountProfileParams>();
  const dataAccessProfileId = Number.isNaN(Number.parseInt(id))
    ? 0
    : Number.parseInt(id);
  const [selectedProfile, setSelectedProfile] = useState<SelectOption | null>(
    null
  );
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [isProfilesOpen, setIsProfilesOpen] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const { workProviders } = useWorkProviders();
  const { addcode, isAdding } = useAddWorkProviderFormalCodes();
  const [message, setMessage] = useState<string>('');
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
  const [isErrorOpenToast, setIsErrorOpenToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    accountprofilesWorkProvider,
    isWorkProvidersLoading,
    reFetchWorkProviders,
  } = useGetAccountprofilesWorkProvider(dataAccessProfileId);

  function addClick(profile: SelectOption) {
    setIsProfilesOpen(false);
    addcode({
      accountProfilePermitMasterIds: [
        ...accountprofilesWorkProvider.map((a) => a.accountProfileWPMasterId),
        profile?.id,
      ],
      accountProfileId: Number.parseInt(id),
    })
      .then(() => {
        setIsRefetching(true);
        setMessage(successMessages.workProviderAdded);
        setIsOpenToast(true);
        reFetchWorkProviders().then(() => setIsRefetching(false));
      })
      .catch((err) => {
        logger?.log(err);
        appInsights?.trackException({ error: err, severityLevel: SeverityLevel.Error });
        setErrorMessage(errorMessages.workProviderAdded);
        setIsErrorOpenToast(true);
      });
  }

  function removeClick(profileId: number) {
    addcode({
      accountProfilePermitMasterIds: [
        ...accountprofilesWorkProvider
          .filter((a) => a.accountProfileWPMasterId !== profileId)
          .map((a) => a.accountProfileWPMasterId),
      ],
      accountProfileId: Number.parseInt(id),
    })
      .then(() => {
        setIsRefetching(true);
        setMessage(successMessages.workProviderRemoved);
        setIsOpenToast(true);
        setSelectedProfile(null);
        reFetchWorkProviders().then(() => setIsRefetching(false));
      })
      .catch((err) => {
        setErrorMessage(errorMessages.workProviderRemoved);
        setIsErrorOpenToast(true);
        logger?.log(err);
      });
  }
  const isBusy = isAdding || isRefetching || isWorkProvidersLoading;
  return (
    <>
      <Container>
        <GrayBackdrop open={isBusy}>
          <Loader />
        </GrayBackdrop>
        <InputLable>Add Work Providers</InputLable>
        <InputSelect
          options={workProviders
            .filter(
              (item) =>
                !accountprofilesWorkProvider.find(
                  (u) =>
                    u.accountProfileWPMasterId ===
                    Number.parseInt(item.workProviderId)
                )
            )
            .map((c) => ({
              id: Number.parseInt(c.workProviderId),
              text: c.formalCode ?? '',
              description: c.description ?? '',
              wpId: c.workProviderId,
              value: c.workProviderId,
            }))}
          open={isProfilesOpen}
          value={selectedProfile}
          onChange={() => {
            setSelectedProfile(null);
          }}
          inputValue={searchValue}
          onOpen={() => {
            setIsProfilesOpen(true);
          }}
          onFocus={() => {
            setIsProfilesOpen(true);
          }}
          onInputChange={(val, reason) => {
            if (reason !== 'reset') setSearchValue(val);
          }}
          onBlur={() => setIsProfilesOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(_props, key, option) => (
            <OptionsContainer>
              <GridItem xs={11}>{key.text}</GridItem>
              <GridItem xs={1}>
                <AddButton
                  onClick={() => {
                    addClick(key);
                  }}
                  disabled={option.selected}
                >
                  Add
                </AddButton>
              </GridItem>
            </OptionsContainer>
          )}
        />
        <InputLable>Added Work Providers</InputLable>

        {accountprofilesWorkProvider &&
          accountprofilesWorkProvider.length > 0 &&
          accountprofilesWorkProvider?.map((item) => {
            return (
              <AddedAcountProfile direction="row" key={1} alignItems="center">
                <GridItem md={4} sm={4} xs={2}>
                  <StyledText noWrap fontSize="m">
                    {item.name}
                  </StyledText>
                </GridItem>
                <GridItem md={8} sm={8} xs={10}>
                  <ButtonsContainer justify="flex-end" wrap="nowrap">
                    <GridItem>
                      <></>
                    </GridItem>
                    <GridItem>
                      <TextButton
                        onClick={() =>
                          removeClick(item.accountProfileWPMasterId)
                        }
                        // disabled={user.defaultUserAccountProfileId === p.id}
                      >
                        <StyledClear />
                        <ButtonText
                          //   isDisabled={user.defaultUserAccountProfileId === p.id}
                          fontSize="m"
                        >
                          Remove
                        </ButtonText>
                      </TextButton>
                    </GridItem>
                  </ButtonsContainer>
                </GridItem>
              </AddedAcountProfile>
            );
          })}
      </Container>
      <PrimaryToast
        message={message}
        isOpen={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        severity="success"
      />
      <PrimaryToast
        message={errorMessage}
        isOpen={isErrorOpenToast}
        onClose={() => setIsErrorOpenToast(false)}
        severity="error"
      />
    </>
  );
}

const OptionsContainer = styled(Container)`
  width: 100%;
  padding: 10px 20px 0 10px;
  cursor: default;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.fontSize.s};
`;

const InputLable = styled(Container)`
  margin-top: ${({ theme }) => theme.margin.s};
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.margin.xs};
`;

const AddButton = styled(PrimaryButton)`
  min-width: 49px;
  height: 27px;
  cursor: pointer;
  padding: 5.5px 12px 5.5px 12px;
  font-size: ${({ theme }) => theme.fontSize.s};
`;

const TextButton = styled(Button)`
  background: transparent;
  line-height: 17px;
  border: 0px;
  color: ${({ theme }) => theme.palette.primary.main};
  :hover {
    background: unset;
    color: ${({ theme }) => theme.palette.primary.activeText};
    p {
      color: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
  text-transform: none;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const StyledClear = styled(Clear)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 20px;
  }
`;

const ButtonText = styled(Text)<{ isDisabled?: boolean }>`
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? `${theme.palette.primary.disabled}`
      : `${theme.palette.primary.dark}`};
  margin-left: 10px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 10px;
    margin: 0px;
  }
`;

const AddedAcountProfile = styled(Container)`
  min-height: 40px;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  box-shadow: ${({ theme }) => theme.shadow.s};
  border-radius: ${({ theme }) => theme.border.radius};
  padding-left: 12px;
  margin-bottom: ${({ theme }) => theme.margin.s};
`;

const ButtonsContainer = styled(Container)`
  padding-right: 12px;
`;

const StyledText = styled(Text)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 10px;
  }
`;
