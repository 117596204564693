import { SLAPerformance } from 'api/resources/models/AutoGenerated';

export const getClaimValue = (
  status: string,
  data: SLAPerformance[],
  totalRow: number
) => {
  const totalEolValue = data?.filter((item) => item.eol == status)?.length;
  return ((totalEolValue / totalRow) * 100).toFixed(2);
};
