import { DtlItem } from "api/resources/models/AutoGenerated";
import StatusChip from "components/StatusChip";
import { ModifiedDisplay } from "components/VehiclesTable/fields/Modified";
import { RegistrationNo } from "components/VehiclesTable/fields/RegistrationNo";
import { theme } from "core/Theming/theme";
import { formatDateTime } from "utils/helpers";

export function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof DtlItem;
    row: DtlItem;
  }) {
    const statusColor = theme.palette.status;
  
    if (headerId === 'formalStatus' && row.formalStatus) {
      let color = statusColor.preOnSite;
      if (row.onSite === 1) {
        color = statusColor.onSite;
      } else if (row.delivered === 1) {
        color = statusColor.delivered
      }

      let tooltip = 'Pre On Site';
      if (row.onSite === 1) {
        tooltip = 'On Site'
      } else if (row.delivered === 1) {
        tooltip = 'Delivered'
      }
  
      return (
        <StatusChip
          toolTipMessage={tooltip}
          color={color}
          description={row.formalStatus}
        />
      );
    }
    if (headerId === 'dtgCreated') return <>{formatDateTime(row.dtgCreated)}</>;
    if (headerId === 'dtgUpdated' && row.dtgUpdated)
      return (
        <ModifiedDisplay
          modified={new Date(row.dtgUpdated)}
          delivered={row.delivered === 1 ? new Date() : undefined}
        />
      );
    if (headerId === 'dtgEcd') return <>{formatDateTime(row.dtgEcd)}</>;
    if (headerId === 'vehicleReg')
      return <RegistrationNo number={row.vehicleReg} />;

    return baseRender();
  }