import styled from '@emotion/styled';
import { Container } from './Layout';

export const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
