import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { useLVOWNKey2KeyReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDateTime } from 'utils/helpers';
import { ReportProps } from '../hooks';
import {
  LVOWN_KEY2KEY_REPORT_GRID_TITLE,
  LVOWN_KEY2KEY_REPORT_MESSAGE,
  LVOWN_KEY2KEY_REPORT_TITLE,
  columns,
  selectOptions,
  tableColumnExtensions,
} from './constants';
import { AxiosError } from 'axios';
import { createReportURL } from 'core/routes';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { SelectOption } from 'components/InputSelect';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { ReportFormKey2KeyReport } from 'components/ReportForms/ReportFormKey2Key';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { reportLogger } from '../reportLogger';
import { valuesConverter } from './helpers';

export function LVOWNKey2KeyReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    year: urlParams.get('year'),
  };

  const queryEnabled = !!urlParamsValue.year;

  const { isReportFetching, refetchReport, reportData } = useLVOWNKey2KeyReport(
    urlParamsValue.year,
    queryEnabled,
    handleError
  );

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const [year, setYear] = useState<SelectOption[]>([]);

  const isBusy = isReportFetching;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsValue.year]);

  useEffect(() => {
    if (urlParamsValue.year != year?.map((e) => e.value)?.join(','))
      setYear(
        selectOptions?.filter((e) =>
          urlParamsValue.year?.split(',').includes(e.value)
        ) || []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={LVOWN_KEY2KEY_REPORT_TITLE}
          infoMessage={LVOWN_KEY2KEY_REPORT_MESSAGE}
        />
        <ReportFormKey2KeyReport
          handleSubmit={handleSubmit}
          isBusy={isBusy}
          setYear={setYear}
          year={year}
        />
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          title={LVOWN_KEY2KEY_REPORT_GRID_TITLE}
          tableColumnExtensions={tableColumnExtensions}
          headerTitleForSheet={LVOWN_KEY2KEY_REPORT_TITLE}
          reportHeaderTagLine={`Report produced on ${formatDateTime(
            new Date()
          )} for the year ${year?.map(({ text }) => text)?.join(', ')}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (urlParamsValue.year == year?.map((e) => e.value)?.join(','))
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (year != null)
      history.replace(
        createReportURL(
          { year: year?.map((e) => e.value)?.join(',') },
          reportPath
        )
      );
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('LVOWN Key2Key Report'));
    setToast(true);
  }
}
