export function getPageInfo({
  totalCount,
  itemsPerPage,
  currentPage,
}: {
  totalCount: number;
  itemsPerPage: number;
  currentPage: number;
}): { pageStartIndex: number; pageEndIndex: number } | string {
  if (currentPage < 0) return `page index can't be less than 0`;
  if (itemsPerPage < 1) return `items per page can't be less than 1`;

  const pageStartItem = currentPage * itemsPerPage + 1;
  const currentPageItemsCount = (currentPage + 1) * itemsPerPage;
  const pageItemsCount =
    currentPageItemsCount > totalCount ? totalCount : currentPageItemsCount;
  return { pageStartIndex: pageStartItem, pageEndIndex: pageItemsCount };
}
