import { Column, TableColumnExtensions } from './types';

export const ACTIVEWEB_USAGE_REPORT = 'ActiveWeb Usage Report V2';

export const ACTIVEWEB_USAGE_REPORT_MESSAGE =
  'This report provides usage statistics for all registered ActiveWeb users within your company. It provides filtering for both Active and Inactive accounts.';

export const ACTIVEWEB_USAGE_REPORT_GRID_TITLE = 'activeWebUsageReportV2';

export const dateColumnsName = ['lastused'];

export const selectOptions = [
  { id: 1, text: 'All Accounts', value: '-1' },
  { id: 2, text: 'Live Accounts', value: '0' },
  { id: 3, text: 'Expired Accounts', value: '1' },
];

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const date = new Date();
const previousMonth = month[date.getMonth() == 0 ? 11 : date.getMonth() - 1];
const previousMonthMinusOne =
  month[
    date.getMonth() == 0 ? 10 : date.getMonth() == 1 ? 11 : date.getMonth() - 2
  ];
const previousMonthMinusTwo =
  month[
    date.getMonth() == 0
      ? 9
      : date.getMonth() == 1
      ? 10
      : date.getMonth() == 2
      ? 11
      : date.getMonth() - 3
  ];
const currentYear = date.getFullYear();
const previousYear = date.getFullYear() - 1;

export const columns: Column[] = [
  { name: 'fullName', title: 'Full Name' },
  { name: 'profile', title: 'Profile' },
  { name: 'lastused', title: 'Last Used' },
  {
    name: 'searchesPriorMonthMinus1',
    title: `${previousMonthMinusTwo} ${
      date.getMonth() == 0 || date.getMonth() == 1 || date.getMonth() == 2
        ? previousYear
        : currentYear
    } searches`,
  },
  {
    name: 'searchesPriorMonth',
    title: `${previousMonthMinusOne} ${
      date.getMonth() == 0 || date.getMonth() == 1 ? previousYear : currentYear
    } searches`,
  },
  {
    name: 'searchesThisMonth',
    title: `${previousMonth} ${
      date.getMonth() == 0 ? previousYear : currentYear
    } searches`,
  },
  {
    name: 'ytdSearchCount',
    title: `Searches ${date.getMonth() == 0 ? previousYear : currentYear} YTD`,
  },
  { name: 'feedbackCount', title: 'Feedback Count' },
  {
    name: 'accountLockedDate',
    title: 'Account Locked',
    getCellValue: (row) => {
      return row.accountLockedDate ? 'TRUE' : 'FALSE';
    },
  },
  {
    name: 'disabled',
    title: 'Account Expired',
    getCellValue: (row) => {
      return `${row.disabled?.toString()?.toUpperCase()}`;
    },
  },
];

export const tableColumnExtensions: TableColumnExtensions[] = [
  { columnName: 'fullName', wordWrapEnabled: true },
  { columnName: 'profile', wordWrapEnabled: true },
  { columnName: 'lastused', wordWrapEnabled: true },
  {
    columnName: 'searchesPriorMonthMinus1',
    wordWrapEnabled: true,
  },
  { columnName: 'searchesPriorMonth', wordWrapEnabled: true },
  { columnName: 'searchesThisMonth', wordWrapEnabled: true },
  { columnName: 'ytdSearchCount', wordWrapEnabled: true },
  { columnName: 'feedbackCount', wordWrapEnabled: true },
  { columnName: 'accountLockedDate', wordWrapEnabled: true },
  { columnName: 'disabled', wordWrapEnabled: true },
];
