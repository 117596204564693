export const getPreviousMonthWithYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  const previousMonth = new Date(today);
  previousMonth.setMonth(today.getMonth() - 1);

  const previousMonthYear = previousMonth.getFullYear();

  const previousMonthName = new Date(previousMonth).toLocaleString('en-US', {
    month: 'long',
  });

  if (previousMonthYear === currentYear) {
    return `${previousMonthName} ${currentYear}`;
  } else {
    return `${previousMonthName} ${previousMonthYear}`;
  }
};
