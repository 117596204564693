import { DataTypeProvider } from '@devexpress/dx-react-grid';

export type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

export const tableColumnExtensions = [
  { columnName: 'repairerGroup', wordWrapEnabled: true },
  { columnName: 'repairerName', wordWrapEnabled: true },
  { columnName: 'activeWebId', wordWrapEnabled: true },
  { columnName: 'schemeCode', wordWrapEnabled: true },
  { columnName: 'registration', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'status', wordWrapEnabled: true },
  { columnName: 'dtgCreated', wordWrapEnabled: true },
  { columnName: 'dtgOnsite', wordWrapEnabled: true },
  { columnName: 'dtgAuthorised', wordWrapEnabled: true },
  { columnName: 'dtgPartsOrdered', wordWrapEnabled: true },
  {
    columnName: 'dtgEstimatedCompletion',
    wordWrapEnabled: true,
  },
  { columnName: 'replacementVehicleModel', wordWrapEnabled: true },
  {
    columnName: 'dtgReplacementVehicleKeysOut',
    wordWrapEnabled: true,
  },
  {
    columnName: 'replacementVehicleKeysOut',
    wordWrapEnabled: true,
  },
  { columnName: 'dtgLastDataUpdate', wordWrapEnabled: true },
  { columnName: 'dtgLastStatusChange', wordWrapEnabled: true },
  { columnName: 'dtgLastActivity', wordWrapEnabled: true },
  { columnName: 'lastActivityBy', wordWrapEnabled: true },
  { columnName: 'dtgLatestPdelay', wordWrapEnabled: true },
  { columnName: 'statusAge', wordWrapEnabled: true },
  { columnName: 'dataAge', wordWrapEnabled: true },
  { columnName: 'lastActivityAge', wordWrapEnabled: true },
];

export const columns = [
  { name: 'repairerGroup', title: 'Repairer Group' },
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'activeWebId', title: 'ActiveWeb Id' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'registration', title: 'Registration' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'status', title: 'Status' },
  { name: 'dtgCreated', title: 'Created Date' },
  { name: 'dtgOnsite', title: 'On Site Date' },
  { name: 'dtgAuthorised', title: 'Authorised Date' },
  { name: 'dtgPartsOrdered', title: 'Parts Ordered Date' },
  { name: 'dtgEstimatedCompletion', title: 'Estimated Completion Date' },
  { name: 'replacementVehicleModel', title: 'Replacement Vehicle' },
  {
    name: 'dtgReplacementVehicleKeysOut',
    title: 'Replacement Vehicle Keys Out Date',
  },
  {
    name: 'replacementVehicleKeysOut',
    title: 'Replacement Vehicle Keys Out',
  },
  { name: 'dtgLastDataUpdate', title: 'Last Data Updated' },
  { name: 'dtgLastStatusChange', title: 'Last Status Change' },
  { name: 'dtgLastActivity', title: 'Last Activity Date' },
  { name: 'lastActivityBy', title: 'Last Activity By' },
  { name: 'dtgLatestPdelay', title: 'Latest PDelay' },
  { name: 'statusAge', title: 'Status Age' },
  { name: 'dataAge', title: 'Data Age' },
  { name: 'lastActivityAge', title: 'Last Activity Age' },
];

export const dateColumnsName = [
  'dtgCreated',
  'dtgOnsite',
  'dtgAuthorised',
  'dtgPartsOrdered',
  'dtgEstimatedCompletion',
  'dtgReplacementVehicleKeysOut',
  'dtgLastDataUpdate',
  'dtgLastStatusChange',
  'dtgLastActivity',
  'dtgLatestPdelay',
];
