import { api } from 'api';
import { config } from 'core/config';
import { formatAPIEndDate, formatAPIStartDate } from 'utils/helpers';
import {
  CycleTimeRequestModel,
  CycleTimeResponseModel,
} from './models/AutoGenerated';

const cycleTimeBaseUrl = '/api/cycletime';

export const getCycleTimeNetworkAverage = (
  startDate: string | null,
  endDate: string | null
): Promise<CycleTimeResponseModel> =>
  api
    .get(`${config.activewebApiUrl}${cycleTimeBaseUrl}/networkaverage`, {
      params: {
        startDate: formatAPIStartDate(startDate),
        endDate: formatAPIEndDate(endDate),
      },
    })
    .then((response) => response.data);

export const getCycleTimeSummaryDetails = (
  params: Partial<CycleTimeRequestModel>
): Promise<CycleTimeResponseModel[]> =>
  api
    .post(`${config.activewebApiUrl}${cycleTimeBaseUrl}/summarydetails`, params)
    .then((response) => response.data);
