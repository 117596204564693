import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { Theme } from 'core/Theming/theme';

type FontSize = keyof Theme['fontSize'];
type align = "right" | "left" | "inherit" | "center" | "justify" | undefined

interface Props {
  children?: ReactNode;
  align?: align;
  className?: string;
  color?: string;
  fontSize?: FontSize;
  fontWeight?: string;
  noWrap?: boolean;
}

const StyledHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: 700;
  font-family: 'Inter', sans-serif;
`;

export const Heading = ({ children, align }: Props) => (
  <StyledHeading variant="h1" align={align}>
    {children}
  </StyledHeading>
);

export const ModalTitle = ({ children }: Props) => {
  return (
    <StyledText fontSize="l" fontWeight="bold">
      {children}
    </StyledText>
  );
};

const StyledSubheader = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: 700;
  font-family: 'Inter', sans-serif;
`;

export const Subheading = ({ children, align, className }: Props) => (
  <StyledSubheader variant="h2" align={align} className={className}>
    {children}
  </StyledSubheader>
);

const StyledText = styled(Typography) <{
  fontcolor?: string;
  fontSize?: FontSize;
  fontWeight?: string;
}>`
  color: ${({ theme, fontcolor }) => fontcolor ?? theme.palette.primary.main};
  font-size: ${({ theme, fontSize = 's' }) => theme.fontSize[fontSize as FontSize]};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
`;

export const ErrorText = styled(StyledText)`
  color: ${({ theme }) => theme.palette.messages.error};
`;

export const Text = ({
  children,
  align,
  color,
  fontSize,
  fontWeight,
  className,
  noWrap,
}: Props) => (
  <StyledText
    variant="body1"
    align={align}
    fontcolor={color}
    fontSize={fontSize}
    fontWeight={fontWeight}
    className={className}
    noWrap={noWrap}
  >
    {children}
  </StyledText>
);

const StyledSecondaryText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: 'Inter', sans-serif;
`;

export const SecondaryText = ({ children, align }: Props) => (
  <StyledSecondaryText variant="body1" align={align}>
    {children}
  </StyledSecondaryText>
);

const StyledSmallMainText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: 'Inter', sans-serif;
`;

export const SmallMainText = ({ children, align }: Props) => (
  <StyledSmallMainText variant="body1" align={align}>
    {children}
  </StyledSmallMainText>
);
