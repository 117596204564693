import { Permit } from 'api/resources/models/AutoGenerated';
import { VehicleTableHeader } from './types';

export const VEHICLES_SEARCH_TEST_ID = 'vehicles-search-table';

const additionalVehicleHeaders: VehicleTableHeader[] = [
    { id: 'k2KDuration', label: 'K2K', permitCode: Permit.FLV_K2K_DURATION },
    { id: 'totalDuration', label: 'Life Cycle', permitCode: Permit.FLV_TOTAL_DURATION },
]

export const basicVehicleTableHeaders: VehicleTableHeader[] = [
    { id: 'repairCode', label: 'Repair Code' },
    { id: 'claimReference', label: 'Claim Ref.' },
    {
      id: 'vehicleReg',
      label: 'Vehicle Reg.',
      permitCode: Permit.FLV_VEHICLE,
    },
    {
      id: 'schemeCode',
      label: 'Stream',
      permitCode: Permit.FLV_STREAM,
    },
    {
      id: 'formalStatus',
      label: 'Status',
      // permitCode: Permit.FLV_FORMAL_STATUS,
    },
    {
      id: 'dtgCreated',
      label: 'Created',
      permitCode: Permit.FLV_CREATED_DATE,
    },
    { id: 'dtgUpdated', label: 'Last Updated' },
    { id: 'workInProgressCode', label: 'Work in Progress Code' },
    {
      id: 'replacementVehicleStatus',
      label: 'Replacement Vehicle',
      permitCode: Permit.FLV_CCAR,
    },
    {
      id: 'dtgEcd',
      label: 'E.C.D.',
      permitCode: Permit.FLV_ECD,
    },
    {
      id: 'diaryDate',
      label: 'Diary Date',
      permitCode: Permit.FLV_DIARY_DATE,
    },
    {
      id: 'dtgCpd',
      label: 'C.P.D.',
      permitCode: Permit.FLV_CPD,
    },
    { id: 'dtgCompleted', label: 'Completed' },
    { id: 'dtgDelivered', label: 'Delivered' },
    {
      id: 'vehicleMake',
      label: 'Manufacturer',
      permitCode: Permit.FLV_VEHICLE,
    },
    { id: 'vehicleModel', label: 'Model', permitCode: Permit.FLV_VEHICLE },
    {
      id: 'repairerGroup',
      label: 'Group',
      permitCode: Permit.FLV_REPAIRER_GROUP,
    },
  ];

export const vehicleInProgressHeaders: VehicleTableHeader[] = [
    ...basicVehicleTableHeaders,
    ...additionalVehicleHeaders,
]