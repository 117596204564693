import dayjs, { Dayjs } from 'dayjs';
import { GridDateStatusIcon } from './DateStatusIcon';
import { DateStatus } from './types';
import { SlaFieldContainer } from './SlaFieldContainer';
import { formatDateTime } from 'utils/helpers';

interface Props {
  cpd?: Date;
  completed?: Date;
}

export function CpdDisplay({ cpd, completed }: Props) {
  const now = dayjs();
  const cpdDate: Dayjs | undefined = cpd ? dayjs(cpd) : undefined;
  const dtgDeliveredDate: Dayjs | undefined = completed
    ? dayjs(completed)
    : undefined;
  const status = getCpdGridStatus(now, cpdDate, dtgDeliveredDate);

  return (
    <SlaFieldContainer>
      <GridDateStatusIcon status={status} />
      {formatDateTime(cpd)}
    </SlaFieldContainer>
  );
}

export function getCpdGridStatus(
  now: Dayjs,
  cpd?: Dayjs,
  completed?: Dayjs
): DateStatus {
  if (!cpd && !completed) return 'grey';
  if (cpd) {
    if (cpd > now && !completed) return 'grey';
    if (cpd < now && !completed) return 'amber';
  }
  if (!cpd && completed) return 'amber';
  if (cpd && completed) {
    const isSameDate = dayjs(cpd).isSame(dayjs(completed), 'date');
    if (isSameDate) return 'grey';
    if (!isSameDate) return 'grey';
  }
  return 'unknown';
}

export function getCpdDetailsStatus(
  now: Dayjs,
  cpd?: Dayjs,
  completed?: Dayjs
): DateStatus {
  if (!cpd && !completed) return 'grey';
  if (cpd) {
    if (cpd > now && !completed) return 'green';
    if (cpd < now && !completed) return 'amber';
  }
  if (!cpd && completed) return 'amber';
  if (cpd && completed) {
    const isSameDate = dayjs(cpd).isSame(dayjs(completed), 'date');
    if (isSameDate) return 'green';
    if (!isSameDate) return 'green';
  }
  return 'unknown';
}
