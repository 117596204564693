import {
  Row,
  Column,
  GridColumnExtension,
} from '@devexpress/dx-react-grid';
import {
  MainPageContainer,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import {
  convertToDate,
  useNpsSummaryRepairGroups,
  useNPSSummaryReportV4,
  useNpsSummarySchemeCodes,
} from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  dateToString,
  formatDate,
  formatDateTime,
} from 'utils/helpers';
import {
  RepairerMetaData,
  WorkProviderScheme,
} from 'api/resources/models/AutoGenerated';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import { ReportsLoader } from 'components/ReportsLoader';
import { SelectOption } from 'components/InputSelect';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { NPS_SUMMARY_REPORT, NPS_SUMMARY_REPORT_MESSAGE, dateColumnsName, defaultColumnBands, defaultColumnExtensions, defaultColumns, summaryItems } from './constants';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { CellComponent, SummaryItemComponent, customizeCell, customizeSummaryCell, reportGroupingOptions, summaryCalculator, valuesConverter } from './helpers';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { NPSSummarySearchForm } from 'components/ReportForms/NPSSummarySearchForm';
import { reportLogger } from '../reportLogger';

export function NPSSummaryReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    endDate: urlParams.get('endDate'),
    schemeCodes: urlParams.get('schemeCodes'),
    noOfDateGroups: urlParams.get('noOfDateGroups'),
    repairSites: urlParams.get('repairSites'),
    reportingGroup: urlParams.get('reportingGroup'),
  };

  const queryEnabled =
    !!urlParamsValue.endDate &&
    !!urlParamsValue.repairSites &&
    !!urlParamsValue.schemeCodes &&
    !!urlParamsValue.noOfDateGroups &&
    !!urlParamsValue.reportingGroup;

  const { repairerSites, isRepairSitesLoading } = useNpsSummaryRepairGroups(
    (error) => handleError(error, 'isRepairSites')
  );
  const { siteCodes, isSiteCodesLoading } = useNpsSummarySchemeCodes((error) =>
    handleError(error, 'isSiteCodes')
  );
  const { reportData, refetchReport, isReportFetching } = useNPSSummaryReportV4(
    {
      endDate: urlParamsValue.endDate,
      repairSites: urlParamsValue.repairSites,
      schemeCodes: urlParamsValue.schemeCodes,
      noOfDateGroups: urlParamsValue.noOfDateGroups,
      reportingGroup: urlParamsValue.reportingGroup,
    },
    queryEnabled,
    (error) => handleError(error, 'isReport')
  );

  const nestedColumns = useMemo(() => {
    const additionalColumns: Column[] = [];
    const additionalTableColumns: GridColumnExtension[] = [];
    const columnBandsChildren: { columnName: string }[] = [];
    reportData?.map((data) => {
      data.monthYearGroupings?.map((e) => {
        if (!additionalColumns.find((a) => a.name == e.name)) {
          additionalColumns.push({
            name: e.name,
            title: e.name,
          });
          additionalTableColumns.push({
            columnName: e.name,
            wordWrapEnabled: true,
            width: 'auto',
          });
          columnBandsChildren.push({ columnName: e.name });
        }
      });
    });
    return {
      additionalColumns,
      additionalTableColumns,
      columnBands: [
        {
          title: 'Month/Year',
          children: columnBandsChildren,
        },
        ...defaultColumnBands,
      ],
    };
  }, [reportData]);

  const rows = useMemo<Row>(
    () =>
      reportData?.map((data) => {
        let prevData = { ...data };
        prevData.monthYearGroupings?.map((e) => {
          prevData = { ...prevData, [e.name]: e.value };
        });
        return prevData;
      }),
    [reportData]
  );
  const [columns, setColumns] = useState<Column[]>();

  useEffect(() => {
    setColumns([
      ...defaultColumns,
      ...nestedColumns.additionalColumns,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const [tableColumnExtensions] = useState([
    ...defaultColumnExtensions,
    ...nestedColumns.additionalTableColumns,
  ]);

  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) || null
  );
  const [repairSites, setRepairSites] = useState<SelectOption[]>([]);
  const [schemeCodes, setSchemeCodes] = useState<SelectOption[]>([]);
  const [noOfDateGroups, setNoOfDateGroups] = useState<number | null>(
    Number(urlParamsValue.noOfDateGroups) || null
  );
  const [reportingGroup, setReportingGroup] = useState<string | null>(
    urlParamsValue.reportingGroup || null
  );
  const repairSiteOptions = useMemo(
    () =>
      repairerSites?.map((e: RepairerMetaData, i: number) => {
        return {
          id: i,
          value: e.siteCode || '',
          text: e.repairerName || '',
        };
      }),
    [repairerSites]
  );
  const siteCodeOptions = siteCodes?.map((e: WorkProviderScheme, i: number) => {
    return {
      id: i,
      value: e.schemeCode || '',
      text: e.schemeCode || '',
    };
  });

  const isOptionsLoading = isRepairSitesLoading || isSiteCodesLoading;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.endDate,
    urlParamsValue.repairSites,
    urlParamsValue.schemeCodes,
    urlParamsValue.noOfDateGroups,
    urlParamsValue.reportingGroup,
  ]);

  useEffect(() => {
    if (
      urlParamsValue.repairSites != repairSites?.map((e) => e.value)?.join(',')
    )
      setRepairSites(
        repairSiteOptions?.filter((e) =>
          urlParamsValue.repairSites?.split(',').includes(e.value)
        ) || []
      );
    if (
      urlParamsValue.schemeCodes != repairSites?.map((e) => e.value)?.join(',')
    )
      setSchemeCodes(
        siteCodeOptions?.filter((e) =>
          urlParamsValue.schemeCodes?.split(',').includes(e.value)
        ) || []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading]);

  return (
    <MainPageContainer isLoading={isReportFetching} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={NPS_SUMMARY_REPORT}
          infoMessage={NPS_SUMMARY_REPORT_MESSAGE}
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
            <NPSSummarySearchForm
              handleSubmit={handleSubmit}
              endDate={endDate}
              setEndDate={setEndDate}
              repairSites={repairSites}
              setRepairSites={setRepairSites}
              schemeCodes={schemeCodes}
              setSchemeCodes={setSchemeCodes}
              noOfDateGroups={noOfDateGroups}
              setNoOfDateGroups={setNoOfDateGroups}
              reportGroupingOptions={reportGroupingOptions}
              repairSiteOptions={repairSiteOptions}
              siteCodeOptions={siteCodeOptions}
              reportingGroup={reportingGroup}
              setReportingGroup={setReportingGroup}
              isDisabled={isReportFetching}
            />
        )}
      </UpperInfoContainer>

      {!isReportFetching && rows?.length === 0 && (
        <NoReportDataMessage />
      )}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          isLoading={isReportFetching}
          disabledGrouping
          disableFiltering
          disableDragAndDrop
          columns={columns || []}
          // disableExportSummary
          title="npsSummaryReportV4"
          summaryItems={summaryItems}
          CustomCellComponent={CellComponent}
          customizeCellExport={customizeCell}
          DateTypeProvider={DateTypeProvider}
          summaryCalculator={summaryCalculator}
          customizeSummaryCell={customizeSummaryCell}
          SummaryItemComponent={SummaryItemComponent}
          columnBandsName={nestedColumns.columnBands}
          dateColumnsName={dateColumnsName}
          tableColumnExtensions={tableColumnExtensions}
          headerTitleForSheet="NPS Summary Report V4"
          reportHeaderTagLine={`Report produced on ${formatDateTime(
            new Date()
          )} for ${formatDate(endDate)}`}
          hideExportToCSV
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.repairSites ==
        repairSites?.map((e) => e.value)?.join(',') &&
      urlParamsValue.schemeCodes ==
        schemeCodes?.map((e) => e.value)?.join(',') &&
      urlParamsValue.noOfDateGroups == noOfDateGroups?.toString() &&
      urlParamsValue.reportingGroup == reportingGroup
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (
      !!endDate &&
      repairSites?.length > 0 &&
      !!schemeCodes &&
      !!noOfDateGroups &&
      !!reportingGroup
    )
      history.replace(
        createReportURL(
          {
            endDate: dateToString(endDate),
            repairSites: repairSites?.map((e) => e.value)?.join(','),
            schemeCodes: schemeCodes?.map((e) => e.value)?.join(','),
            noOfDateGroups: noOfDateGroups?.toString(),
            reportingGroup: reportingGroup,
          },
          reportPath
        )
      );
    else return;
  }

  function handleError(
    error: AxiosError,
    type: 'isReport' | 'isRepairSites' | 'isSiteCodes'
  ) {
    let errorMessage; 
    switch (type) {
      case 'isRepairSites':
        errorMessage = errorMessages.repairSites;
        break;
      case 'isSiteCodes':
        errorMessage = errorMessages.schemeCodes;
        break;
      default:
        errorMessage = errorMessages.reports('NPS Summary Report V4');
    }

    setErrorMessage(errorMessage);
    setToast(true);
    reportLogger(error);
  }
}