import { Container, GridItem } from 'components/Layout';
import { Subheading } from 'components/Typography';
import { pagePaddingLeft } from 'components/PageLayout'
import styled from '@emotion/styled';
import InfoText from 'components/InfoText';
import { DataGrid } from 'components/DataGrid';
import { CycleTimeTableProps } from './types';


export function CycleTimeTable<T>({
    title,
    noDataMessage,
    headers,
    data,
    isLoading,
    children,
    customCellRender,
    currentPage,
    onPageChange,
    onItemsPerPageChange,
    totalCount,
    onSort,
    sortObject,
}: CycleTimeTableProps<T>) {
    return (
        <StyledContainer direction="column">
            <SubheadingGridItem>
                <Subheading>{title}</Subheading>
            </SubheadingGridItem>
            {data?.length === 0 && <InfoText>{noDataMessage}</InfoText>}
            {children}
            <DataGrid
                isLoading={isLoading}
                headers={headers}
                data={data}
                customCellRender={customCellRender}
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalCount={totalCount}
                onItemsPerPageChange={onItemsPerPageChange}
                onSort={onSort}
                sortObject={sortObject}
            />
        </StyledContainer>
    );
}

const SubheadingGridItem = styled(GridItem)`
  margin-left: ${({ theme }) => pagePaddingLeft(theme)};
`;

export const StyledContainer = styled(Container)`
  margin: ${({ theme }) => `${theme.margin.xl} 0`};
`