import { Cell as ExcelCell } from 'exceljs';

export function customizeCell(cell: ExcelCell) {
    const { workbook } = cell;
    const workSheet = workbook.getWorksheet('Main');
    Object.assign(workSheet.getRow(3), {
      font: { bold: true },
      alignment: {
        wrapText: true,
        vertical: 'middle',
      },
    });
  }
