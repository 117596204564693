import { Column, Row } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { dateColumnsName } from './constants';
import { customizeDateColumns } from '../helpers';
import { formatDateTime } from 'utils/helpers';

export const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
  customizeDateColumns(cell, row, column, dateColumnsName)
};

export const valuesConverter = (data: Row[]) => {
  return data.map((row) => {
    const formattedRow = { ...row };
    dateColumnsName.forEach((columnName) => {
      if (row[columnName]) {
        formattedRow[columnName] = formatDateTime(row[columnName]);
      }
    });
    return formattedRow;
  });
};