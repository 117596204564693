import { Column } from '@devexpress/dx-react-grid';

export const NPS_SUMMARY_REPORT = 'NPS Summary Report V4';
export const NPS_SUMMARY_REPORT_MESSAGE =
  'Displays the NPS Summary Report V4 for the date range provided';

export const defaultColumnExtensions = [
  { columnName: 'siteName', wordWrapEnabled: true, width: '20%' },
  { columnName: 'totalRows', wordWrapEnabled: true, width: 'auto' },
  { columnName: 'totalResponses', wordWrapEnabled: true, width: 'auto' },
  {
    columnName: 'totalResponsePercentage',
    wordWrapEnabled: true,
    width: 'auto',
  },
  { columnName: 'detractor', wordWrapEnabled: true, width: 'auto' },
  {
    columnName: 'detractorPercentage',
    wordWrapEnabled: true,
    width: 'auto',
  },
  { columnName: 'passive', wordWrapEnabled: true, width: 'auto' },
  {
    columnName: 'passivePercentage',
    wordWrapEnabled: true,
    width: 'auto',
  },
  { columnName: 'promotor', wordWrapEnabled: true, width: 'auto' },
  {
    columnName: 'promotorPercentage',
    wordWrapEnabled: true,
    width: 'auto',
  },
  { columnName: 'npsScore', wordWrapEnabled: true, width: 'auto' },
];

export const summaryItems = [
  { columnName: 'siteName', type: 'grand_total' },
  { columnName: 'totalRows', type: 'sum' },
  { columnName: 'totalResponses', type: 'sum' },
  { columnName: 'totalResponsesPercentage', type: 'percentage_1' },
  { columnName: 'detractor', type: 'sum' },
  { columnName: 'detractorPercentage', type: 'percentage_2' },
  { columnName: 'passive', type: 'sum' },
  { columnName: 'passivePercentage', type: 'percentage_3' },
  { columnName: 'promotor', type: 'sum' },
  { columnName: 'promotorPercentage', type: 'percentage_4' },
  { columnName: 'npsScore', type: 'score' },
];

export const defaultColumnBands = [
  {
    title: 'Total Response',
    children: [
      { columnName: 'totalResponses' },
      { columnName: 'totalResponsesPercentage' },
    ],
  },
  {
    title: 'Detractor',
    children: [
      { columnName: 'detractor' },
      { columnName: 'detractorPercentage' },
    ],
  },
  {
    title: 'Passive',
    children: [{ columnName: 'passive' }, { columnName: 'passivePercentage' }],
  },
  {
    title: 'Promotor',
    children: [
      { columnName: 'promotor' },
      { columnName: 'promotorPercentage' },
    ],
  },
];

export const defaultColumns: Column[] = [
  { name: 'siteName', title: 'Repairer Name' },
  { name: 'totalRows', title: 'Total Surveys' },
  {
    name: 'totalResponses',
    title: 'Count',
  },
  {
    name: 'totalResponsesPercentage',
    title: '%',
    getCellValue: (row) => {
      let percentage = '0.00';
      if (row.totalRows != 0 && row.totalRows != null) {
        percentage = ((row.totalResponses / row.totalRows) * 100).toFixed(2);
      }
      return `${percentage}%`;
    },
  },
  {
    name: 'detractor',
    title: 'Count',
  },
  {
    name: 'detractorPercentage',
    title: '%',
    getCellValue: (row) => {
      let percentage = '0.00';
      if (row.totalResponses != 0 && row.totalResponses != null) {
        percentage = ((row.detractor / row.totalResponses) * 100).toFixed(2);
      }
      return `${percentage}%`;
    },
  },
  {
    name: 'passive',
    title: 'Count',
  },
  {
    name: 'passivePercentage',
    title: '%',
    getCellValue: (row) => {
      let percentage = '0.00';
      if (row.totalResponses != 0 && row.totalResponses != null) {
        percentage = ((row.passive / row.totalResponses) * 100).toFixed(2);
      }
      return `${percentage}%`;
    },
  },
  {
    name: 'promotor',
    title: 'Count',
  },
  {
    name: 'promotorPercentage',
    title: '%',
    getCellValue: (row) => {
      let percentage = '0.00';
      if (row.totalResponses != 0 && row.totalResponses != null) {
        percentage = ((row.promotor / row.totalResponses) * 100).toFixed(2);
      }
      return `${percentage}%`;
    },
  },
  {
    name: 'npsScore',
    title: 'NPS Score',
  },
];

export const reportsColors = {
  totalRows: 'fa8072',
  totalResponses: 'f5f5f5',
  totalResponsesPercentage: 'f5f5f5',
  detractor: 'ffe4e1',
  detractorPercentage: 'ffe4e1',
  promotor: '20b2aa',
  promotorPercentage: '20b2aa',
  passive: 'fffacd',
  passivePercentage: 'fffacd',
  npsScore: 'fffff0',
  npsScoreRange1: '90EE90', //Above 25
  npsScoreRange2: 'FFA500', //0 to 25
  npsScoreRange3: 'FFC0CB', //Negative Numbers
};

export const dateColumnsName= ['dateStarted', 'dateEnded'];