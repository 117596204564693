import { useTheme } from '@emotion/react';
import { Table as TableBase } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';

export function CellComponent(props: TableBase.DataCellProps) {
    const theme = useTheme();

    const { column, value } = { ...props };
    if (column.name == 'affectsCycleTime')
      return (
        <Table.Cell
          {...props}
          style={{
            background:
              value == 'Yes'
                ? theme.palette.reports.green
                : theme.palette.reports.pink,
          }}
        />
      );
    return (
      <Table.Cell
        {...props}
        style={{
          ...(value === '' ? { background: theme.palette.reports.pink } : {}),
        }}
      />
    );
  }