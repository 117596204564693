import { Table, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { Cell as ExcelCell } from 'exceljs';
import {
  Row,
  Column,
  Table as TableBase,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import { Text } from 'components/Typography';
import { SummaryItemComponentProps } from "./types";
import { dateColumnsName, reportsColors } from './constants';
import { customizeDateColumns } from '../helpers';
import { formatDateTime } from 'utils/helpers';

const groupingOptions = ['Week', 'Month', 'Year'];
export const reportGroupingOptions = groupingOptions.map(
  (e: string, i: number) => {
    return {
      id: i,
      value: e || '',
      text: e || '',
    };
  }
);

export const SummaryItemComponent = (data: SummaryItemComponentProps) => {
  if (data.type == 'grand_total') {
    return (
      <Text fontSize="m" fontWeight="bold">
        Grand Total
      </Text>
    );
  } else if (
    data.type == 'sum' ||
    data.type == 'score' ||
    data.type == 'percentage_1' ||
    data.type == 'percentage_2' ||
    data.type == 'percentage_3' ||
    data.type == 'percentage_4'
  ) {
    return (
      <Text fontSize="m" fontWeight="bold">
        {data.value}
      </Text>
    );
  } else {
    return <TableSummaryRow.Item {...data} />;
  }
};

export const customizeSummaryCell = (cells: ExcelCell) => {
  if (cells.formula)
    if (cells.formula?.includes('SUM')) {
      cells.numFmt = '0';
    } else if (cells.formula?.includes('SCORE')) {
      const totalResponses = cells.worksheet
        .getColumn(3)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      const grandTotalDetractor = cells.worksheet
        .getColumn(5)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      const grandTotalPromotor = cells.worksheet
        .getColumn(9)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      const totalPromotor =
        Number(grandTotalPromotor) / Number(totalResponses) || 0;
      const totalDetractor =
        Number(grandTotalDetractor) / Number(totalResponses) || 0;
      const totalScore = (totalPromotor - totalDetractor) * 100 || 0;
      cells.value = totalScore.toFixed(1);
    } else if (cells.formula?.includes('PERCENTAGE_1')) {
      const totalSurveys = cells.worksheet
        .getColumn(2)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      const totalResponses = cells.worksheet
        .getColumn(3)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      cells.numFmt = '0';
      cells.value = `${((Number(totalResponses) / Number(totalSurveys)) * 100).toFixed(2) ||
        0.0
        }%`;
    } else if (cells.formula?.includes('PERCENTAGE_2')) {
      const totalResponses = cells.worksheet
        .getColumn(3)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      const grandTotalDetractor = cells.worksheet
        .getColumn(5)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      cells.numFmt = '0';
      cells.value = `${((Number(grandTotalDetractor) / Number(totalResponses)) * 100).toFixed(
        2
      ) || 0.0
        }%`;
    } else if (cells.formula?.includes('PERCENTAGE_3')) {
      const totalResponses = cells.worksheet
        .getColumn(3)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      const grandTotalPassive = cells.worksheet
        .getColumn(7)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      cells.numFmt = '0';
      cells.value = `${((Number(grandTotalPassive) / Number(totalResponses)) * 100).toFixed(
        2
      ) || 0.0
        }%`;
    } else if (cells.formula?.includes('PERCENTAGE_4')) {
      const totalResponses = cells.worksheet
        .getColumn(3)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      const grandTotalPromotor = cells.worksheet
        .getColumn(9)
        .values?.filter((data) => typeof data == 'number')
        .reduce((a, b) => Number(a) + Number(b) || 0);
      cells.numFmt = '0';
      cells.value = `${((Number(grandTotalPromotor) / Number(totalResponses)) * 100).toFixed(
        2
      ) || 0.0
        }%`;
    } else if (cells.formula?.includes('GRAND_TOTAL')) {
      cells.value = 'Grand Total';
    } else {
      cells.value = '';
    }
};

export const summaryCalculator = (
  type: string,
  rows: Array<Row>,
  getValue: (row: Row) => Row
): Row => {
  if (!rows.length) return 0;
  if (type == 'score') {
    const grandTotalResponses = rows.reduce(
      (a, b) => a + Number(b.totalResponses || 0),
      0
    );
    if (grandTotalResponses == 0) return 0;
    else {
      const grandTotalPromotor = rows.reduce(
        (a, b) => a + Number(b.promotor || 0),
        0
      );
      const grandTotalDetractor = rows.reduce(
        (a, b) => a + Number(b.detractor || 0),
        0
      );
      const totalPromotor = grandTotalPromotor / grandTotalResponses || 0;
      const totalDetractor = grandTotalDetractor / grandTotalResponses || 0;
      const totalScore = (totalPromotor - totalDetractor) * 100 || 0;
      return Number(totalScore).toFixed(1);
    }
  } else if (type == 'percentage_1') {
    const grandTotalResponses = rows.reduce(
      (a, b) => a + Number(b.totalResponses || 0),
      0
    );
    const grandTotalSurveys = rows.reduce(
      (a, b) => a + Number(b.totalRows || 0),
      0
    );
    const percentage = (grandTotalResponses / grandTotalSurveys) * 100 || 0.0;
    return `${percentage.toFixed(2)}%`;
  } else if (type == 'percentage_2') {
    const grandTotalDetractors = rows.reduce(
      (a, b) => a + Number(b.detractor || 0),
      0
    );
    const grandTotalResponses = rows.reduce(
      (a, b) => a + Number(b.totalResponses || 0),
      0
    );
    const percentage =
      (grandTotalDetractors / grandTotalResponses) * 100 || 0.0;
    return `${percentage.toFixed(2)}%`;
  } else if (type == 'percentage_3') {
    const grandTotalPassive = rows.reduce(
      (a, b) => a + Number(b.passive || 0),
      0
    );
    const grandTotalResponses = rows.reduce(
      (a, b) => a + Number(b.totalResponses || 0),
      0
    );
    const percentage = (grandTotalPassive / grandTotalResponses) * 100 || 0.0;
    return `${percentage.toFixed(2)}%`;
  } else if (type == 'percentage_4') {
    const grandTotalPromotor = rows.reduce(
      (a, b) => a + Number(b.promotor || 0),
      0
    );
    const grandTotalResponses = rows.reduce(
      (a, b) => a + Number(b.totalResponses || 0),
      0
    );
    const percentage = (grandTotalPromotor / grandTotalResponses) * 100 || 0.0;
    return `${percentage.toFixed(2)}%`;
  } else if (type == 'grand_total') {
    return null;
  }
  return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

export const CellComponent = (props: TableBase.DataCellProps) => {
  const {
    column: { name },
    value,
  } = { ...props };
  if (
    name == 'totalResponses' ||
    name == 'totalResponsesPercentage' ||
    name == 'totalRows' ||
    name == 'npsScore' ||
    name == 'detractor' ||
    name == 'detractorPercentage' ||
    name == 'passive' ||
    name == 'passivePercentage' ||
    name == 'promotor' ||
    name == 'promotorPercentage'
  ) {
    let color = reportsColors[name];
    if (name == 'npsScore') {
      if (value > 25) {
        color = reportsColors.npsScoreRange1;
      }
      if (value >= 0 && value <= 25) {
        color = reportsColors.npsScoreRange2;
      }
      if (value < 0) {
        color = reportsColors.npsScoreRange3;
      }
    }
    return <Table.Cell {...props} style={{ background: `#${color}` }} />;
  }
  return <Table.Cell {...props} />;
};

export const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
  const { name } = column;

  customizeDateColumns(cell, row, column, dateColumnsName)

  if (
    name == 'totalResponses' ||
    name == 'totalResponsesPercentage' ||
    name == 'totalRows' ||
    name == 'npsScore' ||
    name == 'detractor' ||
    name == 'detractorPercentage' ||
    name == 'passive' ||
    name == 'passivePercentage' ||
    name == 'promotor' ||
    name == 'promotorPercentage'
  ) {
    let color = reportsColors[name];
    if (name == 'npsScore') {
      const { npsScore } = row;
      if (npsScore > 25) {
        color = reportsColors.npsScoreRange1;
      }
      if (npsScore >= 0 && npsScore <= 25) {
        color = reportsColors.npsScoreRange2;
      }
      if (npsScore < 0) {
        color = reportsColors.npsScoreRange3;
      }
    }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };
  }
};

export const valuesConverter = (data: Row[]) => {
  return data.map((row) => {
    const formattedRow = { ...row };
    dateColumnsName.forEach((columnName) => {
      if (row[columnName]) {
        formattedRow[columnName] = formatDateTime(row[columnName]);
      }
    });
    return formattedRow;
  });
};