import { Row } from '@devexpress/dx-react-grid';
import { dateColumnName } from '../constants';
import { formatDateTime } from 'utils/helpers';

export const valuesConverter = (data: Row[]) => {
    return data.map((row) => {
      const formattedRow = { ...row };
      dateColumnName.forEach((columnName) => {
        if (row[columnName]) {
          formattedRow[columnName] = formatDateTime(row[columnName]);
        }
      });
      return formattedRow;
    });
  };