
import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { InputContainer, Label } from 'components/PageLayout';
import { GridItem } from 'components/Layout';
import { useInMobile } from 'core/Theming/Device';
import { DatePickerField } from 'components/DatePickerFields';
import { renderSearchButtonText, renderSearchIcon } from '../../pages/ReportsDetails/helpers';
import { CustomForm } from 'components/ReportsComponents/CustomForm';
import { MultiSelectNew } from 'components/MultiSelectNew';
import { NargSearchFormProps } from './types';


export function NargSearchForm({
    handleSubmit,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    schemaCodeList,
    setSchemaCodeList,
    isDisabled,
    options,
}: NargSearchFormProps) {
    const isMobile = useInMobile();

    return (
        <CustomForm onSubmit={handleSubmit}>
            <InputContainer direction="row">
                <InputButton md={3} sm={4} xs={12}>
                    <Label>Start Date</Label>
                    <DatePickerField
                        value={startDate}
                        maxDate={endDate}
                        isDisabled={isDisabled}
                        onChange={(date) => setStartDate(date)}
                    />
                </InputButton>
                <InputButton md={3} sm={4} xs={12}>
                    <Label>End Date</Label>
                    <DatePickerField
                        value={endDate}
                        isDisabled={isDisabled}
                        minDate={startDate}
                        onChange={(date) => setEndDate(date)}
                    />
                </InputButton>
                <InputButton lg={3} md={3} sm={4} xs={12}>
                    <Label>Scheme Code</Label>
                    <MultiSelectNew
                        disabled={isDisabled}
                        options={options}
                        selectedOptions={schemaCodeList}
                        onChange={(value) => setSchemaCodeList(value || [])}
                    />
                </InputButton>
                <GridItem md={1} sm={1} xs={2}>
                    <Button
                        type="submit"
                        disabled={
                            !startDate ||
                            !endDate ||
                            schemaCodeList?.length == 0 ||
                            isDisabled
                        }
                    >
                        {renderSearchIcon(isMobile)}
                        {renderSearchButtonText(isMobile)}
                    </Button>
                </GridItem>
            </InputContainer>
        </CustomForm>
    );
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  max-width: 330px !important;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      margin - bottom: 6px;
  }
  & svg {
      width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;