import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
    Label,
} from 'components/PageLayout';
import { useInMobile } from 'core/Theming/Device';
import { Select } from 'components/Select';
import { CustomForm } from 'components/ReportsComponents/CustomForm';
import { WorkProviderSearchProps } from './types';
import { renderSearchButtonText, renderSearchIcon } from 'pages/ReportsDetails/helpers';


export function WorkProviderSearchForm({
    handleSubmit,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isDisabled,
    workProvider,
    setWorkProvider,
    options,
}: WorkProviderSearchProps) {
    const isMobile = useInMobile();
    return (
        <CustomForm onSubmit={handleSubmit}>
            <Container direction="row">
                <InputButton md={3} sm={4} xs={12}>
                    <Label>Start Date</Label>
                    <DatePickerField
                        isDisabled={isDisabled}
                        onChange={(date) => setStartDate(date)}
                        value={startDate}
                        maxDate={endDate}
                    />
                </InputButton>
                <InputButton md={3} sm={4} xs={12}>
                    <Label>End Date</Label>
                    <DatePickerField
                        isDisabled={isDisabled}
                        onChange={(date) => setEndDate(date)}
                        value={endDate}
                        minDate={startDate}
                    />
                </InputButton>
                <InputButton md={3} sm={4} xs={12}>
                    <Label>Work Provider</Label>
                    <Select
                        disabled={isDisabled}
                        options={options}
                        onChange={(status) => setWorkProvider(status.value)}
                        selectedId={
                            options?.find(
                                (e: { value: string | null }) => e.value == workProvider
                            )?.id
                        }
                    />
                </InputButton>
                <GridItem md={1} sm={1} xs={2}>
                    <Button
                        type="submit"
                        disabled={!startDate || !endDate || !workProvider || isDisabled}
                    >
                        {renderSearchIcon(isMobile)}
                        {renderSearchButtonText(isMobile)}
                    </Button>
                </GridItem>
            </Container>
        </CustomForm>
    );
}

const InputButton = styled(GridItem)`
          padding-right: ${({ theme }) => theme.margin.s};
          @media (max-width: ${({ theme }) =>
        `${theme.breakpoints.values.sm}px`}) {
              margin - bottom: 6px;
          }
          & svg {
              width: 20px;
          height: 20px;
          color: ${({ theme }) => theme.palette.secondary.main};
          }
      `;



const Button = styled(PrimaryButton)`
    min-width: 40px;
    font-size: ${({ theme }) => theme.fontSize.s};
    height: 40px;
    padding: 12px;
    margin-top: 20.5px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      width: 100%;
    }
  `;


