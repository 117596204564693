import styled from '@emotion/styled';
import { Heading, Text } from 'components/Typography';
import { LinkItem } from './LinkItem';
import { useUser } from 'pages/hooks';
import { PageHeader, UpperInfoContainer } from 'components/PageLayout';
import { GridItem } from 'components/Layout';
import { Permit } from 'api/resources/models/AutoGenerated';
import { ADMINISTRATION, NO_PERMISSION, listItems } from './constants';

export function AdminPage() {
  const { user } = useUser();

  const permitAllowed = !!user?.userPermits?.find(
    (permit) => permit.code === Permit.GLOBAL_ADMIN
  );

  return (
    <UpperInfoContainer direction="column">
      <StyledPageHeader>
        <Heading>{ADMINISTRATION}</Heading>
      </StyledPageHeader>
      {permitAllowed ? (
        <GridItem>
          {listItems.map((value) => (
            <LinkItem key={value.id} item={value} />
          ))}
        </GridItem>
      ) : (
        <Text>{NO_PERMISSION}</Text>
      )}
    </UpperInfoContainer>
  );
}

const StyledPageHeader = styled(PageHeader)`
  padding: 12px 0;
`;
