import { Column } from '@devexpress/dx-react-grid';

export const tableColumnExtensions = [
  { columnName: 'repairer', wordWrapEnabled: true },
  { columnName: 'repairID', wordWrapEnabled: true },
  { columnName: 'registration', wordWrapEnabled: true },
  { columnName: 'network', wordWrapEnabled: true },
  { columnName: 'creditCount', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'tranmissionDTG', wordWrapEnabled: true },
  { columnName: 'smsBody', wordWrapEnabled: true },
];

export const options = [
  { id: 0, value: 'Allianz', text: 'Allianz' },
  { id: 1, value: 'Narg Zurich', text: 'Narg Zurich' },
  { id: 2, value: 'Covea', text: 'Covea' },
  { id: 3, value: 'Direct Line Group', text: 'Direct Line Group' },
  { id: 4, value: 'RSA Group', text: 'RSA Group' },
];

export const summaryItems = [
  {
    columnName: 'repairer',
    type: 'count',
  },
];

export const groupSummaryItems = [
  {
    columnName: 'smsBody',
    type: 'count',
    showInGroupFooter: false,
    alignByColumn: true,
  },
];

export const defaultGroups = [{ columnName: 'network' }];

export const columns: Column[] = [
  { name: 'network', title: 'Network' },
  { name: 'repairer', title: 'Repairer' },
  { name: 'repairID', title: 'Repairer ID' },
  { name: 'creditCount', title: 'Units' },
  { name: 'claimReference', title: 'Claim Ref.' },
  { name: 'registration', title: 'Vehicle Reg.' },
  { name: 'tranmissionDTG', title: 'Message Sent' },
  { name: 'smsBody', title: 'SMS Body' },
];

export const dateColumnsName = ['tranmissionDTG'];
