import { Worksheet } from 'exceljs';
import { SLAPerformance } from 'api/resources/models/AutoGenerated';
import { convertMinToDayHourMin } from './convertMinToDayHourMin';

export const customFooterResult = (
  worksheet: Worksheet,
  reportData: SLAPerformance[]
) => {
  const { lastRow } = worksheet;
  const currentRowIndex = lastRow && lastRow.number + 1;

  if (currentRowIndex) {
    const totalClaims = reportData?.length;
    worksheet.getRow(currentRowIndex).getCell(3).value = `Total Claims: `;
    worksheet
      .getRow(currentRowIndex + 1)
      .getCell(3).value = `${totalClaims}`;

    let slaCompliance = '0.00%';
    const totalCompletedRow = reportData
      ?.map((row: SLAPerformance) => row.slaBreach)
      .filter((cell) => cell === 0).length;
    if (totalCompletedRow && totalClaims !== 0) {
      slaCompliance = `${Number((totalCompletedRow / totalClaims) * 100).toFixed(
        2
      )}%`;
    }
    worksheet.getRow(currentRowIndex).getCell(4).value = `SLA Compliance: `;
    worksheet.getRow(currentRowIndex + 1).getCell(4).value = `${slaCompliance}`;

    let totalValue = 0;
    reportData?.forEach((a: SLAPerformance) => {
      totalValue = totalValue + Number(a.slaDuration);
    });
    let avgDuration = '0';
    if (totalClaims && totalClaims !== 0) {
      avgDuration = convertMinToDayHourMin(
        totalValue / totalClaims,
        reportData?.[0]?.timeType || 'S'
      );
    }
    worksheet.getRow(currentRowIndex).getCell(7).value = `Average Duration: `;
    worksheet.getRow(currentRowIndex + 1).getCell(7).value = `${avgDuration}`;

    worksheet.getRow(currentRowIndex).font = { bold: true };
    worksheet.getRow(currentRowIndex + 1).font = { bold: true };
  }
};
