import { SelectOptions } from './types';

export const getMonthOptions = () => {
  const options = [];
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let i = 0; i < 12; i++) {
    const monthIndex = (currentMonth - i + 12) % 12;
    const year = currentYear - Math.abs(Math.floor((currentMonth - i) / 12));
    const monthName = new Date(year, monthIndex).toLocaleString('en', {
      month: 'short',
    });
    const optionText = `${monthName.toUpperCase()} ${year}`;
    options.push({
      id: monthIndex,
      value: optionText,
      text: optionText,
    });
  }

  return options;
};

export const monthsOptions = getMonthOptions();

export const getSelectedId = (
  fieldValue: string | null,
  options: SelectOptions[]
) => {
  return options.find((option) => option.value === fieldValue)?.id;
};

export const selectLaterMonths = (
  startDate: string | null,
  options: SelectOptions[]
) => {
  const index = options?.findIndex((month) => month.text === startDate);

  if (index === -1) {
    return options;
  }

  return options?.slice(0, index + 1);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertCycleTimeData = (data: any[]) => {
  return data.map((obj) => {
    const newObj = { ...obj };
    for (const prop in newObj) {
      if (typeof newObj[prop] === 'number') {
        newObj[prop] = Number(newObj[prop].toFixed(2));
      }
    }
    return {
      ...newObj,
      repairer: '1',
      manufacturer: '2',
      stream: '3',
      details: '4',
    };
  });
};
