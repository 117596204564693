import { TableGroupRow as TableGroupRowBase } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';

import { SLAPerformance } from 'api/resources/models/AutoGenerated';

export function GroupCellContent(
  reportData: SLAPerformance[] | undefined,
  props: TableGroupRowBase.ContentProps
) {
  const { column, row } = props;
  if (column.name === 'slaTitle') {
    return (
      <span>
        <strong>{row.value}</strong>
        <CustomText>
          {reportData?.find((a) => a.slaTitle === row.value)?.slaDescription}
        </CustomText>
      </span>
    );
  }
  return row.value;
}

const CustomText = styled(`p`)`
  font-style: italic;
  margin-left: 20px;
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 0;
`;
