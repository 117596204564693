import { useAccountProfilesParams } from './hooks';
import { useLocation } from 'react-router-dom';
import { AccountProfilesSearch } from './AccountProfilesSearch';

export function AccountProfiles() {
  const location = useLocation();
  const urlParams = useAccountProfilesParams(location.search);
  if (typeof urlParams === 'string') return <p>{urlParams}</p>;
  return <AccountProfilesSearch urlParams={urlParams} />;
}
