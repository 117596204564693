import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';

export function BackButtonItem({ text }: { text: string }) {
  return (
    <GridItem>
      <Container>
        <BackButton text={text} />
      </Container>
    </GridItem>
  );
}
