import {
    TableSummaryRow,
} from '@devexpress/dx-react-grid';
import { Text } from 'components/Typography';


export function SummaryItemComponent(data: TableSummaryRow.ItemProps) {
    let summaryItemMessage = 'Total Claims: ';
    if (data.type === 'percentage') {
        summaryItemMessage = 'SLA Compliance: '
    }
    if (data.type === 'average') {
        summaryItemMessage = 'Average Duration: '
    }
    return (
        <Text fontSize="m" fontWeight="bold">
            {summaryItemMessage}
            <br /> {data.value}
        </Text>
    );
}