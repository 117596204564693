import { ROUTES } from 'core/routes';
import { SvgIconComponent } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { ReactComponent as CarCrashSvg } from 'assets/CarCrash.svg';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import { AccordionTypes } from 'components/PrimaryAccordion';
import { DateStatus } from 'components/VehiclesTable/fields/types';
import { Permit } from 'api/resources/models/AutoGenerated';

export interface MenuItem {
  name: string;
  icon:
    | SvgIconComponent
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  route: string;
  permitCode?: Permit;
}

export const topMenuItems: MenuItem[] = [
  {
    name: 'Dashboard',
    route: ROUTES.dashboard,
    icon: DashboardIcon,
  },
  {
    name: 'Vehicles in Progress',
    route: ROUTES.vehiclesSearch,
    icon: DriveEtaIcon,
    permitCode: Permit.CORE_VEHICLES,
  },
  {
    name: 'Search',
    route: ROUTES.repairsSearch,
    icon: SearchIcon,
    permitCode: Permit.CORE_SEARCH,
  },
  {
    name: 'Cycle Time',
    route: ROUTES.cycleTimeSummary,
    icon: HistoryRoundedIcon,
    permitCode: Permit.CORE_CYCLETIME_V2,
  },
  {
    name: 'Definite Total Loss',
    route: ROUTES.dtlSearch,
    icon: CarCrashSvg,
    permitCode: Permit.CORE_TOTALLOSS,
  },
  {
    name: 'Reports',
    route: ROUTES.reports,
    icon: AssignmentIcon,
    permitCode: Permit.CORE_REPORTS,
  },
  {
    name: 'Admin',
    route: ROUTES.admin,
    icon: PersonIcon,
    permitCode: Permit.CORE_ADMIN,
  },
];

export type Mode = 'Legend' | 'Help' | null;

export interface BottomMenuItem {
  name: Mode;
  icon: SvgIconComponent;
}

export const bottomMenuItems: BottomMenuItem[] = [
  { name: 'Legend', icon: InfoIcon },
  { name: 'Help', icon: HelpIcon },
];

export interface AccordionItem {
  id: AccordionTypes;
  summary: string;
  details: { status: DateStatus; description: string }[];
}

export const accordionItems: AccordionItem[] = [
  {
    id: 'panel1',
    summary: 'Repairs List - Last Updated - Only when repair is in progress',
    details: [
      {
        status: 'green',
        description: 'Ongoing repair was last updated less than 3 days ago',
      },
      {
        status: 'amber',
        description: 'Ongoing repair was last updated between 4 and 7 days ago',
      },
      {
        status: 'red',
        description: 'Ongoing repair was last updated more than 7 days ago',
      },
    ],
  },
  {
    id: 'panel2',
    summary: 'Repairs List - Milestone Dates',
    details: [
      {
        status: 'green',
        description: 'On time',
      },
      {
        status: 'amber',
        description: 'Estimation date is missing or milestone is overdue',
      },
      {
        status: 'red',
        description:
          'Milestone is either late or early compared to predicted date',
      },
    ],
  },
  {
    id: 'panel3',
    summary: 'Repair details - Milestone Dates',
    details: [
      {
        status: 'green',
        description: 'Milestone is on time',
      },
      {
        status: 'amber',
        description: 'Estimation date is missing or milestone is overdue',
      },
      {
        status: 'red',
        description:
          'Milestone is either late or early compared to predicted date',
      },
    ],
  },
  {
    id: 'panel4',
    summary: 'Repair details - SLA',
    details: [
      {
        status: 'green',
        description: 'SLA was finished in expected timeframe',
      },
      {
        status: 'amber',
        description: 'SLA was slightly overdue',
      },
      {
        status: 'red',
        description: 'SLA was late',
      },
    ],
  },
];
