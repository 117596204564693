import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { GridItem } from 'components/Layout';
import { useInMobile } from 'core/Theming/Device';
import {
  renderSearchButtonText,
  renderSearchIcon,
} from 'pages/ReportsDetails/helpers';

export interface ViewButtonProps {
  disabled: boolean;
}

export const ViewButton = ({ disabled }: ViewButtonProps) => {
  const isMobile = useInMobile();
  return (
    <GridItem md={1} sm={1} xs={2}>
      <Button type="submit" disabled={disabled}>
        {renderSearchIcon(isMobile)}
        {renderSearchButtonText(isMobile)}
      </Button>
    </GridItem>
  );
};

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
