import { formatDate } from "utils/helpers";
import { getPreviousMonthWithYear } from "./getPreviousMonthWithYear";

export const previousMonthWithYear = getPreviousMonthWithYear();

export const NOTIFICATION_VOLUME_REPORT = `Notification Volume Report for ${previousMonthWithYear}`;
export const NOTIFICATION_VOLUME_REPORT_MESSAGE = "Displays the Monthly Notification Volume Report for last month.";
export const NOTIFICATION_VOLUME_REPORT_TITTLE ="MonthlyNotificationVolumeReport";
export const NOTIFICATION_VOLUME_REPORT_HEADER_TAG_LINE = `Report produced on ${formatDate(new Date())} for period ${previousMonthWithYear}`;

export const columns = [
    { name: 'repairer', title: 'Repairer' },
    { name: 'volume', title: 'Volume' },
    { name: 'hiddenColumn1', title: '' },
  ];

  export const tableColumnExtensions = [
    { columnName: 'repairer', wordWrapEnabled: true },
    { columnName: 'volume', wordWrapEnabled: true },
  ]

 export const hiddenColumnNames = ['hiddenColumn1']