import { zodResolver } from '@hookform/resolvers/zod';
import {
  AccountProfilePermit,
  AccountProfilePermits,
} from 'api/resources/models/AutoGenerated';
import { AxiosError } from 'axios';
import { Container, GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import { SaveButton, SaveIcon } from 'components/PageLayout';
import PrimaryToast from 'components/PrimaryToast';
import { PageTab, PageTabItem, TabPanel } from 'components/Tab';
import { logger } from 'core/logger';
import { ROUTES } from 'core/routes';
import { omit } from 'lodash';
import {
  useCompanies,
  useCreateAccountProfile,
  useUpdateAccountProfile,
} from 'pages/hooks';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import { z } from 'zod';
import { AccountProfileProps } from '.';
import { AccountProfileRecordFilters } from './AccountProfileRecordFilters';
import { AccountProfileGeneralDetails } from './AccountProfileGeneralDetails';
import { AccountProfileReports } from './AccountProfileReports';
import { AccountProfilesRepairDetails } from './AccountProfilesRepairDetails';
import { AccountProfilesRepairsLists } from './AccountProfilesRepairsLists';
import {
  AccountProfileForm,
  AccountProfileFormPermits,
  FirstLevelAccountProfilePermit,
} from './form';
import { AccountProfileUserAccounts } from './AccountProfileUserAccounts';
import { AccountProfileWorkProviders } from './AccountProfileWorkProviders';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'core/logger/ApplicationInsightsService';

const validationSchema = z.object({
  accountProfile: z.object({
    accountProfileId: z.number().optional(),
    name: z
      .string()
      .nonempty('Account profile name is required')
      .min(3, { message: 'Must be 3 or more characters long' })
      .max(50, { message: 'Must be max 50 characters long' }),
    companyId: z.number({ required_error: 'Company is required' }),
    isTemplate: z.boolean(),
  }),
  permits: z.object({
    adminPermits: z.array(
      z.object({
        isActive: z.boolean().optional(),
        accountProfilePermitId: z.number(),
        name: z.string(),
        permit: z.string(),
        children: z
          .array(
            z.object({
              isActive: z.boolean().optional(),
              accountProfilePermitId: z.number(),
              name: z.string(),
              permit: z.string(),
            })
          )
          .optional(),
      })
    ),
    reportPermits: z.object({
      isActive: z.boolean().optional(),
      accountProfilePermitId: z.number(),
      name: z.string(),
      permit: z.string(),
      children: z
        .array(
          z.object({
            isActive: z.boolean().optional(),
            accountProfilePermitId: z.number(),
            name: z.string(),
            permit: z.string().nullable(),
          })
        )
        .optional(),
    }),
    repairsListsPermits: z.object({
      corePermits: z.array(
        z.object({
          isActive: z.boolean().optional(),
          accountProfilePermitId: z.number(),
          name: z.string(),
          permit: z.string(),
          children: z
            .array(
              z.object({
                isActive: z.boolean().optional(),
                accountProfilePermitId: z.number(),
                name: z.string(),
                permit: z.string(),
              })
            )
            .optional(),
        })
      ),
      fieldLevelPermits: z.array(
        z.object({
          isActive: z.boolean().optional(),
          accountProfilePermitId: z.number(),
          name: z.string(),
          permit: z.string(),
          children: z
            .array(
              z.object({
                isActive: z.boolean().optional(),
                accountProfilePermitId: z.number(),
                name: z.string(),
                permit: z.string(),
              })
            )
            .optional(),
        })
      ),
    }),
    repairDetailsPermits: z.array(
      z.object({
        isActive: z.boolean().optional(),
        accountProfilePermitId: z.number(),
        name: z.string(),
        permit: z.string(),
      })
    ),
    recordFiltersPermits: z.array(
      z.object({
        isActive: z.boolean().optional(),
        accountProfilePermitId: z.number(),
        name: z.string(),
        permit: z.string().nullable(),
      })
    ),
  }),
});

export function AccountProfileDetailsForm({
  accountProfile,
  accountProfilePermits,
  mode,
}: AccountProfileProps) {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<string>('accountProfile');
  const { companies, isCompaniesLoading } = useCompanies({});

  const permits: AccountProfileFormPermits = {
    adminPermits: accountProfilePermits.adminPermits.map(mapToFormPermit) ?? [],
    repairsListsPermits: {
      corePermits:
        accountProfilePermits.repairsListsPermits.corePermits.map(
          mapToFormPermit
        ),
      fieldLevelPermits:
        accountProfilePermits.repairsListsPermits.fieldLevelPermits.map(
          mapToFormPermit
        ),
    },
    repairDetailsPermits:
      accountProfilePermits.repairsDetailsPermits.map(mapToFormPermit) ?? [],
    recordFiltersPermits:
      accountProfilePermits.recordFiltersPermits.map(mapToFormPermit) ?? [],
    reportPermits: mapToFormPermit(accountProfilePermits.reportPermits),
  };

  const accountProfileForm = useForm<AccountProfileForm>({
    defaultValues: {
      accountProfile,
      permits,
    },
    resolver: zodResolver(validationSchema),
  });

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const [createdAccountProfileId, setCreatedAccountProfileId] =
    useState<number>();

  const { createAccountProfile, isCreatingAccountProfile } =
    useCreateAccountProfile();
  const { updateAccountProfile, isUpdatingAccountProfile } =
    useUpdateAccountProfile();

  const isBusy =
    isCreatingAccountProfile || isUpdatingAccountProfile || isCompaniesLoading;

  return (
    <FormProvider {...accountProfileForm}>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <form onSubmit={accountProfileForm.handleSubmit(onSubmit, onError)}>
        <Container>
          <SaveButton type="submit">
            <SaveIcon />
            Save Changes
          </SaveButton>
        </Container>
        <Container>
          <GridItem md={12} sm={12} xs={12}>
            <PageTab
              value={selectedTab}
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <PageTabItem label="General" value="accountProfile" />
              <PageTabItem
                label="Filters"
                value="filters"
                disabled={mode == 'Create'}
              />
              <PageTabItem
                label="Repairs Lists"
                value="repairLists"
                disabled={mode == 'Create'}
              />
              <PageTabItem
                label="Repair Details"
                value="repairDetails"
                disabled={mode == 'Create'}
              />
              <PageTabItem
                label="Reports"
                value="reports"
                disabled={mode == 'Create'}
              />
              <PageTabItem
                label="User Accounts"
                value="userAccounts"
                disabled={mode == 'Create'}
              />
              <PageTabItem
                label="Work Providers"
                value="workProviders"
                disabled={mode == 'Create'}
              />
            </PageTab>
            <TabPanel value={selectedTab} index="accountProfile">
              <GridItem md={6} sm={12} xs={12}>
                <AccountProfileGeneralDetails
                  companies={companies}
                  mode={mode}
                />
              </GridItem>
            </TabPanel>
            <TabPanel value={selectedTab} index="filters">
              <GridItem md={6} sm={12} xs={12}>
                <AccountProfileRecordFilters />
              </GridItem>
            </TabPanel>
            <TabPanel value={selectedTab} index="reports">
              <GridItem md={6} sm={12} xs={12}>
                <AccountProfileReports />
              </GridItem>
            </TabPanel>
            <TabPanel value={selectedTab} index="repairLists">
              <GridItem md={6} sm={12} xs={12}>
                <AccountProfilesRepairsLists />
              </GridItem>
            </TabPanel>
            <TabPanel value={selectedTab} index="repairDetails">
              <GridItem md={6} sm={12} xs={12}>
                <AccountProfilesRepairDetails />
              </GridItem>
            </TabPanel>
            <TabPanel value={selectedTab} index="userAccounts">
              <GridItem md={12} sm={12} xs={12}>
                <AccountProfileUserAccounts
                  accountProfileId={accountProfile?.accountProfileId}
                />
              </GridItem>
            </TabPanel>
            <TabPanel value={selectedTab} index="workProviders">
              <GridItem md={6} sm={12} xs={12}>
                <AccountProfileWorkProviders />
              </GridItem>
            </TabPanel>
          </GridItem>
        </Container>
      </form>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => {
          setToastState({ ...toastState, isOpen: false });
          if (createdAccountProfileId)
            navigateToEditAccountProfile(createdAccountProfileId);
        }}
        severity={toastState.severity}
      />
    </FormProvider>
  );

  function onError(accountProfileErrors: FieldErrors<AccountProfileForm>) {
    const keys = Object.keys(accountProfileErrors);
    const key = keys.find((value) => value === selectedTab);
    setSelectedTab(key ? key : keys[0]);
  }

  function mapToFormPermit(
    p: AccountProfilePermit
  ): FirstLevelAccountProfilePermit {
    return {
      ...p,
      children: p.children?.map((c) => omit(c, ['children'])),
    };
  }

  function mapToPermit(
    p: FirstLevelAccountProfilePermit
  ): AccountProfilePermit {
    return {
      ...p,
      children:
        p.children?.map((c) => {
          return { ...c, children: null };
        }) ?? null,
    };
  }

  function navigateToEditAccountProfile(accountProfileId: number) {
    history.replace(
      generatePath(ROUTES.accountProfileEdit, { id: accountProfileId })
    );
  }

  function onSubmit(data: AccountProfileForm) {
    const mappedBackPermits: AccountProfilePermits = {
      adminPermits: data.permits.adminPermits.map(mapToPermit),
      repairsDetailsPermits: data.permits.repairDetailsPermits.map(mapToPermit),
      repairsListsPermits: {
        corePermits:
          data.permits.repairsListsPermits.corePermits.map(mapToPermit),
        fieldLevelPermits:
          data.permits.repairsListsPermits.fieldLevelPermits.map(mapToPermit),
      },
      recordFiltersPermits: data.permits.recordFiltersPermits.map(mapToPermit),
      reportPermits: mapToPermit(data.permits.reportPermits),
    };
    if (mode === 'Create') {
      createAccountProfile({
        accountProfile: data.accountProfile,
        permits: mappedBackPermits,
      })
        .then((response) => {
          setCreatedAccountProfileId(response.accountProfileId);
          setToastState({
            isOpen: true,
            severity: 'success',
            message: successMessages.accountProfileCreated,
          });
        })
        .catch((error: AxiosError) => handleError({ error }));
    } else
      updateAccountProfile({
        accountProfile: data.accountProfile,
        permits: mappedBackPermits,
      })
        .then(() =>
          setToastState({
            isOpen: true,
            severity: 'success',
            message: successMessages.accountProfileUpdated,
          })
        )
        .catch((error: AxiosError) => handleError({ error, isUpdate: true }));
  }

  function handleError({
    error,
    isUpdate,
  }: {
    error: AxiosError;
    isUpdate?: boolean;
  }) {
    logger?.error(error);
    appInsights?.trackException({ error, severityLevel: SeverityLevel.Error });

    setToastState({
      message: isUpdate
        ? errorMessages.accountProfileUpdated
        : errorMessages.accountProfileCreated,
      isOpen: true,
      severity: 'error',
    });
  }
}
