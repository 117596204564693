import styled from '@emotion/styled/macro';
import { Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { InputSelectProps } from './types';

export interface SelectOption<T = string> {
  id: number;
  value?: T;
  text: string;
}

export default function InputSelect<T>({
  options,
  value,
  defaultValue,
  onChange,
  inputValue,
  onInputChange,
  renderInput,
  renderOption,
  open,
  getOptionDisabled,
  className,
  onOpen,
  onClose,
  size = 'medium',
  onBlur,
  onFocus,
  groupBy,
  renderGroup,
  filterOptions,
  disabled,
  noOptionsText,
  forcePopupIcon = false,
}: InputSelectProps<T>) {
  return (
    <Container open={open}>
      <Autocomplete
        disabled={disabled}
        size={size}
        id="repairers-box"
        forcePopupIcon={forcePopupIcon}
        defaultValue={defaultValue}
        options={options}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={(_event, newValue) => {
          if (onChange) onChange(newValue);
        }}
        PaperComponent={({ children }) => <StyledPaper>{children}</StyledPaper>}
        inputValue={inputValue}
        onInputChange={(event, newInputValue, reason) => {
          onInputChange && event && onInputChange(newInputValue, reason);
        }}
        getOptionLabel={(option) => option.text}
        getOptionDisabled={getOptionDisabled}
        renderInput={renderInput}
        renderOption={renderOption}
        isOptionEqualToValue={(opt: { id: number | undefined }) => {
          return opt.id === value?.id;
        }}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        className={className}
        groupBy={groupBy}
        renderGroup={renderGroup}
        filterOptions={filterOptions}
        noOptionsText={noOptionsText}
      />
    </Container>
  );
}

const Container = styled.div<{ open?: boolean }>`
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
    visibility: visible;
  }

  fieldset {
    border: ${({ open }) => (open ? '0px' : 'inherits')};
  }

  & .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0px 30px 0px 5px;
    font-size: ${({ theme }) => theme.fontSize.s};
    border-bottom-left-radius: ${({ open }) => (open ? 0 : 'inherits')};
    border-bottom-right-radius: ${({ open }) => (open ? 0 : 'inherits')};
    box-shadow: ${({ theme, open }) => (open ? theme.shadow.m : 'inherits')};
    border: ${({ open }) => (open ? '0px' : 'inherits')};
    border-bottom: ${({ theme, open }) =>
      open ? `1px solid ${theme.palette.secondary.active}` : 'inherits'};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ open }) => (open ? '0px !important' : 'inherits')};
  }
`;

const StyledPaper = styled(Paper)`
  margin-top: 0px;
  box-shadow: ${({ theme }) => theme.shadow.m};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  font-size: ${({ theme }) => theme.fontSize.s};
`;
