import styled from '@emotion/styled/macro';
import { ControllerFieldState } from 'react-hook-form';
import { TextField } from './TextField';
import { getNumberInputValue } from './Utils/getNumberInputValue';

interface Props {
  fieldState?: ControllerFieldState;
  onChange: (...event: unknown[]) => void;
  value: number | null;
  disabled?: boolean;
}

export default function NumberField({
  value,
  onChange,
  disabled,
  fieldState,
}: Props) {
  return (
    <NumberInput
      disabled={disabled}
      onChange={(event) => {
        const inputValue = getNumberInputValue(event.target.value);
        if (inputValue === undefined) {
          event.preventDefault();
          return;
        }
        onChange(inputValue);
      }}
      value={value}
      fullWidth
      type="number"
      helperText={fieldState?.error?.message}
      error={!!fieldState?.error}
      onPaste={(event) => {
        if (isNaN(parseFloat(event.clipboardData.getData('Text'))))
          event.preventDefault();
      }}
      onKeyPress={(event) => {
        if (
          event?.key === '-' ||
          event?.key === '+' ||
          event?.key === 'e' ||
          event?.key === 'E'
        ) {
          event.preventDefault();
        }
      }}
    />
  );
}

const NumberInput = styled(TextField)`
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
