import { Company } from 'api/resources/models/AutoGenerated';

export interface CompaniesTableHeader {
  id: keyof Company;
  label: string;
}

export const companiesTableHeaders: CompaniesTableHeader[] = [
  { id: 'id', label: 'Id' },
  { id: 'name', label: 'Company Name' },
];
