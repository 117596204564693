import {
  OwnerReason,
  RepairerReason,
  ReplacementVehicleStatus,
} from 'api/resources/models/AutoGenerated';
import { SelectOption } from 'components/Select';
import { isDefined } from 'utils/predicates';
import {
  OwnerReasonToText,
  Reason,
  RepairerReasonToText,
  ReplacementVehicleToText,
} from './types';

const repairerReasonToTextMapping: RepairerReasonToText = {
  [RepairerReason.BookIn]: 'Booking In',
  [RepairerReason.BookOut]: 'Booking Out',
  [RepairerReason.CustomerUpdate]: 'Customer Update',
  [RepairerReason.Other]: 'Other',
  [RepairerReason.PoorData]: 'Poor Data',
  [RepairerReason.RepairQuality]: 'Repair Quality',
  [RepairerReason.RepairerStaff]: 'Repairer Staff',
  [RepairerReason.ReplacementVehicle]: 'Replacement Vehicle',
};

const replacementVehicleStatusToTextMapping: ReplacementVehicleToText = {
  [ReplacementVehicleStatus.IsRequired]: 'Is required',
  [ReplacementVehicleStatus.NotRequired]: 'Is not required',
  [ReplacementVehicleStatus.AssignedOut]: 'Assigned and out with the customer',
  [ReplacementVehicleStatus.AssignedReturned]: 'Assigned and returned',
  [ReplacementVehicleStatus.Invalid]: 'Invalid',
};

const ownerReasonToTextMapping: OwnerReasonToText = {
  [OwnerReason.BookIn]: 'Booking In',
  [OwnerReason.BookOut]: 'Booking Out',
  [OwnerReason.CustomerUpdate]: 'Customer Update',
  [OwnerReason.Other]: 'Other',
  [OwnerReason.PoorData]: 'Poor Data',
  [OwnerReason.RepairQuality]: 'Repair Quality',
  [OwnerReason.RepairerStaff]: 'Repairer Staff',
  [OwnerReason.ReplacementVehicle]: 'Replacement Vehicle',
};

export const ownerReasons = Object.keys(OwnerReason)
  .map<Reason<OwnerReason> | undefined>((key) => {
    const reason = key as OwnerReason;
    return {
      key: reason,
      displayText: ownerReasonToTextMapping[reason],
    };
  })
  .filter(isDefined);

export const repairerReasons = Object.keys(RepairerReason)
  .map<Reason<RepairerReason> | undefined>((key) => {
    const reason = key as RepairerReason;
    return {
      key: reason,
      displayText: repairerReasonToTextMapping[reason],
    };
  })
  .filter(isDefined);

export const replacementVehicleStatuses = Object.keys(ReplacementVehicleStatus)
  .map<SelectOption<ReplacementVehicleStatus>>((value, key) => {
    const status = value as ReplacementVehicleStatus;
    return {
      id: key,
      value: status,
      text: replacementVehicleStatusToTextMapping[status],
    };
  })
  .filter(isDefined);
