import { Row } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import { MainPageContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { AdminDataAccessProfileParams } from 'core/routes';
import {
  ISiteCodeOnError,
  ISiteCodeOnSuccess,
  useGetALLSiteCodeFormalCode,
  useRemoveSiteCodeFormalCodeAsign,
} from 'pages/hooks';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfoText from 'components/InfoText';
import PrimaryToast from 'components/PrimaryToast';
import { warningMessages } from 'api/resources/responseMessages/warningMessages';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { columns } from './constants';

interface IFormalCodeObj {
  siteCode: string;
  schemeCode: string;
}

export function DataAccessReport() {
  const { id } = useParams<AdminDataAccessProfileParams>();
  const dataAccessProfileId = Number(id);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'warning' | 'success';
  }>();
  const {
    allSiteCodeFormalCode,
    refetchAllSiteCodeFormalCode,
    isAllSiteCodeLoading,
  } = useGetALLSiteCodeFormalCode(dataAccessProfileId);

  const { removeSiteCodeFormalCodeAssign, isRemoving } =
    useRemoveSiteCodeFormalCodeAsign({
      onSuccess: onSuccessResponse,
      onError: onErrorResponse,
    });

  const isBusy = isRemoving || isAllSiteCodeLoading;

  const rows = useMemo<Row>(
    () => allSiteCodeFormalCode,
    [allSiteCodeFormalCode]
  );

  return (
    <MainPageContainer direction="column" wrap="nowrap">
      {rows && rows?.length == 0 && (
        <CustomInfoText>
          {'Sorry, there are no data access details for this profile'}
        </CustomInfoText>
      )}
      <ReportGridContainer direction="column">
        <ReportGrid
          isLoading={isBusy}
          isRemovable={true}
          columns={columns}
          rows={rows}
          title="Data Access Report"
          removeMethodName={onRemove}
        />
      </ReportGridContainer>
      {toastState && (
        <PrimaryToast
          message={toastState.message}
          isOpen={toastState.isOpen}
          severity={toastState.severity}
          onClose={() => setToastState({ ...toastState, isOpen: false })}
        />
      )}
    </MainPageContainer>
  );

  function deleteRows(deleted: (string | number)[]) {
    const rowsForDelete = rows.slice();
    let deletedRowObj = {} as IFormalCodeObj;

    deleted?.forEach((rowId) => {
      deletedRowObj = rowsForDelete[rowId];
    });
    return deletedRowObj;
  }

  function onRemove(deleted: (string | number)[]) {
    const currentObj: IFormalCodeObj = deleteRows(deleted);
    // eslint-disable-next-line no-empty
    removeSiteCodeFormalCodeAssign({
      dataAccessProfileId,
      siteCode: currentObj?.siteCode?.trim(),
      formalCode: currentObj?.schemeCode?.trim(),
    });
  }

  function onSuccessResponse({
    response,
    siteCode,
    formalCode,
  }: ISiteCodeOnSuccess) {
    response?.status !== 204
      ? setToastState({
          isOpen: true,
          message: warningMessages.removeDataAccessCodes(siteCode, formalCode),
          severity: 'warning',
        })
      : setToastState({
          isOpen: true,
          message: successMessages.removeDataAccessCodes(siteCode, formalCode),
          severity: 'success',
        });
    refetchAllSiteCodeFormalCode();
  }

  function onErrorResponse({
    siteCode,
    formalCode,
  }: ISiteCodeOnError) {
      setToastState({
          isOpen: true,
          message: warningMessages.removeDataAccessCodes(siteCode, formalCode),
          severity: 'error',
        })
      }

}

const ReportGridContainer = styled(Container)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
  padding-left: ${({ theme }) => theme.padding.xs};
  margin-top: ${({ theme }) => theme.padding.xs};
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;
