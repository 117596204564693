import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { formatDateTime } from 'utils/helpers';

type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

const DateFormatter = ({ value }: DateFormatterProps) => {
  return <div>{formatDateTime(value)}</div>;
};

export const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;
