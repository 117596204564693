import { Table as TableBase } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

export function CellComponent(props: TableBase.DataCellProps) {
  const theme = useTheme();
  const { value, row } = { ...props };
  if (row.slaBreach === 0) {
    return (
      <TableCell
        {...props}
        background={theme.palette.reports.green}
        value={
          value && typeof value == 'string' && value != 'NULL' ? value : ''
        }
      />
    );
  }
  if (row.slaBreach === 1) {
    return (
      <TableCell
        {...props}
        background={theme.palette.reports.pink}
        value={
          value && typeof value == 'string' && value != 'NULL' ? value : ''
        }
      />
    );
  }
  return <Table.Cell {...props} />;
}

const TableCell = styled(Table.Cell)<{ background: string }>`
  background-color: ${({ background }) => background};
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
`;
