import { Company, SortOrder } from 'api/resources/models/AutoGenerated';

type CompanyParams = {
  sort: string | null;
  sortOrder: SortOrder;
  page: number;
};

export function useCompaniesParams(search: string): CompanyParams {
  const urlParams = new URLSearchParams(search);
  const page = urlParams.get('page');
  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  if (sort && !isKeyOfCompany(sort))
    throw new Error(`'sort' parameter has incorrect value`);

  return {
    page: page ? Number(page) : 0,
    sort,
    sortOrder: Object.values(SortOrder).includes(sortOrder)
      ? sortOrder
      : SortOrder.Descending,
  };
}

export function isKeyOfCompany(value: string): value is keyof Company {
  type R = keyof Company;
  const rKeys: Record<R, string> = {
    id: '',
    name: '',
    autoMainReference: '',
    lastMainReference: '',
    mainReferencePrefix: '',
    poP3Host: '',
    poP3Port: '',
    poP3UserName: '',
    poP3Password: '',
    poP3UseSSL: '',
    smtpHost: '',
    smtpPort: '',
    smtpReplyTo: '',
    smtpDisplayName: '',
    urlExtension: '',
    configPath: '',
    logTable: '',
    workStart: '',
    workEnd: '',
    locale: '',
    validSourceIP: ''
  };
  return value in rKeys;
}
