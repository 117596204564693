import { Row, Column } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { useActiveWebUsageReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDateTime } from 'utils/helpers';
import { ReportProps } from '../hooks';
import { AxiosError } from 'axios';
import { createReportURL } from 'core/routes';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { useTheme } from '@emotion/react';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import {
  columns,
  dateColumnsName,
  selectOptions,
  tableColumnExtensions,
  ACTIVEWEB_USAGE_REPORT,
  ACTIVEWEB_USAGE_REPORT_GRID_TITLE,
  ACTIVEWEB_USAGE_REPORT_MESSAGE,
} from './constants';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportSelectForm } from 'components/ReportForms/ReportSelectForm';
import { CellComponent } from './CellComponent';
import { customizeDateColumns } from '../helpers';
import { reportLogger } from '../reportLogger';
import { valuesConverter } from './helpers';

export function ActiveWebUsageV2({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    accountStatus: urlParams.get('accountStatus'),
  };

  const queryEnabled = !!urlParamsValue.accountStatus;

  const { isReportFetching, refetchReport, reportData } =
    useActiveWebUsageReport(
      urlParamsValue.accountStatus,
      queryEnabled,
      handleError
    );

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const [accountStatus, setAccountStatus] = useState<string | null>(
    urlParamsValue.accountStatus ?? null
  );

  const isBusy = isReportFetching;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsValue.accountStatus]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={ACTIVEWEB_USAGE_REPORT}
          infoMessage={ACTIVEWEB_USAGE_REPORT_MESSAGE}
        />
        <ReportSelectForm
          isBusy={isBusy}
          label="Account Status"
          handleSubmit={handleSubmit}
          onChange={setAccountStatus}
          selectOptions={selectOptions}
          selectedValue={accountStatus}
        />
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          dateColumnsName={dateColumnsName}
          CustomCellComponent={CellComponent}
          customizeCellExport={customizeCell}
          DateTypeProvider={DateTypeProvider}
          title={ACTIVEWEB_USAGE_REPORT_GRID_TITLE}
          headerTitleForSheet={ACTIVEWEB_USAGE_REPORT}
          tableColumnExtensions={tableColumnExtensions}
          reportHeaderTagLine={`Report produced on ${formatDateTime(
            new Date()
          )}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function customizeCell(cell: ExcelCell, row: Row, column: Column) {
    const { workbook } = cell;
    const workSheet = workbook.getWorksheet('Main');
    Object.assign(workSheet.getRow(3), {
      font: { bold: true },
      alignment: {
        horizontal: 'center',
        wrapText: true,
        vertical: 'middle',
      },
    });
    
    customizeDateColumns(cell, row, column, dateColumnsName);

    if (row.disabled)
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: theme.palette.reports.pink?.replace('#', ''),
        },
      };
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (accountStatus != null)
      if (urlParamsValue.accountStatus == accountStatus) refetchReport();
      else history.replace(createReportURL({ accountStatus }, reportPath));
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('ActiveWeb Usage Report V2'));
    setToast(true);
  }
}
