import { z } from 'zod';
import dayjs from 'dayjs';
import { IP_DUPLICATED_MESSAGE } from './constants';

export const ipValidation = /^(25[0-5]|2[0-4]\d|[01]?\d\d?)(\.(25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/;

export const formSchema = z.object({
    name: z
      .string()
      .min(3, { message: 'Must be 3 or more characters long' })
      .nonempty({ message: 'Required' })
      .max(50, { message: 'Must be max 50 characters long' }),
    autoMainReference: z.boolean(),
    lastMainReference: z.number({
      required_error: 'Last Main Reference is required',
      invalid_type_error: 'Please enter valid number',
    }),
    mainReferencePrefix: z.string().nullable(),
    poP3Host: z.string().nullable(),
    poP3Port: z
      .number({
        invalid_type_error: 'Please enter valid number',
      })
      .nullable(),
    poP3UserName: z.string().nullable(),
    poP3Password: z.string().nullable(),
    poP3UseSSL: z.boolean().nullable(),
    smtpHost: z.string().nullable(),
    smtpPort: z
      .number({
        invalid_type_error: 'Please enter valid number',
      })
      .nullable(),
    smtpReplyTo: z.string().nullable(),
    smtpDisplayName: z.string().nullable(),
    urlExtension: z.string().nullable(),
    configPath: z.string().nullable(),
    logTable: z.string().nullable(),
    workStart: z.date().nullable(),
    workEnd: z.date().nullable(),
    locale: z.string().nullable(),
    validSourceIP: z.array(
      z.object({
        ip: z
          .string()
          .refine(
            (data) => data == '*' || (data && ipValidation.test(data)),
            { message: 'Enter Valid IP Address' }
          ),
      })
    ),
  })
    .refine(data => !dayjs(data.workStart).isAfter(data.workEnd), {
      message: "Work End date cannot be earlier than Work Start date",
      path: ["workEnd"],
    })
    .refine((data) => {
      const seen = new Set();
      for (const item of data.validSourceIP) {
        const propertyValue = item['ip'];
        if (seen.has(propertyValue)) {
          return false; // Found a duplicate
        }
        seen.add(propertyValue);
      }
      return true; // No duplicates found
    }, (data) => ({ message: IP_DUPLICATED_MESSAGE, path: [`validSourceIP.[${data.validSourceIP.length}].ip`] }));
    