import { useMemo, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Company, SortOrder } from 'api/resources/models/AutoGenerated';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import { Container, GridItem } from 'components/Layout';
import {
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import { SelectOption, SortObject } from 'components/types';
import { Heading, Text } from 'components/Typography';
import { TextField } from 'components/TextField';
import InputSelect from 'components/InputSelect';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { createCompaniesUrl, ROUTES } from 'core/routes';
import { orderBy } from 'lodash';
import { useCompanies } from 'pages/hooks';
import { useCompaniesOptions } from 'pages/DataAccessProfiles/hooks';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useCompaniesParams } from './hooks';
import { companiesTableHeaders } from './types';
import { BackButtonItem } from 'components/ReportsComponents/BackButton';
import { BACK_TO_ADMINISTRATION } from 'pages/constants';
import { DesktopButton, MobileButton } from './CreateButtons';
import { NO_COMPANIES_DATA } from './constants';

export function Companies() {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useCompaniesParams(location.search);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();
  const { companies, isCompaniesLoading } = useCompanies({});
  const companiesOptions = useCompaniesOptions();

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sort ?? '',
    sortOrder: urlParams.sortOrder,
  });

  const [sortedCompanies, setSortedCompanies] = useState(companies);
  const [page, setPage] = useState(urlParams.page);
  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(null);

  const sortCompanies = useCallback(
    (sortObj: SortObject) => {
      if (selectedCompany && selectedCompany?.text !== 'All') {
        return companies.filter(({ name }) => name === selectedCompany.text);
      }
      const currentListed = page * itemsPerPage;
      const orderedList = orderBy(
        companies,
        sortObj.sortString,
        sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
      );

      return orderedList?.slice(currentListed, currentListed + itemsPerPage);
    },
    [companies, itemsPerPage, page, selectedCompany]
  );

  const navigateToCompanies = useCallback(() => {
    history.replace(
      createCompaniesUrl(
        sortObject.sortString ?? undefined,
        sortObject.sortOrder
      )
    );
  }, [history, sortObject]);

  useEffect(() => {
    setSortedCompanies(sortCompanies(sortObject));
  }, [companies, sortCompanies, sortObject]);

  useEffect(() => {
    navigateToCompanies();
  }, [sortObject, navigateToCompanies]);

  const onCompanyChange = (company: SelectOption | null) => {
    setPage(0);
    setSelectedCompany(company);
  };

  const totalCount = useMemo(() =>
    selectedCompany && selectedCompany?.text !== 'All' ? sortedCompanies?.length : companies?.length,
    [companies.length, selectedCompany, sortedCompanies.length]);

  return (
    <MainPageContainer
      isLoading={isCompaniesLoading}
      direction="column"
      wrap="nowrap"
    >
      <UpperInfoContainer direction="column">
        <BackButtonItem text={BACK_TO_ADMINISTRATION}/>
        <PageHeader>
          <Heading>Companies</Heading>
        </PageHeader>
        <Container direction="row" alignItems="flex-end">
          <SelectCompanyContainer md={3} xs={12}>
            <TextItem>
              <Text>Select Company</Text>
            </TextItem>
            <InputSelect
              options={companiesOptions}
              value={selectedCompany}
              onChange={onCompanyChange}
              renderInput={(params) => <TextField {...params} />}
              forcePopupIcon
            />
          </SelectCompanyContainer>
        </Container>
        <DesktopButton/>
      </UpperInfoContainer>
      <Container direction="column">
        {!isCompaniesLoading && sortedCompanies.length <= 0 ? (
          <InfoText>{NO_COMPANIES_DATA}</InfoText>
        ) : (
            <DataGrid
              isLoading={isCompaniesLoading}
              headers={companiesTableHeaders}
              data={sortedCompanies}
              onSort={sort}
              onRowClick={rowClick}
              sortObject={sortObject}
              currentPage={page}
              onPageChange={setPage}
              totalCount={totalCount}
              onItemsPerPageChange={setItemsPerPage}
            />
        )}
      </Container>
      <MobileButton/>
    </MainPageContainer>
  );

  function rowClick(row: Company) {
    const route = generatePath(ROUTES.companiesEdit, {
      id: row.id,
    });
    history.push(route);
  }

  function sort(sortValue: keyof Company) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedCompanies(sortCompanies(sortObj));
      return sortObj;
    });
  }
}

const SelectCompanyContainer = styled(GridItem)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: ${({ theme }) => theme.padding.s};
  }
`;

const TextItem = styled(GridItem)`
  padding-bottom: 12px;
`;