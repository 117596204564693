import { Row } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Subheading, Text } from 'components/Typography';
import { Container, GridItem } from 'components/Layout';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useRepairStatusReportCovea, convertToDate } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReportProps, useReportParams } from '../hooks';
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack } from '@devexpress/dx-react-chart';
import { Workbook, Worksheet } from 'exceljs';
import domtoimage from 'dom-to-image';
import {
  convertBlobToBase64,
  dateToString,
  formatDate,
} from '../../../utils/helpers';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import {
  COVEA_REPORT_GRID_TITLE,
  COVEA_REPORT_MESSAGE,
  COVEA_REPORT_TITLE,
  columns,
  rootChartContainerId,
  tableColumnExtensions,
} from './constants';
import {
  SpecialLabelComponent,
  SpecialMarkerComponent,
} from 'components/ReportsComponents/SpecialMarkerAndLabelComponent';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportSearchForm } from 'components/ReportForms/ReportSearchForm';
import { reportLogger } from '../reportLogger';

export function CoveaReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useReportParams(location.search);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) ?? null
  );

  const queryEnabled = !!urlParams.startDate && !!urlParams.endDate;
  const { reportData, refetchReport, isReportFetching } =
    useRepairStatusReportCovea(
      {
        startDate: urlParams.startDate,
        endDate: urlParams.endDate,
      },
      queryEnabled,
      handleError
    );

  const rows = useMemo<Row>(
    () => reportData?.repairSatus,
    [reportData?.repairSatus]
  );

  const chartData = useMemo(
    () => [
      {
        state: 'Summary',
        totalNotifications: reportData?.mobileCount?.recs,
        totalRepairs: reportData?.mobileCount?.repairCount,
        totalMobileNumberSupplied: reportData?.mobileCount?.mobileNumbers,
      },
    ],
    [reportData]
  );

  useEffect(() => {
    navigateToReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.startDate, urlParams.endDate]);

  const isBusy = isReportFetching;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={COVEA_REPORT_TITLE}
          infoMessage={COVEA_REPORT_MESSAGE}
        />
        <ReportSearchForm
          endDate={endDate}
          isDisabled={isBusy}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleSubmit={handleSubmit}
        />
      </UpperInfoContainer>

      {!isBusy && rows && rows?.length === 0 && <NoReportDataMessage />}
      {!isBusy && rows && rows?.length > 0 && (
        <>
          <ReportGridContainer direction="column">
            <NotificationGridItem>
              <Subheading>Notification Summary</Subheading>
            </NotificationGridItem>
            <GridItem>
              <Container spacing={4}>
                <GridItem>
                  <Text fontWeight="600" color="#bebdbd" fontSize="s">
                    Total Notifications
                  </Text>
                  <Text fontSize="l">{reportData?.mobileCount?.recs}</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600" color="#bebdbd" fontSize="s">
                    Total Repairs
                  </Text>
                  <Text fontSize="l">
                    {reportData?.mobileCount?.repairCount}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600" color="#bebdbd" fontSize="s">
                    Total Mobile Number Supplied
                  </Text>
                  <Text fontSize="l">
                    {reportData?.mobileCount?.mobileNumbers}
                  </Text>
                </GridItem>
              </Container>
            </GridItem>
          </ReportGridContainer>

          <ChartPaper id={rootChartContainerId}>
            <Text fontSize="s" color="#bebdbd">
              Summary of notifications
            </Text>
            <Chart data={chartData} rotated height={200}>
              {/* <ArgumentScale factory={scaleBand} /> */}
              <ArgumentAxis showLine={true} />
              <ValueAxis />

              <BarSeries
                valueField="totalNotifications"
                argumentField="state"
                name="Total Notifications"
                color="#01B8AA"
              />
              <BarSeries
                valueField="totalRepairs"
                argumentField="state"
                name="Total Repairs"
                color="#374649"
              />
              <BarSeries
                valueField="totalMobileNumberSupplied"
                argumentField="state"
                name="Total Mobile Number Supplied"
                color="#FD625E"
              />
              <Legend
                position="top"
                markerComponent={SpecialMarkerComponent}
                labelComponent={SpecialLabelComponent}
              />
              <Stack />
            </Chart>
          </ChartPaper>
        </>
      )}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          title={COVEA_REPORT_GRID_TITLE}
          handleNewSheet={handleNewSheet}
          headerTitleForSheet={COVEA_REPORT_TITLE}
          tableColumnExtensions={tableColumnExtensions}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          showAdditionalExportOptions
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  async function handleNewSheet(
    workbook: Workbook,
    customizeHeader: (worksheet: Worksheet) => void,
    headerImageId: number
  ) {
    const graphSheet = workbook.addWorksheet('Repair status usage summary', {
      properties: { defaultColWidth: 20 },
    });
    const newGraphSheet = workbook.getWorksheet('Repair status usage summary');

    customizeHeader(newGraphSheet);
    const chart = document.querySelector(`#${rootChartContainerId}`) as Node;
    const blobData = await domtoimage.toBlob(chart);
    const base64String = await convertBlobToBase64(blobData);

    newGraphSheet.mergeCells(2, 1, 2, 7);
    newGraphSheet.getRow(2).getCell(1).alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };
    newGraphSheet.getRow(2).getCell(1).font = { bold: true, size: 12 };
    newGraphSheet.getRow(2).getCell(1).value = 'Notification summary';

    const generalStyles = {
      font: { bold: true },
      alignment: { horizontal: 'left' },
    };

    for (let rowIndex = 3; rowIndex < 5; rowIndex += 1) {
      newGraphSheet.mergeCells(rowIndex, 1, rowIndex, 2);
      newGraphSheet.mergeCells(rowIndex, 3, rowIndex, 4);
      newGraphSheet.mergeCells(rowIndex, 5, rowIndex, 5);
      Object.assign(newGraphSheet.getRow(rowIndex).getCell(1), generalStyles);
      Object.assign(newGraphSheet.getRow(rowIndex).getCell(3), generalStyles);
      Object.assign(newGraphSheet.getRow(rowIndex).getCell(5), generalStyles);
    }
    newGraphSheet.getRow(3).getCell(1).value = 'Total notifications';
    newGraphSheet.getRow(3).getCell(3).value = 'Total repairs';
    newGraphSheet.getRow(3).getCell(5).value = 'Total mobile numbers supplied';
    newGraphSheet.getRow(4).getCell(1).value =
      reportData?.mobileCount?.recs?.toString() || '';
    newGraphSheet.getRow(4).getCell(3).value =
      reportData?.mobileCount?.repairCount?.toString() || '';
    newGraphSheet.getRow(4).getCell(5).value =
      reportData?.mobileCount?.mobileNumbers?.toString() || '';
    const imageId = workbook.addImage({
      base64: base64String as string,
      extension: 'jpeg',
    });

    newGraphSheet.addImage(imageId, {
      tl: { col: 0, row: 5 },
      ext: { width: 800, height: 250 },
    });

    newGraphSheet.addImage(headerImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 250, height: 50 },
    });
    graphSheet.state = 'visible';
    workbook.eachSheet((worksheet) => {
      if (worksheet.name === 'Main') {
        worksheet.name = 'Repair Status Usage';
      }
    });
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParams.startDate == dateToString(startDate) &&
      urlParams.endDate == dateToString(endDate)
    )
      refetchReport();
    else navigateToReport();
  }

  function navigateToReport() {
    if (!!startDate || !!endDate)
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
          },
          reportPath
        )
      );
  }
  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('Repair Status Portal Usage V1'));
    setToast(true);
  }
}

const ChartPaper = styled('div')`
  width: 55vw;
  z-index: 0;
  background: ${({ theme }) => theme.palette.background.default};
  margin-bottom: ${({ theme }) => theme.padding.xl};
  padding-left: ${({ theme }) => theme.padding.xl};
  #top-left {
    display: none;
  }
  #top-container {
    .MuiList-root {
      display: flex;
      width: 800px;
    }
    .MuiListItem-root {
      min-width: 200px;
    }
  }
`;
const NotificationGridItem = styled(GridItem)`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;
