import { DataGrid } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import { BaseUserAccount, useUserAccountsByCompanyId } from 'pages/hooks';
import { MobileRow } from 'pages/UserAccounts/MobileRow';
import { Company } from './form';
import { usersTableHeaders } from './types';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from 'core/routes';

interface Props {
  company: Company;
}

export function CompanyUserAccounts({ company }: Props) {
  const history = useHistory();
  const { userAccounts, userAccountsLoading } = useUserAccountsByCompanyId(
    company.id
  );
  const isBusy = userAccountsLoading;

  if (!isBusy && userAccounts && userAccounts.length === 0) {
    return (
      <InfoText>
        {"Sorry, we couldn't find any users associated with this company."}
      </InfoText>
    );
  }

  return (
    <DataGrid
      removeLeftPadding
      isLoading={isBusy}
      data={userAccounts}
      onRowClick={rowClick}
      headers={usersTableHeaders}
      mobileRow={<MobileRow users={userAccounts} />}
    />
  );

  function rowClick(row: BaseUserAccount) {
    const route = generatePath(ROUTES.userAccountEdit, {
      id: row.id,
    });

    history.push(route);
  }
}
