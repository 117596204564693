export const NPS_DETAIL_REPORT = 'NPS Detail Report V4';
export const NPS_DETAIL_REPORT_MESSAGE =
  'Provides a detailed list of the ActiveWeb NPS services responses for a chosen NPS category';

export const columns = [
  { name: 'name', title: 'Name' },
  { name: 'siteCode', title: 'Site Code' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'regmark', title: 'Regmark' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'claimId', title: 'Claim Id' },
  { name: 'surveyDate', title: 'Survey Date' },
  { name: 'responseDate', title: 'Response Date' },
  { name: 'response', title: 'Response' },
  { name: 'score', title: 'Score' },
  { name: 'responseText', title: 'Response Text' },
];

export const tableColumnExtensions = [
  { columnName: 'name', wordWrapEnabled: true },
  { columnName: 'siteCode', wordWrapEnabled: true },
  { columnName: 'schemeCode', wordWrapEnabled: true },
  { columnName: 'regmark', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'claimId', wordWrapEnabled: true },
  { columnName: 'surveyDate', wordWrapEnabled: true },
  { columnName: 'responseDate', wordWrapEnabled: true },
  { columnName: 'response', wordWrapEnabled: true },
  { columnName: 'score', wordWrapEnabled: true },
  { columnName: 'responseText', wordWrapEnabled: true },
];

export const nPSScoreOptions = [
  { id: 0, value: 'Detractor', text: 'Detractor' },
  { id: 1, value: 'Promoter', text: 'Promoter' },
  { id: 2, value: 'Passive', text: 'Passive' },
  { id: 3, value: 'All Responses', text: 'All Responses' },
  { id: 4, value: 'All Surveys', text: 'All Surveys' },
];

export const dateColumnsName = ['surveyDate', 'responseDate'];