import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { useInvoiceExtractReport, convertToDate } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { ROUTES } from 'core/routes';
import { ReportProps } from '../hooks';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import {
  INVOICE_EXTRACT_REPORT_GRID_TITLE,
  INVOICE_EXTRACT_REPORT_MESSAGE,
  INVOICE_EXTRACT_REPORT_TITLE,
  columns,
  dateColumnsName,
  workProviderOptions,
} from './constants';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportFormInvoiceExtractForm } from 'components/ReportForms/ReportFormInvoiceExtractForm';
import { customizeCell, valuesConverter } from './helpers';
import { reportLogger } from '../reportLogger';

export function InvoiceExtract({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    startDate: urlParams.get('startDate'),
    endDate: urlParams.get('endDate'),
    dataAccessProfileName: urlParams.get('dataAccessProfileName'),
    basedOnCreatedDateOnly: urlParams.get('basedOnCreatedDateOnly') === 'true',
  };

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) ?? null
  );
  const [basedOnCreatedDateOnly, setBasedOnCreatedDateOnly] = useState<boolean>(urlParamsValue.basedOnCreatedDateOnly ?? false);

  const [dataAccessProfileName, setDataAccessProfileName] = useState<
    string | null
  >(urlParamsValue.dataAccessProfileName ?? null);

  const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate;
  const { reportData, refetchReport, isReportFetching } =
    useInvoiceExtractReport(
      {
        startDate: urlParamsValue.startDate,
        endDate: urlParamsValue.endDate,
        dataAccessProfileName: urlParamsValue.dataAccessProfileName,
        basedOnCreatedDateOnly: urlParamsValue.basedOnCreatedDateOnly,
      },
      queryEnabled,
      handleError
    );

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const isBusy = isReportFetching;

  useEffect(() => {
    navigateToReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    urlParamsValue.dataAccessProfileName,
    urlParamsValue.basedOnCreatedDateOnly,
  ]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={INVOICE_EXTRACT_REPORT_TITLE}
          infoMessage={INVOICE_EXTRACT_REPORT_MESSAGE}
        />
        <ReportFormInvoiceExtractForm
          isBusy={isBusy}
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          handleSubmit={handleSubmit}
          setStartDate={setStartDate}
          workProviderOptions={workProviderOptions}
          dataAccessProfileName={dataAccessProfileName}
          setDataAccessProfileName={setDataAccessProfileName}
          basedOnCreatedDateOnly={basedOnCreatedDateOnly}
          setBasedOnCreatedDateOnly={setBasedOnCreatedDateOnly}
        />
      </UpperInfoContainer>
      {!isBusy && rows && rows?.length === 0 && <NoReportDataMessage />}
      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          dateColumnsName={dateColumnsName}
          DateTypeProvider={DateTypeProvider}
          customizeCellExport={customizeCell}
          title={INVOICE_EXTRACT_REPORT_GRID_TITLE}
          headerTitleForSheet={INVOICE_EXTRACT_REPORT_TITLE}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.dataAccessProfileName == dataAccessProfileName &&
      urlParamsValue.basedOnCreatedDateOnly == basedOnCreatedDateOnly
    )
      refetchReport();
    else navigateToReport();
  }

  function createReportURL(
    startDateValue: string,
    endDateValue: string,
    dataAccessProfileNameValue: string | null,
    reportName: string
  ): string {
    const url = new URLSearchParams();
    if (startDateValue) url.set('startDate', startDateValue);
    if (endDateValue) url.set('endDate', endDateValue);
    if (dataAccessProfileNameValue)
      url.set('dataAccessProfileName', dataAccessProfileNameValue);
    url.set('basedOnCreatedDateOnly', basedOnCreatedDateOnly.toString() ?? 'false');
    return `${ROUTES.reports}/${reportName}?${url.toString()}`;
  }

  function navigateToReport() {
    if (!!startDate && !!endDate && dataAccessProfileName)
      history.replace(
        createReportURL(
          dateToString(startDate),
          dateToString(endDate),
          dataAccessProfileName,
          reportPath
        )
      );
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(
      errorMessages.reports('Invoice Extract Report - PPG Internal')
    );
    setToast(true);
  }
}
