import { Container, GridItem } from 'components/Layout';
import { SecondaryText, Text } from 'components/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { useOwnerDetails } from 'pages/hooks';
import { Skeleton } from '@mui/material';
import { EmailPhone } from './ContactRepairer';
import {
  MainModalContainer,
  ModalButton,
  ModalTextSkeleton,
} from 'components/ModalLayout';

interface ContactOwnerProps {
  repairId: number;
  onContinue: VoidFunction;
}

export function ContactOwner({ repairId, onContinue }: ContactOwnerProps) {
  const { ownerDetails, isLoading } = useOwnerDetails(repairId);
  return (
    <MainModalContainer direction="column">
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Owner Name</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <Text>
            {ownerDetails?.firstName} {ownerDetails?.surname}
          </Text>
        )}
      </GridItem>
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Work Phone</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <Container direction="row">
            <Skeleton variant="circular" className="image"></Skeleton>
            <ModalTextSkeleton />
          </Container>
        ) : (
          <Container direction="row">
            <PhoneIcon className="image" />{' '}
            <EmailPhone href={`tel:${ownerDetails?.telephoneWork}`}>
              {ownerDetails?.telephoneWork}
            </EmailPhone>
          </Container>
        )}
      </GridItem>
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Mobile Phone</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <Container direction="row">
            <Skeleton variant="circular" className="image"></Skeleton>
            <ModalTextSkeleton />
          </Container>
        ) : (
          <Container direction="row">
            <PhoneIcon className="image" />{' '}
            <EmailPhone href={`tel:${ownerDetails?.telephoneMobile}`}>
              {ownerDetails?.telephoneMobile}
            </EmailPhone>
          </Container>
        )}
      </GridItem>
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Home Phone</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <Container direction="row">
            <Skeleton variant="circular" className="image"></Skeleton>
            <ModalTextSkeleton />
          </Container>
        ) : (
          <Container direction="row">
            <PhoneIcon className="image" />{' '}
            <EmailPhone href={`tel:${ownerDetails?.telephoneHome}`}>
              {ownerDetails?.telephoneHome}
            </EmailPhone>
          </Container>
        )}
      </GridItem>
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Email</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <Container direction="row">
            <Skeleton variant="circular" className="image"></Skeleton>
            <ModalTextSkeleton />
          </Container>
        ) : (
          <Container direction="row">
            <MailIcon className="image" />{' '}
            <EmailPhone href={`mailto:${ownerDetails?.email}`}>
              {ownerDetails?.email}
            </EmailPhone>
          </Container>
        )}
      </GridItem>
      <GridItem>
        {isLoading ? (
          <Skeleton variant="rectangular" height={40}></Skeleton>
        ) : (
          <ModalButton onClick={onContinue}>Done</ModalButton>
        )}
      </GridItem>
    </MainModalContainer>
  );
}
