import styled from '@emotion/styled';
import { DatePickerField } from 'components/DatePickerFields';
import { GridItem } from 'components/Layout';
import { Label } from 'components/PageLayout';

export interface DateFieldProps {
  value: Date | null;
  isDisabled: boolean;
  label: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: (date: Date | null) => void;
}

export function DateField({
  value,
  isDisabled,
  label,
  maxDate,
  minDate,
  onChange,
}: Readonly<DateFieldProps>) {

  return (
    <InputButton md={3} sm={3} xs={12}>
      <Label>{label}</Label>
      <DatePickerField
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
      />
    </InputButton>
  );
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  max-width: 330px !important;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;


