import { SLAPerformance } from "api/resources/models/AutoGenerated";

import { theme } from 'core/Theming/theme';
import { convertMinToDayHourMin } from "./convertMinToDayHourMin";
import { getClaimValue } from "./getClaimValue";

export const getGroupSummaryData = (data: SLAPerformance[]) => {
    const totalRow = data.length;
    let duration = 'd0 h0 m0';
    let percentage = '0.00%';
    let totalValue = 0;
    let color = 'FFBBC7';
    data.forEach((a) => {
      totalValue = totalValue + Number(a.slaDuration);
    });
  
    if (totalRow && totalRow !== 0) {
      duration = convertMinToDayHourMin(
        totalValue / totalRow,
        data?.[0]?.timeType || 'S'
      );
    } 
  
    const totalCompletedRow = data
      .map((a: SLAPerformance) => a.slaBreach)
      .filter((a) => a === 0).length;
    if (totalRow !== 0 && totalRow !== null) {
      const value = Number((totalCompletedRow / totalRow) * 100);
      percentage = `${value.toFixed(2)}%`;
      if (value > 85) {
        color = theme.palette.reports.green?.replace('#', '');
      } else if (value > 75) {
        color = theme.palette.reports.orange1?.replace('#', '');
      } else {
        color = theme.palette.reports.pink?.replace('#', '');
      }
    }
  
    const shouldDisplay = data?.filter((item) => item.slaTitle?.includes('Bookin Accuracy')).length > 0;
  
    return {
      claims: `Claims: ${totalRow}`,
      slaCompliance: `SLA Compliance: ${percentage}`,
      averageDuration: `Average Duration: ${duration}`,
      percentages: {
        display: shouldDisplay,
        onTime: getClaimValue('OnTime', data, totalRow),
        late: getClaimValue('Late', data, totalRow),
        early: getClaimValue('Early', data, totalRow),
        notSet: getClaimValue('N/A', data, totalRow),
      },
      color: color,
    };
  };