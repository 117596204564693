import { Row } from '@devexpress/dx-react-grid';
import { SLAPerformance } from 'api/resources/models/AutoGenerated';
import { convertMinToDayHourMin } from './helpers/convertMinToDayHourMin';

export const SLA_PERFORMANCE_REPORT ="SLA Performance V3 Report"
export const SLA_PERFORMANCE_INFO_MESSAGE ="Allows a detailed analysis of SLA Performance."

export const columns = [
  { name: 'repairerGroup', title: 'Repairer Group' },
  { name: 'repairCode', title: 'Repair Code' },
  { name: 'regMark', title: 'Registration' },
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'startDTG', title: 'SLA Start' },
  { name: 'expectedDataValue', title: 'SLA Expected End' },
  { name: 'actualDataValue', title: 'SLA Actual End' },
  {
    name: 'slaDuration',
    title: 'Duration',
    getCellValue: (row: Row) => {
      return convertMinToDayHourMin(row.slaDuration, row.timeType);
    },
  },
  { name: 'hashtag', title: 'Hashtag' },
  {
    name: 'slaTitle',
    title: 'SLA Title',
    getCellValue: (row: SLAPerformance) => {
      return row.slaTitle;
    },
  },
  {
    name: 'passed',
    title: 'Passed',
    getCellValue: (row: SLAPerformance) => {
      if (row.slaTitle?.includes('Bookin Accuracy'))
        switch (row.eol) {
          case 'OnTime':
            return 'On Time';
          case 'Late':
            return 'Late';
          case 'Early':
            return 'Early';
          default:
            return '';
        }
      return '';
    },
  },
];

export const tableColumnExtensions = [
  {
    columnName: 'repairerGroup',
    title: 'Repairer Group',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'repairCode',
    title: 'Repair Code',
    width: 'auto',
    wordWrapEnabled: true,
  },
  {
    columnName: 'regMark',
    title: 'Registration',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'repairerName',
    title: 'Repairer Name',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'claimReference',
    title: 'Claim Reference',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'startDTG',
    title: 'SLA Start',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'expectedDataValue',
    title: 'SLA Expected End',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'actualDataValue',
    title: 'SLA Actual End',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'slaDuration',
    title: 'Duration',
    wordWrapEnabled: true,
    width: 'auto',
  },
  {
    columnName: 'hashtag',
    title: 'Hashtag',
    wordWrapEnabled: true,
    width: 'auto',
  },
];

export const summaryItems = [
  {
    columnName: 'claimReference',
    type: 'count',
  },
  {
    columnName: 'startDTG',
    type: 'percentage',
  },
  {
    columnName: 'slaDuration',
    type: 'average',
    showInGroupFooter: false,
    alignByColumn: true,
  },
];

export const groupSummaryItems = [
  {
    columnName: 'claimReference',
    type: 'count',
    showInGroupFooter: false,
    alignByColumn: true,
  },
  {
    columnName: 'startDTG',
    type: 'percentage',
    showInGroupFooter: false,
    alignByColumn: true,
  },
  {
    columnName: 'slaDuration',
    type: 'average',
    showInGroupFooter: false,
    alignByColumn: true,
  },
  {
    columnName: 'expectedDataValue',
    type: 'claims_percentage',
    showInGroupFooter: false,
    alignByColumn: true,
  },
];

export const defaultGroupingColumn = [
  { columnName: 'slaTitle' },
  { columnName: 'repairerGroup' },
  { columnName: 'repairerName' },
];

export const dateColumnName = [
  'startDTG',
  'breachDTG',
  'completedDTG',
  'actualDataValue',
  'expectedDataValue',
];
