import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useTotalLoss, convertToDate } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReportProps, useReportParams } from '../hooks';
import { dateToString, formatDate } from 'utils/helpers';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { columns, dateColumnsName, tableColumnExtensions } from './constants';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { customizeCell, valuesConverter } from './helpers';
import { ReportSearchForm } from 'components/ReportForms/ReportSearchForm';
import { reportLogger } from '../reportLogger';

export function TotalLoss({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useReportParams(location.search);
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );

  const queryEnabled = !!urlParams.startDate && !!urlParams.endDate;
  const { reportData, refetchReport, isReportFetching } = useTotalLoss(
    {
      startDate: urlParams.startDate,
      endDate: urlParams.endDate,
    },
    queryEnabled,
    handleError
  );

  const rows = useMemo<Row>(() => reportData, [reportData]);
  useEffect(() => {
    navigateToReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.startDate, urlParams.endDate]);

  return (
    <MainPageContainer isLoading={isReportFetching} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName="TL Engineering Schedule Report"
          infoMessage="Displays the forward schedule for the Total Loss Engineering team for the date range provided."
        />
        <ReportSearchForm
          handleSubmit={handleSubmit}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          isDisabled={isReportFetching}
        />
      </UpperInfoContainer>
      {!isReportFetching && rows && rows?.length === 0 && <NoReportDataMessage />}
      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isReportFetching}
          title="tlEngineeringScheduleReport"
          tableColumnExtensions={tableColumnExtensions}
          DateTypeProvider={DateTypeProvider}
          customizeCellExport={customizeCell}
          dateColumnsName={dateColumnsName}
          headerTitleForSheet="TL Engineering Schedule Report"
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParams.startDate == dateToString(startDate) &&
      urlParams.endDate == dateToString(endDate)
    )
      return refetchReport();
    else navigateToReport();
  }

  function navigateToReport() {
    if (!!startDate || !!endDate)
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
          },
          reportPath
        )
      );
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('TL Engineering Schedule Report'));
    setToast(true);
  }
}
