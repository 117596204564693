import { ROUTES } from 'core/routes';
import { ListItem } from './types';

export const ADMINISTRATION = 'Administration';
export const NO_PERMISSION = 'User do not have permissions';

export const listItems: ListItem[] = [
    { id: 0, name: 'Manage User Accounts', link: ROUTES.userAccounts },
    { id: 1, name: 'Manage Account Profiles', link: ROUTES.accountProfiles },
    { id: 2, name: 'Manage Data Access Profiles', link: ROUTES.dataAccessProfiles },
    { id: 3, name: 'Manage Companies', link: ROUTES.companies, disabled: false },
];
