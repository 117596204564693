import { Row } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { useAllSiteConnectionsReport } from 'pages/hooks';
import { useMemo } from 'react';
import { formatDateTime } from 'utils/helpers';
import { ReportProps } from '../hooks';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import {
  ALL_SITE_CONNECTIONS_REPORT_GRID_TITLE,
  ALL_SITE_CONNECTIONS_REPORT_MESSAGE,
  All_SITE_CONNECTION_REPORT,
  columns,
  tableColumnExtensions,
} from './constants';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { reportLogger } from '../reportLogger';

export function AllSitesConnectionReport({
  setErrorMessage,
  setToast,
}: ReportProps) {
  const { isReportFetching, reportData } =
    useAllSiteConnectionsReport(handleError);

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const isBusy = isReportFetching;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={All_SITE_CONNECTION_REPORT}
          infoMessage={ALL_SITE_CONNECTIONS_REPORT_MESSAGE}
        />
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          customizeCellExport={customizeCell}
          tableColumnExtensions={tableColumnExtensions}
          title={ALL_SITE_CONNECTIONS_REPORT_GRID_TITLE}
          headerTitleForSheet={All_SITE_CONNECTION_REPORT}
          reportHeaderTagLine={`Report produced on ${formatDateTime(
            new Date()
          )}`}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function customizeCell(cell: ExcelCell) {
    const { workbook } = cell;
    const workSheet = workbook.getWorksheet('Main');
    Object.assign(workSheet.getRow(3), {
      font: { bold: true },
      alignment: {
        wrapText: true,
        vertical: 'middle',
      },
    });
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('All site connections Report'));
    setToast(true);
  }
}
