import MobileBottomButton, { CreateButton } from 'components/Button';
import { CREATE_NEW_USER } from './constants';
import { ROUTES } from 'core/routes';
import { DesktopContainer } from 'core/Theming/Device';
import { GridItem } from 'components/Layout';

function renderCreateButton() {
  return (
    <CreateButton text={CREATE_NEW_USER} route={ROUTES.userAccountCreate} />
  );
}

export function DesktopButton() {
  return (
    <GridItem md={5}>
      <DesktopContainer>{renderCreateButton()}</DesktopContainer>
    </GridItem>
  );
}

export function MobileButton() {
  return <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>;
}
