import { PageHeader, UpperInfoContainer } from 'components/PageLayout';
import { Container, GridItem } from 'components/Layout';
import { BackButton } from 'components/Button';
import { Heading } from 'components/Typography';
import { PageTab, PageTabItem, TabPanel } from 'components/Tab';
import React, { useEffect, useState } from 'react';
import { CompaniesCreate } from './CompaniesCreate';
import { useParams } from 'react-router-dom';
import { AdminUserAccountParams } from 'core/routes';
import { useCompaniesById } from 'pages/hooks';
import { GrayBackdrop, Loader } from 'components/Loader';
import { CompanyAccountProfiles } from './CompanyAccountProfiles';
import CompaniesEdit from './CompaniesEdit';
import { CompanyDataAccessProfiles } from './CompanyDataAccessProfiles';
import { Company, convertToFormData } from './form';
import { CompanyUserAccounts } from './CompanyUserAccounts';
import { usePageData } from 'core/PageData';

const useCompanyAccountParams = () => {
  const { id: rawUserId } = useParams<AdminUserAccountParams>();
  const companyID = Number(rawUserId);
  return isNaN(companyID) ? 'Company id must be a number' : companyID;
};

export function CompanyCreate() {
  return <CompanyDetails />;
}

export function CompanyEdit() {
  const companyID = useCompanyAccountParams();
  if (typeof companyID === 'string') throw new Error(companyID);
  const { company, isLoading } = useCompaniesById({ companyId: companyID });
  return isLoading || !company ? (
    <GrayBackdrop open={isLoading}>
      <Loader />
    </GrayBackdrop>
  ) : (
    <CompanyDetails company={convertToFormData(company)} />
  );
}

interface Props {
  company?: Company;
}

export function CompanyDetails({ company }: Props) {
  const { pageData } = usePageData();
  const [selectedTab, setSelectedTab] = useState<string>('details');

  useEffect(() => {
    const isPreviousPageUser =
      pageData?.previousPathName?.includes('user-accounts');
    if (isPreviousPageUser) setSelectedTab('userAccounts');
  }, [pageData]);

  return (
    <UpperInfoContainer direction="column">
      <Container direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Companies" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            {company ? `Edit ${company?.name}` : 'Create New Company'}
          </Heading>
        </PageHeader>
      </Container>
      <Container direction="column">
        <Container>
          <GridItem md={6} sm={12} xs={12}>
            <PageTab
              value={selectedTab}
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <PageTabItem label="Details" value="details" />
              <PageTabItem
                label="Account Profiles"
                value="accountProfiles"
                disabled={!company}
              />
              <PageTabItem
                label="Data Access Profiles"
                value="dataAccessProfiles"
                disabled={!company}
              />
              <PageTabItem
                label="User Accounts"
                value="userAccounts"
                disabled={!company}
              />
            </PageTab>
          </GridItem>
        </Container>
        <Container direction="row">
          <GridItem md={12} sm={12} xs={12}>
            {company ? (
              <React.Fragment>
                <GridItem md={6} sm={12} xs={12}>
                  <TabPanel value={selectedTab} index="details">
                    <CompaniesEdit company={company} />
                  </TabPanel>
                </GridItem>
                <GridItem md={6} sm={12} xs={12}>
                  <TabPanel value={selectedTab} index="accountProfiles">
                    <CompanyAccountProfiles company={company} />
                  </TabPanel>
                </GridItem>
                <GridItem md={6} sm={12} xs={12}>
                  <TabPanel value={selectedTab} index="dataAccessProfiles">
                    <CompanyDataAccessProfiles company={company} />
                  </TabPanel>
                </GridItem>
                <GridItem md={12} sm={12} xs={12}>
                  <TabPanel value={selectedTab} index="userAccounts">
                    <CompanyUserAccounts company={company} />
                  </TabPanel>
                </GridItem>
              </React.Fragment>
            ) : (
              <GridItem md={6} sm={12} xs={12}>
                <TabPanel value={selectedTab} index="details">
                  <CompaniesCreate />
                </TabPanel>
              </GridItem>
            )}
          </GridItem>
        </Container>
      </Container>
    </UpperInfoContainer>
  );
}
