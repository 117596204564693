import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PDFExportProps } from '../types';
import { base64HeaderImage } from '../constants';
import { Column, Row } from '@devexpress/dx-react-grid';
import { FEEDBACK_REPORT_TITLE } from 'pages/ReportsDetails/Feedback Report/constants';

const customStyles = {
  0: {cellWidth:40},
  1: {cellWidth:30}, 
  3: {cellWidth:30}, 
  4: {cellWidth:30}, 
  5: {cellWidth:30},
  6: {cellWidth:30}, 
  7: {cellWidth:50},
}

export const convertDataForPDF = (rows: Row[], headers: Column[], valuesConverter?: (rows: Row[]) => Row[]) => {
  let tableData = rows; 
  if (valuesConverter) tableData = valuesConverter(rows)
  return tableData.map(row => {
      return headers.map(header => row[header.name]);
  });
};

export const onPDFExport = ({
  headerTitleForSheet,
  reportHeaderTagLine,
  columns,
  rows,
  title,
  pdfOrientation = 'portrait',
  valuesConverter,
}: PDFExportProps) => {
  const shouldApplyCustomStyle = headerTitleForSheet === FEEDBACK_REPORT_TITLE
  const pdfRowsData = convertDataForPDF( rows ?? [], columns, valuesConverter);

  const doc = new jsPDF({orientation: pdfOrientation});

  doc.addImage(base64HeaderImage, 'png', 10, 10, 55, 15);
  doc.setFont('Times', undefined, 'bold').setFontSize(18);
  doc.text(headerTitleForSheet ?? 'Report', 70, 25);
  doc.setFontSize(14);
  doc.text(reportHeaderTagLine ?? '', 10, 35, { maxWidth: 280 });

  autoTable(doc, {
    head: [columns],
    body: pdfRowsData,
    margin: { top: 50, left: 10 },
    styles: {
      minCellHeight: 9,
      fontSize: 11,
    },
    headStyles: {fillColor: [255, 255, 255], textColor: [0, 0, 0]}, 
    columnStyles: shouldApplyCustomStyle ? customStyles : undefined,
  });

  doc.save(`${title + dayjs().format('DDMMYYYY')}.pdf`);
};
