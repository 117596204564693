import styled from '@emotion/styled';
import InfoText from 'components/InfoText';


export function NoReportDataMessage() {
    return (
        <CustomInfoText>{`Sorry, we couldn't find any reports with this search criteria.`}</CustomInfoText>
    );
}


const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;
