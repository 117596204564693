import { decode } from 'html-entities';
import { Cell as ExcelCell } from 'exceljs';
import { Row, Column } from '@devexpress/dx-react-grid';
import { customizeDateColumns } from "../helpers";
import { dateColumnsName } from "./constants";

export const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
    if (column.name == 'messageContent') {
      const decoded_xml = decode(decode(row.messageContent, { level: 'xml' }), {
        level: 'xml',
      });
      if (decoded_xml !== '') {
        cell.value = decoded_xml;
      }
    }

    customizeDateColumns(cell, row, column, dateColumnsName);
  }