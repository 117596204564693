import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import { PrimaryButton } from './Button';
import { Container } from './Layout';

export const MainModalContainer = styled(Container)`
  width: 360px;
  max-width: 100%;

  & .image {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
  & .secondary-text {
    margin-bottom: ${({ theme }) => theme.margin.xs};
  }
  & .text {
    margin-bottom: ${({ theme }) => theme.margin.m};
  }
`;

export const ModalButton = styled(PrimaryButton)`
  width: 100%;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
`;

export const ModalTextSkeleton = styled(Skeleton)`
  flex: auto;
`;

export const ModalInputContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;
