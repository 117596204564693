import { Container, GridItem } from 'components/Layout';
import styled from '@emotion/styled';
import { DateStatusIcon } from 'components/VehiclesTable/fields/DateStatusIcon';
import { DateStatus } from 'components/VehiclesTable/fields/types';
import { AdditionalStatus } from 'components/VehiclesTable/fields/additionalStatus';

interface Props {
  name: string;
  date?: string;
  status?: DateStatus;
  additionalStatus?: AdditionalStatus | undefined;
  additionalMessage?: string | null;
}

export function MilestoneItem({
  name,
  date,
  status,
  additionalStatus,
  additionalMessage,
}: Props) {
  const getNameXS = () => {
    if (date && additionalStatus) return 4;
    return !date && !additionalStatus && status !== 'amber'
      ? 12
      : 7
  }
  return (
    <MainContainer
      sm="auto"
      xs={12}
      date={date}
      status={status}
      additionalStatus={additionalStatus}
    >
      {(date && status) ||
      (additionalStatus?.comparison === 'overdue' && status) ||
      status ? (
        <GridItem sm="auto" xs={1} className="icon">
          <DateStatusIcon status={status} />
        </GridItem>
      ) : null}
      <GridItem
        sm="auto"
        xs={getNameXS()}
        className="name-item"
      >
        {name}
      </GridItem>
      {date && (
        <GridItem sm="auto" xs={4} className="date-item">
          {date}
        </GridItem>
      )}
      {additionalStatus && (
        <GridItem
          sm="auto"
          xs={additionalStatus.comparison === 'overdue' && !date ? 4 : 3}
          className="date-item"
        >
          {additionalStatus.message}
        </GridItem>
      )}
      {additionalMessage && (
        <GridItem sm="auto" className="message-item">
          {additionalMessage}
        </GridItem>
      )}
    </MainContainer>
  );
}

const MainContainer = styled(Container)<{
  date?: string | null;
  status?: DateStatus;
  additionalStatus?: AdditionalStatus;
}>`
  height: 130px;
  min-width: 136px;

  background: ${({
    status,
    date,
    additionalStatus,
    theme: {
      palette: { background, milestone, primary },
    },
  }) => {
    if (
      !date &&
      additionalStatus?.comparison !== 'overdue' &&
      status !== 'amber'
    )
      return background.default;
    if (status === 'green') return milestone.green;
    if (status === 'amber' || additionalStatus?.comparison === 'overdue')
      return primary.active;
    if (status === 'red') return milestone.red;
    return background.default;
  }};

  border-radius: ${({ theme }) => theme.border.radius};
  margin-right: ${({ theme }) => theme.margin.s};
  margin-bottom: ${({ theme }) => theme.margin.s};
  justify-content: ${({ date, status, additionalStatus }) =>
    (date && status !== 'grey') ||
    additionalStatus?.comparison === 'overdue' ||
    status === 'amber'
      ? 'start'
      : 'center'};

  padding-top: ${({ date, status, additionalStatus }) =>
    (date && status !== 'grey') ||
    additionalStatus?.comparison === 'overdue' ||
    status === 'amber'
      ? '14px'
      : '0px'};
  align-items: center;
  flex-direction: column;
  border: ${({ theme, date, status, additionalStatus }) =>
    (date && status !== 'unknown') ||
    additionalStatus?.comparison === 'overdue' ||
    status === 'amber'
      ? 0
      : `2px solid ${theme.palette.button.light}`};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    height: 56px;
    margin-bottom: ${({ theme }) => theme.margin.s};
    flex-direction: row;
    padding-top: 0px;
  }

  & .icon {
    padding-bottom: 5px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      align-self: center;
      padding-left: ${({ theme }) => theme.margin.m};
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      padding-left: ${({ theme }) => theme.margin.s};
    }
  }

  & .name-item {
    font-size: ${({ theme }) => theme.fontSize.l};
    font-style: normal;
    font-weight: ${({ date, additionalStatus }) =>
      date || additionalStatus?.comparison === 'overdue' ? 700 : 'normal'};
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    width: 100px;
    flex-wrap: wrap;
    color: ${({
      status,
      date,
      additionalStatus,
      theme: {
        palette: { milestone, icon },
      },
    }) => {
      if (!date && status === 'grey') return milestone.inactiveText;
      if (status === 'green' && date) return icon.green;
      if (
        (status === 'amber' && date) ||
        additionalStatus?.comparison === 'overdue' ||
        status === 'amber'
      )
        return icon.warn;
      if (status === 'red' && date) return icon.red;
      return milestone.inactiveText;
    }};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      font-size: ${({ theme }) => theme.fontSize.s};
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      padding-left: ${({ theme }) => theme.margin.m};
      text-align: start;
      align-self: center;

      padding-bottom: ${({ status }) => {
        return status === 'grey' ? '13px' : '0';
      }};
    }
  }

  & .date-item {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.l};
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: ${({
      status,
      date,
      additionalStatus,
      theme: {
        palette: { milestone, icon },
      },
    }) => {
      if (!date && status === 'grey') return milestone.inactiveText;
      if (status === 'green' && date) return icon.green;
      if (
        (status === 'amber' && date) ||
        additionalStatus?.comparison === 'overdue'
      )
        return icon.warn;
      if (status === 'red' && date) return icon.red;
      return milestone.inactiveText;
    }};
    width: 100px;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      text-align: end;
      align-self: center;
      padding-right: ${({ theme }) => theme.margin.m};
    }
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      font-size: ${({ theme }) => theme.fontSize.s};
    }
  }

  & .message-item {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.s};
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.palette.icon.red};
    width: 100px;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      align-self: center;
    }
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      font-size: ${({ theme }) => theme.fontSize.s};
    }
  }
`;
