import styled from '@emotion/styled';
import { SelectOption } from 'components/InputSelect';
import { PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import { Select } from 'components/Select';
import { Label } from 'components/PageLayout';
import { Checkbox } from 'components/Checkbox';
import { MultiSelectNew } from 'components/MultiSelectNew';
import {
  renderSearchButtonText,
  renderSearchIcon,
} from 'pages/ReportsDetails/helpers';
import { useInMobile } from 'core/Theming/Device';
import { ReportFormDetailedCycleTimeProps } from './types';
import { SELECT_ALL } from 'pages/constants';

export const ReportFormDetailedCycleTime = ({
  isBusy,
  endDate,
  siteCode,
  startDate,
  showExcep,
  setEndDate,
  setSiteCode,
  setStartDate,
  setShowExcep,
  handleSubmit,
  repairerGroup,
  setRepairerGroup,
  siteCodesOptions,
  repairerGroupOptions,
}: ReportFormDetailedCycleTimeProps) => {
  const isMobile = useInMobile();
  return (
    <CustomForm onSubmit={handleSubmit}>
      <CustomGridContainer direction="row">
        <InputButton md={3} sm={3} xs={12}>
          <Label>Start Date</Label>
          <DatePickerField
            isDisabled={isBusy}
            onChange={(date) => setStartDate(date)}
            value={startDate}
            maxDate={endDate}
          />
        </InputButton>
        <InputButton md={3} sm={3} xs={12}>
          <Label>End Date</Label>
          <DatePickerField
            isDisabled={isBusy}
            onChange={(date) => setEndDate(date)}
            value={endDate}
            minDate={startDate}
          />
        </InputButton>
        <GridItem md={1} sm={1} xs={2}>
          <Button
            type="submit"
            disabled={
              !startDate ||
              !endDate ||
              !repairerGroup ||
              isBusy ||
              (repairerGroup.value !== SELECT_ALL && siteCode.length <= 0)
            }
          >
            {renderSearchIcon(isMobile)}
            {renderSearchButtonText(isMobile)}
          </Button>
        </GridItem>
      </CustomGridContainer>
      <Container direction="row" alignItems="center">
        <InputButton md={3} sm={3} xs={12}>
          <Label>Repairer Group</Label>
          <Select
            disabled={isBusy}
            options={repairerGroupOptions ?? []}
            selectedId={repairerGroup?.id}
            onChange={(value: SelectOption | null) => {
              setRepairerGroup(value);
              setSiteCode([]);
            }}
          />
        </InputButton>
        <InputButton md={3} sm={3} xs={12}>
          <Label>Select Sites</Label>
          <MultiSelectNew
            disabled={isBusy || repairerGroup?.value === SELECT_ALL}
            options={siteCodesOptions}
            selectedOptions={siteCode}
            onChange={(value) => setSiteCode(value ?? [])}
          />
        </InputButton>
        <InputButton md={3} sm={3} xs={12}>
          <CheckBoxButton>
            <Checkbox
              disabled={isBusy}
              checked={showExcep}
              onChange={(e) => setShowExcep(Boolean(e.target.checked))}
              label="Include Exceptions"
              color="secondary"
            />
          </CheckBoxButton>
        </InputButton>
      </Container>
    </CustomForm>
  );
};

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  max-width: 330px !important;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const CustomGridContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;
const CheckBoxButton = styled('div')`
  margin-top: 20px;
`;
