import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { useMonthlyNotificationVolumeReport } from 'pages/hooks';
import { useMemo } from 'react';
import { ReportProps } from '../hooks';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import {
  NOTIFICATION_VOLUME_REPORT,
  NOTIFICATION_VOLUME_REPORT_HEADER_TAG_LINE,
  NOTIFICATION_VOLUME_REPORT_MESSAGE,
  NOTIFICATION_VOLUME_REPORT_TITTLE,
  columns,
  hiddenColumnNames,
  tableColumnExtensions,
} from './constants';
import { customizeCell } from './customizeCell';
import { reportLogger } from '../reportLogger';

export function MonthlyNotificationVolume({
  setErrorMessage,
  setToast,
}: Readonly<ReportProps>) {
  const { isReportFetching, reportData } =
    useMonthlyNotificationVolumeReport(handleError);

  const rows = useMemo<Row>(() => reportData, [reportData]);
  return (
    <MainPageContainer isLoading={false} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={NOTIFICATION_VOLUME_REPORT}
          infoMessage={NOTIFICATION_VOLUME_REPORT_MESSAGE}
        />
      </UpperInfoContainer>

      {!isReportFetching && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isReportFetching}
          customizeCellExport={customizeCell}
          tableColumnExtensions={tableColumnExtensions}
          title={NOTIFICATION_VOLUME_REPORT_TITTLE}
          headerTitleForSheet={NOTIFICATION_VOLUME_REPORT}
          reportHeaderTagLine={NOTIFICATION_VOLUME_REPORT_HEADER_TAG_LINE}
          hiddenColumnNames={hiddenColumnNames}
          showAdditionalExportOptions
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports(NOTIFICATION_VOLUME_REPORT));
    setToast(true);
  }
}
