import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import { Label } from 'components/PageLayout';
import { useInMobile } from 'core/Theming/Device';
import { Checkbox } from 'components/Checkbox';
import {
  renderSearchButtonText,
  renderSearchIcon,
} from '../../pages/ReportsDetails/helpers';
import { CustomForm } from 'components/ReportsComponents/CustomForm';
import { VehiclesOnSiteSearchProps } from './types';

export function VehiclesOnSiteSearch({
  handleSubmit,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  priorFullMonth,
  setPriorFullMonth,
  isDisabled,
}: VehiclesOnSiteSearchProps) {
  const isMobile = useInMobile();

  return (
    <CustomForm onSubmit={handleSubmit}>
      <CustomGridContainer direction="row" alignItems="center">
        <InputButton md={3} sm={4} xs={12}>
          <Label>Start Date</Label>
          <DatePickerField
            isDisabled={isDisabled}
            onChange={(date) => setStartDate(date)}
            value={startDate}
            maxDate={endDate}
          />
        </InputButton>
        <InputButton md={3} sm={4} xs={12}>
          <Label>End Date</Label>
          <DatePickerField
            isDisabled={isDisabled}
            onChange={(date) => setEndDate(date)}
            value={endDate}
            minDate={startDate}
          />
        </InputButton>
        <CheckBoxButton>
          <Checkbox
            disabled={isDisabled}
            checked={priorFullMonth}
            onChange={(e) => setPriorFullMonth(Boolean(e.target.checked))}
            label="Prior Full Month"
            color="secondary"
          />
        </CheckBoxButton>
        <GridItem md={1} sm={1} xs={2}>
          <Button type="submit" disabled={!startDate || !endDate || isDisabled}>
            {renderSearchIcon(isMobile)}
            {renderSearchButtonText(isMobile)}
          </Button>
        </GridItem>
      </CustomGridContainer>
    </CustomForm>
  );
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  max-width: 330px !important;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const CustomGridContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;

const CheckBoxButton = styled('div')`
  margin-top: 20px;
`;
