import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { useInMobile } from 'core/Theming/Device';
import StartInputDate from 'components/StartInputDate';
import EndDateInput from 'components/EndDateInput';
import {
  renderSearchButtonText,
  renderSearchIcon,
} from '../../pages/ReportsDetails/helpers';
import { CustomForm } from 'components/ReportsComponents/CustomForm';
import { SearchFormProps } from './types';

export function ReportSearchForm({
  handleSubmit,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isDisabled,
}: SearchFormProps) {
  const isMobile = useInMobile();

  return (
    <CustomForm onSubmit={handleSubmit}>
      <Container direction="row">
        <StartInputDate
          label="Start Date"
          isDisabled={isDisabled}
          onChange={(date) => setStartDate(date)}
          value={startDate}
          maxDate={endDate}
        />
        <EndDateInput
          label="End Date"
          isDisabled={isDisabled}
          onChange={(date) => setEndDate(date)}
          value={endDate}
          minDate={startDate}
        />
        <GridItem md={1} sm={1} xs={2}>
          <Button type="submit" disabled={!startDate || !endDate || isDisabled}>
            {renderSearchIcon(isMobile)}
            {renderSearchButtonText(isMobile)}
          </Button>
        </GridItem>
      </Container>
    </CustomForm>
  );
}

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
