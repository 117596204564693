import { Column } from '@devexpress/dx-react-grid';

export const columns: Column[] = [
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'claimReference', title: 'Claim Ref.' },
  { name: 'regMark', title: 'Vehicle Reg.' },
  { name: 'messageType', title: 'Message Type' },
  { name: 'messageDescription', title: 'Message Description' },
  { name: 'messageDate', title: 'Message Date' },
  { name: 'messageTime', title: 'Message Time' },
  { name: 'smsBody', title: 'SMS Body' },
];

export const tableColumnExtensions = [
  { columnName: 'repairerName', wordWrapEnabled: true },
  { columnName: 'schemeCode', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'regMark', wordWrapEnabled: true },
  { columnName: 'messageType', wordWrapEnabled: true },
  { columnName: 'messageDescription', wordWrapEnabled: true },
  { columnName: 'messageDate', wordWrapEnabled: true },
  { columnName: 'messageTime', wordWrapEnabled: true },
  { columnName: 'smsBody', wordWrapEnabled: true },
];

export const summaryItems = [{ columnName: 'repairerName', type: 'count' }];

export const dateColumnsName = ['messageDate'];
