import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
    Label,
} from 'components/PageLayout';
import { useInMobile } from 'core/Theming/Device';
import { Select } from 'components/Select';
import { MultiSelectNew } from 'components/MultiSelectNew';
import { CustomForm } from 'components/ReportsComponents/CustomForm';
import { renderSearchButtonText, renderSearchIcon } from 'pages/ReportsDetails/helpers';
import { NPSDetailFormProps } from './types';

export function NPSDetailForm({
    handleSubmit,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isDisabled,
    nPSScore,
    setNPSScore,
    nPSScoreOptions,
    repairSites,
    setRepairSites,
    repairerSitesOptions,
    schemeCodes,
    setSchemeCodes,
    schemeCodesOptions,
}: NPSDetailFormProps) {
    const isMobile = useInMobile();

    return (
        <CustomForm onSubmit={handleSubmit}>
            <CustomGridContainer direction="row" alignItems="center">
                <InputButton md={3} sm={4} xs={12}>
                    <Label>Start Date</Label>
                    <DatePickerField
                        isDisabled={isDisabled}
                        onChange={(date) => setStartDate(date)}
                        value={startDate}
                        maxDate={endDate}
                    />
                </InputButton>
                <InputButton md={3} sm={4} xs={12}>
                    <Label>End Date</Label>
                    <DatePickerField
                        isDisabled={isDisabled}
                        onChange={(date) => setEndDate(date)}
                        value={endDate}
                        minDate={startDate}
                    />
                </InputButton>
                <GridItem md={1} sm={1} xs={2}>
                    <Button
                        type="submit"
                        disabled={
                            isDisabled ||
                            !startDate ||
                            !endDate ||
                            !nPSScore ||
                            schemeCodes.length <= 0 ||
                            repairSites.length <= 0
                        }
                    >
                        {renderSearchIcon(isMobile)}
                        {renderSearchButtonText(isMobile)}
                    </Button>
                </GridItem>
            </CustomGridContainer>
            <CustomGridContainer direction="row" alignItems="center">
                <InputButton md={3} sm={3} xs={12}>
                    <Label>Repairer Sites</Label>
                    <MultiSelectNew
                        disabled={isDisabled}
                        options={repairerSitesOptions}
                        selectedOptions={repairSites}
                        onChange={(value) => setRepairSites(value || [])}
                    />
                </InputButton>
                <InputButton md={3} sm={3} xs={12}>
                    <Label>Scheme Codes</Label>
                    <MultiSelectNew
                        disabled={isDisabled}
                        options={schemeCodesOptions}
                        selectedOptions={schemeCodes}
                        onChange={(value) => setSchemeCodes(value || [])}
                    />
                </InputButton>
            </CustomGridContainer>
            <Container direction="row" alignItems="center">
                <InputButton md={3} sm={3} xs={12}>
                    <Label>NPS Score</Label>
                    <Select
                        disabled={isDisabled}
                        options={nPSScoreOptions || []}
                        selectedId={
                            nPSScoreOptions?.find(
                                (e: { value: string | null }) => e.value == nPSScore
                            )?.id
                        }
                        onChange={(item) => {
                            setNPSScore(item.value);
                        }}
                    />
                </InputButton>
            </Container>
        </CustomForm>
    );
}

const InputButton = styled(GridItem)`
    padding-right: ${({ theme }) => theme.margin.s};
    max-width: 330px !important;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      margin-bottom: 6px;
    }
    & svg {
      width: 20px;
      height: 20px;
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  `;

const Button = styled(PrimaryButton)`
    min-width: 40px;
    font-size: ${({ theme }) => theme.fontSize.s};
    height: 40px;
    padding: 12px;
    margin-top: 20.5px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      width: 100%;
    }
  `;

const CustomGridContainer = styled(Container)`
    margin-bottom: ${({ theme }) => theme.margin.m};
  `;
