import { Container, GridItem } from 'components/Layout';
import styled from '@emotion/styled';
import { DateStatusIcon } from 'components/VehiclesTable/fields/DateStatusIcon';

interface Props {
  name: string;
  date?: string;
  additionalMessage?: string | null;
}

export function BasicMilestoneItem({ name, date, additionalMessage }: Props) {
  return (
    <MainContainer sm="auto" xs={12} date={date}>
      {date && (
        <GridItem sm="auto" xs={1} className="icon">
          <DateStatusIcon status="green" />
        </GridItem>
      )}
      <GridItem sm="auto" xs={date ? 7 : 12} className="name-item">
        {name}
      </GridItem>
      {date && (
        <GridItem sm="auto" xs={4} className="date-item">
          {date}
        </GridItem>
      )}
      {additionalMessage && (
        <GridItem sm="auto" className="message-item">
          {additionalMessage}
        </GridItem>
      )}
    </MainContainer>
  );
}

const MainContainer = styled(Container)<{
  date?: string | null;
}>`
  height: 130px;
  min-width: 130px;

  background: ${({ theme, date }) =>
    date ? theme.palette.milestone.green : theme.palette.background.default};

  border-radius: ${({ theme }) => theme.border.radius};
  margin-right: ${({ theme }) => theme.margin.s};
  margin-bottom: ${({ theme }) => theme.margin.s};
  justify-content: ${({ date }) => (date ? 'start' : 'center')};
  padding-top: ${({ date }) => (date ? '14px' : '0px')};
  align-items: center;
  flex-direction: column;
  border: ${({ theme, date }) =>
    date ? 0 : `2px solid ${theme.palette.button.light}`};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    height: 56px;
    margin-bottom: ${({ theme }) => theme.margin.s};
    flex-direction: row;
    padding-top: 0px;
  }

  & .icon {
    padding-bottom: 5px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      align-self: center;

      padding-left: ${({ theme }) => theme.margin.m};
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      padding-left: ${({ theme }) => theme.margin.s};
    }
  }

  & .name-item {
    font-size: ${({ theme }) => theme.fontSize.l};
    font-style: normal;
    font-weight: ${({ date }) => (date ? 700 : 'normal')};
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: ${({ theme, date }) =>
      date ? theme.palette.icon.green : theme.palette.milestone.inactiveText};
    width: 100px;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      font-size: ${({ theme }) => theme.fontSize.s};
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      padding-left: ${({ theme }) => theme.margin.m};
      text-align: start;
      align-self: center;
      padding-bottom: 0px;
    }
  }

  & .date-item {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.l};
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.palette.icon.green};
    width: 100px;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      text-align: end;
      align-self: center;
      padding-right: ${({ theme }) => theme.margin.m};
    }
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      font-size: ${({ theme }) => theme.fontSize.s};
    }
  }

  & .message-item {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.s};
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.palette.icon.red};
    width: 100px;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      align-self: center;
    }
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
      font-size: ${({ theme }) => theme.fontSize.s};
    }
  }
`;
