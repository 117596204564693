import InputSelect from 'components/InputSelect';
import { Container, GridItem } from 'components/Layout';
import {
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import { Heading, Text } from 'components/Typography';
import styled from '@emotion/styled';
import { useCompaniesOptions } from 'pages/DataAccessProfiles/hooks';
import { useCallback, useEffect, useState } from 'react';
import { AccountProfilesParams } from './hooks';
import { generatePath, useHistory } from 'react-router-dom';
import { TextField } from 'components/TextField';
import { Select } from 'components/Select';
import { useAccountProfiles } from 'pages/hooks';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import InfoText from 'components/InfoText';
import { DataGrid, getSortObject } from 'components/DataGrid';
import {
  accountProfilesTableHeaders,
  disabledAccountProfileColumns,
} from './types';
import { SelectOption, SortObject } from 'components/types';
import { AccountProfile, SortOrder } from 'api/resources/models/AutoGenerated';
import { createAccountProfilesUrl, ROUTES } from 'core/routes';
import { mapAccountProfiles } from './helpers';
import { CustomCellRender } from './CustomCellRender';
import { NO_ACCOUNT_PROFILES_DATA, showOptions } from './constants';
import { DesktopButton, MobileButton } from './CreateButtons';
import { BackButtonItem } from 'components/ReportsComponents/BackButton';
import { BACK_TO_ADMINISTRATION } from 'pages/constants';

export function AccountProfilesSearch({
  urlParams,
}: {
  urlParams: AccountProfilesParams;
}) {
  const history = useHistory();
  const companiesOptions = useCompaniesOptions();

  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString,
    sortOrder: urlParams.sortOrder,
  });

  const { accountProfiles, isAccountProfilesLoading } = useAccountProfiles({
    config: {
      skip: urlParams.page * itemsPerPage,
      take: itemsPerPage,
      companyId: urlParams.companyId,
      isTemplate: urlParams.isTemplate,
      sortOrder: urlParams.sortOrder,
      sortString: urlParams.sortString,
    },
    onSuccess: () => {
      setPage(urlParams.page);
      setSortObject({
        sortString: urlParams.sortString,
        sortOrder: urlParams.sortOrder,
      });
      setSelectedCompanyId(urlParams.companyId);
      setShowOption(getShowOption());
    },
  });
  const profiles = accountProfiles?.items.map(mapAccountProfiles);

  const getShowOption = () =>
    showOptions.find((i) => i.value === urlParams.isTemplate) ?? showOptions[0];

  const [selectedCompanyId, setSelectedCompanyId] = useState(
    urlParams.companyId
  );
  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(
    null
  );
  const [page, setPage] = useState(urlParams.page);
  const [showOption, setShowOption] = useState<
    SelectOption<boolean | undefined>
  >(getShowOption());

  const navigateToAccountProfiles = useCallback(() => {
    history.replace(
      createAccountProfilesUrl(
        page,
        selectedCompanyId,
        showOption?.value,
        sortObject?.sortOrder,
        sortObject?.sortString
      )
    );
  }, [history, showOption, page, selectedCompanyId, sortObject]);

  useEffect(() => {
    navigateToAccountProfiles();
    setSelectedCompany(
      companiesOptions.find((c) => c.id === selectedCompanyId) ?? null
    );
  }, [
    page,
    sortObject,
    selectedCompanyId,
    showOption,
    companiesOptions,
    navigateToAccountProfiles,
  ]);

  return (
    <MainPageContainer
      isLoading={isAccountProfilesLoading}
      wrap="nowrap"
      direction="column"
    >
      <UpperInfoContainer direction="column">
        <BackButtonItem text={BACK_TO_ADMINISTRATION}/>
        <PageHeader>
          <Heading>Account Profiles</Heading>
        </PageHeader>

        <Container direction="row" alignItems="flex-end">
          <CompanyContainer md={3} xs={12}>
            <TextItem>
              <Text>Select Company</Text>
            </TextItem>
            <InputSelect
              options={companiesOptions}
              value={selectedCompany}
              onChange={(company: SelectOption | null) => {
                setPage(0);
                setSelectedCompany(company);
                setSelectedCompanyId(company?.id);
              }}
              renderInput={(params) => <TextField {...params} />}
              forcePopupIcon
            />
          </CompanyContainer>

          <TemplatesContainer md={2} xs={12}>
            <TextItem>
              <Text>Show</Text>
            </TextItem>
            <Select
              options={showOptions}
              selectedId={showOption?.id}
              onChange={(value) => {
                setPage(0);
                setShowOption(value);
              }}
            />
          </TemplatesContainer>
          <DesktopButton/>
        </Container>
      </UpperInfoContainer>

      {!isAccountProfilesLoading &&
        accountProfiles?.items &&
        accountProfiles?.items.length <= 0 ? (
        <InfoText>{NO_ACCOUNT_PROFILES_DATA}</InfoText>
      ) : (
        <DataGrid
          isLoading={isAccountProfilesLoading}
          headers={accountProfilesTableHeaders}
          data={profiles}
          onSort={sort}
          disabledSortColumns={disabledAccountProfileColumns}
          sortObject={sortObject}
          totalCount={accountProfiles?.totalRows}
          currentPage={page}
          onPageChange={setPage}
          onItemsPerPageChange={setItemsPerPage}
          onRowClick={rowClick}
          customCellRender={CustomCellRender}
        />
      )}
      <MobileButton/>
    </MainPageContainer>
  );

  function sort(sortValue: string) {
    setSortObject((prevValue) => {
      return getSortObject(
        sortValue,
        prevValue || { sortString: sortValue, sortOrder: SortOrder.Ascending }
      );
    });
  }

  function rowClick(row: AccountProfile) {
    const route = generatePath(ROUTES.accountProfileEdit, {
      id: row.accountProfileId,
    });

    history.push(route);
  }
}

const TextItem = styled(GridItem)`
  padding-bottom: 12px;
`;

const CompanyContainer = styled(GridItem)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-right: 0px;
    margin-bottom: ${({ theme }) => theme.padding.s};
  }
`;

const TemplatesContainer = styled(GridItem)`
  padding-left: ${({ theme }) => theme.padding.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-left: 0px;
    margin-top: ${({ theme }) => theme.padding.s};
    margin-bottom: ${({ theme }) => theme.padding.m};
  }
`;
