import { Row } from "@devexpress/dx-react-grid";
import { monthNames } from "./constants";

export const valuesConverter = (data: Row[]) => {
    return data.map((row) => {
      return {
        ...row,
        month: row.month && monthNames[row.month - 1],
        key2Key: row.key2Key?.toFixed(2),
        sumLabourHours: row.sumLabourHours?.toFixed(2),
        avgLabourHours: row.avgLabourHours?.toFixed(2),
        avgOnsiteToStarted: row.avgOnsiteToStarted?.toFixed(2),
        avgStartToCompleted: row.avgStartToCompleted?.toFixed(2),
        avgCompletedToDelivered: row.avgCompletedToDelivered?.toFixed(2),
        avgCreatedToOnsite: row.avgCreatedToOnsite?.toFixed(2),
      };
    });
  };