
import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { Label } from 'components/PageLayout';
import { useInMobile } from 'core/Theming/Device';
import { Select } from 'components/Select';

import {
    renderSearchButtonText,
    renderSearchIcon,
} from 'pages/ReportsDetails/helpers';
import { WorkInProgressSearchFormProps } from './types';
import { CustomForm } from 'components/ReportsComponents/CustomForm';

export function WorkInProgressSearchForm({
    handleSubmit,
    options,
    repairGroup,
    setRepairGroup,
    isBusy,
}: WorkInProgressSearchFormProps) {
    const isMobile = useInMobile();

    return (
        <CustomForm onSubmit={handleSubmit}>
            <Container direction="row">
                <InputButton md={3} sm={4} xs={12}>
                    <Label>Repairer Groups</Label>
                    <Select
                        disabled={isBusy}
                        options={options}
                        onChange={(status) => setRepairGroup(status.value)}
                        selectedId={
                            options?.find(
                                (e: { value: string | null }) => e.value == repairGroup
                            )?.id
                        }
                    />
                </InputButton>
                <GridItem md={1} sm={1} xs={2}>
                    <Button type="submit" disabled={!repairGroup || isBusy}>
                        {renderSearchIcon(isMobile)}
                        {renderSearchButtonText(isMobile)}
                    </Button>
                </GridItem>
            </Container>
        </CustomForm>
    );
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  @media (max-width: ${({ theme }) =>
        `${theme.breakpoints.values.sm}px`}) {
      margin - bottom: 6px;
  }
  & svg {
      width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
