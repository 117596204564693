import { Column, TableColumnExtensions } from './types';

export const LVOWN_KEY2KEY_REPORT_TITLE = 'LVOWN Key2Key Report';

export const LVOWN_KEY2KEY_REPORT_MESSAGE =
  'This report shows the number of LVOWN Key2Key transactions for each month.';

export const LVOWN_KEY2KEY_REPORT_GRID_TITLE = 'LVOWNKey2KeyReport';

const date = new Date();
const currentYear = date.getFullYear();
const years = Array.from(
  { length: currentYear - 1990 + 1 },
  (v, k) => currentYear - k
);
export const selectOptions = years.map((year) => ({
  id: year,
  text: `${year}`,
  value: `${year}`,
}));

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const columns: Column[] = [
  { name: 'code', title: 'Code' },
  {
    name: 'year',
    title: 'Year',
  },
  {
    name: 'month',
    title: 'Month',
    getCellValue: (row) => row.month && monthNames[row.month - 1],
  },
  {
    name: 'key2Key',
    title: 'Key2Key',
    getCellValue: (row) => row.key2Key?.toFixed(2),
  },
  {
    name: 'sumLabourHours',
    title: 'Sum Labour Hours',
    getCellValue: (row) => row.sumLabourHours?.toFixed(2),
  },
  {
    name: 'avgLabourHours',
    title: 'Average Labour Hours',
    getCellValue: (row) => row.avgLabourHours?.toFixed(2),
  },
  {
    name: 'avgOnsiteToStarted',
    title: 'Average Onsite To Started',
    getCellValue: (row) => row.avgOnsiteToStarted?.toFixed(2),
  },
  {
    name: 'avgStartToCompleted',
    title: 'Average Start To Completed',
    getCellValue: (row) => row.avgStartToCompleted?.toFixed(2),
  },
  {
    name: 'avgCompletedToDelivered',
    title: 'Avearge Completed To Delivered',
    getCellValue: (row) => row.avgCompletedToDelivered?.toFixed(2),
  },
  {
    name: 'avgCreatedToOnsite',
    title: 'Average Created To Onsite',
    getCellValue: (row) => row.avgCreatedToOnsite?.toFixed(2),
  },
];

export const tableColumnExtensions: TableColumnExtensions[] = [
  { columnName: 'code', wordWrapEnabled: true },
  { columnName: 'avgCompletedToDelivered', wordWrapEnabled: true },
  { columnName: 'avgLabourHours', wordWrapEnabled: true },
  {
    columnName: 'avgOnsiteToStarted',
    wordWrapEnabled: true,
  },
  {
    columnName: 'avgStartToCompleted',
    wordWrapEnabled: true,
  },
  { columnName: 'avgCreatedToOnsite', wordWrapEnabled: true },
  {
    columnName: 'key2Key',
    wordWrapEnabled: true,
  },
  {
    columnName: 'month',
    wordWrapEnabled: true,
  },
  { columnName: 'sumLabourHours', wordWrapEnabled: true },
  {
    columnName: 'year',
    wordWrapEnabled: true,
  },
];
