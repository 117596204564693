import { TableSummaryRow } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Text } from 'components/Typography';

export function GroupSummaryItemComponent(data: TableSummaryRow.ItemProps) {
  const theme = useTheme();

  if (data.type === 'percentage') {
    let color = '';
    if (data?.value) {
      const value = Number(data?.value.toString().substring(0, 4));
      if (value > 85) {
        color = theme.palette.reports.green;
      } else if (value > 75) {
        color = theme.palette.reports.orange1;
      } else {
        color = theme.palette.reports.pink;
      }
    }
    return (
      <StyledText fontSize="s" fontWeight="bold" bgColor={color}>
        SLA Compliance:
        <br /> {data.value}
      </StyledText>
    );
  }
  if (data.type === 'average') {
    return (
      <Text fontSize="s" fontWeight="bold">
        Average Duration: <br /> {data.value}
      </Text>
    );
  }
  if (data.type === 'claims_percentage') {
    if (data.value?.toString()?.includes(',')) {
      const values = data?.value?.toString()?.split(',');
      if (values?.[0] == 'true')
        return (
          <Text fontSize="s" fontWeight="bold">
            {values?.[1]}% On Time
            <br />
            {values?.[2]}% Early
            <br />
            {values?.[3]}% Late
            <br />
            {values?.[4]}% Not Set
            <br />
          </Text>
        );
    }
  }
  return <Text fontSize="s" fontWeight="bold" />;
}

const StyledText = styled(Text)<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ theme }) => theme.padding.s};
  line-height: ${({ theme }) => theme.fontSize.l};
`;
