import { useInDesktop } from 'core/Theming/Device';
import { FormEvent } from 'react';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import { FilterModal } from '../../components/MobileFilterModal';
import { FilterButton } from 'pages/VehiclesSearch/VehiclesSearchFilters';
import { StyledGridItem } from 'components/PageLayout';
import { GridItem } from 'components/Layout';

export interface SearchFormProps {
  handleSubmit: (e: FormEvent) => void;
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: (data: boolean) => void;
  children: JSX.Element;
}

export function SearchForm({
  handleSubmit,
  isFilterModalOpen,
  setIsFilterModalOpen,
  children,
}: Readonly<SearchFormProps>) {
  const isDesktop = useInDesktop();

  return (
    <>
      {isDesktop ? (
        <StyledGridItem>{children}</StyledGridItem>
      ) : (
        <StyledGridItem>
          <FilterButton
            onClick={() => setIsFilterModalOpen(true)}
            type="button"
          >
            <FilterIcon />
            {'Search'}
          </FilterButton>
          <FilterModal
            isOpen={isFilterModalOpen}
            onClose={() => setIsFilterModalOpen(false)}
            title={'Search'}
          >
            <form onSubmit={handleSubmit}>
              <GridItem>{children}</GridItem>
            </form>
          </FilterModal>
        </StyledGridItem>
      )}
    </>
  );
}
