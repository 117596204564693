import { Column } from '@devexpress/dx-react-grid';
import { SVGBBoxElement } from './types';

export const rootChartContainerId = 'chart-container';

export const COVEA_REPORT_TITLE = 'Repair Status Portal Usage V1';

export const COVEA_REPORT_MESSAGE = 'Shows the repair status portal usage.';

export const COVEA_REPORT_GRID_TITLE = 'RepairStatusPortalUsage';

export const originalGetBBox = (SVGElement.prototype as SVGBBoxElement).getBBox;

export const tableColumnExtensions = [
  { columnName: 'repairCode', width: '13%', wordWrapEnabled: true },
  { columnName: 'repairer', width: '15%', wordWrapEnabled: true },
  { columnName: 'regmark', width: '10%', wordWrapEnabled: true },
  { columnName: 'claimReference', width: '10%', wordWrapEnabled: true },
  { columnName: 'onSiteDate', width: '10%', wordWrapEnabled: true },
  { columnName: 'smsSentDate', width: '10%', wordWrapEnabled: true },
  { columnName: 'termsAcceptedDate', width: '10%', wordWrapEnabled: true },
  { columnName: 'numberOfHits', width: '10%', wordWrapEnabled: true },
];

export const columns: Column[] = [
  { name: 'repairCode', title: 'Repair Code' },
  { name: 'repairer', title: 'Repairer Name' },
  { name: 'regmark', title: 'Vehicle Reg' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'onSiteDate', title: 'On Site Date' },
  { name: 'smsSentDate', title: 'SMS Sent Date' },
  { name: 'termsAcceptedDate', title: 'Terms Accepted Date' },
  { name: 'numberOfHits', title: 'Number of Hits' },
];
