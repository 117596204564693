import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { AxiosError } from 'axios';
import PrimaryToast from 'components/PrimaryToast';
import { useCreateCompany } from 'pages/hooks';
import React, { useState } from 'react';
import { CompaniesDetails } from './CompaniesDetails';
import { Company, convertToAPIData } from './form';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from 'core/routes';

export function CompaniesCreate() {
  const history = useHistory();
  const { createCompany, isCreating } = useCreateCompany();
  const [createdCompanyId, setCreatedCompanyId] = useState<number>();
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  return (
    <React.Fragment>
      <CompaniesDetails onSubmit={onSubmit} isBusy={isCreating} />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => {
          setToastState({ ...toastState, isOpen: false });
          if (createdCompanyId) navigateToEditCompany(createdCompanyId);
        }}
        severity={toastState.severity}
      />
    </React.Fragment>
  );

  function navigateToEditCompany(companyId: number) {
    history.replace(generatePath(ROUTES.companiesEdit, { id: companyId }));
  }

  function onSubmit(data: Company) {
    createCompany(convertToAPIData({ ...data }))
      .then((response) => {
        setToastState({
          message: successMessages.companyCreated,
          isOpen: true,
          severity: 'success',
        });
        setCreatedCompanyId(response.id);
      })
      .catch((error: AxiosError) => handleError(error));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(_error: AxiosError) {
    setToastState({
      message: errorMessages.companyCreated,
      isOpen: true,
      severity: 'error',
    });
  }
}
