import { Column, TableColumnExtensions } from './types';

export const All_SITE_CONNECTION_REPORT = 'All site connections';

export const ALL_SITE_CONNECTIONS_REPORT_MESSAGE =
  'A list of all connections in the system for every site. INTERNAL.';

export const ALL_SITE_CONNECTIONS_REPORT_GRID_TITLE =
  'allSitesConnectionsReport';

export const tableColumnExtensions: TableColumnExtensions[] = [
  { columnName: 'accountStatus', wordWrapEnabled: true },
  { columnName: 'accountStatus', wordWrapEnabled: true },
  { columnName: 'allianz', wordWrapEnabled: true },
  { columnName: 'audatex', wordWrapEnabled: true },
  { columnName: 'admiral', wordWrapEnabled: true },
  { columnName: 'capsInboundConnection', wordWrapEnabled: true },
  {
    columnName: 'capsOutboundConnections',
    wordWrapEnabled: true,
  },
  { columnName: 'coveaCode', wordWrapEnabled: true },
  { columnName: 'dlgNetwork', wordWrapEnabled: true },
  { columnName: 'dlgvwg', wordWrapEnabled: true },
  { columnName: 'esure', wordWrapEnabled: true },
  { columnName: 'imsCode', wordWrapEnabled: true },
  { columnName: 'innovationSiteCode', wordWrapEnabled: true },
  { columnName: 'itasNetwork', wordWrapEnabled: true },
  { columnName: 'ivmSiteCode', wordWrapEnabled: true },
  { columnName: 'kindertons', wordWrapEnabled: true },
  { columnName: 'lvConnection', wordWrapEnabled: true },
  { columnName: 'name', wordWrapEnabled: true },
  { columnName: 'repairerGroup', wordWrapEnabled: true },
  { columnName: 'nargNetwork', wordWrapEnabled: true },
  { columnName: 'nnsSiteCode', wordWrapEnabled: true },
  { columnName: 'address', wordWrapEnabled: true },
  { columnName: 'postcode', wordWrapEnabled: true },
  // { columnName: 'repairerID', wordWrapEnabled: true },
  { columnName: 'rsa', wordWrapEnabled: true },
  { columnName: 'siteCode', wordWrapEnabled: true },
  { columnName: 'slaterGordon', wordWrapEnabled: true },
  { columnName: 'systemType', wordWrapEnabled: true },
  { columnName: 'thrifty', wordWrapEnabled: true },
];

export const columns: Column[] = [
  { name: 'siteCode', title: 'Site Code' },
  { name: 'audatex', title: 'Audatex' },
  { name: 'admiral', title: 'Admiral' },
  { name: 'name', title: 'Name' },
  { name: 'repairerGroup', title: 'Group' },
  { name: 'address', title: 'Address' },
  { name: 'postcode', title: 'Postcode' },
  { name: 'systemType', title: 'System Type' },
  { name: 'accountStatus', title: 'Account Status' },
  { name: 'allianz', title: 'Allianz' },
  {
    name: 'capsInboundConnection',
    title: 'CAPS Inbound Connection',
  },
  {
    name: 'capsOutboundConnections',
    title: 'CAPS Outbound Connections',
  },
  { name: 'coveaCode', title: 'Covea Code' },
  { name: 'dlgNetwork', title: 'DLG Network' },
  {
    name: 'esure',
    title: 'Esure',
  },
  { name: 'imsCode', title: 'IMSCode' },
  { name: 'innovationSiteCode', title: 'Innovation Site Code' },
  { name: 'ivmSiteCode', title: 'IVMSite Code' },
  { name: 'lvConnection', title: 'LV Connection' },
  { name: 'nargNetwork', title: 'NARG Network' },
  { name: 'nnsSiteCode', title: 'NNSSite Code' },
  // { name: 'repairerID', title: 'Repairer ID' },
  { name: 'rsa', title: 'RSA' },
  { name: 'slaterGordon', title: 'Slater Gordon' },
  { name: 'itasNetwork', title: 'ITAS Network' },
  { name: 'thrifty', title: 'Thrifty' },
  { name: 'kindertons', title: 'Kindertons' },
  {
    name: 'dlgvwg',
    title: 'DLGVWG',
  },
];
