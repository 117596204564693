import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import { createReportURL } from 'core/routes';
import {
  convertToDate,
  useSLAPerformanceV3,
  useGetRepairerGroup,
  useGetRepairerByGroupName,
} from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import { ErrorTypes } from './types';
import { ReportsLoader } from 'components/ReportsLoader';
import { SelectOption } from 'components/InputSelect';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { SLAPerformanceV3SearchForm } from 'components/ReportForms/SLAPerformanceV3SearchForm';
import { reportLogger } from '../reportLogger';
import { SLAReportTable } from './SLAReportTable';
import { SLA_PERFORMANCE_INFO_MESSAGE, SLA_PERFORMANCE_REPORT } from './constants';

export function SLAPerformanceV3({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const urlParams = {
    endDate: urlSearchParams.get('endDate'),
    startDate: urlSearchParams.get('startDate'),
    repairGroup: urlSearchParams.get('repairGroup'),
    siteCode: urlSearchParams.get('siteCode'),
  };
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) ?? null
  );
  const [repairerGroup, setRepairerGroup] = useState<string | null>(
    urlParams.repairGroup ?? null
  );
  const [siteCode, setSiteCode] = useState<SelectOption[]>([]);

  const { repairerGroupList, isRepairerGroupListLoading } = useGetRepairerGroup(
    (error) => handleError(error, 'isRepairerGroup')
  );

  const { siteCodes, isSiteCodesLoading } = useGetRepairerByGroupName(
    repairerGroup ?? '',
    repairerGroup !== null,
    (error) => handleError(error, 'isSiteCodes')
  );

  const repairerGroupOptions = useMemo(
    () =>
      repairerGroupList?.map((item, index) => {
        return { id: index, value: item.groupName, text: item.groupName };
      }),
    [repairerGroupList]
  );

  const siteCodesOptions = useMemo(
    () =>
      siteCodes?.map((item, index) => {
        return {
          id: index,
          value: item.formalSiteCode,
          text: item.repairerName,
        };
      }),
    [siteCodes]
  );

  const reportQueryEnabled =
    !!urlParams.startDate &&
    !!urlParams.endDate &&
    !!urlParams.repairGroup &&
    !!urlParams.siteCode;

  const { reportData, refetchReport, isReportFetching } = useSLAPerformanceV3(
    {
      startDate: urlParams.startDate,
      endDate: urlParams.endDate,
      repairerGroup: urlParams.repairGroup,
      siteCode: urlParams.siteCode,
    },
    reportQueryEnabled,
    (error) => handleError(error, 'isReport')
  );

  const isOptionsLoading = isRepairerGroupListLoading || isSiteCodesLoading;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParams.startDate,
    urlParams.endDate,
    urlParams.repairGroup,
    urlParams.siteCode,
  ]);

  useEffect(() => {
    if (urlParams.siteCode != siteCode?.map((e) => e.value)?.join(','))
      setSiteCode(
        siteCodesOptions?.filter((e) =>
          urlParams.siteCode?.split(',').includes(e.value)
        ) ?? []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading]);

  const reportHeaderTagLine = `Report produced on ${formatDate(
    new Date()
  )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`;

  const isSearchDisabled = !startDate || !endDate || !repairerGroup || siteCode.length <= 0;

  const dateFields = useMemo(
    () => [
      {
        value: startDate,
        isDisabled: isReportFetching,
        label: 'Start Date',
        maxDate: endDate,
        onChange: setStartDate,
      },
      {
        value: endDate,
        isDisabled: isReportFetching,
        label: 'End Date',
        minDate: startDate,
        onChange: setEndDate,
      },
    ],
    [endDate, isReportFetching, startDate]
  );

  return (
    <MainPageContainer
      isLoading={isReportFetching}
      direction="column"
      wrap="nowrap"
    >
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={SLA_PERFORMANCE_REPORT}
          infoMessage={SLA_PERFORMANCE_INFO_MESSAGE}
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
          <SLAPerformanceV3SearchForm
            handleSubmit={handleSubmit}
            isDisabled={isReportFetching}
            repairerGroupOptions={repairerGroupOptions ?? []}
            repairerGroup={repairerGroup}
            setRepairerGroup={setRepairerGroup}
            siteCodesOptions={siteCodesOptions ?? []}
            siteCode={siteCode}
            setSiteCode={setSiteCode}
            isSearchDisabled={isSearchDisabled}
            dateFields={dateFields}
          />
        )}
      </UpperInfoContainer>
      <SLAReportTable
        reportData={reportData}
        isReportFetching={isReportFetching}
        reportHeaderTagLine={reportHeaderTagLine}
      />
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParams.startDate == dateToString(startDate) &&
      urlParams.repairGroup == repairerGroup &&
      urlParams.siteCode == siteCode?.map((e) => e.value)?.join(',') &&
      urlParams.endDate == dateToString(endDate)
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate && !!repairerGroup && siteCode.length > 0) {
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            repairGroup: repairerGroup,
            siteCode: siteCode?.map((e) => e.value)?.join(','),
          },
          reportPath
        )
      );
    }
  }

  function handleError(error: AxiosError, type: ErrorTypes) {
    reportLogger(error);
    const errorMessagesMap: Record<ErrorTypes, string> = {
      isRepairerGroup: errorMessages.repairersList,
      isSiteCodes: errorMessages.siteCodes,
      isReport: errorMessages.reports('SLA Performance V3 Report'),
    };
    const errorMessage = errorMessagesMap[type];
    setErrorMessage(errorMessage);
    setToast(true);
  }
}
