import { z } from 'zod';

export const mobileRegex = /^(((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6})?$/;

export const mobileValidatorUK = () => {
  return z
    .string()
    .regex(mobileRegex, { message: 'Must be a valid mobile number' })
    .nullable();
};
