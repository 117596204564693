import { DtlItem, SortOrder } from 'api/resources/models/AutoGenerated';
import { DtlListParams } from './types';
import { SortObject } from 'components/types';
import { orderBy } from 'lodash';

export function useDtlListParams(url: string): DtlListParams {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  return {
    sortString: sort as keyof DtlItem,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
  };
}

export function sortDtlList(sortObj: SortObject, dtlList: DtlItem[]) {
  return orderBy(
    dtlList,
    sortObj.sortString,
    sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
  );
}
