/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ReplacementVehicleIcon } from '../../components/ReplacementVehicleIcon';
import { EcdDisplay } from './fields/Ecd';
import { FormalStatusField } from './fields/FormalStatus';
import { ModifiedDisplay } from './fields/Modified';
import { RegistrationNo } from './fields/RegistrationNo';
import { rowTitle } from './helpers';
import { Repair } from 'api/resources/models/AutoGenerated';

interface Props {
  rows?: Repair[];
  onRowClick: (row: Repair) => void;
}

export function MobileTableRow({ rows, onRowClick }: Props) {
  return (
    <>
      {rows?.map((row, i) => (
        <TableRowContainer key={i} onClick={() => onRowClick(row)}>
          <MobileTableCell>
            <MobileCellColumn>
              <RegistrationNo number={row.vehicleReg} />
            </MobileCellColumn>
            <MobileCellColumn>
              <span className="title">{rowTitle('repairerGroup')}</span>
              <div>{row.repairerGroup}</div>
            </MobileCellColumn>
            <MobileCellColumn>
              <span className="title">Vehicle</span>
              <div>
                {row.vehicleMake} {row.vehicleModel}
              </div>
            </MobileCellColumn>
            <MobileCellColumn>
              <span className="title">{rowTitle('dtgEcd')}</span>
              <div>
                {row.dtgEcd ? (
                  <EcdDisplay
                    ecd={new Date(row.dtgEcd)}
                    completed={
                      row.dtgCompleted ? new Date(row.dtgCompleted) : undefined
                    }
                  />
                ) : (
                  ''
                )}
              </div>
            </MobileCellColumn>
          </MobileTableCell>
          <MobileTableCell>
            <StyledStatusRow
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              {row.formalStatus && row.formalStatusDescription ? (
                <FormalStatusField
                  status={row.formalStatus}
                  dtgOnSite={row.dtgOnSite ? new Date(row.dtgOnSite) : null}
                  statusDescription={row.formalStatusDescription}
                />
              ) : (
                ''
              )}
            </StyledStatusRow>
            <MobileCellColumn>
              <span className="title">{rowTitle('dtgUpdated')}</span>
              {row.dtgUpdated ? (
                <ModifiedDisplay
                  modified={new Date(row.dtgUpdated)}
                  delivered={
                    row.dtgDelivered ? new Date(row.dtgDelivered) : undefined
                  }
                />
              ) : (
                ''
              )}
            </MobileCellColumn>
            <MobileCellColumn>
              <span className="title">{rowTitle('claimReference')}</span>
              <div>{row.claimReference}</div>
            </MobileCellColumn>
            <MobileCellColumn>
              <span className="title">
                {rowTitle('replacementVehicleStatus')}
              </span>
              <div>
                {row.replacementVehicleStatus ? (
                  <ReplacementVehicleIcon
                    status={row.replacementVehicleStatus}
                  />
                ) : (
                  ''
                )}
              </div>
            </MobileCellColumn>
          </MobileTableCell>
        </TableRowContainer>
      ))}
    </>
  );
}

const MobileTableCell = styled(TableCell)`
  padding: ${({ theme }) => `0 0 ${theme.padding.m} 0`};
  vertical-align: top;
  width: 50%;
  font-size: ${({ theme }) => theme.fontSize.s};
  border-bottom: none;

  &:first-child {
    padding-left: ${({ theme }) => `${theme.padding.m}`};
  }

  &:last-child {
    padding-left: ${({ theme }) => `${theme.padding.s}`};
    padding-right: ${({ theme }) => `${theme.padding.m}`};

    @media (min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      padding-left: 60px;
    }
  }
`;

const MobileCellColumn = styled.div`
  .title {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-bottom: ${({ theme }) => theme.margin.xs};
  }

  margin-top: ${({ theme }) => theme.margin.m};
  min-height: 30px;
  .display-left {
    display: flex;
    justify-content: flex-end;
  }

  & > div {
    display: flex;
    align-items: center;
  }
`;

const StyledStatusRow = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin-top: ${({ theme }) => theme.margin.m};
  min-height: 30px;
`;

const TableRowContainer = styled(TableRow)`
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.palette.row.hover};
    border-radius: ${({ theme }) => theme.border.radius};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    border-bottom: 1px solid #e5e5e5;
    &:first-child {
      border-top: 1px solid #e5e5e5;
    }
  }
`;
