import { queryClient } from 'api/client';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { SortObject } from 'components/types';
import { Heading } from 'components/Typography';
import { createRepairsSearchUrl, REPAIRS_SEARCH, ROUTES } from 'core/routes';
import {
  getSearchRepairsKey,
  SearchConfig,
  useSearchRepairs,
  useUser,
} from 'pages/hooks';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getSortObject, hasOnlySpaces } from 'utils/helpers';
import { VehicleTable } from '../../components/VehiclesTable';
import { useRepairsSearchParams } from './hooks';
import { Permit, Repair } from 'api/resources/models/AutoGenerated';
import { generatePath } from 'react-router-dom';
import { logger } from 'core/logger';
import {
  MainPageContainer,
  StyledGridItem,
  UpperInfoContainer,
} from 'components/PageLayout';
import InfoText from 'components/InfoText';
import { SearchForm } from './SearchForm';
import { SearchFieldsForm } from './SearchFieldsForm';
import { basicVehicleTableHeaders } from 'components/VehiclesTable/constants';

export function RepairsSearch() {
  const location = useLocation();
  const history = useHistory();
  const { user } = useUser();

  const mustSearchDual = user?.userPermits.find(
    (p) => p.code === Permit.MUST_SEARCH_DUAL
  );

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const urlParams = useRepairsSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState(urlParams.searchTerm);
  const [claimReference, setClaimReference] = useState(urlParams.claimReferece);
  const [registration, setRegistration] = useState(urlParams.registration);
  const [sortObject, setSortObject] = useState<SortObject>(
    !mustSearchDual
      ? {
          sortString: urlParams.sort ?? '',
          sortOrder: urlParams.sortOrder,
        }
      : {}
  );

  const [page, setPage] = useState(!mustSearchDual ? urlParams.page : null);

  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const dualSearchDisabled: boolean =
    !!mustSearchDual &&
    (urlParams.claimReferece === null || urlParams.registration === null);
  const singleSearchDisabled: boolean =
    !mustSearchDual && !urlParams.searchTerm;

  const isQueryDisabled = dualSearchDisabled || singleSearchDisabled;

  const queryConfig = (): SearchConfig => {
    if (!isQueryDisabled) {
      if (!mustSearchDual && urlParams.searchTerm)
        return {
          searchText: urlParams.searchTerm,
          skip: urlParams.page * itemsPerPage,
          take: itemsPerPage,
          sortString: urlParams.sort ?? undefined,
          sortOrder: urlParams.sortOrder,
        };
      if (urlParams.registration && urlParams.claimReferece)
        return {
          registration: urlParams.registration,
          claimReference: urlParams.claimReferece,
        };
    }
    return 'disabled';
  };

  const { repairs, isSearching } = useSearchRepairs(
    queryConfig(),
    isQueryDisabled
  );

  useEffect(() => {
    navigateToRepairsSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject, page]);

  useEffect(() => {
    setSearchTerm(urlParams.searchTerm);
    setPage(urlParams.page);
    setClaimReference(urlParams.claimReferece);
    setRegistration(urlParams.registration);
    if (urlParams.sort)
      setSortObject({
        sortOrder: urlParams.sortOrder,
        sortString: urlParams.sort,
      });
  }, [
    urlParams.searchTerm,
    urlParams.page,
    urlParams.sort,
    urlParams.sortOrder,
    urlParams.claimReferece,
    urlParams.registration,
  ]);

  const isFilled = (value: string | null): boolean =>
    !!(value && !hasOnlySpaces(value));

  const isSearchTermFilled: boolean = isFilled(searchTerm);
  const isClaimReferenceFilled: boolean = isFilled(claimReference);
  const isRegistrationFilled: boolean = isFilled(registration);

  const isSearchDisabled = mustSearchDual
    ? !(isClaimReferenceFilled && isRegistrationFilled)
    : !isSearchTermFilled;

  return (
    <MainPageContainer isLoading={isSearching} direction="column" wrap="nowrap">
      <form onSubmit={handleSubmit}>
        <UpperInfoContainer direction="column">
          <StyledGridItem>
            <Heading className="subheading">Search</Heading>
          </StyledGridItem>
          <SearchForm
            handleSubmit={handleSubmit}
            isFilterModalOpen={isFilterModalOpen}
            setIsFilterModalOpen={setIsFilterModalOpen}
          >
            <SearchFieldsForm
              mustSearchDual={mustSearchDual}
              searchTerm={searchTerm ?? ''}
              setSearchTerm={setSearchTerm}
              claimReference={claimReference ?? ''}
              setClaimReference={setClaimReference}
              registration={registration ?? ''}
              setRegistration={setRegistration}
              isSearchDisabled={isSearchDisabled}
            />
          </SearchForm>
        </UpperInfoContainer>
        {!isSearching &&
          !isQueryDisabled &&
          (!repairs || repairs?.items?.length === 0) && (
            <InfoText>
              {"Sorry, we couldn't find any repairs with those details."}
            </InfoText>
          )}
        <VehicleTable
          repairs={repairs}
          tableHeaders={basicVehicleTableHeaders}
          loading={isSearching}
          selected={sortObject.sortString}
          onSortClick={sort}
          sortOrder={sortObject.sortOrder}
          itemsCount={repairs?.totalRecordCount}
          currentPage={page ?? 1}
          onPageChange={setPage}
          onItemsPerPageChange={setItemsPerPage}
          onRepairClick={handleRepairClick}
        />
      </form>
    </MainPageContainer>
  );

  function handleRepairClick(row: Repair) {
    if (!row.repairCode)
      return logger?.error(
        'Unable to navigate to Vehicle details from Repair Search',
        {
          repairId: row.repairId,
        }
      );

    const route = generatePath(ROUTES.vehicleDetails, {
      repairId: row.repairId,
      repairCode: row.repairCode,
      search: REPAIRS_SEARCH,
    });
    history.push(route);
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setIsFilterModalOpen(false);
    setSortObject({});
    setPage(null);
    queryClient.invalidateQueries(getSearchRepairsKey(queryConfig()));
  }

  function navigateToRepairsSearch() {
    history.replace(
      createRepairsSearchUrl(
        searchTerm,
        page,
        sortObject.sortString ? sortObject.sortString : null,
        sortObject.sortOrder ? sortObject.sortOrder : null,
        registration,
        claimReference
      )
    );
  }

  function sort(sortValue: string) {
    setSortObject((prevValue) => getSortObject(prevValue, sortValue));
  }
}
