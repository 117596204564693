import {
    TableGroupRow as TableGroupRowBase,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { TableGroupRow } from '@devexpress/dx-react-grid-material-ui';


export const GroupIconComponent = (props: TableGroupRowBase.IconProps) => {
    return <CustomRowIcon {...props} />;
};

const CustomRowIcon = styled(TableGroupRow.Icon)`
  margin: 0;
  padding: 0;
`;