import styled from '@emotion/styled';
import Search from '@mui/icons-material/Search';

import { PrimaryButton } from 'components/Button';
import { useInMobile } from 'core/Theming/Device';

export const SubmitButton = ({isDisabled}: {isDisabled: boolean}) => {
  const isMobile = useInMobile();

  return <Button type="submit" disabled={isDisabled}>{isMobile ? <Search /> : 'Search'}</Button>;
};

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
