import { Row } from '@devexpress/dx-react-grid';
import { dateColumnsName } from './constants';
import { formatDateTime } from 'utils/helpers';

export const valuesConverter = (data: Row[]) => {
  return data.map((row) => {
    const formattedRow = { ...row };
    dateColumnsName.forEach((columnName) => {
      if(row[columnName]) {
        formattedRow[columnName] = formatDateTime(row[columnName]) ;
      }
    });
    return {
      ...formattedRow,
      accountLockedDate: row.accountLockedDate ? 'TRUE' : 'FALSE',
      disabled: `${row.disabled?.toString()?.toUpperCase()}`,
    };
  });
};
