import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { UserPermit } from 'api/resources/models/AutoGenerated';
import { PrimaryButton } from 'components/Button';
import { TextField } from 'components/TextField';

export interface SearchFieldsFormProps {
  mustSearchDual: UserPermit | undefined;
  searchTerm: string | null;
  setSearchTerm: (data: string) => void;
  claimReference: string | null;
  setClaimReference: (data: string) => void;
  registration: string | null;
  setRegistration: (data: string) => void;
  isSearchDisabled: boolean;
}

export function SearchFieldsForm({
  mustSearchDual,
  searchTerm,
  setSearchTerm,
  claimReference,
  setClaimReference,
  registration,
  setRegistration,
  isSearchDisabled,
}: Readonly<SearchFieldsFormProps>) {
  return (
    <>
      {!mustSearchDual ? (
        <TextInput
          placeholder="Claim Ref. or Vehicle Reg."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      ) : (
        <>
          <TextInput
            placeholder="Claim Ref."
            value={claimReference}
            onChange={(e) => setClaimReference(e.target.value)}
          />
          <TextInput
            placeholder="Vehicle Reg."
            value={registration}
            onChange={(e) => setRegistration(e.target.value)}
          />
        </>
      )}
      <SearchButton type="submit" disabled={isSearchDisabled}>
        <SearchIcon />
        Search
      </SearchButton>
    </>
  );
}

const TextInput = styled(TextField)`
  width: 260px;
  margin-right: 8px;

  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
    overflow: hidden;
  }
`;

const SearchButton = styled(PrimaryButton)`
  width: 99px;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-right: 10px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: 12px;
    width: 100%;
  }
`;

const SearchIcon = styled(Search)`
  height: 17.49px;
  width: 17.49px;
  margin-right: 10px;
`;
