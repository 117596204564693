/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import { useRepairers, useRepairs } from 'pages/hooks';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { VehicleTable } from '../../components/VehiclesTable';
import VehicleUpperInfo from 'components/VehicleUpperInfo';
import { Repair, SortOrder } from 'api/resources/models/AutoGenerated';
import { SortObject } from 'components/types';
import {
  useVehiclesFiltersValidation,
  useVehiclesSearchParams,
  VehiclesSearchParams,
} from './hooks';
import {
  createVehiclesInProgressUrl,
  ROUTES,
  VEHICLES_SEARCH,
} from 'core/routes';
import { getSortObject } from 'utils/helpers';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { logger } from 'core/logger';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import InfoText from 'components/InfoText';
import { VehiclesLanding } from './VehiclesLanding';
import { NO_DATA_MESSAGE } from './constants';
import { vehicleInProgressHeaders } from 'components/VehiclesTable/constants';

export function VehiclesSearchPage() {
  const { search } = useLocation();
  const urlParams = useVehiclesSearchParams(search);
  const { isValid, isLoading } = useVehiclesFiltersValidation(
    urlParams && typeof urlParams !== 'string' 
      ? {
          repairerId: urlParams.id,
          filterId: urlParams.filterId,
          group: urlParams.group,
        }
      : undefined
  );

  const history = useHistory();

  if (typeof urlParams === 'string' || (!isValid && !isLoading)) {
    history.replace(ROUTES.notFound);
    return <></>;
  }

  if (urlParams === undefined)
    return (
      <VehiclesLanding
        headerText="Vehicles in Progress"
        navigateTo={(repairerId, filterId) =>
          createVehiclesInProgressUrl(repairerId, filterId)
        }
      />
    );
  return <VehiclesSearch query={urlParams} />;
}

export function VehiclesSearch({ query }: { query: VehiclesSearchParams }) {
  const history = useHistory();
  const { repairers, isLoading: isRepairersLoading } = useRepairers();
  const urlParams = query;

  const [selectedRepairerId, setSelectedRepairerId] = useState<
    number | undefined
  >(urlParams.id ?? undefined);
  const [selectedRepairerGroup, setSelectedRepairerGroup] = useState<
    string | undefined
  >(urlParams.group ?? undefined);

  const [selectedFilterId, setSelectedFilterId] = useState(urlParams.filterId);
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sort,
    sortOrder: urlParams.sortOrder,
  });

  const [page, setPage] = useState(urlParams.page);
  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const queryEnabled = repairers.length > 0;

  const repairsConfig = {
    recordFilterId: Number(urlParams.filterId),
    skip: urlParams.page * itemsPerPage,
    take: itemsPerPage,
    siteCode:
      repairers.find((r) => r.repairerId === urlParams.id)?.siteCode ?? '',
    sortString: urlParams.sort,
    sortOrder: urlParams.sortOrder,
  };

  const {
    repairs,
    isLoading: isRepairsLoading,
    isFetching,
  } = useRepairs(repairsConfig, {
    disabled: !queryEnabled,
    onSuccess: () => {
      setPage(urlParams.page);
      if (urlParams.sort)
        setSortObject({
          sortString: urlParams.sort,
          sortOrder: urlParams.sortOrder,
        });
    },
  });

  const isTableDataLoading =
    isRepairsLoading || isFetching || isRepairersLoading;

  const [isFixed, setIsFixed] = useState<boolean>(false);

  useEffect(() => {
    if (selectedRepairerId !== undefined) navigate(selectedRepairerId, urlParams.filterId );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject, page]);

  return (
    <MainContainer
      isLoading={isTableDataLoading}
      direction="column"
      wrap="nowrap"
    >
      <UpperInfoContainer direction="column">
        <VehicleUpperInfo
          headerText="Vehicles in Progress"
          repairs={repairs}
          onRepairerIdChange={setSelectedRepairerId}
          onRepairerGroupChange={setSelectedRepairerGroup}
          onFilterIdChange={setSelectedFilterId}
          onApply={onApplyClick}
          selectedRepairerId={selectedRepairerId}
          selectedRepairGroup={selectedRepairerGroup}
          selectedFilterId={selectedFilterId}
          onScroll={(fixed) => setIsFixed(fixed)}
        />
      </UpperInfoContainer>
      <FixedDesktopTableContainer isFixed={isFixed}>
        {!isTableDataLoading &&
          urlParams.id !== null &&
          (!repairs || repairs.items.length === 0) && (
            <InfoText>{NO_DATA_MESSAGE}</InfoText>
          )}
        <VehicleTable
          repairs={repairs}
          tableHeaders={vehicleInProgressHeaders}
          loading={isTableDataLoading}
          selected={sortObject.sortString}
          onSortClick={sort}
          sortOrder={sortObject.sortOrder}
          itemsCount={repairs?.totalRecordCount}
          currentPage={page}
          onPageChange={setPage}
          onItemsPerPageChange={setItemsPerPage}
          onRepairClick={handleVehicleClick}
        />
      </FixedDesktopTableContainer>
    </MainContainer>
  );

  function handleVehicleClick(row: Repair) {
    if (!row.repairCode)
      return logger?.error(
        'Unable to navigate to Vehicle details from Vehicle Search',
        {
          repairId: row.repairId,
        }
      );

    const route = generatePath(ROUTES.vehicleDetails, {
      repairId: row.repairId,
      repairCode: row.repairCode,
      search: VEHICLES_SEARCH,
    });
    history.push(route);
  }

  function sort(sortValue: string) {
    setSortObject((prevValue) => getSortObject(prevValue, sortValue));
  }

  function navigate(repairerId: number, filterId: number) {
    history.replace(
      createVehiclesInProgressUrl(
        repairerId,
        filterId,
        page,
        sortObject.sortString ?? 'dtgUpdated',
        sortObject.sortOrder ?? SortOrder.Descending
      )
    );
  }

  function onApplyClick() {
    clearSort();
    setPage(0);
    if (selectedRepairerId !== undefined) navigate(selectedRepairerId, selectedFilterId);
  }

  function clearSort() {
    setSortObject({});
  }
}

const FixedDesktopTableContainer = styled(Container)<{
  isFixed: boolean;
}>`
  margin-top: ${({ isFixed }) => (isFixed ? '80px' : 0)};
`;

export const MainContainer = styled(MainPageContainer)`
  padding-top: ${({ theme }) => ` ${theme.padding.m}`};
  & .MuiGrid-item {
    padding-right: 0;
  }
  & .subheading {
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: 700;
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
  & .input-container {
    margin-left: ${({ theme }) => theme.padding.m};
  }
  & .activity-container {
    justify-content: flex-end;

    & .activity-text {
      align-items: center;
      flex-wrap: nowrap;

      & .activity-icon {
        margin-right: ${({ theme }) => theme.margin.s};
      }
    }
  }
`;
