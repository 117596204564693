export const REPAIRER_CAPACITY_REPORT = 'Repairer Capacity Report';

export const REPAIRER_CAPACITY_REPORT_MESSAGE =
  'Provides detailed information regarding a repairers historic performance against their future workload';

export const REPAIRER_CAPACITY_REPORT_GRID_HEADER = 'Capacity Report';

export const REPAIRER_CAPACITY_REPORT_GRID_TITLE = 'Rolling30DayReport';

export const tableColumnExtensions = [
  { columnName: 'measureName', wordWrapEnabled: true, width: '13%' },
  ...[...Array(32)].map((u, i) => {
    return { columnName: `c${i}`, wordWrapEnabled: true, width: '4%' };
  }),
];

export const rollingTableColumnExtensions = [
  { columnName: 'measureName', wordWrapEnabled: true, width: '13%' },
  ...[...Array(32)].map((u, i) => {
    return { columnName: `c${i}`, wordWrapEnabled: true, width: '4%' };
  }),
];
