import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import { Select } from 'components/Select';
import { Label } from 'components/PageLayout';
import { MultiSelectNew } from 'components/MultiSelectNew';
import {
  renderSearchIcon,
  renderSearchButtonText,
} from 'pages/ReportsDetails/helpers';
import { useInMobile } from 'core/Theming/Device';
import { ReportFormRepairerCapacityReportProps } from './types';

export const ReportFormRepairerCapacityReport = ({
  isBusy,
  runDate,
  streams,
  siteCode,
  setStreams,
  setRunDate,
  setSiteCode,
  handleSubmit,
  repairerGroup,
  streamsOptions,
  setRepairerGroup,
  siteCodesOptions,
  repairerGroupOptions,
}: ReportFormRepairerCapacityReportProps) => {
  const isMobile = useInMobile();
  return (
    <CustomForm onSubmit={handleSubmit}>
      <CustomGridContainer direction="row">
        <InputButton md={3} sm={3} xs={12}>
          <Label>Run Date</Label>
          <DatePickerField
            isDisabled={isBusy}
            onChange={(date) => setRunDate(date)}
            value={runDate}
          />
        </InputButton>
        <InputButton md={3} sm={3} xs={12}>
          <Label>Repairer Group</Label>
          <Select
            disabled={isBusy}
            options={repairerGroupOptions || []}
            selectedId={
              repairerGroupOptions?.find(
                (e: { value: string | null }) => e.value == repairerGroup
              )?.id
            }
            onChange={(item) => {
              setRepairerGroup(item.value);
              setSiteCode([]);
            }}
          />
        </InputButton>
        <GridItem md={1} sm={1} xs={2}>
          <Button
            type="submit"
            disabled={
              isBusy ||
              !runDate ||
              !repairerGroup ||
              siteCode.length <= 0 ||
              streams.length <= 0
            }
          >
            {renderSearchIcon(isMobile)}
            {renderSearchButtonText(isMobile)}
          </Button>
        </GridItem>
      </CustomGridContainer>
      <Container direction="row" alignItems="center">
        <InputButton md={3} sm={3} xs={12}>
          <Label>Select Sites</Label>
          <MultiSelectNew
            disabled={isBusy}
            options={siteCodesOptions}
            selectedOptions={siteCode}
            onChange={(value) => setSiteCode(value ?? [])}
          />
        </InputButton>
        <InputButton md={3} sm={3} xs={12}>
          <Label>Select Streams</Label>
          <MultiSelectNew
            disabled={isBusy}
            options={streamsOptions}
            selectedOptions={streams}
            onChange={(value) => setStreams(value ?? [])}
          />
        </InputButton>
      </Container>
    </CustomForm>
  );
};

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  max-width: 330px !important;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
const CustomGridContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;
