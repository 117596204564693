import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { Container } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import { PageHeader } from 'components/PageLayout';
import { Heading } from 'components/Typography';
import { ROUTES } from 'core/routes';
import { useUser } from 'pages/hooks';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

export function Reports() {
  const history = useHistory();
  const { user, isLoading, isFetching } = useUser();
  const reportsList = useMemo(() => {
    return (
      user?.reportPermits?.sort((a, b) =>
        (a?.title || '').localeCompare(b?.title || '')
      ) || []
    );
  }, [user?.reportPermits]);
  const isBusy = isLoading || isFetching;

  return (
    <MainContainer direction="column">
      <StyledPageHeader>
        <Heading>Reports</Heading>
      </StyledPageHeader>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      {reportsList?.map((data, key) => (
        <AddedAcountProfile direction="row" alignItems="center" key={key}>
          <CustomButton
            variant="text"
            onClick={() => getReportPath(data?.file)}
            fullWidth={true}
          >
            {data.title}
          </CustomButton>
        </AddedAcountProfile>
      ))}
    </MainContainer>
  );

  function getReportPath(reportPath: string | null) {
    if (reportPath) {
      const route = generatePath(ROUTES.reportViewer, {
        reportPath: reportPath.replace('/', ''),
      });
      history.push(route);
    } else {
      throw new Error('Reports Path not found');
    }
  }
}

const MainContainer = styled(Container)`
  padding: ${({ theme }) => `0  ${theme.padding.xl}`};
`;
const StyledPageHeader = styled(PageHeader)`
  padding: 12px 0;
`;

const AddedAcountProfile = styled(Container)`
  min-height: 40px;
  width: 60%;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  box-shadow: ${({ theme }) => theme.shadow.s};
  border-radius: ${({ theme }) => theme.border.radius};
  padding-left: 12px;
  margin-bottom: ${({ theme }) => theme.margin.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const CustomButton = styled(Button)`
  justify-content: flex-start;
  text-transform: none;
`;
