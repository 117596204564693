import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Container, GridItem } from 'components/Layout';
import {
    Label,
    StyledGridItem,
    UpperInfoContainer,
} from 'components/PageLayout';
import { Heading } from 'components/Typography';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { useInMobile } from 'core/Theming/Device';
import { Select } from 'components/Select';
import { PrimaryButton } from 'components/Button';
import { getSelectedId, monthsOptions, selectLaterMonths } from './helpers';
import { summaryTypeOptions } from './constants';
import { ROUTES, createCycleTimeUrl } from 'core/routes';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTheme } from '@emotion/react';
import { Text } from 'components/Typography';


export function CycleTimeSearchForm() {
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();

    const urlParams = new URLSearchParams(location.search);
    const urlParamsValue = {
        startDate: urlParams.get('startDate'),
        endDate: urlParams.get('endDate'),
        summaryType: urlParams.get('summaryType'),
    };

    const [startDate, setStartDate] = useState<string | null>(
        urlParamsValue.startDate ?? null
    );
    const [endDate, setEndDate] = useState<string | null>(
        urlParamsValue.endDate ?? null
    );
    const [summaryType, setSummaryType] = useState<
        string | null
        >(urlParamsValue.summaryType ?? null);

    const handleSearch = () => {
        const url = createCycleTimeUrl(startDate, endDate, summaryType);
        history.push(url);
    };

    const isMobile = useInMobile();
    const renderButton = () => {
        return isMobile ? <Search /> : 'View';
    };

    const summaryText = urlParamsValue.startDate ? `between ${urlParamsValue.startDate} and ${urlParamsValue.endDate}` : '';

    return (
        <UpperInfoContainer direction="column">
            {urlParamsValue.startDate ? (<Container>
                <GridItem>
                    <Link to={ROUTES.cycleTimeSummary} style={{ textDecoration: 'none' }}>
                        <StyledDiv>
                            <KeyboardBackspaceIcon htmlColor={theme.palette.background.dark} />
                            <Text fontSize="xs">{'Back to New Search'}</Text>
                        </StyledDiv>
                    </Link>
                </GridItem>
            </Container>) : null}

            <Container>
                <GridItem>
                    <Heading>{`Cycle Time Summary ${summaryText}`}</Heading>
                </GridItem>
            </Container>



            {!urlParamsValue.startDate ? (
            <StyledGridItem>
                <CustomForm onSubmit={handleSearch}>
                    <Container direction="row">
                            <GridItemField md={3} sm={4} xs={12}>
                            <Label>Start Date</Label>
                            <Select
                                selectedId={getSelectedId(startDate, monthsOptions)}
                                onChange={(e) => {
                                    setStartDate(e.text)
                                }}
                                options={monthsOptions}
                            />
                            </GridItemField>
                            <GridItemField md={3} sm={4} xs={12}>
                            <Label>End Date</Label>
                            <Select
                                selectedId={getSelectedId(endDate, monthsOptions)}
                                onChange={(e) => {
                                    setEndDate(e.text)
                                }}
                                    options={selectLaterMonths(startDate, monthsOptions)}
                            />
                            </GridItemField>
                            <GridItemField md={3} sm={4} xs={12}>
                            <Label>Summary Type</Label>
                            <Select
                                selectedId={getSelectedId(summaryType, summaryTypeOptions)}
                                onChange={(e) => {
                                    setSummaryType(e.value)
                                }}
                                options={summaryTypeOptions}
                            />
                            </GridItemField>
                        <GridItem md={1} sm={1} xs={2}>
                            <ViewButton
                                type="submit"
                                autoFocus
                                variant="contained"
                                disabled={!startDate || !endDate || !summaryType}
                            >
                                {renderButton()}
                            </ViewButton >
                        </GridItem>
                    </Container>
                </CustomForm>
                </StyledGridItem>
            ) : null}
        </UpperInfoContainer>
    );
}


const ViewButton = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const GridItemField = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  & svg {
    margin-right: ${({ theme }) => theme.margin.xs};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-top: ${({ theme }) => theme.margin.m};
  }
`;