/** @jsxImportSource @emotion/react */

import { VehicleTableHeader } from './types';
import { WithWrapper } from 'components/WithWrapper';
import Permits from 'components/Permits';
import { StyledTableCell, StyledTableRow } from 'components/DataGrid';
import { renderTableItem } from './renderTableItem';
import { Repair } from 'api/resources/models/AutoGenerated';

interface Props {
  rows?: Repair[];
  onRowClick: (row: Repair) => void;
  headers: VehicleTableHeader[];
}

export function DesktopTableRow({
  rows,
  onRowClick,
  headers,
}: Props): JSX.Element {
  return (
    <>
      {rows?.map((row) => (
        <StyledTableRow key={row.repairId} onClick={() => onRowClick(row)}>
          {headers.map((header) => {
            return (
              <WithWrapper
                key={header.id}
                condition={!!header?.permitCode}
                wrap={(children) => (
                  <Permits permit={header.permitCode!}>{children}</Permits>
                )}
              >
                <StyledTableCell component="td" scope="row">
                  {renderTableItem(row, header.id)}
                </StyledTableCell>
              </WithWrapper>
            );
          })}
        </StyledTableRow>
      ))}
    </>
  );
}