import { createTheme } from '@mui/material/styles';

export type Theme = typeof theme;

export const theme = {
  palette: {
    background: {
      default: '#ffffff',
      dark: '#303A52',
    },
    primary: {
      light: '#ffffff',
      main: '#303A52',
      dark: '#000',
      selected: '#FCB500',
      hover: '#DFEDF5',
      disabled: '#979EAE',
      border: '#DADADA',
      active: '#FFFCF3',
      activeBorder: '#FCB500',
      activeText: '#FCB500',
      skeleton: '#F1F8FC',
    },
    secondary: {
      light: '#fff',
      main: '#979EAE',
      dark: '#000,',
      hover: '#F1F8FC',
      active: '#DDECF4',
    },
    messages: {
      success: '#2CA800',
      error: '#F84444',
    },
    button: {
      light: '#F1F8FC',
      dark: '#3D4C70',
      hover: '#303A52',
      active: '#FCB500',
      activeText: '#FFFFFF',
      disabled: '#DADADA',
    },
    row: {
      hover: '#F1F8FC',
    },
    drawer: {
      width: 72,
      height: 64,
      hoverColor: '#3d4c70',
    },
    milestone: {
      green: '#EBFEE5',
      red: '#FEE5E5',
      inactiveText: '#D1E4EF',
    },
    icon: {
      green: '#2CA800',
      red: '#F84444',
      warn: '#FCB500',
    },
    contact: {
      primary: '#F1F8FC',
      button: '#3d4c70',
    },
    menuIcons: {
      default: '#fff',
      active: '#FCB500',
    },
    status: {
      preOnSite: '#AAC2FF',
      onSite: '#5F85E8',
      delivered: '#2E53B4',
      terminal: '#303A52',
    },
    reports: {
      pink: '#FFBBC7',
      green: '#90EE90',
      orange1: '#FFA500',
      header: '#DFEDF5',
    },
    activities: {
      thistle: '#D8BFD8',
      lightPink: '#FFB6C1',
      wheat: '#F5DEB3',
      lightSkyBlue: '#87CEFA',
      lightGreen: '#90EE90',
    },
  },
  fontSize: {
    xxs: '10px',
    xs: '11px',
    s: '13px',
    m: '14px',
    l: '16px',
    xl: '18px',
    xxl: '24px',
  },
  padding: {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
  },
  margin: {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
  },
  border: {
    radius: '5px',
  },
  shadow: {
    s: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    m: '0px 4px 8px rgba(0, 0, 0, 0.25)',
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 350,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export const muiTheme = createTheme(theme, {
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          '& #root': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        },
      },
    },
  },
});
