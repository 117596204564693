/** @jsxImportSource @emotion/react */
import { disabledVehicleSearchColumns } from 'config';
import { DesktopTableRow } from './DesktopTableRow';
import { DataGrid } from 'components/DataGrid';
import { VehicleTableHeader } from './types';
import { MobileTableRow } from './MobileTableRow';
import { Repair, SortOrder } from 'api/resources/models/AutoGenerated';
import { VEHICLES_SEARCH_TEST_ID } from './constants';

interface Props {
  headers: VehicleTableHeader[];
  data?: Repair[];
  itemsCount?: number;
  currentPage: number;
  selected?: string;
  onSort?: (id: string, sortOrder: SortOrder) => void;
  isLoading?: boolean;
  sortOrder?: SortOrder;
  onPageChange: (page: number) => void;
  onItemsPerPageChange?: (count: number) => void;
  onRepairClick: (repair: Repair) => void;
}

export function VehiclesSearchTable({
  headers,
  data,
  itemsCount,
  currentPage,
  selected,
  onSort,
  isLoading,
  sortOrder,
  onPageChange,
  onItemsPerPageChange,
  onRepairClick,
}: Props) {
  return (
    <DataGrid
      headers={headers}
      data={data}
      dataTestId={VEHICLES_SEARCH_TEST_ID}
      totalCount={itemsCount}
      isLoading={isLoading}
      disabledSortColumns={disabledVehicleSearchColumns}
      onSort={onSort}
      sortObject={{
        sortString: selected,
        sortOrder: sortOrder,
      }}
      currentPage={currentPage}
      onPageChange={onPageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      desktopRow={
        <DesktopTableRow
          rows={data}
          headers={headers}
          onRowClick={onRepairClick}
        />
      }
      mobileRow={<MobileTableRow rows={data} onRowClick={onRepairClick} />}
    />
  );
}
