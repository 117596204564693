import { SortOrder } from 'api/resources/models/AutoGenerated';

export type VehicleDetailsRouteParams = {
  search: string;
  repairId: string;
  repairCode: string;
};

export type AdminUserAccountParams = {
  id: string;
};

export type AdminDataAccessProfileParams = {
  id: string;
};

export type AccountProfileParams = {
  id: string;
};

export const CYCLE_TIME_SUMMARY = 'cycle-time-summary';
export const VEHICLES_SEARCH = 'vehicles-search';
export const REPAIRS_SEARCH = 'repairs-search';
export const TOTAL_LOSS = 'definite-total-loss';

export const ROUTES = {
  notFound: '/*',
  redirectHandler: '/',
  dashboard: '/dashboard',
  aboutUs: '/about-us',
  login: '/login',
  vehiclesSearch: `/${VEHICLES_SEARCH}`,
  repairsSearch: `/${REPAIRS_SEARCH}`,
  dtlSearch: `/${TOTAL_LOSS}`,
  dtlDetails: `/:search(${TOTAL_LOSS})/details/:repairId(\\d+)/:repairCode`,
  vehiclesSearchDetails: `/:search(${VEHICLES_SEARCH})/details/:repairId(\\d+)/:repairCode`,
  repairsSearchDetails: `/:search(${REPAIRS_SEARCH})/details/:repairId(\\d+)/:repairCode`,
  vehicleDetails: `/:search(${VEHICLES_SEARCH}|${REPAIRS_SEARCH}|${TOTAL_LOSS})/details/:repairId(\\d+)/:repairCode`,
  manualUpdate: `/:search(${VEHICLES_SEARCH}|${REPAIRS_SEARCH}|${TOTAL_LOSS})/update/:repairId(\\d+)/:repairCode`,
  singinOidc: '/signin-oidc',
  signoutOidc: '/signout-oidc',
  silentSigninOidc: '/silent-signin-oidc',
  cycleTimeSummary: `/${CYCLE_TIME_SUMMARY}`,
  reports: '/reports',
  reportViewer: '/reports/:reportPath',
  admin: '/admin',
  userAccounts: '/admin/user-accounts',
  userAccountEdit: '/admin/user-accounts/:id(\\d+)',
  userAccountCreate: '/admin/user-accounts/create',
  dataAccessProfiles: '/admin/data-access-profiles',
  dataAccessProfileEdit: '/admin/data-access-profiles/:id(\\d+)',
  dataAccessProfilesDetailsCreate: '/admin/data-access-profiles/create',
  accountProfiles: '/admin/account-profiles',
  accountProfileEdit: '/admin/account-profiles/:id(\\d+)',
  accountProfileCreate: '/admin/account-profiles/create',
  companies: '/admin/companies',
  companiesEdit: '/admin/companies/:id(\\d+)',
  companiesCreate: '/admin/companies/create',
  help: '/help',
};

export function createAdminUsersAccountsUrl(
  searchTerm: string,
  sortString?: string,
  companyId?: string,
  sortOrder?: SortOrder,
  includeDisabledAccounts?: boolean
): string {
  const url = new URLSearchParams();
  if (searchTerm) url.set('searchTerm', searchTerm);
  if (sortString) url.set('sort', sortString);
  if (sortOrder) url.set('sortDirection', sortOrder);
  if (companyId) url.set('companyId', companyId);
  if (includeDisabledAccounts)
    url.set('disabledAccounts', String(includeDisabledAccounts));
  return `${ROUTES.userAccounts}?${url.toString()}`;
}

export function createVehiclesInProgressUrl(
  optionId: number,
  filterId: number,
  pageNumber = 0,
  sortingString = 'dtgUpdated',
  sortOrder: SortOrder = SortOrder.Descending
) {
  const url = new URLSearchParams();
  url.set('id', optionId.toString());
  url.set('filter', filterId.toString());
  url.set('page', pageNumber.toString());
  url.set('sort', sortingString);
  url.set('sortDirection', sortOrder);
  return `${ROUTES.vehiclesSearch}?${url.toString()}`;
}

export function createRepairsSearchUrl(
  searchTerm: string | null,
  pageNumber: number | null,
  sortingString: string | null,
  sortOrder: SortOrder | null,
  registration: string | null,
  claimReference: string | null
) {
  const url = new URLSearchParams();
  if (searchTerm) url.set('searchString', searchTerm);
  if (pageNumber) url.set('page', pageNumber.toString());
  if (sortingString) url.set('sort', sortingString);
  if (sortOrder) url.set('sortDirection', sortOrder);
  if (registration) url.set('registration', registration);
  if (claimReference) url.set('claimReference', claimReference);
  return `${ROUTES.repairsSearch}?${url.toString()}`;
}

export function createDtlUrl(
  sortingString = '',
  sortOrder: SortOrder = SortOrder.Ascending
) {
  const url = new URLSearchParams();
  url.set('sort', sortingString);
  url.set('sortDirection', sortOrder);
  return `${ROUTES.dtlSearch}?${url.toString()}`;
}

export function createAdminDataAccessProfilesUrl(
  companyId?: number,
  sortString?: string,
  sortOrder?: SortOrder
): string {
  const url = new URLSearchParams();
  if (companyId) url.set('companyId', companyId.toString());
  if (sortString) url.set('sort', sortString);
  if (sortOrder) url.set('sortDirection', sortOrder);
  return `${ROUTES.dataAccessProfiles}?${url.toString()}`;
}

export function createAccountProfileFromTemplate(
  accountProfileId: number
): string {
  const url = new URLSearchParams();
  url.set('accountProfileId', accountProfileId.toString());
  return `${ROUTES.accountProfileCreate}?${url.toString()}`;
}

export function createCompaniesUrl(
  sortString?: string,
  sortOrder?: SortOrder
): string {
  const url = new URLSearchParams();
  if (sortString) url.set('sort', sortString);
  if (sortOrder) url.set('sortDirection', sortOrder);
  return `${ROUTES.companies}?${url.toString()}`;
}

export function createAccountProfilesUrl(
  page: number,
  companyId?: number,
  isTemplate?: boolean,
  sortOrder?: SortOrder,
  sortString?: string
): string {
  const url = new URLSearchParams();
  url.set('page', page.toString());
  if (companyId) url.set('companyId', companyId.toString());
  if (isTemplate !== undefined) url.set('isTemplate', isTemplate.toString());
  if (sortString) url.set('sort', sortString);
  if (sortOrder) url.set('sortDirection', sortOrder);
  return `${ROUTES.accountProfiles}?${url.toString()}`;
}

export function createUserAccountsDataProfilesUrl(
  accountProfileId: number | undefined,
  sortOrder?: SortOrder,
  sortString?: string
): string {
  const url = new URLSearchParams();
  if (sortString) url.set('sort', sortString);
  if (sortOrder) url.set('sortDirection', sortOrder);
  return `${ROUTES.accountProfiles}/${accountProfileId}?${url.toString()}`;
}

export function createReportURL(
  config: {
    startDate?: string;
    endDate?: string;
    repairGroup?: string;
    workProvider?: string;
    runDate?: string;
    userAccountId?: string;
    streams?: string;
    siteCode?: string;
    schemaCodeList?: string;
    priorFullMonth?: boolean;
    repairSites?: string;
    schemeCodes?: string;
    noOfDateGroups?: string;
    reportingGroup?: string;
    repairSiteList?: string;
    nPSScore?: string;
    accountStatus?: string;
    showExcep?: string;
    year?: string;
    isInitialWorkCompletedDate?: string;
  },
  reportName: string
): string {
  const url = new URLSearchParams();
  if (config.year) url.set('year', config.year);
  if (config.endDate) url.set('endDate', config.endDate);
  if (config.runDate) url.set('runDate', config.runDate);
  if (config.streams) url.set('streams', config.streams);
  if (config.nPSScore) url.set('nPSScore', config.nPSScore);
  if (config.siteCode) url.set('siteCode', config.siteCode);
  if (config.showExcep) url.set('showExcep', config.showExcep);
  if (config.startDate) url.set('startDate', config.startDate);
  if (config.repairGroup) url.set('repairGroup', config.repairGroup);
  if (config.repairSites) url.set('repairSites', config.repairSites);
  if (config.schemeCodes) url.set('schemeCodes', config.schemeCodes);
  if (config.repairGroup) url.set('repairGroup', config.repairGroup);
  if (config.workProvider) url.set('workProvider', config.workProvider);
  if (config.workProvider) url.set('workProvider', config.workProvider);
  if (config.userAccountId) url.set('userAccountId', config.userAccountId);
  if (config.schemaCodeList) url.set('schemaCodeList', config.schemaCodeList);
  if (config.noOfDateGroups) url.set('noOfDateGroups', config.noOfDateGroups);
  if (config.reportingGroup) url.set('reportingGroup', config.reportingGroup);
  if (config.repairSiteList) url.set('repairSiteList', config.repairSiteList);
  if (config.accountStatus) url.set('accountStatus', config.accountStatus);
  if (config.isInitialWorkCompletedDate)
    url.set('isInitialWorkCompletedDate', config.isInitialWorkCompletedDate);
  if (config.priorFullMonth !== undefined)
    url.set(
      'priorFullMonth',
      config.priorFullMonth === true ? 'true' : 'false'
    );
  return `${ROUTES.reports}/${reportName}?${url.toString()}`;
}

export function createCycleTimeUrl(
  startDate: string | null,
  endDate: string | null,
  summaryType: string | number | null,
  siteCode?: string | null,
  manufacturer?: string | null,
  stream?: string | null,
  prevSummary?: string | null,
  breadcrumbs?: string | null
): string {
  const url = new URLSearchParams();
  if (siteCode) url.set('siteCode', siteCode);
  if (manufacturer) url.set('manufacturer', manufacturer);
  if (stream) url.set('stream', stream);
  if (startDate) url.set('startDate', startDate);
  if (endDate) url.set('endDate', endDate);
  if (summaryType) url.set('summaryType', summaryType.toString());
  if (prevSummary) url.set('prevSummary', prevSummary);
  if (breadcrumbs) url.set('breadcrumbs', breadcrumbs);

  return `${ROUTES.cycleTimeSummary}?${url.toString()}`;
}