import { Column, Row } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { theme } from 'core/Theming/theme';

export const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
  const { name } = column;
  if (name == 'affectsCycleTime') {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb:
          row.affectsCycleTime == 0
            ? theme.palette.reports.pink?.replace('#', '')
            : theme.palette.reports.green?.replace('#', ''),
      },
    };
  }
  if (!row[name] && row[name] != 0)
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: theme.palette.reports.pink?.replace('#', ''),
      },
    };
};

export const valuesConverter = (data: Row[]) => {
  return data.map((row) => {
    return {
      ...row,
      affectsCycleTime: row.affectsCycleTime == 0 ? 'No' : 'Yes',
    };
  });
};
