import { useHistory } from 'react-router-dom';
import { Row } from '@devexpress/dx-react-grid';
import { CellButton } from 'components/DataGrid';
import { createAccountProfileFromTemplate } from 'core/routes';

export const CustomCellRender = ({
  baseRender,
  headerId,
  row,
  value,
}: {
  baseRender: () => JSX.Element;
  headerId: string;
  row: Row;
  value: string | number | boolean | null;
}) => {
  const history = useHistory();

  if (typeof value !== 'boolean') return baseRender();
  if (headerId === 'isTemplate') return <>{value ? 'Yes' : 'No'}</>;
  if (headerId === 'isCreatingAsCopy')
    return (
      <CellButton
        onClick={(event) => {
          event.stopPropagation();
          history.push(createAccountProfileFromTemplate(row.accountProfileId));
        }}
      >
        Copy profile
      </CellButton>
    );

  return baseRender();
};
