import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import { TextField } from 'components/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { replacementVehicleStatuses } from 'pages/VehicleDetails/mappings';
import { ReplacementVehicleIcon } from 'components/ReplacementVehicleIcon';
import { Select } from 'components/Select';
import { RepairManualUpdate } from './form';
import dayjs from 'dayjs';
import {
  InputContainer,
  InputFirst,
  InputSecond,
  Label,
  MainInputContainer,
} from 'components/PageLayout';
import { Text } from 'components/Typography';
import { DatePickerField } from 'components/DatePickerFields';

export default function Mobility() {
  const { control, getValues, setValue } = useFormContext<RepairManualUpdate>();

  return (
    <MainInputContainer>
      <InputContainer direction="column">
        <Label>Replacement vehicle</Label>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              disabled
              selectedId={
                replacementVehicleStatuses.find(
                  (status) => status.value === value
                )?.id
              }
              onChange={(status) => onChange(status.value)}
              options={replacementVehicleStatuses.map((status) => ({
                id: status.id,
                value: status.value,
                text: status.text ?? '',
                optionElement: (
                  <IconContainer>
                    <ReplacementVehicleIcon
                      isStatusTooltipVisible={false}
                      status={status.value}
                    />
                    {status.text}
                  </IconContainer>
                ),
              }))}
            />
          )}
          name="replacementVehicle.replacementVehicleStatus"
          control={control}
        />
      </InputContainer>
      <InputContainer direction="row">
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Date Out</Label>
          <Controller
            render={({ field }) => (
              <DatePickerField
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                  if (
                    getValues('replacementVehicle.dateIn') &&
                    dayjs(value).isAfter(getValues('replacementVehicle.dateIn'))
                  ) {
                    //@ts-ignore
                    setValue('replacementVehicle.dateIn', null);
                  }
                }}
              />
            )}
            name="replacementVehicle.dateOut"
            control={control}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <Label>Date In</Label>
          <Controller
            render={({ field }) => (
              <DatePickerField
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                  if (
                    getValues('replacementVehicle.dateOut') &&
                    dayjs(value).isBefore(
                      getValues('replacementVehicle.dateOut')
                    )
                  )
                    //@ts-ignore
                    setValue('replacementVehicle.dateOut', null);
                }}
              />
            )}
            name="replacementVehicle.dateIn"
            control={control}
          />
        </InputSecond>
      </InputContainer>
      <InputContainer direction="row">
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Make</Label>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
            name="replacementVehicle.courtesyMake"
            control={control}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <Label>Model</Label>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
            name="replacementVehicle.courtesyModel"
            control={control}
          />
        </InputSecond>
      </InputContainer>
      <InputContainer direction="column">
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Registration</Label>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ maxLength: 40 }}
              />
            )}
            name="replacementVehicle.replacementReg"
            control={control}
          />
        </InputFirst>
      </InputContainer>
      <NotificationContainer>
        <Text fontSize="l">
          Please keep in mind that adding Date Out and Date In information might
          be useful to track replacement vehicles and their usage.
        </Text>
      </NotificationContainer>
    </MainInputContainer>
  );
}

const NotificationContainer = styled(Container)`
  background-color: ${({ theme }) => theme.palette.primary.active};
  padding: ${({ theme }) => theme.padding.s};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-bottom: 12px;
  }
`;

const IconContainer = styled(Container)`
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.margin.s};
  }
`;
