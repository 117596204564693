import { Column } from '@devexpress/dx-react-grid';

export const DETAILED_CYCLE_TIME_REPORT_TITLE =
  'Detailed Cycle Time Report with Exceptions';

export const DETAILED_CYCLE_TIME_REPORT_MESSAGE =
  'Provides a detailed breakdown of claims and their cycle times using the same criteria as the Cycle Time screen - this report also includes Exceptions that show why jobs do NOT appear on the Cycle Time Screen (Version 2)';

export const DETAILED_CYCLE_TIME_REPORT_GRID_TITLE =
  'DetailedCycleTimeReport_wEX_V2';

export const tableExtensionColumn = (showExcep: boolean) => [
  { columnName: 'repairer', width: '10%', wordWrapEnabled: true },
  { columnName: 'stream', width: 'auto', wordWrapEnabled: true },
  { columnName: 'manufacturer', width: '8%', wordWrapEnabled: true },
  { columnName: 'activeWebID', width: '10%', wordWrapEnabled: true },
  { columnName: 'registration', width: 'auto', wordWrapEnabled: true },
  { columnName: 'hours', width: 'auto', wordWrapEnabled: true },
  { columnName: 'fnolToOnsite', width: 'auto', wordWrapEnabled: true },
  { columnName: 'onSiteToStarted', width: 'auto', wordWrapEnabled: true },
  {
    columnName: 'startedToCompleted',
    width: 'auto',
    wordWrapEnabled: true,
  },
  {
    columnName: 'completedToDelivered',
    width: 'auto',
    wordWrapEnabled: true,
  },
  { columnName: 'keyToKey', width: 'auto', wordWrapEnabled: true },
  {
    columnName: showExcep ? 'fnolToDelivered' : 'fnolToInvoiced',
    width: 'auto',
    wordWrapEnabled: true,
  },
  { columnName: 'affectsCycleTime', width: 'auto', wordWrapEnabled: true },
];

export const columnsList = (showExcep: boolean): Column[] => [
  {
    name: 'repairer',
    title: 'Repairer',
    getCellValue: (row) => {
      return row['repairer'] || row['repairer'] == 0 ? row['repairer'] : '';
    },
  },
  {
    name: 'stream',
    title: 'Stream',
    getCellValue: (row) => {
      return row['stream'] || row['stream'] == 0 ? row['stream'] : '';
    },
  },
  {
    name: 'manufacturer',
    title: 'Manufacturer',
    getCellValue: (row) => {
      return row['manufacturer'] || row['manufacturer'] == 0
        ? row['manufacturer']
        : '';
    },
  },
  {
    name: 'activeWebID',
    title: 'ActiveWebID',
    getCellValue: (row) => {
      return row['activeWebID'] || row['activeWebID'] == 0
        ? row['activeWebID']
        : '';
    },
  },
  {
    name: 'registration',
    title: 'Registration',
    getCellValue: (row) => {
      return row['registration'] || row['registration'] == 0
        ? row['registration']
        : '';
    },
  },
  {
    name: 'hours',
    title: 'Hours',
    getCellValue: (row) => {
      return row['hours'] || row['hours'] == 0 ? row['hours'] : '';
    },
  },
  {
    name: 'fnolToOnsite',
    title: 'FNOL to On Site',
    getCellValue: (row) => {
      return row['fnolToOnsite'] || row['fnolToOnsite'] == 0
        ? row['fnolToOnsite']
        : '';
    },
  },
  {
    name: 'onSiteToStarted',
    title: 'Onsite to Started',
    getCellValue: (row) => {
      return row['onSiteToStarted'] || row['onSiteToStarted'] == 0
        ? row['onSiteToStarted']
        : '';
    },
  },
  {
    name: 'startedToCompleted',
    title: 'Started to Completed',
    getCellValue: (row) => {
      return row['startedToCompleted'] || row['startedToCompleted'] == 0
        ? row['startedToCompleted']
        : '';
    },
  },
  {
    name: 'completedToDelivered',
    title: 'Completed to Delivered',
    getCellValue: (row) => {
      return row['completedToDelivered'] || row['completedToDelivered'] == 0
        ? row['completedToDelivered']
        : '';
    },
  },
  {
    name: 'keyToKey',
    title: 'Key to Key',
    getCellValue: (row) => {
      return row['keyToKey'] || row['keyToKey'] == 0 ? row['keyToKey'] : '';
    },
  },
  {
    name: showExcep ? 'fnolToDelivered' : 'fnolToInvoiced',
    title: showExcep ? 'FNOL to Delivered' : 'FNOL to Invoiced',
    getCellValue: (row) => {
      const name = showExcep ? 'fnolToDelivered' : 'fnolToInvoiced';
      return row[name] || row[name] == 0 ? row[name] : '';
    },
  },
  {
    name: 'affectsCycleTime',
    title: 'Affects CycleTime',
    getCellValue: (row) => (row.affectsCycleTime == 0 ? 'No' : 'Yes'),
  },
];
