import {
  Repair,
  Repairer,
  SortOrder,
} from 'api/resources/models/AutoGenerated';
import { SelectOption } from 'components/InputSelect';
import { config } from 'core/config';
import { sortBy } from 'lodash';
import { useRepairers, useUser } from 'pages/hooks';
import { isSortOrder } from 'utils/helpers';

export type VehiclesSearchParams = {
  id: number | null;
  group: string | null;
  filterId: number;
  page: number;
  sort: string;
  sortOrder: SortOrder;
};

export function useVehiclesSearchParams(
  search: string
): VehiclesSearchParams | string | undefined {
  const urlParams = new URLSearchParams(search);

  if (
    !urlParams.has('filter') ||
    !urlParams.has('page') ||
    !urlParams.has('sort') ||
    !urlParams.has('sortDirection')
  )
    return undefined;

  const error = (param: keyof VehiclesSearchParams) =>
    `'${param}' is missing from query parameters or invalid parameter type`;

  const id = urlParams.get('id');
  if (id !== null && isNaN(Number(id))) return error('id');

  const group = urlParams.get('group');
  if (id === null && group === null)
    return `'id' or 'group' parameters are required`;

  const filterId = urlParams.get('filter');
  if (isNaN(Number(filterId))) return error('filterId');

  const page = urlParams.get('page');
  if (isNaN(Number(page)) || Number(page) < 0) return error('page');

  const sort = urlParams.get('sort');
  if (sort && !isKeyOfRepair(sort))
    return `'sort' parameter has incorrect value`;

  const sortOrder = urlParams.get('sortDirection');

  return {
    id: Number(id),
    group: group,
    filterId: Number(filterId),
    page: page != null ? Number(page) : 0,
    sort: sort ?? '',
    sortOrder:
      sortOrder !== null && isSortOrder(sortOrder)
        ? sortOrder
        : SortOrder.Ascending,
  };
}

export function isKeyOfRepair(value: string): value is keyof Repair {
  type R = keyof Repair;
  const rKeys: Record<R, string> = {
    claimReference: '',
    diaryDate: '',
    dtgCompleted: '',
    dtgCpd: '',
    dtgCreated: '',
    dtgDelivered: '',
    dtgReceived: '',
    dtgEcd: '',
    dtgOnSite: '',
    dtgUpdated: '',
    formalStatus: '',
    formalStatusDescription: '',
    repairCode: '',
    repairId: '',
    repairerGroup: '',
    replacementVehicleStatus: '',
    schemeCode: '',
    siteCode: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleReg: '',
    workInProgressCode: '',
    repairStage: '',
    totalDuration: '',
    k2KDuration: '',
  };

  return value in rKeys;
}

export function useFilterOptions(): SelectOption[] {
  const { user } = useUser();
  return sortBy(
    user?.recordFilters.map((record) => ({
      id: record.recordFilterId,
      value: record?.code ?? '',
      text: record?.name ?? '',
    })),
    (a) => (a.value === 'REPAIRS_ALLRECORDS' ? -1 : 0)
  );
}

export function useRepairersOptions(): {
  repairers: SelectOption<Repairer>[];
  isLoading: boolean;
} {
  const { repairers, isLoading } = useRepairers();
  return {
    repairers: [
      { id: 0, value: undefined, text: 'ALL REPAIRERS' },
      ...repairers.map((r) => ({
        id: r.repairerId,
        value: r,
        text: r.repairerName ?? '[Unknown name]',
      })),
    ],
    isLoading,
  };
}

export function useVehiclesFiltersValidation(
  params:
    | {
        filterId: number;
        repairerId: number | null;
        group: string | null;
      }
    | undefined
): { isValid: boolean; isLoading: boolean } {
  const { repairers, isLoading } = useRepairersOptions();
  const filterOptions = useFilterOptions();
  if (isLoading) return { isValid: false, isLoading };
  if (params === undefined) return { isValid: true, isLoading };

  if (!filterOptions.find((o) => o.id === params.filterId))
    return { isValid: false, isLoading };
  if (params.repairerId && !repairers.find((o) => o.id === params.repairerId))
    return { isValid: false, isLoading };
  if (
    params.group &&
    !repairers.find((o) => o.value?.repairerGroup === params.group)
  )
    return { isValid: false, isLoading };
  if (
    params.filterId ===
      filterOptions.find((f) => f.id === config.allRepairsFilterValue)?.id &&
    params.repairerId === repairers[0].id
  )
    return { isValid: false, isLoading };
  else return { isValid: true, isLoading };
}
