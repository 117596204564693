import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { Label } from 'components/PageLayout';
import { useInMobile } from 'core/Theming/Device';
import { Select } from 'components/Select';
import { MultiSelectNew } from 'components/MultiSelectNew';
import { CustomForm } from 'components/ReportsComponents/CustomForm';
import { SLAPerformanceV3FormProps } from './types';
import { renderSearchButton } from 'pages/ReportsDetails/helpers';
import { DateField } from './DateField';

export function SLAPerformanceV3SearchForm({
    handleSubmit,
    isDisabled,
    repairerGroupOptions,
    repairerGroup,
    setRepairerGroup,
    siteCodesOptions,
    siteCode,
    setSiteCode,
    isSearchDisabled,
    dateFields,
}: Readonly<SLAPerformanceV3FormProps>) {
    const isMobile = useInMobile();

    return (
        <CustomForm onSubmit={handleSubmit}>
            <CustomGridContainer direction="row"> 
                {dateFields.map(filed =>
                    <DateField
                        key={filed.label}
                        value={filed.value}
                        isDisabled={filed.isDisabled}
                        label={filed.label}
                        maxDate={filed.maxDate}
                        minDate={filed.minDate}
                        onChange={filed.onChange}
                    />)}
                <GridItem md={1} sm={1} xs={2}>
                    <Button
                        type="submit"
                        disabled={isSearchDisabled}
                    >
                        {renderSearchButton(isMobile)}
                    </Button>
                </GridItem>
            </CustomGridContainer>
            <Container>
                <InputButton md={3} sm={3} xs={12}>
                    <Label>Repairer Group</Label>
                    <Select
                        disabled={isDisabled}
                        options={repairerGroupOptions || []}
                        selectedId={
                            repairerGroupOptions?.find(
                                (e: { value: string | null }) => e.value == repairerGroup
                            )?.id
                        }
                        onChange={(item) => {
                            setRepairerGroup(item.value);
                            setSiteCode([]);
                        }}
                    />
                </InputButton>
                <InputButton md={3} sm={3} xs={12}>
                    <Label>Select Sites</Label>
                    <MultiSelectNew
                        disabled={isDisabled}
                        options={siteCodesOptions}
                        selectedOptions={siteCode}
                        onChange={(value) => setSiteCode(value ?? [])}
                    />
                </InputButton>
            </Container>
        </CustomForm>
    );
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  max-width: 330px !important;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const CustomGridContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;
