import styled from '@emotion/styled';
import { Container } from './Layout';
import Skeleton from './Skeleton';

interface Props {
  loadingRowsCount: number;
}

export const ReportsLoader = ({ loadingRowsCount = 1 }: Props) => {
  return (
    <Container lg={6}>
      {[...Array(loadingRowsCount)]?.map((e, i) => (
        <SkeletonContainer lg={6} wrap={'wrap'} key={i}>
          <SkeletonLoader
            variant="rectangular"
            height={56}
            width={323}
            startingPoint={'start'}
          />
          <SkeletonLoader
            variant="rectangular"
            height={56}
            width={323}
            startingPoint={'end'}
          />
        </SkeletonContainer>
      ))}
    </Container>
  );
};

const SkeletonContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SkeletonLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-bottom: ${({ theme }) => `${theme.margin.m}`};
  margin-right: ${({ theme }) => `${theme.margin.m}`};
`;
