import { Row, IntegratedSummary } from '@devexpress/dx-react-grid';
import { SLAPerformance } from 'api/resources/models/AutoGenerated';
import { convertMinToDayHourMin } from './convertMinToDayHourMin';
import { getClaimValue } from './getClaimValue';

export const summaryCalculator = (
  type: string,
  rows: SLAPerformance[],
  getValue: (row: Row) => Row
) => {
  if (!rows.length) {
    return null;
  }

  const totalRow = rows.length;

  if (type === 'percentage') {
    let percentageValue = '0.00%';
    const totalCompletedRow = rows
      .map((a: SLAPerformance) => a.slaBreach)
      .filter((a) => a === 0).length;
    if (totalRow && totalRow !== 0) {
      percentageValue = `${Number((totalCompletedRow / totalRow) * 100).toFixed(2)}%`;
    }
    return percentageValue;
  }
  if (type === 'average') {
    let totalValue = 0;
    rows.forEach((a) => {
      totalValue = totalValue + Number(a.slaDuration);
    });

    let averageValue: number | string = 0

    if (totalRow && totalRow !== 0) {
      averageValue = convertMinToDayHourMin(
        totalValue / totalRow,
        rows?.[0]?.timeType || 'S'
      );
    }

    return averageValue;
  }

  if (type === 'claims_percentage') {
    const shouldDisplay = rows?.filter((data) => data.slaTitle?.includes('Bookin Accuracy')).length > 0;

    const claimsPercentage = `${shouldDisplay},${getClaimValue(
      'OnTime', rows, totalRow
    )},${getClaimValue('Late', rows, totalRow)},${getClaimValue('Early', rows, totalRow)},${getClaimValue(
      'N/A', rows, totalRow
    )}`;

    return claimsPercentage;
  }
  return IntegratedSummary.defaultCalculator(type, rows, getValue);
};


