/** @jsxImportSource @emotion/react */

import { Container, GridItem } from 'components/Layout';
import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';

export function VehicleDetailsLoader() {
    return (
        <>
            <SkeletonContainer>
                <SkeletonLoader
                    variant="rectangular"
                    height={56}
                    width={323}
                    startingPoint={'start'}
                />
                <SkeletonLoader
                    variant="rectangular"
                    height={56}
                    width={323}
                    startingPoint={'end'}
                />
            </SkeletonContainer>
            <SkeletonFullWidth direction="column">
                <SkeletonLoader
                    variant="rectangular"
                    height={145}
                    startingPoint={'start'}
                />
                <Separator />
                <SkeletonLoader
                    variant="rectangular"
                    height={145}
                    startingPoint={'middle'}
                />
            </SkeletonFullWidth>
            <SkeletonRow>
                <GridItem md={4} sm={12} xs={12}>
                    <InfoLoader
                        variant="rectangular"
                        height={520}
                        startingPoint={'end'}
                    />
                </GridItem>
                <GridItem md={4} sm={12} xs={12}>
                    <SlaLoader
                        variant="rectangular"
                        height={520}
                        startingPoint={'start'}
                    />
                </GridItem>
                <GridItem md={4} sm={12} xs={12}>
                    <ActivitiesLoader
                        variant="rectangular"
                        height={520}
                        startingPoint={'middle'}
                    />
                </GridItem>
            </SkeletonRow>
        </>
    );
}

const SkeletonContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-top: ${({ theme }) => `${theme.margin.s}`};
`;

const SkeletonFullWidth = styled(Container)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  width: 100%;
`;

const Separator = styled.div`
  margin-top: ${({ theme }) => `${theme.margin.m}`};
`;

const SkeletonLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-bottom: ${({ theme }) => `${theme.margin.m}`};
`;

const SkeletonRow = styled(Container)`
  background-color: ${({ theme }) => `${theme.palette.primary.skeleton}`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 35px 32px 45px 32px;
`;

const InfoLoader = styled(Skeleton)`
  margin-right: 12px;
`;
const SlaLoader = styled(Skeleton)`
  margin: 0px 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: ${({ theme }) => `${theme.margin.m}`};
    margin-bottom: ${({ theme }) => `${theme.margin.m}`};
  }
`;
const ActivitiesLoader = styled(Skeleton)`
  margin-left: 12px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-right: 12px;
  }
`;