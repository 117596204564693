import { Table as TableBase } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import XMLViewer from 'react-xml-viewer';
import { decode } from 'html-entities';
import { useState } from 'react';

function XMLViewerComponent({ xml }: { xml: string }) {
  const [collapse, setCollapse] = useState(true);
  return (
    <div onClick={() => setCollapse((prevData) => !prevData)}>
      <XMLViewer
        xml={collapse ? xml.substring(0, 30) : xml}
        invalidXml={collapse ? xml.substring(0, 30) : xml}
        theme={{ overflowBreak: true }}
      />
    </div>
  );
}

export function CellComponent(props: TableBase.DataCellProps) {
  const { column, value } = { ...props };
  if (column.name == 'messageContent') {
    const decoded_xml = decode(decode(value, { level: 'xml' }), {
      level: 'xml',
    });
    return (
      <Table.Cell {...props}>
        <XMLViewerComponent xml={decoded_xml} />
      </Table.Cell>
    );
  }
  return <Table.Cell {...props} />;
}
