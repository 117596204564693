import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { convertToDate, useVolumeTrendsReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate, formatDateTime } from 'utils/helpers';
import { ReportProps } from '../hooks';

import { AxiosError } from 'axios';
import { createReportURL } from 'core/routes';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import {
    VOLUME_TRENDS_REPORT,
    columns,
    customizeCell,
    dateColumnsName,
    tableColumnExtensions,
} from './constants';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { ReportSearchForm } from 'components/ReportForms/ReportSearchForm';
import { reportLogger } from '../reportLogger';
import { valuesConverter } from './helpers';


export function VolumeTrendsReport({
    reportPath,
    setErrorMessage,
    setToast,
}: ReportProps) {
    const history = useHistory();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const urlParamsValue = {
        endDate: urlParams.get('endDate'),
        startDate: urlParams.get('startDate'),
    };

    const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate;

    const { isReportFetching, refetchReport, reportData } = useVolumeTrendsReport(
        {
            startDate: urlParamsValue.startDate,
            endDate: urlParamsValue.endDate,
            enabled: queryEnabled,
            onError: handleError,
        }
    );

    const rows = useMemo<Row>(() => reportData, [reportData]);

    const [startDate, setStartDate] = useState<Date | null>(
        convertToDate(urlParamsValue.startDate) ?? null
    );
    const [endDate, setEndDate] = useState<Date | null>(
        convertToDate(urlParamsValue.endDate) ?? null
    );

    useEffect(() => {
        navigateIfPossible();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParamsValue.startDate, urlParamsValue.endDate]);

    return (
        <MainPageContainer
            isLoading={isReportFetching}
            direction="column"
            wrap="nowrap"
        >
            <UpperInfoContainer direction="column">
                <ReportBackButton />
                <ReportHeader
                    reportName={VOLUME_TRENDS_REPORT}
                    infoMessage="Displays the Volume Trends Report for the selected date range."
                />
                <ReportSearchForm
                    handleSubmit={handleSubmit}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    isDisabled={isReportFetching}
                />
            </UpperInfoContainer>

            {!isReportFetching && rows?.length === 0 && (
                <NoReportDataMessage />
            )}

            <ReportGridContainer direction="column">
                <ReportGrid
                    rows={rows}
                    columns={columns}
                    isLoading={isReportFetching}
                    title="VolumeTrendsReport"
                    customizeCellExport={customizeCell}
                    DateTypeProvider={DateTypeProvider}
                    dateColumnsName={dateColumnsName}
                    tableColumnExtensions={tableColumnExtensions}
                    headerTitleForSheet={VOLUME_TRENDS_REPORT}
                    reportHeaderTagLine={`Report produced on ${formatDateTime(
                        new Date()
                    )} from ${formatDate(startDate)} to ${formatDate(endDate)}`}
                    showAdditionalExportOptions
                    valuesConverter={valuesConverter}
                />
            </ReportGridContainer>
        </MainPageContainer>
    );

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        if (
            urlParamsValue.endDate == dateToString(endDate) &&
            urlParamsValue.startDate == dateToString(startDate)
        ) {
            refetchReport();
        } else navigateIfPossible();
    }

    function navigateIfPossible() {
        if (!!startDate && !!endDate) {
            history.replace(
                createReportURL(
                    {
                        startDate: dateToString(startDate),
                        endDate: dateToString(endDate),
                    },
                    reportPath
                )
            );
        }
    }

    function handleError(error: AxiosError) {
        reportLogger(error);
        setErrorMessage(errorMessages.reports(VOLUME_TRENDS_REPORT));
        setToast(true);
    }
}

