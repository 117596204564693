import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import {
  MainPageContainer,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { convertToDate, useGetVehiclesOnSiteReport } from 'pages/hooks';
import { FormEvent, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import { columns, dateColumnsName, tableColumnExtensions } from './constants';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { VehiclesOnSiteSearch } from '../../../components/ReportForms/VehiclesOnSiteSearch';
import { customizeCell, valuesConverter } from './helpers';
import { reportLogger } from '../reportLogger';

export function VehiclesOnSite({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const urlParams = {
    startDate: urlSearchParams.get('startDate'),
    endDate: urlSearchParams.get('endDate'),
    priorFullMonth:
      urlSearchParams.get('priorFullMonth') === 'true' ? true : false,
  };

  const queryEnabled = !!urlParams.startDate && !!urlParams.endDate;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.startDate, urlParams.endDate, urlParams.priorFullMonth]);

  const { vehiclesOnSiteReportData, refetchReport, isReportFetching } =
    useGetVehiclesOnSiteReport(
      {
        startDate: urlParams.startDate,
        endDate: urlParams.endDate,
        priorFullMonth:
          urlSearchParams.get('priorFullMonth') === 'true'
            ? true
            : urlSearchParams.get('priorFullMonth') === 'false'
              ? false
              : true,
      },
      queryEnabled,
      handleError
    );

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );

  const [priorFullMonth, setPriorFullMonth] = useState<boolean>(
    urlSearchParams.get('priorFullMonth') === 'true'
      ? true
      : urlSearchParams.get('priorFullMonth') === 'false'
        ? false
        : true
  );

  return (
    <MainPageContainer isLoading={isReportFetching} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName="Vehicles On Site"
          infoMessage="Displays the Vehicles On Site report for the date range provided"
        />
        <VehiclesOnSiteSearch
          handleSubmit={handleSubmit}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          priorFullMonth={priorFullMonth}
          setPriorFullMonth={setPriorFullMonth}
          isDisabled={isReportFetching}
        />
      </UpperInfoContainer>
      {!isReportFetching && vehiclesOnSiteReportData && vehiclesOnSiteReportData?.length === 0 && (
        <NoReportDataMessage />
      )}
      <ReportGridContainer direction="column">
        <ReportGrid
          rows={vehiclesOnSiteReportData}
          columns={columns}
          title="VehiclesOnSite"
          isLoading={isReportFetching}
          DateTypeProvider={DateTypeProvider}
          customizeCellExport={customizeCell}
          headerTitleForSheet="Vehicles on site (v01)"
          tableColumnExtensions={tableColumnExtensions}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          dateColumnsName={dateColumnsName}
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParams.startDate == dateToString(startDate) &&
      urlParams.endDate == dateToString(endDate) &&
      urlParams.priorFullMonth == priorFullMonth
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate) {
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            priorFullMonth: priorFullMonth,
          },
          reportPath
        )
      );
    }
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports('Vehicles on site (v01)'));
    setToast(true);
  }
}

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  margin-bottom: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
