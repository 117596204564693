import { api } from 'api';
import { config } from 'core/config';
import {
  CoveaReport,
  GoodWillReport,
  NPSBillingReport,
  RenderExtension,
  TotalLossReport,
  SsrsReportParameter,
  InvoiceReport,
  SmsReport,
  CycleTimeReportExV2,
  Measure30Result,
  WorkInProgress,
  SLAPerformance,
  SMSBillingReport,
  NARGControlSheetV05,
  B2BRestQueueMessage,
  VehiclesOnsiteReport,
  WorkProviderScheme,
  NPSSummaryReport04,
  NPSDetailReport04,
  ActiveWebUsageReport,
  AllSiteConnection,
  FeedbackReport,
  GetKey2KeyDataLVOWN,
  Key2KeyDataRSA,
  CSITriggerReport,
  VolumeTrendsReport,
  CoreMIReport,
  NotificationVolumeResponseModel,
  CombinedBillingReport,
} from './models/AutoGenerated';
import * as qs from 'qs';
import { reportAPIEndDate } from 'utils/helpers';

const reportsUrl = '/api/reports';
const reportsTimeout = 60000;

export const getHtmlReport = (
  reportPath: string,
  pageNumber: number,
  parameters: { [name: string]: string }[]
): Promise<string> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/${reportPath}.html`, {
      params: {
        pageNumber,
        parameters,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then((response) => response.data);

export const getReportFile = (
  reportPath: string,
  parameters: { [name: string]: string }[]
) =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/file/${reportPath}`, {
      params: {
        parameters,
      },
      paramsSerializer: (params) => qs.stringify(params),
      responseType: 'blob',
    })
    .then((response) => {
      return response;
    });

export const getReportParameters = (
  reportPath: string
): Promise<SsrsReportParameter[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/${reportPath}/parameters`, {
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getRenderExtensions = (): Promise<RenderExtension[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/extensions`)
    .then((response) => response.data);

export const getReportPageCount = (
  reportPath: string,
  parameters: { [name: string]: string }[]
): Promise<number> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/${reportPath}/page-count`, {
      params: {
        parameters,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then((response) => response.data);

export const getRepairStatusReportCovea = (
  startDate: string | null,
  endDate: string | null
): Promise<CoveaReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/covea`, {
      params: { startDate, endDate: reportAPIEndDate(endDate) },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getNPSBillingReport = (
  startDate: string | null,
  endDate: string | null
): Promise<NPSBillingReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/nps`, {
      params: { startDate, endDate: reportAPIEndDate(endDate) },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getSMSReport = (
  startDate: string | null,
  endDate: string | null
): Promise<SmsReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/sms`, {
      params: { startDate, endDate: reportAPIEndDate(endDate) },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getWorkInProgressV3 = (
  repairerGroup: string | null
  // startDate?: string | null,
  // endDate?: string | null
): Promise<WorkInProgress> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/workinprogressv3`, {
      params: { repairerGroup },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getNargControlSheetV5 = (
  startDate: string | null,
  endDate: string | null,
  schemeCodeList: string | null
): Promise<NARGControlSheetV05> =>
  api
    .post(
      `${config.activewebApiUrl}${reportsUrl}/nargcontrolsheetv05`,
      {
        startDate,
        endDate: reportAPIEndDate(endDate),
        schemeCodeList,
      },
      { timeout: reportsTimeout }
    )
    .then((response) => response.data);

export const getNpsSummaryReportV4 = (
  endDate: string | null,
  repairSites: string | null,
  schemeCodes: string | null,
  noOfDateGroups: string | null,
  reportingGroup: string | null
): Promise<NPSSummaryReport04[]> =>
  api
    .post(
      `${config.activewebApiUrl}${reportsUrl}/npssummaryreportV04`,
      {
        startDate: '',
        endDate: reportAPIEndDate(endDate),
        repairSites,
        schemeCodes,
        reportingGroup,
        noOfDateGroups,
        dateRangeOption: 'Partial',
      },
      { timeout: reportsTimeout }
    )
    .then((response) => response.data);

export const getB2BRestMessagesReport = (
  startDate: string | null,
  endDate: string | null,
  userAccountId: string | null
): Promise<B2BRestQueueMessage> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/b2bmessage`, {
      params: { startDate, endDate: reportAPIEndDate(endDate), userAccountId },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getSMSBillingReport02 = (
  startDate: string | null,
  endDate: string | null,
  workProvider: string | null
): Promise<SMSBillingReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/smsbilling`, {
      params: {
        startDate,
        endDate: reportAPIEndDate(endDate),
        wpMasterName: workProvider,
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const gettotalLoss = (
  startDate: string | null,
  endDate: string | null
): Promise<TotalLossReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/totalLossSchedule`, {
      params: { startDate, endDate: reportAPIEndDate(endDate) },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getDLProposedGoodwillReport = (
  startDate: string | null,
  endDate: string | null
): Promise<GoodWillReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/goodwill`, {
      params: { startDate, endDate: reportAPIEndDate(endDate) },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getSLAPerformanceV3Report = (
  startDate: string | null,
  endDate: string | null,
  repairerGroup: string | null,
  siteCode: string | null
): Promise<SLAPerformance[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/slaperformancev3`, {
      params: {
        startDate,
        endDate: reportAPIEndDate(endDate),
        repairerGroup,
        siteCode,
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getInvoiceExtractReport = (
  startDate: string | null,
  endDate: string | null,
  dataAccessProfileName: string | null,
  basedOnCreatedDateOnly: boolean
): Promise<InvoiceReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/invoice`, {
      params: {
        startDate,
        endDate: reportAPIEndDate(endDate),
        dataAccessProfileName:
          dataAccessProfileName && encodeURI(dataAccessProfileName),
        basedOnCreatedDateOnly,
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getDetailedCycleTimeReport = (
  startDate: string | null,
  endDate: string | null,
  repairerGroup: string | null,
  siteCode: string | null,
  showExep: boolean
): Promise<CycleTimeReportExV2[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/detailedcycletimeteportexv2`, {
      params: {
        startDate,
        endDate: reportAPIEndDate(endDate),
        repairerGroup,
        siteCode,
        showExep,
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getMeasureFuture30report = (
  runDate: string | null,
  repairerGroup: string | null,
  siteCodes: string | null,
  rollingMeasureCodes: string | null,
  wpMasterIds: string | null
): Promise<Measure30Result[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/measurefuture30report`, {
      params: {
        runDate,
        repairerGroup,
        siteCodes,
        rollingMeasureCodes,
        wpMasterIds,
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getMeasureRolling30Report = (
  runDate: string | null,
  repairerGroup: string | null,
  siteCodes: string | null,
  rollingMeasureCodes: string | null,
  wpMasterIds: string | null
): Promise<Measure30Result[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/measurerolling30report`, {
      params: {
        runDate,
        repairerGroup,
        siteCodes,
        rollingMeasureCodes,
        wpMasterIds,
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getVehiclesOnSiteReport = (
  startDate: string | null,
  endDate: string | null,
  priorFullMonth: boolean | null
): Promise<VehiclesOnsiteReport[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/vehiclesonsite`, {
      params: { startDate, endDate: reportAPIEndDate(endDate), priorFullMonth },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getNpsSummarySiteCodes = (): Promise<WorkProviderScheme[]> =>
  api
    .get(`${config.activewebApiUrl}/api/workprovider/npsschemecodes`, {
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getNPSDetailV4Report = (
  startDate: string | null,
  endDate: string | null,
  repairSites: string | null,
  schemeCodes: string | null,
  nPSScore: string | null,
  groupingValue: string | null,
  reportGrouping: string | null
): Promise<NPSDetailReport04[]> =>
  api
    .post(
      `${config.activewebApiUrl}${reportsUrl}/npsdetailreportV04`,
      {
        startDate,
        endDate: reportAPIEndDate(endDate),
        repairSites,
        schemeCodes,
        nPSScore,
        groupingValue,
        reportGrouping,
      },
      { timeout: reportsTimeout }
    )
    .then((response) => response.data);

export const getActiveWebUsageReportV2 = (
  accountStatus: string | null
): Promise<ActiveWebUsageReport[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/activewebusage`, {
      params: { accountStatus },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getAllSitesConnectionReport = (): Promise<AllSiteConnection[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/allsiteconnections`, {
      timeout: reportsTimeout,
    })
    .then((response) => response.data);
export const getFeedbackReport = (
  startDate: string | null,
  endDate: string | null
): Promise<FeedbackReport[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/handlerfeedback`, {
      params: { startDate, endDate: reportAPIEndDate(endDate) },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getKey2KeyRSAReport = (
  years: string | null
): Promise<Key2KeyDataRSA[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/key2KeyReportRSA`, {
      params: { years },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getKey2KeyLVOWNReport = (
  years: string | null
): Promise<GetKey2KeyDataLVOWN[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/key2KeyReportLVOWN`, {
      params: { years },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getZurichCSIReport = (
  startDate: string | null,
  endDate: string | null
): Promise<CSITriggerReport[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/csitriggerreport`, {
      params: { startDate, endDate: reportAPIEndDate(endDate) },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getCoreMiReport = (
  startDate: string | null,
  endDate: string | null,
  isInitialWorkCompletedDate: boolean
): Promise<CoreMIReport[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/coremireport`, {
      params: {
        startDate,
        endDate: reportAPIEndDate(endDate),
        isInitialWorkCompletedDate,
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getVolumeTrendsReport = (
  startDate: string | null,
  endDate: string | null
): Promise<{ data: VolumeTrendsReport[] }> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/volumetrendsreport`, {
      params: {
        startDate,
        endDate: reportAPIEndDate(endDate),
      },
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getMonthlyNotificationVolumeReport = (): Promise<{data: NotificationVolumeResponseModel[]}> =>
  api  
    .get(`${config.activewebApiUrl}${reportsUrl}/monthlynotificationvolume`, {
      timeout: reportsTimeout,
    })
    .then((response) => response.data);

export const getCombinedNpsSmsEmailBillingReport= (
  startDate: string | null,
  endDate: string | null,
  wPGroupNames: string | null
): Promise<CombinedBillingReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/combinedBillingReport`, {
      params: {
        startDate,
        endDate: reportAPIEndDate(endDate),
        wPGroupNames
      },
      timeout: reportsTimeout,
    })
    .then((response) => (response.data));