import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { Label } from 'components/PageLayout';
import { useInMobile } from 'core/Theming/Device';
import {
  renderSearchButtonText,
  renderSearchIcon,
} from '../../pages/ReportsDetails/helpers';
import { Select } from 'components/Select';
import { SelectFormProps } from './types';

export function ReportSelectForm({
  selectOptions,
  selectedValue,
  handleSubmit,
  onChange,
  isBusy,
  label,
}: SelectFormProps) {
  const isMobile = useInMobile();

  return (
    <CustomForm onSubmit={handleSubmit}>
      <Container direction="row">
        <InputButton md={3} sm={4} xs={12}>
          <Label>{label}</Label>
          <Select
            disabled={isBusy}
            options={selectOptions}
            onChange={(status) => onChange(status.value)}
            selectedId={
              selectOptions?.find(
                (e: { value: string | null }) => e.value == selectedValue
              )?.id
            }
          />
        </InputButton>
        <GridItem md={1} sm={1} xs={2}>
          <Button type="submit" disabled={!selectedValue || isBusy}>
            {renderSearchIcon(isMobile)}
            {renderSearchButtonText(isMobile)}
          </Button>
        </GridItem>
      </Container>
    </CustomForm>
  );
}

const InputButton = styled(GridItem)`
    padding-right: ${({ theme }) => theme.margin.s};
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        margin - bottom: 6px;
    }
    & svg {
        width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
    }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
