import { useTheme } from '@emotion/react';
import { Table as TableBase } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';

export function CellComponent(props: TableBase.DataCellProps) {
  const theme = useTheme();

  const { row } = { ...props };
  if (row.disabled)
    return (
      <Table.Cell
        {...props}
        style={{ background: theme.palette.reports.pink }}
      />
    );
  return <Table.Cell {...props} />;
}
