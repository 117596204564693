import { Row, Column } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { Columns, TableColumnExtensions } from './types';
import { customizeDateColumns } from '../helpers';

export const VOLUME_TRENDS_REPORT = 'Volume Trends Report';

export const columns: Columns[] = [
  { name: 'yearSelected', title: 'Year' },
  { name: 'day', title: 'Day' },
  { name: 'volume', title: 'Volume' },
  { name: 'measure', title: 'Measure' },
];

export const tableColumnExtensions: TableColumnExtensions[] = [
  { columnName: 'yearSelected', wordWrapEnabled: true },
  { columnName: 'day', wordWrapEnabled: true },
  { columnName: 'volume', wordWrapEnabled: true },
  { columnName: 'measure', wordWrapEnabled: true },
];

export const dateColumnsName: string[] = ['day'];

export const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
  customizeDateColumns(cell, row, column, dateColumnsName)
};