import styled from '@emotion/styled';
import { SecondaryButton } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Container, GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import PrimaryToast from 'components/PrimaryToast';
import { TextArea } from 'components/TextArea';
import { ErrorText } from 'components/Typography';
import { logger } from 'core/logger';
import { useAddActivity, useUser } from 'pages/hooks';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { MainModalContainer, ModalButton } from 'components/ModalLayout';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const formSchema = z.object({
  activityComment: z
    .string()
    .min(3, { message: 'Must be 3 or more characters long' }),
  isComplaint: z.boolean(),
});

interface AddActivityModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onAdded: VoidFunction;
  repairCode: string;
}
type FormData = {
  activityComment: string;
  isComplaint: boolean;
};

export function AddActivityModal({
  isOpen,
  onClose,
  onAdded,
  repairCode,
}: AddActivityModalProps) {
  const { addActivity, isAdding } = useAddActivity();
  const { user } = useUser();
  const [toastMessage, setToastMessage] = useState('');
  const [isOpenToast, setIsOpenToast] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      activityComment: '',
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    if (user?.fullName) {
      addActivity(repairCode, {
        userName: user?.fullName,
        activityComment: data.activityComment,
        isComplaint: data.isComplaint,
      })
        .then(() => {
          onAdded();
          reset();
        })
        .catch(() => {
          setToastMessage(errorMessages.addActivity);
          setIsOpenToast(true);
        });
    } else {
      setToastMessage(errorMessages.default);
      const errorMessage = 'Can not add activity. User or userName was found null or undefined'
      logger?.error(errorMessage);
      appInsights?.trackException({ error: new Error(errorMessage), severityLevel: SeverityLevel.Error });
      setIsOpenToast(true);
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnEsc
      title="Add Activity"
    >
      <form onSubmit={onSubmit}>
        <MainModalContainer direction="column">
          <GrayBackdrop open={isAdding}>
            <Loader />
          </GrayBackdrop>
          <Controller
            render={({ field }) => (
              <StyledTextArea
                autoFocus
                {...field}
                placeholder="Type in your comment"
                error={!!errors.activityComment}
              />
            )}
            name="activityComment"
            control={control}
          />
          <ErrorText>{errors.activityComment?.message}</ErrorText>
          <Controller
            render={({ field }) => (
              <Checkbox {...field} label="This is a complaint" />
            )}
            name="isComplaint"
            control={control}
            defaultValue={false}
          />
          <Container direction="row">
            <GridItem sm={6} xs={12}>
              <Cancel onClick={handleOnClose}>Cancel</Cancel>
            </GridItem>
            <GridItem sm={6} xs={12}>
              <ModalButton type="submit">Add Activity</ModalButton>
            </GridItem>
          </Container>
        </MainModalContainer>
      </form>
      <PrimaryToast
        message={toastMessage}
        isOpen={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        severity="error"
      />
    </Modal>
  );

  function handleOnClose() {
    onClose();
    reset();
  }
}

const StyledTextArea = styled(TextArea)<{ error?: boolean }>`
  height: 174px;
  width: 312px;
  border: ${({ theme, error }) =>
    `1px solid  ${
      error ? theme.palette.messages.error : theme.palette.secondary.main
    }`};
  outline: none;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    width: auto;
  }

  :hover {
    border: ${({ theme, error }) =>
      `1px solid  ${
        error ? theme.palette.messages.error : theme.palette.secondary.main
      }`};
  }
`;

const Cancel = styled(SecondaryButton)`
  width: 100%;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
`;
