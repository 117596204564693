import styled from '@emotion/styled';
import { FormHelperText } from '@mui/material';
import { Permit } from 'api/resources/models/AutoGenerated';
import { Container, GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import { TextField } from 'components/TextField';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from 'pages/hooks';
import { useUserPermit } from 'components/Permits';
import { useCallback, useEffect, useState } from 'react';
import {
  Header,
  InputFirst,
  InputSecond,
  Label,
  SaveButton,
  SaveIcon,
  InputContainer,
  RequiredLabel,
} from 'components/PageLayout';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { Checkbox } from 'components/Checkbox';
import { DatePickerField } from 'components/DatePickerFields';
import dayjs from 'dayjs';
import { formSchema } from './formSchema';
import { UserAccount, UserDetailsProps } from './types';

export function UserDetails({
  user,
  isBusy,
  onSubmit,
  companies,
  toastHandler,
  accountProfiles,
  onCompanyChange,
  dataAccessProfiles,
  dataAccessProfileClick,
  blockNavigationHandler,
}: UserDetailsProps) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<UserAccount>({
    defaultValues: {
      firstName: user?.firstName ?? '',
      surname: user?.surname ?? '',
      email: user?.email ?? '',
      companyId: user?.companyId ?? undefined,
      teamName: user?.teamName ?? '',
      dataAccessProfileId: user?.dataAccessProfileId ?? undefined,
      defaultUserAccountProfileId:
        user?.defaultUserAccountProfileId ?? undefined,
      telephone: user?.telephone ?? null,
      mobile: user?.mobile ?? null,
      allowIPOverride: user?.allowIPOverride ?? false,
      expiryDate: user?.expiryDate ?? null,
    },
    resolver: zodResolver(formSchema),
  });

  const { user: loggedInUser } = useUser();
  const { permitAllowed: isGlobalAdmin } = useUserPermit(Permit.GLOBAL_ADMIN);

  const companyOptions = companies?.map((c) => ({
    id: c.id,
    text: c.name ?? '',
    value: c.name ?? undefined,
  }));
  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(
    companyOptions?.find((c) => c.id === user?.companyId) ?? null
  );

  const dataAccessProfileOptions = useCallback(
    () =>
      dataAccessProfiles.map((d) => ({
        id: d.id,
        value: d.name ?? undefined,
        text: d.name ?? '',
      })),
    [dataAccessProfiles]
  );

  const [selectedDataAccessProfile, setSelectedDataAccessProfile] =
    useState<SelectOption | null>(null);

  const accountProfileOptions = accountProfiles?.map((a) => ({
    id: a.accountProfileId,
    value: a.name ?? undefined,
    text: a.name ?? '',
  }));
  const [selectedDefaultAccountProfile, setSelectedDefaultAccountProfile] =
    useState<SelectOption | null>(
      accountProfileOptions?.find(
        (d) => d.id === user?.defaultUserAccountProfileId
      ) ?? null
    );

  useEffect(() => {
    setSelectedDataAccessProfile(
      dataAccessProfileOptions()?.find(
        (d) => d.id === user?.dataAccessProfileId
      ) ?? null
    );
  }, [dataAccessProfileOptions, user?.dataAccessProfileId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <Container direction="column">
        <GridItem>
          <StyledSaveButton type="submit">
            <SaveIcon />
            Save Changes
          </StyledSaveButton>
          <Header>Personal Details</Header>
          <InputContainer direction="row">
            <InputFirst md={6} sm={6} xs={12}>
              <RequiredLabel>First Name</RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.firstName}
                    fullWidth
                    helperText={errors.firstName?.message}
                    onChange={(e) => {
                      blockNavigationHandler(true);
                      setValue('firstName', e.target.value);
                    }}
                  />
                )}
                name="firstName"
                control={control}
              />
            </InputFirst>
            <InputSecond md={6} sm={6} xs={12}>
              <RequiredLabel>Surname</RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    error={!!errors.surname}
                    helperText={errors.surname?.message}
                    onChange={(e) => {
                      blockNavigationHandler(true);
                      setValue('surname', e.target.value);
                    }}
                  />
                )}
                name="surname"
                control={control}
              />
            </InputSecond>
          </InputContainer>
          <Header>Contact Details</Header>
          <InputContainer direction="row">
            <InputFirst md={6} sm={6} xs={12}>
              <RequiredLabel>Email</RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    onChange={(e) => {
                      blockNavigationHandler(true);
                      setValue('email', e.target.value);
                    }}
                  />
                )}
                name="email"
                control={control}
              />
            </InputFirst>
          </InputContainer>
          <InputContainer direction="row">
            <InputFirst md={6} sm={6} xs={12}>
              <Label>Mobile</Label>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors.mobile}
                    helperText={errors.mobile?.message}
                    onChange={(e) => {
                      let mobileValue: string | null = e.target.value;
                      if (e.target.value === '') mobileValue = null;
                      setValue('mobile', mobileValue);
                    }}
                  />
                )}
                name="mobile"
                control={control}
              />
            </InputFirst>
            <InputSecond md={6} sm={6} xs={12}>
              <Label>Telephone</Label>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    onChange={(e) => {
                      e.target.value === ''
                        ? setValue('telephone', null)
                        : setValue('telephone', e.target.value);
                    }}
                  />
                )}
                name="telephone"
                control={control}
              />
            </InputSecond>
          </InputContainer>
          <Header>Company Details</Header>
          <InputContainer direction="row">
            <InputFirst md={6} sm={6} xs={12}>
              <RequiredLabel>Company Name</RequiredLabel>
              {user ? (
                <TextField disabled value={user?.companyName} fullWidth />
              ) : null}
              {!user && loggedInUser && !isGlobalAdmin ? (
                <TextField
                  disabled
                  value={loggedInUser?.companyName}
                  fullWidth
                />
              ) : (
                companyOptions && (
                  <Controller
                    render={() => (
                      <InputSelect
                        options={companyOptions}
                        value={selectedCompany}
                        onChange={(e: SelectOption | null) => {
                          blockNavigationHandler(true);
                          setSelectedCompany(e);
                          setSelectedDataAccessProfile(null);
                          setSelectedDefaultAccountProfile(null);
                          reset(
                            {
                              ...getValues(),
                              companyId: undefined,
                              dataAccessProfileId: undefined,
                              defaultUserAccountProfileId: undefined,
                            },
                            { keepErrors: true }
                          );
                          if (e) setValue('companyId', e.id);
                          onCompanyChange?.(e?.id);
                          clearErrors('companyId');
                        }}
                        renderInput={(params) => (
                          <TextField {...params} error={!!errors.companyId} />
                        )}
                        forcePopupIcon
                      />
                    )}
                    name="companyId"
                    control={control}
                  />
                )
              )}
              {!!errors.companyId && (
                <SelectHelperText>{errors.companyId.message}</SelectHelperText>
              )}
            </InputFirst>
            <InputSecond md={6} sm={6} xs={12}>
              <RequiredLabel>Team Name</RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.teamName}
                    helperText={errors.teamName?.message}
                    fullWidth
                    onChange={(e) => {
                      blockNavigationHandler(true);
                      e.target.value === ''
                        ? setValue('teamName', null)
                        : setValue('teamName', e.target.value);
                    }}
                  />
                )}
                name="teamName"
                control={control}
              />
            </InputSecond>
          </InputContainer>
          <Header>User Data</Header>
          <InputContainer direction="row">
            <InputFirst md={6} sm={6} xs={12}>
              <RequiredLabel>Data Access Profile ID</RequiredLabel>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <InputSelect
                    forcePopupIcon
                    options={dataAccessProfileOptions()}
                    value={selectedDataAccessProfile}
                    onChange={(e: SelectOption | null) => {
                      e?.id != user?.dataAccessProfileId
                        ? blockNavigationHandler(true)
                        : blockNavigationHandler(false);
                      setSelectedDataAccessProfile(e);
                      onChange(e?.id);
                      clearErrors('dataAccessProfileId');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !!errors.dataAccessProfileId ||
                          (value > 0 &&
                            !dataAccessProfiles.find((a) => a.id === value))
                        }
                      />
                    )}
                  />
                )}
                name="dataAccessProfileId"
                control={control}
              />
              {!!errors.dataAccessProfileId && (
                <SelectHelperText>
                  {errors.dataAccessProfileId.message}
                </SelectHelperText>
              )}
            </InputFirst>
            {!!selectedDataAccessProfile && user && (
              <InputSecond md={6} sm={6} xs={12}>
                <StyledCustomButton
                  onClick={() =>
                    toastHandler() &&
                    dataAccessProfileClick(selectedDataAccessProfile)
                  }
                >
                  View Details
                </StyledCustomButton>
              </InputSecond>
            )}
          </InputContainer>
          {!user && accountProfileOptions && (
            <InputContainer direction="row">
              <InputFirst md={6} sm={6} xs={12}>
                <RequiredLabel>Default User Account Profile</RequiredLabel>
                <Controller
                  render={({ field: { onChange } }) => (
                    <InputSelect
                      forcePopupIcon
                      options={accountProfileOptions}
                      value={selectedDefaultAccountProfile}
                      onChange={(e: SelectOption | null) => {
                        blockNavigationHandler(true);
                        setSelectedDefaultAccountProfile(e);
                        onChange(e?.id);
                        clearErrors('defaultUserAccountProfileId');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.defaultUserAccountProfileId}
                        />
                      )}
                    />
                  )}
                  name="defaultUserAccountProfileId"
                  control={control}
                />
                {!!errors.defaultUserAccountProfileId && (
                  <SelectHelperText>
                    {errors.defaultUserAccountProfileId.message}
                  </SelectHelperText>
                )}
              </InputFirst>
            </InputContainer>
          )}
          <Header>IP Whitelisting</Header>
          <InputContainer direction="column">
            <Label>Override company level IP Whitelisting</Label>
            <Controller
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  label="Allow to login from any IP Address"
                  onChange={() => {
                    setValue('allowIPOverride', !field.value);
                  }}
                />
              )}
              name="allowIPOverride"
              control={control}
            />
          </InputContainer>
          <Header>Account Expiry</Header>
          <InputContainer direction="row">
            <InputFirst md={6} sm={6} xs={12}>
              <Label>Expiry Date</Label>
              <Controller
                render={({ field }) => {
                  return (
                    <DatePickerField
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        if (getValues('expiryDate')) {
                          return dayjs(value).isAfter(getValues('expiryDate'))
                            ? setValue('expiryDate', null)
                            : null;
                        }
                      }}
                      minDate={new Date()}
                    />
                  );
                }}
                name="expiryDate"
                control={control}
              />
              {!!errors.expiryDate && (
                <SelectHelperText>{errors.expiryDate.message}</SelectHelperText>
              )}
            </InputFirst>
          </InputContainer>
        </GridItem>
      </Container>
    </form>
  );
}

const StyledSaveButton = styled(SaveButton)`
  margin-top: ${({ theme }) => theme.margin.l};
`;

const StyledCustomButton = styled(SaveButton)`
  margin-top: 20.5px;
`;

const SelectHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.messages.error};
`;
