import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { pagePaddingLeft } from 'components/PageLayout';
import styled from '@emotion/styled';
import { BreadcrumbsProps } from './types';
import { useHistory, useLocation } from 'react-router-dom';
import { createCycleTimeUrl } from 'core/routes';
import { useMemo } from 'react';
import { summaryTypeMap } from './constants';

export function CycleTimeBreadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
    const history = useHistory();
    const location = useLocation();

    const urlParamsValue = useMemo(() => {
        const urlParams = new URLSearchParams(location.search);

        return {
            startDate: urlParams.get('startDate'),
            endDate: urlParams.get('endDate'),
        };
    }, [location.search]);

    const backToSearchView = () => {
        const defaultValue = '%';
        const summaryType = summaryTypeMap[breadcrumbs[0]];

        const newRoute = createCycleTimeUrl(
            urlParamsValue.startDate,
            urlParamsValue.endDate,
            summaryType,
            defaultValue,
            defaultValue,
            defaultValue,
        )

        history.push(newRoute);
    }

    return (
        <StyledBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {breadcrumbs.map((path, index) => {
                const first = index === 0;
                return first && breadcrumbs.length > 1 ? (
                    <Typography
                        key={index}
                        color="text.primary"
                        onClick={backToSearchView}
                        style={{ cursor: 'pointer', fontWeight: 'bold' }}
                        data-testid={`breadcrumb-clickable-${path}`}
                    >
                        {path}
                    </Typography>
                ) : (
                    <Typography color="text.primary" key={index} data-testid={`breadcrumb-item-${path}`}>
                        {path}
                    </Typography>
                );
            })}
        </StyledBreadcrumbs>
    );
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: ${({ theme }) =>
        `${pagePaddingLeft(theme)} 0 0 ${pagePaddingLeft(theme)}`};
`;
