export const B2B_REST_MESSAGES = 'B2B Rest Queue Messages';
export const B2B_REST_MESSAGES_INFO_MESSAGE =
  'Displays the B2B Rest Queue Messages for the date range provided';
export const REPORT_TITLE = 'b2bRestQueueMessages';
export const dateColumnsName = ['entryDate', 'readDate'];

export const columns = [
  { name: 'queue', title: 'Queue' },
  { name: 'queueMessageId', title: 'Queue Message Id' },
  { name: 'messageGuid', title: 'Message Guid' },
  { name: 'messageType', title: 'Message Type' },
  { name: 'messageContent', title: 'Message Content' },
  { name: 'messageHash', title: 'Message Hash' },
  { name: 'sender', title: 'Sender' },
  { name: 'senderReference', title: 'Sender Reference' },
  { name: 'recipient', title: 'Recipient' },
  { name: 'repairerSiteCode', title: 'Repairer Site Code' },
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'entryDate', title: 'Entry Date' },
  { name: 'readDate', title: 'Read Date' },
];

export const tableColumnExtensions = [
  { columnName: 'queue', wordWrapEnabled: true },
  { columnName: 'queueMessageId', wordWrapEnabled: true },
  { columnName: 'messageGuid', wordWrapEnabled: true },
  { columnName: 'messageType', wordWrapEnabled: true },
  { columnName: 'messageContent', wordWrapEnabled: true, width: '15%' },
  { columnName: 'messageHash', wordWrapEnabled: true },
  { columnName: 'sender', wordWrapEnabled: true },
  { columnName: 'senderReference', wordWrapEnabled: true },
  { columnName: 'recipient', wordWrapEnabled: true },
  { columnName: 'repairerSiteCode', wordWrapEnabled: true },
  { columnName: 'repairerName', wordWrapEnabled: true },
  { columnName: 'entryDate', wordWrapEnabled: true },
  { columnName: 'readDate', wordWrapEnabled: true },
];