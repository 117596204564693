/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GridItem } from 'components/Layout';
import { TextField } from 'components/TextField';
import InputSelectMultiple, { SelectOption } from './InputSelectMultiple';
import { Box, Button, Checkbox, ClickAwayListener } from '@mui/material';
import { Text } from './Typography';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  disabled?: boolean;
  options?: SelectOption[];
  selectedOptions?: SelectOption[];
  onChange: (value: SelectOption[] | null) => void;
}

export function MultiSelectNew({
  options,
  onChange,
  disabled,
  selectedOptions,
}: Props) {
  const [inputRepairerValue, setInputRepairerValue] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState(false);

  const selectedOptionName = selectedOptions?.map((o) => o.value)?.join(', ');

  const listOfOptions = useMemo(() => {
    if (options && options?.length > 0)
      return [{ id: -1, value: 'all', text: 'Select All' }, ...(options ?? [])];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter, options?.length]);

  const onChangeHandler = useCallback(
    (newValue: SelectOption[] | null) => {
      if (newValue) {
        if (selectAll && newValue.length < (listOfOptions?.length ?? 0))
          setSelectAll(false);
        else if (!selectAll && newValue.length === listOfOptions?.length)
          setSelectAll(true);
      }
      onChange(newValue);
    },
    [selectAll, listOfOptions, onChange]
  );

  useEffect(() => {
    if (options?.length == selectedOptions?.length && !selectAll)
      setSelectAll(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <ClickAwayListener onClickAway={() => setShowFilter(false)}>
      <MainFiltersContainer>
        <FilterItem>
          <FilterButton
            data-testid={`multi-select-button-${selectedOptionName}`}
            type="button"
            fullWidth
            disabled={disabled}
            onClick={() => setShowFilter((prevData) => !prevData)}
            endIcon={showFilter ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
          >
            <p> {selectedOptionName ?? 'Select...'}</p>
          </FilterButton>
          {showFilter && (
            <FilterContainer>
              <InputSelectMultiple
                options={listOfOptions ?? []}
                value={selectedOptions ?? []}
                onChange={onChangeHandler}
                inputValue={inputRepairerValue}
                onInputChange={setInputRepairerValue}
                open={showFilter}
                renderInput={({ ...params }) => (
                  <TextField
                    {...params}
                    autoFocus={true}
                    onFocus={(e) => e.target.select()}
                    variant="outlined"
                    placeholder="Search..."
                    fullWidth
                    style={{ width: 320 }}
                  />
                )}
                renderOption={(props, option, state) => {
                  if (option.id === -1) {
                    return (
                      <Box
                        component="li"
                        {...(props as Record<string, unknown>)}
                        onClick={() => {
                          if (selectAll) {
                            setSelectAll(false);
                            onChange([]);
                          } else {
                            setSelectAll(true);
                            onChange(options || []);
                          }
                        }}
                      >
                        <Checkbox
                          checked={selectAll}
                          color="primary"
                          size="small"
                        />
                        <Text fontSize="m">Select All</Text>
                      </Box>
                    );
                  }

                  return (
                    <Box component="li" {...(props as Record<string, unknown>)}>
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={state.selected}
                        id={`checkbox-${option.id}`}
                      />
                      <Text fontSize="m">{option.text}</Text>
                    </Box>
                  );
                }}
              />
            </FilterContainer>
          )}
        </FilterItem>
      </MainFiltersContainer>
    </ClickAwayListener>
  );
}

export const FilterButton = styled(Button)<{
  fullWidth?: boolean;
  disabled?: boolean;
}>`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.button.disabled : theme.palette.primary.light};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-size: ${({ theme }) => theme.fontSize.s};
  border: ${({ disabled }) => (disabled ? 0 : '1.5px')} solid #ccc;
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.primary.disabled : theme.palette.background.dark};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.border.radius};
  height: 40px;

  & .MuiButton-endIcon {
    margin-left: auto;
  }

  :hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.background.dark};
  }

  :active {
    border-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.background.dark};
    path {
      fill: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.xs}px`}) {
    & p {
      width: 15vw;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }

  .MuiOutlinedInput-adornedEnd {
    padding: 1px;
  }

  ${({ fullWidth, theme }) =>
    fullWidth &&
    `@media (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
    }`};
`;

const FilterItem = styled(GridItem)`
  position: relative;
  width: 100%;
`;
const FilterContainer = styled.div`
  position: absolute;
  top: 44px;
  width: 100%;
  background: #f1f8fc;
  z-index: 999;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const MainFiltersContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
`;
