import MobileBottomButton, { CreateButton } from 'components/Button';
import { CREATE_NEW_PROFILE } from './constants';
import { ROUTES } from 'core/routes';
import { DesktopContainer } from 'core/Theming/Device';
import { GridItem } from 'components/Layout';

function renderCreateButton() {
  return (
    <CreateButton
      text={CREATE_NEW_PROFILE}
      route={ROUTES.dataAccessProfilesDetailsCreate}
    />
  );
}

export function DesktopButton() {
  return (
    <DesktopContainer>
      <GridItem sm={9} xs={12}>
        {renderCreateButton()}
      </GridItem>
    </DesktopContainer>
  );
}

export function MobileButton() {
  return <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>;
}
