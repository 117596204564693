export const warningMessages = {
  addDataAccessCodes: (siteCode: string, formalCode: string) =>
    `Couldn't add Site code(${siteCode}) ` +
    `and fomalCode(${formalCode}) to data access profile`,
  removeDataAccessCodes: (
    siteCodeOnSuccess: string,
    formalCodeOnSuccess: string
  ) =>
    `Couldn't remove Site code(${siteCodeOnSuccess}) ` +
    `and fomalCode(${formalCodeOnSuccess}) to data access profile`,
};
