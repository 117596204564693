import { DataTypeProvider } from '@devexpress/dx-react-grid';

export type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

export const tableColumnExtensions = [
  { columnName: 'measureName', wordWrapEnabled: true },
  { columnName: 'repairerName', wordWrapEnabled: true },
  { columnName: 'schemeCode', wordWrapEnabled: true },
  { columnName: 'regMark', wordWrapEnabled: true },
  { columnName: 'claimID', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'localStatus', wordWrapEnabled: true },
  { columnName: 'manufacturer', wordWrapEnabled: true },
  { columnName: 'model', wordWrapEnabled: true },
  { columnName: 'dtgCreated', wordWrapEnabled: true },
  { columnName: 'dtgBookedIn', wordWrapEnabled: true },
  { columnName: 'dtgOnSite', wordWrapEnabled: true },
  { columnName: 'courtesyMake', wordWrapEnabled: true },
  { columnName: 'replacementReg', wordWrapEnabled: true },
];

export const columns = [
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'regMark', title: 'Vehicle Reg.' },
  { name: 'claimID', title: 'Claim ID' },
  { name: 'claimReference', title: 'Claim Ref.' },
  { name: 'localStatus', title: 'Local Status' },
  { name: 'manufacturer', title: 'Manufacturer' },
  { name: 'model', title: 'Model' },
  { name: 'dtgCreated', title: 'Date Created' },
  { name: 'dtgBookedIn', title: 'Date Booked In' },
  { name: 'dtgOnSite', title: 'On Site Date' },
  { name: 'courtesyMake', title: 'Courtesy Make' },
  { name: 'replacementReg', title: 'Replacement Reg.' },
];

export const dateColumnsName = ['dtgBookedIn', 'dtgCreated', 'dtgOnSite'];
