import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { convertToDate, useCoreMIReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate, formatDateTime } from 'utils/helpers';
import { ReportProps } from '../hooks';
import {
  CORE_MI_REPORT,
  columns,
  dateColumnNames,
  tableColumnExtensions,
  CORE_MI_REPORT_TITLE_INFO_MESSAGE,
  CORE_MI_REPORT_GRID_TITLE,
  currencyColumnsName,
} from './constants';
import { AxiosError } from 'axios';
import { createReportURL } from 'core/routes';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportFormCoreMI } from 'components/ReportForms/ReportFormCoreMI';
import { customizeCell, valuesConverter } from './helpers';
import { reportLogger } from '../reportLogger';

export function CoreMIReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    endDate: urlParams.get('endDate'),
    startDate: urlParams.get('startDate'),
    isInitialWorkCompletedDate:
      urlParams.get('isInitialWorkCompletedDate') == 'true',
  };

  const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate;

  const { isReportFetching, refetchReport, reportData } = useCoreMIReport({
    startDate: urlParamsValue.startDate,
    endDate: urlParamsValue.endDate,
    isInitialWorkCompletedDate: urlParamsValue.isInitialWorkCompletedDate,
    enabled: queryEnabled,
    onError: handleError,
  });

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) ?? null
  );
  const [isInitialWorkCompletedDate, setIsInitialWorkCompletedDate] =
    useState<boolean>(urlParamsValue.isInitialWorkCompletedDate);

  const isBusy = isReportFetching;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    urlParamsValue.isInitialWorkCompletedDate,
  ]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={CORE_MI_REPORT}
          infoMessage={CORE_MI_REPORT_TITLE_INFO_MESSAGE}
        />
        <ReportFormCoreMI
          isBusy={isBusy}
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleSubmit={handleSubmit}
          isInitialWorkCompletedDate={isInitialWorkCompletedDate}
          setIsInitialWorkCompletedDate={setIsInitialWorkCompletedDate}
        />
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          title={CORE_MI_REPORT_GRID_TITLE}
          dateColumnsName={dateColumnNames}
          currencyColumnsName={currencyColumnsName}
          customizeCellExport={customizeCell}
          DateTypeProvider={DateTypeProvider}
          headerTitleForSheet={CORE_MI_REPORT}
          tableColumnExtensions={tableColumnExtensions}
          reportHeaderTagLine={`Report produced on ${formatDateTime(
            new Date()
          )} from ${formatDate(startDate)} to ${formatDate(endDate)}`}
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.isInitialWorkCompletedDate == isInitialWorkCompletedDate
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate) {
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            isInitialWorkCompletedDate: isInitialWorkCompletedDate.toString(),
          },
          reportPath
        )
      );
    }
  }

  function handleError(error: AxiosError) {
    reportLogger(error);
    setErrorMessage(errorMessages.reports(CORE_MI_REPORT));
    setToast(true);
  }
}
