import { Column } from '@devexpress/dx-react-grid';

export const columns: Column[] = [
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'repairerGroup', title: 'Repairer Group' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'billingCount', title: 'Billing Count' },
  { name: 'startDate', title: 'Start Date' },
  { name: 'endDate', title: 'End Date' },
];

export const dateColumnsName = ['startDate', 'endDate'];