import { z } from 'zod';
import { mobileValidatorUK } from './mobileValidatorUK';

export const formSchema = z.object({
  firstName: z
    .string()
    .nonempty({ message: 'First name is required' })
    .max(50, { message: 'Must be max 50 characters long' }),
  surname: z
    .string()
    .nonempty({ message: 'Surname is required' })
    .max(50, { message: 'Must be max 50 characters long' }),
  email: z
    .string()
    .email()
    .max(50, { message: 'Must be max 50 characters long' }),
  companyId: z.number({ required_error: 'Company is required' }),
  teamName: z
    .string()
    .nonempty({ message: 'Team name is required' })
    .max(50, { message: 'Must be max 50 characters long' }),
  dataAccessProfileId: z.number({
    required_error: 'Data access profile is required',
  }),
  defaultUserAccountProfileId: z.number({
    required_error: 'User account profile is required',
    invalid_type_error: 'User account profile is required',
  }),
  telephone: z.string().nullable(),
  mobile: mobileValidatorUK(),
  allowIPOverride: z.boolean(),
  expiryDate: z.date().nullable(),
});