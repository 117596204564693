import { Row, Column } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { TableColumnExtensions } from './types';
import { customizeDateColumns } from '../helpers';

export const ZURICH_CSI_REPORT = 'CSI Trigger Report';

export const ZURICH_CSI_REPORT_MESSAGE =
  'CSI Triggers for delivered jobs entries for the chosen date range';

export const ZURICH_CSI_REPORT_GRID_TITLE = 'CSITriggerReport';

export const dateColumnsName = ['dateReturned'];

export const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
  customizeDateColumns(cell, row, column, dateColumnsName)
};

export const columns: Column[] = [
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'registration', title: 'Registration' },
  { name: 'manufacturer', title: 'Manufacturer' },
  { name: 'model', title: 'Model' },
  { name: 'dateReturned', title: 'Date Returned' },
  { name: 'elapsedK2K', title: 'Elapsed K2K' },
  { name: 'comments', title: 'Comments' },
  // { name: 'name', title: 'Name' },
  // { name: 'ownerEmail', title: 'Owner Email' },
  // { name: 'ownerMobile', title: 'Owner Mobile' },
  // { name: 'ownerName', title: 'Owner Name' },
];

export const tableColumnExtensions: TableColumnExtensions[] = [
  { columnName: 'registration', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'comments', wordWrapEnabled: true },
  {
    columnName: 'dateReturned',
    wordWrapEnabled: true,
  },
  { columnName: 'elapsedK2K', wordWrapEnabled: true },
  { columnName: 'manufacturer', wordWrapEnabled: true },
  { columnName: 'model', wordWrapEnabled: true },
  { columnName: 'name', wordWrapEnabled: true },
  { columnName: 'ownerEmail', wordWrapEnabled: true },
  { columnName: 'ownerMobile', wordWrapEnabled: true },
  { columnName: 'ownerName', wordWrapEnabled: true },
];
