import { TableSummaryRow } from '@devexpress/dx-react-grid';
import { Text } from 'components/Typography';

export const SummaryItemComponent = (data: TableSummaryRow.ItemProps) => {
  return (
    <Text fontSize="m" fontWeight="bold">
      Total Messages: {data.value}
    </Text>
  );
};
