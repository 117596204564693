import { Company as ApiCompany } from 'api/resources/models/AutoGenerated';
import dayjs from 'dayjs';
import { convertToDate } from 'pages/hooks';

export const dateToString = (date: Date) =>
  dayjs(date).format('YYYY-MM-DDTHH:mm:ss');

export const convertToAPIData = (data: Company): ApiCompany => ({
  ...data,
  workStart: data.workStart ? dateToString(data.workStart) : null,
  workEnd: data.workEnd ? dateToString(data.workEnd) : null,
  validSourceIP:
    data.validSourceIP?.reduce(function (address, val) {
      const semicolen = address.length ? ';' : '';
      return address + semicolen + val.ip;
    }, '') || null,
});

export const convertToFormData = (data: ApiCompany): Company => ({
  ...data,
  workStart: data.workStart ? convertToDate(data.workStart) : null,
  workEnd: data.workEnd ? convertToDate(data.workEnd) : null,
  validSourceIP:
    data.validSourceIP?.split(';')?.map((element) => {
      return { ip: element };
    }) || null,
});

interface validSourceIP {
  ip: string | null;
}

export interface Company {
  id: number;
  name: string | null;
  autoMainReference: boolean;
  lastMainReference: number;
  mainReferencePrefix: string | null;
  poP3Host: string | null;
  poP3Port: number | null;
  poP3UserName: string | null;
  poP3Password: string | null;
  poP3UseSSL: boolean | null;
  smtpHost: string | null;
  smtpPort: number | null;
  smtpReplyTo: string | null;
  smtpDisplayName: string | null;
  urlExtension: string | null;
  configPath: string | null;
  logTable: string | null;
  workStart: Date | null;
  workEnd: Date | null;
  locale: string | null;
  validSourceIP: validSourceIP[] | null;
}
