import styled from '@emotion/styled';
import { Label, PageHeader, UpperInfoContainer } from 'components/PageLayout';
import { useParams } from 'react-router-dom';
import { CoveaReport } from './Covea';
import { NPSBilling } from './NPSBilling';
import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { Heading } from 'components/Typography';
import { TotalLoss } from './TotalLoss';
import { GoodWillReport } from './Goodwill';
import { InvoiceExtract } from './InvoiceExtract';
import { SLAPerformanceV3 } from './SLAPerformanceV3';
import { DetailedCycleTimeReport } from './DetailedCycleTime';
import { SMSBillingReport } from './SMSBillling';
import { SMSReport } from './SMSReport';
import { Rolling30DayReport } from './Rolling30DayReport';
import { WorkInProgress } from './WorkInProgress';
import { B2BRestMessagesReport } from './B2BRestMessages';
import { VehiclesOnSite } from './VehiclesOnSite';
import { NargControlSheetV5 } from './NARGControl';
import { NPSSummaryReport } from './NPS Summary Report';
import { NPSDetailV4 } from './NPSDetailV4';
import PrimaryToast from 'components/PrimaryToast';
import { useState } from 'react';
import { ActiveWebUsageV2 } from './ActiveWeb Usage Report';
import { AllSitesConnectionReport } from './All Site Connections';
import { FeedbackReport } from './Feedback Report';
import { LVOWNKey2KeyReport } from './LVOWN Key2Key Report';
import { RSAKey2KeyReport } from './RSA Key2Key Report';
import { ZurichCSIReport } from './Zurich CSI Report';
import { CoreMIReport } from './Core MI Report';
import { VolumeTrendsReport } from './VolumeTrendsReport';
import { MonthlyNotificationVolume } from './MonthlyNotificationVolume';
import { CombinedNpsSmsEmailBillingReport } from './CombinedNpsSmsEmailBilling Report';

type ReportViewerParams = {
  reportPath: string;
};

function ReportNotFound() {
  return (
    <MainContainer direction="column">
      <Container direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>Not found</Heading>
        </PageHeader>
        <GridItem>
          <Label>No report found, please try again.</Label>
        </GridItem>
      </Container>
    </MainContainer>
  );
}

export function ReportsDetails() {
  const { reportPath } = useParams<ReportViewerParams>();
  const CurrentReport = generateReportTitle(reportPath);
  const [message, setMessage] = useState<string>('');
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
  return (
    <>
      <CurrentReport.component
        reportPath={reportPath}
        setErrorMessage={(data: string) => setMessage(data)}
        setToast={(data: boolean) => setIsOpenToast(data)}
      />
      <PrimaryToast
        message={message}
        isOpen={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        severity="error"
      />
    </>
  );

  function generateReportTitle(report: string) {
    switch (report) {
      case 'GetRepairStatusReportCovea': {
        return { title: 'Repair Status Portal Usage', component: CoveaReport };
      }
      case 'GetNPSBillingReport': {
        return { title: 'NPS Billing Report', component: NPSBilling };
      }
      case 'GetTLEngineeringSchedule': {
        return {
          title: 'TL Engineering Schedule Report',
          component: TotalLoss,
        };
      }
      case 'GetDLGoodwillReport': {
        return {
          title: 'DL Proposed Goodwill Report',
          component: GoodWillReport,
        };
      }
      case 'GetInvoiceExtractReport ': {
        return { title: 'Invoice Extract Report', component: InvoiceExtract };
      }
      case 'GetSLAPerformanceV3Report': {
        return {
          title: 'SLA Performance V3 Report',
          component: SLAPerformanceV3,
        };
      }
      case 'GetDetailedCycleTimeReportExV2': {
        return {
          title: 'Detailed Cycle Time Report with Exceptions',
          component: DetailedCycleTimeReport,
        };
      }
      case 'GetSMSReport': {
        return { title: 'SMS Report', component: SMSReport };
      }
      case 'Rolling30DayReport': {
        return {
          title: 'Repairer Capacity Report',
          component: Rolling30DayReport,
        };
      }
      case 'GetWorkInProgressV3Report': {
        return {
          title: 'Work In Progress V3 Report',
          component: WorkInProgress,
        };
      }
      case 'SMSBillingReport02': {
        return {
          title: 'SMS Billing Report - 02',
          component: SMSBillingReport,
        };
      }
      case 'GetB2BRestQueueMessages': {
        return {
          title: 'B2B Rest Queue Messages',
          component: B2BRestMessagesReport,
        };
      }
      case 'GetVehiclesOnSite': {
        return {
          title: 'Vehicles On Site',
          component: VehiclesOnSite,
        };
      }
      case 'GetNARGControlSheetV05': {
        return {
          title: 'Narg Control Sheet V5',
          component: NargControlSheetV5,
        };
      }
      case 'GetNPSSummaryReportV04': {
        return {
          title: 'NPS Summary Report V4',
          component: NPSSummaryReport,
        };
      }
      case 'GetNPSDetailReportV04': {
        return {
          title: 'NPS Detail Report V4',
          component: NPSDetailV4,
        };
      }
      case 'ActiveWebUsage01': {
        return {
          title: 'ActiveWeb Usage Report V2',
          component: ActiveWebUsageV2,
        };
      }
      case 'GetAllSiteConnections': {
        return {
          title: 'All Sites Connections',
          component: AllSitesConnectionReport,
        };
      }
      case 'GetFeedbackReport': {
        return {
          title: 'Feedback Report',
          component: FeedbackReport,
        };
      }
      case 'GetKey2KeyDataForLVOWN': {
        return {
          title: 'LVOWN Key2Key Report',
          component: LVOWNKey2KeyReport,
        };
      }
      case 'GetKey2KeyDataForRSA': {
        return {
          title: 'RSA Key2Key Report',
          component: RSAKey2KeyReport,
        };
      }
      case 'GetCSITriggerReport': {
        return {
          title: 'CSI Trigger Report',
          component: ZurichCSIReport,
        };
      }
      case 'GetCoreMIReport': {
        return {
          title: 'Core MI Report',
          component: CoreMIReport,
        };
      }
      case 'GetVolumeTrendsReport': {
        return {
          title: 'Volume Trends Report',
          component: VolumeTrendsReport,
        }
      }
      case 'GetMonthlyNotificationVolume': {
        return {
          title: 'Monthly Notification Volume Report',
          component: MonthlyNotificationVolume,
        }
      }
      case 'GetCombinedBillingReport': {
        return {
          title: 'Combined NPS, SMS and Email Billing Report',
          component: CombinedNpsSmsEmailBillingReport,
        }
      }
      
      default: {
        return { title: 'Report not found', component: ReportNotFound };
      }
    }
  }
}

const MainContainer = styled(UpperInfoContainer)`
  padding: ${({ theme }) => `0 ${theme.padding.xl}`};
`;
