import { Column } from '@devexpress/dx-react-grid';

export const INVOICE_EXTRACT_REPORT_TITLE =
  'Invoice Extract Report - PPG Internal';

export const INVOICE_EXTRACT_REPORT_MESSAGE =
  'Invoicing extract for monthly reporting - Download to Excel and keep in sharepoint for audit purposes (PPG ONLY)';

export const INVOICE_EXTRACT_REPORT_GRID_TITLE = 'InvoiceExtractPPG';

export const dateColumnsName = ['created_Date', 'finalisationDate'];

export const columns: Column[] = [
  { name: 'activeWebID', title: 'Active Web ID' },
  { name: 'repairerCode', title: 'Repairer Code' },
  { name: 'repairer_Name', title: 'Repairer Name' },
  { name: 'created_Date', title: 'Created Date' },
  { name: 'reportBasedOn', title: 'Report Based On' },
  { name: 'scheme_Code', title: 'Scheme Code' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'registration', title: 'Registration' },
  { name: 'finalisationDate', title: 'Finalisation Date'}
];

export const workProviders = [
  { id: 1, text: 'Allianz', value: 'ALLIANZ - MANAGER TEMPLATE' },
  { id: 2, text: 'RSA', value: 'RSA SRM PLUS TEMPLATE' },
  {
    id: 3,
    text: 'Direct Line Group',
    value: 'DLG AREA NETWORK CONTROLLER/MANAGER/REG MGR',
  },
  { id: 4, text: 'NARG', value: 'NARG ADMIN TEMPLATE' },
  { id: 5, text: 'LV', value: 'LV= MANAGER TEMPLATE' },
  { id: 6, text: 'Esure', value: 'Esure Claims Supervisor TEMPLATE' },
  { id: 7, text: 'Covea', value: 'COVEA CLAIM HANDLER TEMPLATE' },
  {
    id: 8,
    text: 'NNS',
    value: 'NATIONWIDE NETWORK SERVICE TEMPLATE USER (NWS)',
  },
  { id: 9, text: 'Kindertons', value: 'KINDERTONS - MANAGER TEMPLATE' },
  { id: 10, text: 'Avant', value: 'AVANT MANAGER TEMPLATE' },
  { id: 11, text: 'Admiral', value: 'Admiral-DAP' },
];

export const workProviderOptions = [...workProviders].sort((a, b) => a.text.localeCompare(b.text));