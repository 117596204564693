import { Column, Row } from '@devexpress/dx-react-grid';
import { SelectOption } from 'components/InputSelect';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import {
  convertToDate,
  useGetDetailedCycleTimeReport,
  useGetRepairerGroup,
  useGetRepairerByGroupName,
} from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReportProps } from '../hooks';
import { dateToString, formatDate } from 'utils/helpers';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { ReportsLoader } from 'components/ReportsLoader';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import {
  DETAILED_CYCLE_TIME_REPORT_GRID_TITLE,
  DETAILED_CYCLE_TIME_REPORT_MESSAGE,
  DETAILED_CYCLE_TIME_REPORT_TITLE,
  columnsList,
  tableExtensionColumn,
} from './constants';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { ReportFormDetailedCycleTime } from 'components/ReportForms/ReportFormDetailedCycleTime';
import { customizeCell, valuesConverter } from './helpers';
import { CellComponent } from './CellComponent';
import { SELECT_ALL } from 'pages/constants';
import { reportLogger } from '../reportLogger';

export function DetailedCycleTimeReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    startDate: urlParams.get('startDate'),
    endDate: urlParams.get('endDate'),
    siteCode: urlParams.get('siteCode'),
    repairGroup: urlParams.get('repairGroup'),
    showExcep: urlParams.get('showExcep') === 'true' ? true : false,
  };

  const queryEnabled =
    !!urlParamsValue.startDate &&
    !!urlParamsValue.endDate &&
    !!urlParamsValue.repairGroup &&
    (!!urlParamsValue.siteCode || urlParamsValue.repairGroup === SELECT_ALL);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) ?? null
  );
  const [repairerGroup, setRepairerGroup] =
    useState<SelectOption<string> | null>(null);
  const [siteCode, setSiteCode] = useState<SelectOption[]>([]);
  const [showExcep, setShowExcep] = useState<boolean>(
    urlParamsValue.showExcep || false
  );

  const { reportData, isReportFetching, reFetchReportData } =
    useGetDetailedCycleTimeReport(
      {
        startDate: urlParamsValue.startDate,
        endDate: urlParamsValue.endDate,
        repairerGroup: urlParamsValue.repairGroup,
        siteCode: urlParamsValue.siteCode,
        showExcep: urlParamsValue.showExcep || false,
      },
      queryEnabled,
      (error) => handleError(error, 'isReport')
    );
  const { repairerGroupList, isRepairerGroupListLoading } = useGetRepairerGroup(
    (error) => handleError(error, 'isRepairerList')
  );
  const { siteCodes, isSiteCodesLoading } = useGetRepairerByGroupName(
    repairerGroup?.value ?? '',
    repairerGroup !== null && repairerGroup?.value !== SELECT_ALL,
    (error) => handleError(error, 'isSiteCodes')
  );

  const repairerGroupOptions = useMemo(() => {
    const repairerGroupData =  repairerGroupList?.map((item, index) => {
      return { id: index, value: item.groupName, text: item.groupName };
    });
    if (repairerGroupList)
      return [{ id: -1, value: SELECT_ALL, text: SELECT_ALL }, ...(repairerGroupData ?? [])];
  }, [repairerGroupList]);

  const siteCodesOptions = siteCodes?.map((item, index) => {
    return { id: index, value: item.formalSiteCode, text: item.repairerName };
  });

  const tableColumnExtensions = useMemo(
    () => tableExtensionColumn(showExcep),
    [showExcep]
  );

  const columns = useMemo<Column[]>(() => columnsList(showExcep), [showExcep]);

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const isBusy = isReportFetching;
  const isOptionsLoading = isRepairerGroupListLoading || isSiteCodesLoading;

  useEffect(() => {
    navigateToReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    urlParamsValue.repairGroup,
    urlParamsValue.siteCode,
    urlParamsValue.showExcep,
  ]);

  useEffect(() => {
    if (!repairerGroup?.value && !!urlParamsValue.repairGroup) {
      const urlRepairerGroup = repairerGroupOptions?.find(
        (e) => e.value == urlParamsValue.repairGroup
      );
      setRepairerGroup(urlRepairerGroup ?? null);
    }
    if (urlParamsValue.siteCode?.length != siteCode?.length)
      setSiteCode(
        siteCodesOptions?.filter((e) =>
          urlParamsValue.siteCode?.split(',').includes(e.value)
        ) ?? []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={DETAILED_CYCLE_TIME_REPORT_TITLE}
          infoMessage={DETAILED_CYCLE_TIME_REPORT_MESSAGE}
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
          <ReportFormDetailedCycleTime
            isBusy={isBusy}
            endDate={endDate}
            siteCode={siteCode}
            showExcep={showExcep}
            startDate={startDate}
            setEndDate={setEndDate}
            setSiteCode={setSiteCode}
            setStartDate={setStartDate}
            handleSubmit={handleSubmit}
            setShowExcep={setShowExcep}
            repairerGroup={repairerGroup}
            setRepairerGroup={setRepairerGroup}
            siteCodesOptions={siteCodesOptions}
            repairerGroupOptions={repairerGroupOptions}
          />
        )}
      </UpperInfoContainer>
      {!isBusy && rows && rows?.length === 0 && <NoReportDataMessage />}
      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          CustomCellComponent={CellComponent}
          customizeCellExport={customizeCell}
          tableColumnExtensions={tableColumnExtensions}
          title={DETAILED_CYCLE_TIME_REPORT_GRID_TITLE}
          headerTitleForSheet={DETAILED_CYCLE_TIME_REPORT_TITLE}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.siteCode == siteCode?.map((e) => e.value)?.join(',') &&
      urlParamsValue.repairGroup == repairerGroup?.value &&
      urlParamsValue.showExcep == showExcep
    )
      reFetchReportData();
    else navigateToReport();
  }

  function navigateToReport() {
    const shouldUrlChange =
      !!endDate &&
      !!startDate &&
      (siteCode.length > 0 || repairerGroup?.value === SELECT_ALL) &&
      !!repairerGroup?.value;

    if (shouldUrlChange)
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            siteCode: siteCode?.map((e) => e.value)?.join(','),
            repairGroup: repairerGroup?.value,
            showExcep: showExcep?.toString() || 'false',
          },
          reportPath
        )
      );
  }

  function handleError(
    error: AxiosError,
    type: 'isReport' | 'isRepairerList' | 'isSiteCodes'
  ) {
    reportLogger(error);
    let errorMessage = '';
    if (type == 'isRepairerList') errorMessage = errorMessages.repairersList;
    else if (type == 'isSiteCodes') errorMessage = errorMessages.siteCodes;
    else errorMessages.reports('Detailed Cycle Time Report with Exceptions');
    setErrorMessage(errorMessage);
    setToast(true);
  }
}
