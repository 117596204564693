import { RepairerReason } from 'api/resources/models/AutoGenerated';
import { GrayBackdrop, Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { useRepairerContact } from 'pages/hooks';
import { useState } from 'react';
import { ContactRepairer } from './ContactRepairer';
import { repairerReasons } from './mappings';
import { ReasonsForm } from './ReasonsForm';

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  repairId: number;
}

type ModalStep = 'RepairerInformation' | 'ReasonsForm';

export function ContactRepairerModal({
  isOpen,
  onClose,
  repairId,
}: ContactModalProps) {
  const [currentStep, setCurrentStep] = useState<ModalStep>('ReasonsForm');

  const repairerContact = useRepairerContact();

  return (
    <Modal
      isOpen={isOpen}
      title={'Contact Repairer'}
      onClose={currentStep === 'RepairerInformation' ? undefined : onClose}
      onExited={() => setCurrentStep('ReasonsForm')}
    >
      <GrayBackdrop open={repairerContact.isRecording}>
        <Loader />
      </GrayBackdrop>
      {currentStep === 'RepairerInformation' ? (
        <ContactRepairer repairId={repairId} onClose={onClose} />
      ) : (
        <ReasonsForm<RepairerReason>
          reasons={repairerReasons}
          defaultReason={RepairerReason.Other}
          onSubmit={onSubmit}
          type={'repairer'}
        />
      )}
    </Modal>
  );

  function onSubmit(
    selectedReason: RepairerReason,
    additionalNotes: string | null
  ) {
    repairerContact
      .record(repairId, selectedReason, additionalNotes)
      .then(() => setCurrentStep('RepairerInformation'));
  }
}
