import { DtlListHeader } from './types';

export const DEFINITE_TOTAL_LOSS = 'Definite Total Loss';
export const NO_DTL_DATA_MESSAGE = `Sorry, we couldn't find any DTL repairs.`;

export const dtlTableHeaders: DtlListHeader[] = [
  { id: 'repairCode', label: 'Repair Code' },
  { id: 'claimReference', label: 'Claim Ref.' },
  { id: 'vehicleReg', label: 'Vehicle Reg.' },
  { id: 'schemeCode', label: 'Stream' },
  { id: 'formalStatus', label: 'Status' },
  { id: 'dtgCreated', label: 'Created' },
  { id: 'dtgUpdated', label: 'Last Updated' },
  { id: 'dtgEcd', label: 'E.C.D' },
  { id: 'vehicleMake', label: 'Vehicle' },
  { id: 'repairerGroup', label: 'Repair Group' },
  { id: 'repairStage', label: 'Repair Stage' },
];
