import { Row } from '@devexpress/dx-react-grid';
import {
  MainPageContainer,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useRepairGroups, useWorkInProgressV3Report } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDateTime } from 'utils/helpers';
import { RepairerGroupsResult } from 'api/resources/models/AutoGenerated';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import {
  columns,
  dateColumnsName,
  tableColumnExtensions,
} from './constants';
import { ReportsLoader } from 'components/ReportsLoader';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { WorkInProgressSearchForm } from 'components/ReportForms/WorkInProgressSearchForm';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { customizeCell, valuesConverter } from './helpers';
import { reportLogger } from '../reportLogger';

export function WorkInProgress({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    repairGroup: urlParams.get('repairGroup'),
  };

  const queryEnabled = !!urlParamsValue.repairGroup;

  const { repairerGroups, isRepairGroupsLoading } = useRepairGroups((error) =>
    handleError(error, 'isRepairerGroup')
  );
  const { reportData, refetchReport, isReportFetching } =
    useWorkInProgressV3Report(
      { repairerGroup: urlParamsValue.repairGroup },
      queryEnabled,
      (error) => handleError(error, 'isReport')
    );

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const [repairGroup, setRepairGroup] = useState<string | null>(
    urlParamsValue.repairGroup || null
  );
  const options = repairerGroups?.map((e: RepairerGroupsResult, i: number) => {
    return { id: i, value: e.repairerGroupValue, text: e.repairerGroup };
  });

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsValue.repairGroup]);

  const isBusy = isReportFetching;
  const isOptionsLoading = isRepairGroupsLoading;

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName="Work In Progress V3 Report"
          infoMessage="Displays the Work In Progress V3 report for the date range provided"
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
          <WorkInProgressSearchForm
            handleSubmit={handleSubmit}
            options={options}
            repairGroup={repairGroup}
            setRepairGroup={setRepairGroup}
            isBusy={isBusy}
          />
        )}
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && (
        <NoReportDataMessage />
      )}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          title="workInProgress"
          dateColumnsName={dateColumnsName}
          customizeCellExport={customizeCell}
          DateTypeProvider={DateTypeProvider}
          tableColumnExtensions={tableColumnExtensions}
          headerTitleForSheet="Work In Progress V3 Report"
          reportHeaderTagLine={`Report produced on ${formatDateTime(
            new Date()
          )}`}
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (urlParamsValue.repairGroup == repairGroup) refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (repairGroup)
      history.replace(
        createReportURL({ repairGroup: repairGroup }, reportPath)
      );
  }

  function handleError(
    error: AxiosError,
    type: 'isRepairerGroup' | 'isReport'
  ) {
    reportLogger(error);
    setErrorMessage(
      type == 'isRepairerGroup'
        ? errorMessages.repairersList
        : errorMessages.reports('SLA Performance V3 Report')
    );
    setToast(true);
  }
}
