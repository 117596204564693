import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import {
  useServiceAccounts,
  useB2BRestMessagesReport,
  convertToDate,
} from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { ReportProps } from '../hooks';
import { AxiosError } from 'axios';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { ReportsLoader } from 'components/ReportsLoader';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import {
  B2B_REST_MESSAGES,
  B2B_REST_MESSAGES_INFO_MESSAGE,
  REPORT_TITLE,
  columns,
  dateColumnsName,
  tableColumnExtensions,
} from './constants';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { ReportFormB2B } from 'components/ReportForms/ReportFormB2B';
import { customizeCell } from './helpers';
import { CellComponent } from './CellComponent';
import { reportLogger } from '../reportLogger';

export function B2BRestMessagesReport({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    endDate: urlParams.get('endDate'),
    startDate: urlParams.get('startDate'),
    userAccountId: urlParams.get('userAccountId'),
  };

  const queryEnabled =
    !!urlParamsValue.startDate &&
    !!urlParamsValue.endDate &&
    !!urlParamsValue.userAccountId;

  const { serviceAccounts, isServiceAccountsLoading } = useServiceAccounts(
    (error) => handleError({ error, type: 'isServiceAccount' })
  );
  const { reportData, isReportFetching, refetchReport } =
    useB2BRestMessagesReport(
      {
        endDate: urlParamsValue.endDate,
        startDate: urlParamsValue.startDate,
        userAccountId: urlParamsValue.userAccountId,
      },
      queryEnabled,
      (error) => handleError({ error, type: 'isReport' })
    );

  const rows = useMemo<Row>(() => reportData, [reportData]);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) ?? null
  );
  const [userAccountId, setUserAccountId] = useState<string | null>(
    urlParamsValue.userAccountId ?? null
  );
  const options = serviceAccounts?.map((account, key) => {
    return {
      id: key,
      value: account.userAccountId.toString(),
      text: account.username,
    };
  });
  const isBusy = isReportFetching;
  const isOptionsLoading = isServiceAccountsLoading;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    urlParamsValue.userAccountId,
  ]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={B2B_REST_MESSAGES}
          infoMessage={B2B_REST_MESSAGES_INFO_MESSAGE}
        />

        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
          <ReportFormB2B
            isBusy={isBusy}
            options={options}
            endDate={endDate}
            startDate={startDate}
            handleSubmit={handleSubmit}
            onChangeEndDate={setEndDate}
            onChangeStartDate={setStartDate}
            onChangeSelect={setUserAccountId}
            selectedAccountId={userAccountId}
          />
        )}
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={columns}
          isLoading={isBusy}
          title={REPORT_TITLE}
          dateColumnsName={dateColumnsName}
          DateTypeProvider={DateTypeProvider}
          CustomCellComponent={CellComponent}
          customizeCellExport={customizeCell}
          headerTitleForSheet={B2B_REST_MESSAGES}
          tableColumnExtensions={tableColumnExtensions}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.userAccountId == userAccountId
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate && !!userAccountId) {
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            userAccountId: userAccountId,
          },
          reportPath
        )
      );
    }
  }

  function handleError({
    error,
    type,
  }: {
    error: AxiosError;
    type: 'isReport' | 'isServiceAccount';
  }) {
    reportLogger(error);
    setErrorMessage(
      type == 'isReport'
        ? errorMessages.reports('B2B Rest Queue Messages')
        : errorMessages.serviceAccounts
    );
    setToast(true);
  }
}
