import { logger } from 'core/logger';
import store from 'core/store';
import { queryClient } from 'api/client';
import {
  userLoadingStarted,
  userLoggedin,
  userLoginFailed,
  userLoggedout,
  userSwitchedProfile,
  userImpersonated,
  impersonatedUserSwitchedProfile,
  userImpersonationStarted,
  userImpersonationFailed,
  userImpersonationRemoved,
} from 'core/store/auth';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { config } from '../config';
import { TokenResponse } from 'api/resources/models/AutoGenerated';
import jwt_decode from 'jwt-decode';
import { getRepairersCacheKey, getUserCashKey } from 'pages/hooks';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const userManager = new UserManager({
  authority: config.authority,
  client_id: config.clientId,
  redirect_uri: config.redirectUri,
  response_type: config.responseType,
  scope: config.scope,
  post_logout_redirect_uri: config.postLogoutRedirectUri,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silent_redirect_uri: config.silentSigninUri,
});

export const localStorageSelectedProfileKey = 'selectedProfileId';
export const localStorageImpersonatedProfileKey =
  'selectedImpersonatedProfileId';
export const localStorageImpersonatedUser = 'impersonatedUser';
export const localStorageImpersonatedUserId = 'impersonatedUserId';

export async function loadUserFromStorage() {
  try {
    const isUserImpersonated = localStorage.getItem(
      localStorageImpersonatedUser
    );
    const impersonatedUserId = localStorage.getItem(
      localStorageImpersonatedUserId
    );
    if (isUserImpersonated && impersonatedUserId) {
      updatedImpersonateUser(
        JSON.parse(isUserImpersonated),
        Number(impersonatedUserId)
      );
      const impersonatedProfileId = localStorage.getItem(
        localStorageImpersonatedProfileKey
      );
      if (impersonatedProfileId) {
        switchImpersonatedUserProfile(Number(impersonatedProfileId));
      }
    } else {
      store.dispatch(userImpersonationFailed());
    }

    store.dispatch(userLoadingStarted());
    const user = await userManager.getUser();
    if (!user) {
      return store.dispatch(userLoginFailed());
    }
    store.dispatch(
      userLoggedin({
        accessToken: user.access_token,
        name: user.profile.name,
        email: user.profile.email,
      })
    );
    const profileId = localStorage.getItem(localStorageSelectedProfileKey);
    if (profileId) {
      switchUserProfile(Number(profileId));
    }
  } catch (e) {
    logger?.error(`UserService -> User not found: ${e}`);
    appInsights?.trackException({ error: new Error(`UserService -> User not found: ${e}`), severityLevel: SeverityLevel.Error });
    store.dispatch(userLoginFailed());
  }
}

export function clearUserManagerUserState() {
  userManager.clearStaleState();
  userManager.removeUser();
  localStorage.removeItem(localStorageSelectedProfileKey);
}

export function signinRedirect() {
  store.dispatch(userLoadingStarted());
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function silentSigninCallback() {
  return userManager.signinSilentCallback();
}

export function signoutRedirectCallback() {
  clearUserManagerUserState();
  return userManager.signoutRedirectCallback();
}

window.addEventListener('storage', function(event){
  if (event.key == 'logout-event') { 
    signout();
  }
});

export function signout(message?: string) {
  store.dispatch(userLoggedout());
    queryClient.clear();
    removeImpersonateUser();
        if (message)
          return userManager.signoutRedirect({
            extraQueryParams: {
              reason: message,
            },
          });
        else return userManager.signoutRedirect();
}

export function userImpersonate(userInfo: TokenResponse, userId: number) {
  localStorage.setItem(
    localStorageImpersonatedUser,
    JSON.stringify(userInfo)
  );
  localStorage.setItem(localStorageImpersonatedUserId, userId.toString());
  updatedImpersonateUser(userInfo, userId);
}

export function updatedImpersonateUser(
  userInfo: TokenResponse,
  userId: number
) {
  if (userInfo.accessToken) {
    store.dispatch(userImpersonationStarted());
    const decoded: { name: string; email: string } = jwt_decode(
      userInfo.accessToken
    );
    store.dispatch(
      userImpersonated({
        accessToken: userInfo.accessToken,
        name: decoded.name ?? undefined,
        email: decoded.email ?? undefined,
        userId: userId,
      })
    );
    userManager.clearStaleState();
    queryClient.invalidateQueries(getUserCashKey());
    queryClient.invalidateQueries(getRepairersCacheKey());
    queryClient.clear();
  }
}

export function removeImpersonateUser() {
  store.dispatch(userImpersonationRemoved());
  localStorage.removeItem(localStorageImpersonatedUser);
  localStorage.removeItem(localStorageImpersonatedUserId);
  localStorage.removeItem(localStorageImpersonatedProfileKey);
  userManager.clearStaleState();
  queryClient.invalidateQueries(getUserCashKey());
  queryClient.invalidateQueries(getRepairersCacheKey());
  queryClient.clear();
}

export function switchUserProfile(profileId: number | undefined) {
  store.dispatch(userSwitchedProfile(Number(profileId)));
  if (profileId)
    localStorage.setItem(
      localStorageSelectedProfileKey,
      profileId?.toString()
    );
}

export function switchImpersonatedUserProfile(profileId: number | undefined) {
  store.dispatch(impersonatedUserSwitchedProfile(Number(profileId)));
  if (profileId)
    localStorage.setItem(
      localStorageImpersonatedProfileKey,
      profileId?.toString()
    );
}
export function clearLocalStorage() {
  localStorage.removeItem(localStorageSelectedProfileKey);
  if (localStorage.getItem(localStorageImpersonatedUser)) {
    localStorage.removeItem(localStorageImpersonatedUser);
    localStorage.removeItem(localStorageImpersonatedUserId);
    localStorage.removeItem(localStorageImpersonatedProfileKey);
  }
}

export default userManager;
