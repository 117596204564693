import styled from '@emotion/styled';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import { Label } from 'components/PageLayout';
import { Select } from 'components/Select';
import { useInMobile } from 'core/Theming/Device';
import { ReportFormInvoiceExtractFormProps } from './types';
import { Checkbox } from 'components/Checkbox';
import { ViewButton } from './ViewButton';

export const ReportFormInvoiceExtractForm = ({
  isBusy,
  endDate,
  startDate,
  setEndDate,
  handleSubmit,
  setStartDate,
  workProviderOptions,
  dataAccessProfileName,
  setDataAccessProfileName,
  basedOnCreatedDateOnly,
  setBasedOnCreatedDateOnly,
}: ReportFormInvoiceExtractFormProps) => {
  const isMobile = useInMobile();
  const isViewDisabled = !startDate || !endDate || dataAccessProfileName == null || isBusy;

  return (
    <CustomForm onSubmit={handleSubmit}>
      <CustomGridContainer direction="row">
        <InputButton md={3} sm={4} xs={12}>
          <Label>Start Date</Label>
          <DatePickerField
            isDisabled={isBusy}
            onChange={(date) => setStartDate(date)}
            value={startDate}
            maxDate={endDate}
          />
        </InputButton>
        <InputButton md={3} sm={4} xs={12}>
          <Label>End Date</Label>
          <DatePickerField
            isDisabled={isBusy}
            onChange={(date) => setEndDate(date)}
            value={endDate}
            minDate={startDate}
          />
        </InputButton>
        {!isMobile ? <ViewButton disabled={isViewDisabled} /> : null}
      </CustomGridContainer>
      <Container direction="row">
        <InputButton md={3} sm={4} xs={12}>
          <Label>Work Provider</Label>
          <Select
            disabled={isBusy}
            selectedId={
              workProviderOptions.find(
                (status) => status.value === dataAccessProfileName
              )?.id
            }
            onChange={(status) => setDataAccessProfileName(status.value)}
            options={workProviderOptions}
          />
        </InputButton>
        <InputButton md={3} sm={3} xs={12}>
          <CheckBoxButton>
            <Checkbox
              disabled={isBusy}
              checked={basedOnCreatedDateOnly}
              onChange={(e) =>
                setBasedOnCreatedDateOnly(Boolean(e.target.checked))
              }
              label="Based on Created Date Only"
              color="secondary"
            />
          </CheckBoxButton>
        </InputButton>
        {isMobile ? <ViewButton disabled={isViewDisabled} /> : null}
      </Container>
    </CustomForm>
  );
};

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const CheckBoxButton = styled('div')`
  margin-top: 20px;
`;

const CustomGridContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;