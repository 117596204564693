import { formatCurrency } from 'utils/helpers';
import { Columns, TableColumnExtensions } from './types';

export const CORE_MI_REPORT = 'Core MI Report';
export const CORE_MI_REPORT_GRID_TITLE = 'CoreMIReport';
export const CORE_MI_REPORT_TITLE_INFO_MESSAGE =
  'Provides a complete breakdown of all core information on a claim by claim basis for your chosen date range or where the initial work completed date is within your chosen date range';

export const columns: Columns[] = [
  { name: 'name', title: 'Name' },
  { name: 'siteCode', title: 'Site Code' },
  { name: 'repairerGroup', title: 'Repairer Group' },
  { name: 'stream', title: 'Stream' },
  { name: 'insurerName', title: 'Insurer Name' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'regMark', title: 'Reg Mark' },
  { name: 'claimId', title: 'Claim Id' },
  { name: 'status', title: 'Status' },
  { name: 'statusDTG', title: 'Status DTG' },
  { name: 'manufacturer', title: 'Manufacturer' },
  { name: 'model', title: 'Model' },
  { name: 'vehicleMobile', title: 'Vehicle Mobile' },
  { name: 'labourHours', title: 'Labour Hours' },
  {
    name: 'labourCost',
    title: 'Labour Cost',
    getCellValue: (row) => formatCurrency(row.labourCost),
  },
  {
    name: 'partsCost',
    title: 'Parts Cost',
    getCellValue: (row) => formatCurrency(row.partsCost),
  },
  {
    name: 'paintCost',
    title: 'Paint Cost',
    getCellValue: (row) => formatCurrency(row.paintCost),
  },
  {
    name: 'otherCost',
    title: 'Other Cost',
    getCellValue: (row) => formatCurrency(row.otherCost),
  },
  {
    name: 'totalCost',
    title: 'Total Cost',
    getCellValue: (row) => formatCurrency(row.totalCost),
  },
  { name: 'ownerExcess', title: 'Owner Excess' },
  { name: 'courtesyCarProvided', title: 'Courtesy Car Provided' },
  { name: 'courtesyCar', title: 'Courtesy Car' },
  { name: 'courtesyCarOut', title: 'Courtesy Car Out' },
  { name: 'ownerMobileNumber', title: 'Owner Mobile Number' },
  { name: 'ownerEmail', title: 'Owner Email' },
  { name: 'createdDate', title: 'Created Date' },
  { name: 'bookedInDate', title: 'Booked-In Date' },
  { name: 'onSiteDate', title: 'On-Site Date' },
  { name: 'partsOrderedDate', title: 'Parts Ordered Date' },
  { name: 'workStartedDate', title: 'Work Started Date' },
  { name: 'workCompletedDate', title: 'Work Completed Date' },
  { name: 'bookedOutDate', title: 'Booked-Out Date' },
  { name: 'offSiteDate', title: 'Off-Site Date' },
  { name: 'invoiceDate', title: 'Invoice Date' },
  { name: 'ecdDate', title: 'ECD Date' },
  { name: 'liabilityImpact', title: 'Liability Impact' },
  { name: 'initialBookInDate', title: 'Initial Booked-In Date' },
  { name: 'initialBookOutDate', title: 'Initial Booked Out Date' },
  { name: 'bookInDateChanges', title: 'Booked In Date Changes' },
  { name: 'bookOutDateChanges', title: 'Booked Out Date Changes' },
  { name: 'initialECDDate', title: 'Initial ECDDate' },
  { name: 'ecdDateChanges', title: 'ECDDate Changes' },
  { name: 'cT_FNOL_OnSite', title: 'CT FNOL On Site' },
  { name: 'cT_Key_2_Key', title: 'CT Key 2 Key' },
  { name: 'cT_CycleTime', title: 'CT Cycle Time' },
  { name: 'within15Days', title: 'Within15Days' },
  { name: 'within21Days', title: 'Within21Days' },
  { name: 'webPortalUsage', title: 'Web Portal Usage' },
  { name: 'smsMessages', title: 'SMSMessages' },
  { name: 'hashTags', title: 'Hash Tags' },
  { name: 'initialOnSiteDate', title: 'Initial On Site Date' },
  { name: 'initialWorkCompletedDate', title: 'Initial Work Completed Date' },
  { name: 'replacementVehicleCode', title: 'Courtesy Car Required' },
];

export const tableColumnExtensions: TableColumnExtensions[] = [
  { columnName: 'name', wordWrapEnabled: true },
  { columnName: 'siteCode', wordWrapEnabled: true },
  { columnName: 'repairerGroup', wordWrapEnabled: true },
  { columnName: 'stream', wordWrapEnabled: true },
  { columnName: 'insurerName', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'regMark', wordWrapEnabled: true },
  { columnName: 'claimId', wordWrapEnabled: true },
  { columnName: 'status', wordWrapEnabled: true },
  { columnName: 'statusDTG', wordWrapEnabled: true },
  { columnName: 'manufacturer', wordWrapEnabled: true },
  { columnName: 'model', wordWrapEnabled: true },
  { columnName: 'vehicleMobile', wordWrapEnabled: true },
  { columnName: 'labourHours', wordWrapEnabled: true },
  { columnName: 'labourCost', wordWrapEnabled: true },
  { columnName: 'partsCost', wordWrapEnabled: true },
  { columnName: 'paintCost', wordWrapEnabled: true },
  { columnName: 'otherCost', wordWrapEnabled: true },
  { columnName: 'totalCost', wordWrapEnabled: true },
  { columnName: 'ownerExcess', wordWrapEnabled: true },
  { columnName: 'courtesyCarProvided', wordWrapEnabled: true },
  { columnName: 'courtesyCar', wordWrapEnabled: true },
  { columnName: 'courtesyCarOut', wordWrapEnabled: true },
  { columnName: 'ownerMobileNumber', wordWrapEnabled: true },
  { columnName: 'ownerEmail', wordWrapEnabled: true },
  { columnName: 'createdDate', wordWrapEnabled: true },
  { columnName: 'bookedInDate', wordWrapEnabled: true },
  { columnName: 'onSiteDate', wordWrapEnabled: true },
  { columnName: 'partsOrderedDate', wordWrapEnabled: true },
  { columnName: 'workStartedDate', wordWrapEnabled: true },
  { columnName: 'workCompletedDate', wordWrapEnabled: true },
  { columnName: 'bookedOutDate', wordWrapEnabled: true },
  { columnName: 'offSiteDate', wordWrapEnabled: true },
  { columnName: 'invoiceDate', wordWrapEnabled: true },
  { columnName: 'ecdDate', wordWrapEnabled: true },
  { columnName: 'liabilityImpact', wordWrapEnabled: true },
  { columnName: 'initialBookInDate', wordWrapEnabled: true },
  { columnName: 'bookInDateChanges', wordWrapEnabled: true },
  { columnName: 'initialBookOutDate', wordWrapEnabled: true },
  { columnName: 'bookOutDateChanges', wordWrapEnabled: true },
  { columnName: 'initialECDDate', wordWrapEnabled: true },
  { columnName: 'ecdDateChanges', wordWrapEnabled: true },
  { columnName: 'cT_FNOL_OnSite', wordWrapEnabled: true },
  { columnName: 'cT_Key_2_Key', wordWrapEnabled: true },
  { columnName: 'cT_CycleTime', wordWrapEnabled: true },
  { columnName: 'within15Days', wordWrapEnabled: true },
  { columnName: 'within21Days', wordWrapEnabled: true },
  { columnName: 'webPortalUsage', wordWrapEnabled: true },
  { columnName: 'smsMessages', wordWrapEnabled: true },
  { columnName: 'hashTags', wordWrapEnabled: true },
  { columnName: 'initialOnSiteDate', wordWrapEnabled: true },
  { columnName: 'initialWorkCompletedDate', wordWrapEnabled: true },
  { columnName: 'replacementVehicleCode', wordWrapEnabled: true },
];

export const dateColumnNames: string[] = [
  'statusDTG',
  'createdDate',
  'bookedInDate',
  'onSiteDate',
  'partsOrderedDate',
  'workStartedDate',
  'workCompletedDate',
  'bookedOutDate',
  'offSiteDate',
  'invoiceDate',
  'ecdDate',
  'initialBookInDate',
  'initialBookOutDate',
  'initialECDDate',
  'initialOnSiteDate',
  'initialWorkCompletedDate',
];

export const currencyColumnsName: string[] = [
  'labourCost',
  'partsCost',
  'paintCost',
  'otherCost',
  'totalCost',
];