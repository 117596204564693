import { Column } from '@devexpress/dx-react-grid';

export const dateColumnsName = [
  'createdDate',
  'bookingInDate',
  'estimatedDTG',
  'engineerInstructedDate',
  'confirmedOnSiteDate',
  'estimatedCompletionDate',
  'partsOrderedDate',
  'partsArrivedDate',
  'workStartedDate',
  'actualCompletionDate',
  'deliveredDate',
  'invoiceDate',
];

export const reportColumns: Column[] = [
  { name: 'claimReference', title: 'Claim Ref.' },
  { name: 'bodyshop', title: 'Bodyshop' },
  { name: 'activeWebID', title: 'Active Web ID' },
  { name: 'status', title: 'Status' },
  { name: 'claimSource', title: 'Claim Source' },
  { name: 'liability', title: 'Liability' },
  { name: 'isThirdParty', title: 'Is Third Party' },
  { name: 'createdDate', title: 'Created Date' },
  { name: 'ownerName', title: 'Owner Name' },
  { name: 'manufacturer', title: 'Manufacturer' },
  { name: 'model', title: 'Model' },
  { name: 'regmark', title: 'Vehicle Reg.' },
  { name: 'notificationSent', title: 'Notification Sent' },
  {
    name: 'notificationAcknowledged',
    title: 'Notification Acknowledged',
  },
  {
    name: 'bookingInDate',
    title: 'Booking In Date',
  },
  { name: 'estimatedDTG', title: 'Estimated DTG' },
  { name: 'labourHours', title: 'Labour Hours' },
  { name: 'possibleTotalLoss', title: 'Possible Total Loss' },
  { name: 'engineerInstructedDate', title: 'Engineer Instructed Date' },
  { name: 'declaredTotalLossDate', title: 'Declared Total Loss Date' },
  { name: 'confirmedOnSiteDate', title: 'Confirmed On Site Date' },
  { name: 'estimatedCompletionDate', title: 'Estimated Completion Date' },
  { name: 'partsOrderedDate', title: 'Parts Ordered Date' },
  { name: 'partsArrivedDate', title: 'Parts Arrived Date' },
  { name: 'workStartedDate', title: 'Work Started Date' },
  { name: 'actualCompletionDate', title: 'Actual Completion Date' },
  { name: 'deliveredDate', title: 'Delivered Date' },
  { name: 'invoiceDate', title: 'Invoice Date' },
  { name: 'hashTags', title: 'Hash Tags' },
];

export const tableColumnExtensions = [
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'bodyshop', wordWrapEnabled: true },
  { columnName: 'activeWebID', wordWrapEnabled: true },
  { columnName: 'status', wordWrapEnabled: true },
  { columnName: 'claimSource', wordWrapEnabled: true },
  { columnName: 'liability', wordWrapEnabled: true },
  { columnName: 'isThirdParty', wordWrapEnabled: true },
  { columnName: 'createdDate', wordWrapEnabled: true },
  { columnName: 'ownerName', wordWrapEnabled: true },
  { columnName: 'manufacturer', wordWrapEnabled: true },
  { columnName: 'model', wordWrapEnabled: true },
  { columnName: 'regmark', wordWrapEnabled: true },
  { columnName: 'notificationSent', wordWrapEnabled: true },
  { columnName: 'notificationAcknowledged', wordWrapEnabled: true },
  { columnName: 'bookingInDate', wordWrapEnabled: true },
  { columnName: 'estimatedDTG', wordWrapEnabled: true },
  { columnName: 'labourHours', wordWrapEnabled: true },
  { columnName: 'possibleTotalLoss', wordWrapEnabled: true },
  { columnName: 'engineerInstructedDate', wordWrapEnabled: true },
  { columnName: 'declaredTotalLossDate', wordWrapEnabled: true },
  { columnName: 'confirmedOnSiteDate', wordWrapEnabled: true },
  { columnName: 'estimatedCompletionDate', wordWrapEnabled: true },
  { columnName: 'partsOrderedDate', wordWrapEnabled: true },
  { columnName: 'partsArrivedDate', wordWrapEnabled: true },
  { columnName: 'workStartedDate', wordWrapEnabled: true },
  { columnName: 'actualCompletionDate', wordWrapEnabled: true },
  { columnName: 'deliveredDate', wordWrapEnabled: true },
  { columnName: 'invoiceDate', wordWrapEnabled: true },
  { columnName: 'hashTags', wordWrapEnabled: true },
];
