import { Column } from '@devexpress/dx-react-grid';
import { Workbook, CellValue } from 'exceljs';
import { Measure30Result } from 'api/resources/models/AutoGenerated';

//Excel sheet
export const handleNewSheet = async ({
  workbook,
  measureFuture30ReportData,
  measureFuture30ReportColumns,
}: {
  workbook: Workbook;
  measureFuture30ReportColumns?: Column[];
  measureFuture30ReportData?: Measure30Result[];
}) => {
  const mainSheet = workbook.getWorksheet('Main');
  mainSheet.addRow({});
  let lastRowIndex = mainSheet.getSheetValues().length;

  measureFuture30ReportColumns?.forEach((item, index) => {
    mainSheet.getRow(lastRowIndex).getCell(index + 1).value =
      item.title as CellValue;
    mainSheet.getRow(lastRowIndex).getCell(index + 1).font = { bold: true };
    mainSheet.getRow(lastRowIndex).getCell(index + 1).alignment = {
      horizontal: 'left',
    };
  });
  lastRowIndex++;
  measureFuture30ReportData?.forEach((element: Measure30Result) => {
    let colIndex = 1;
    Object.keys(element).forEach((key) => {
      mainSheet.getRow(lastRowIndex).getCell(colIndex).value = element[
        key as keyof Measure30Result
      ] as CellValue;
      colIndex++;
    });
    lastRowIndex++;
  });
};
