import { ReactComponent as NoComms } from 'assets/activities/NoComms.svg';
import { ReactComponent as RVCheckEracAtt } from 'assets/activities/RVCheckEracAtt.svg';
import { ReactComponent as CustomerComment } from 'assets/activities/CustomerComment.svg';
import { ReactComponent as NPS } from 'assets/activities/NPS.svg';
import { ReactComponent as ContactRepairerLargeSvg } from 'assets/ContactRepairerLarge.svg';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Status from 'components/Status';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Event, Forum, Message } from '@mui/icons-material';
import { logger } from 'core/logger';

interface Props {
  status: string;
}

export function ActivityStatus({ status }: Props) {
  return <>{renderActivityIcon(status)}</>;
}

function renderActivityIcon(icon: string) {
  switch (icon) {
    case 'Tag': {
      return (
        <Status toolTipMessage="Hashtag">
          <LocalOfferIcon />
        </Status>
      );
    }
    case 'NoComms': {
      return (
        <Status toolTipMessage="Communication">
          <NoComms />
        </Status>
      );
    }
    case 'DataMonitor': {
      return (
        <Status toolTipMessage="Data Monitor">
          <VerifiedUserIcon />
        </Status>
      );
    }
    case 'Diary': {
      return (
        <Status toolTipMessage="Diary">
          <Event />
        </Status>
      );
    }
    case 'Sms': {
      return (
        <Status toolTipMessage="SMS">
          <Forum />
        </Status>
      );
    }
    case 'RVCheck':
    case 'EracAtt': {
      return (
        <Status toolTipMessage="Replacement Vehicle">
          <RVCheckEracAtt />
        </Status>
      );
    }
    case 'Customer Comment': {
      return (
        <Status toolTipMessage="Customer Comment">
          <CustomerComment />
        </Status>
      );
    }
    case 'NPS': {
      return (
        <Status toolTipMessage="NPS">
          <NPS />
        </Status>
      );
    }
    case 'Comment': {
      return (
        <Status toolTipMessage="Comment">
          <Message />
        </Status>
      );
    }
    case 'OwnerContact': {
      return (
        <Status toolTipMessage="Owner Contact">
          <PersonIcon />
        </Status>
      );
    }
    case 'RepairerContact': {
      return (
        <Status toolTipMessage="Repairer Contact">
          <ContactRepairerLargeSvg />
        </Status>
      );
    }
    default: {
      logger?.warn(`Activity icon ${icon} not found!`);
      break;
    }
  }
}
