import { useTheme } from '@emotion/react';
import {
  MaintenanceMessage,
  MaintenanceMessageType,
} from 'api/resources/models/AutoGenerated';
import { useAppSelector } from 'core/store';
import dayjs from 'dayjs';
import { differenceWith } from 'lodash';
import { useMaintenanceConfig } from 'pages/hooks';
import { MaintenancePage } from 'pages/Maintenance';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { formatDateTime, setRecursiveTimeout } from 'utils/helpers';
import { isDefined } from 'utils/predicates';
import { useBrowserTabsSharedSession } from './hooks';
import { MaintenanceWarning } from './MaintenanceWarning';
import { getMaintenanceCacheTimeMs } from './utils';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

interface Props {
  children: ReactNode;
}

interface TimeoutToastConfig {
  message: MaintenanceMessage;
  timeoutDuration: number;
  toastConfig?: ToastOptions;
  timeoutType?: 'message' | 'warning' | 'inProgress';
}

interface CloseDate {
  [key: number]: Date;
}
interface MaintenanceState {
  messages: MaintenanceMessage[];
  messagesCloseDates: CloseDate;
}

export function MaintenanceMessages({ children }: Props) {
  const theme = useTheme();
  const user = useAppSelector((state) => state.auth.user);
  const [cacheTime, setCacheTime] = useState<number>();

  const [maintenanceInProgressMessage, setMaintenanceInProgressMessage] =
    useState<string>();
  const [state, setState] = useState<MaintenanceState>();

  const maintenanceInProgressMessageRef = useRef<string>();
  maintenanceInProgressMessageRef.current = maintenanceInProgressMessage;

  const toastOptions: ToastOptions = {
    autoClose: false,
    closeOnClick: false,
    position: 'top-right',
    bodyStyle: {
      color: theme.palette.background.default,
      whiteSpace: 'pre-line',
    },
    style: { background: theme.palette.background.dark, top: '5em' },
    closeButton: CloseButton,
  };

  const {
    isFinished,
    stateFromSession: closeDates,
    set,
  } = useBrowserTabsSharedSession<CloseDate>({
    key: 'closeDates',
  });

  const { maintenanceConfig } = useMaintenanceConfig({
    enabled: !!user && isFinished,
    refetchInterval: cacheTime ?? Infinity,
  });

  const latestMessages = useRef<MaintenanceMessage[]>();
  latestMessages.current = maintenanceConfig?.messages;

  useEffect(() => {
    if (maintenanceConfig) {
      const cacheTimeMs = getMaintenanceCacheTimeMs({
        cachedOn: dayjs(maintenanceConfig.cachedOn).toDate(),
        cacheLifeTimeInSeconds: maintenanceConfig.cacheLifeTimeInSeconds,
        now: new Date(),
      });
      setCacheTime(cacheTimeMs);
      setState((prev) => ({
        ...prev,
        messagesCloseDates: closeDates
          ? closeDates
          : { ...prev?.messagesCloseDates },
        messages: maintenanceConfig?.messages,
      }));
    }
  }, [maintenanceConfig, closeDates]);

  useEffect(() => {
    if (state) {
      set(state.messagesCloseDates);
      const relevantMessages = getRelevantMessages(state.messages);
      const toastConfigs = relevantMessages.map((message) => {
        const data = [
          initialInfo(message),
          extendTimoutInfo(message),
          inProgressWarningTimeoutInfo(message),
          inProgressTimeoutInfo(message),
          closeTimeoutInfo(message),
        ];
        return data.filter(isDefined);
      });

      const subscriptions = toastConfigs.flat().map((config) => {
        const timeoutId = setRecursiveTimeout(() => {
          if (config.timeoutType === 'warning') {
            toast(
              <MaintenanceWarning
                message={`${config.message.message}: ${formatDateTime(
                  new Date(config.message.validFrom)
                )}`}
                countTillDate={new Date(config.message.validFrom)}
              />,
              config.toastConfig
            );
          } else if (config.timeoutType === 'inProgress') {
            setMaintenanceInProgressMessage(config.message.message);
          } else {
            toast(config.message.message, config.toastConfig);
          }
        }, config.timeoutDuration);

        const dismissTimeoutId = setRecursiveTimeout(() => {
          toast.dismiss(config.message.messageId);
          if (config.timeoutType === 'inProgress')
            setMaintenanceInProgressMessage(undefined);
        }, dayjs(config.message.validTo).diff(dayjs()));

        return () => {
          clearTimeout(timeoutId);
          clearTimeout(dismissTimeoutId);
        };
      });

      return () => {
        subscriptions.forEach((unsubscribe) => unsubscribe());
        dismissIrrelevantToasts(relevantMessages);
      };
    }
  }, [state, maintenanceInProgressMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {maintenanceInProgressMessage ? (
        <MaintenancePage message={maintenanceInProgressMessage} />
      ) : (
        children
      )}
    </Fragment>
  );

  function initialInfo(
    message: MaintenanceMessage
  ): TimeoutToastConfig | undefined {
    if (!state) return;
    if (state.messagesCloseDates[message.messageId]) return;

    if (message.type !== MaintenanceMessageType.Scheduled) return;
    return {
      message: message,
      timeoutDuration: 0,
      timeoutType: 'message',
      toastConfig: {
        ...toastOptions,
        toastId: message.messageId,
        onClose: () => handleMessageClose(message),
      },
    };
  }

  function extendTimoutInfo(
    message: MaintenanceMessage
  ): TimeoutToastConfig | undefined {
    if (!state) return;

    const closeDate = state.messagesCloseDates[message.messageId];
    if (!(closeDate && maintenanceConfig && !toast.isActive(message.messageId)))
      return;

    const timeoutDuration = dayjs
      .duration(
        maintenanceConfig.recurrenceIntervalInSeconds -
          dayjs().diff(dayjs(closeDate), 'seconds'),
        'seconds'
      )
      .asMilliseconds();
    return {
      message,
      timeoutDuration,
      timeoutType: 'message',
      toastConfig: {
        ...toastOptions,
        toastId: message.messageId,
        onClose: () => handleMessageClose(message),
      },
    };
  }

  function inProgressWarningTimeoutInfo(
    message: MaintenanceMessage
  ): TimeoutToastConfig | undefined {
    if (!maintenanceConfig) return;
    if (message.type !== MaintenanceMessageType.InProgress) return;
    if (dayjs(message.validFrom) <= dayjs()) return;
    return {
      message,
      timeoutDuration: dayjs(message.validFrom)
        .subtract(maintenanceConfig.warningBeforeInSeconds, 'seconds')
        .diff(dayjs(), 'ms'),
      timeoutType: 'warning',
      toastConfig: {
        ...toastOptions,
        toastId: message.messageId,
        closeButton: false,
        draggable: false,
      },
    };
  }

  function inProgressTimeoutInfo(
    message: MaintenanceMessage
  ): TimeoutToastConfig | undefined {
    if (message.type === MaintenanceMessageType.InProgress)
      return {
        message,
        timeoutDuration: dayjs(message.validFrom).diff(dayjs(), 'ms'),
        timeoutType: 'inProgress',
      };
  }

  function closeTimeoutInfo(
    message: MaintenanceMessage
  ): TimeoutToastConfig | undefined {
    if (!state || !maintenanceConfig) return;
    if (toast.isActive(message.messageId)) return;

    const closeDate = state.messagesCloseDates[message.messageId];
    if (!closeDate) return;
    const duration = dayjs
      .duration(
        maintenanceConfig.recurrenceIntervalInSeconds -
          dayjs().diff(dayjs(closeDate), 'seconds'),
        'seconds'
      )
      .asMilliseconds();

    return {
      message,
      timeoutDuration: duration,
      toastConfig: {
        ...toastOptions,
        toastId: message.messageId,
        onClose: () => handleMessageClose(message),
      },
    };
  }

  function dismissIrrelevantToasts(messages: MaintenanceMessage[]) {
    const latest = latestMessages.current;
    if (latest) {
      differenceWith(messages, latest, (a, b) => {
        return a.messageId === b.messageId;
      }).forEach((old) => {
        toast.dismiss(old.messageId);
      });
    }
  }

  function handleMessageClose(message: MaintenanceMessage) {
    if (maintenanceInProgressMessageRef.current) return;
    setState((prevState) =>
      prevState
        ? {
            ...prevState,
            messagesCloseDates: {
              ...prevState.messagesCloseDates,
              [message.messageId]: new Date(),
            },
          }
        : prevState
    );
  }

  function getRelevantMessages(
    messages: MaintenanceMessage[]
  ): MaintenanceMessage[] {
    return messages.filter(({ validFrom, validTo, type }) => {
      const from = dayjs(validFrom);
      const to = dayjs(validTo);
      const isScheduled = type === MaintenanceMessageType.Scheduled;
      const isInProgress = type === MaintenanceMessageType.InProgress;
      return (
        (isScheduled && from <= dayjs() && to >= dayjs()) ||
        (isInProgress && (from >= dayjs() || from < dayjs()) && to >= dayjs())
      );
    });
  }

  function CloseButton({ closeToast }: { closeToast: () => void }) {
    return (
      <CloseIconContainer onClick={closeToast}>
        <CloseIcon color="inherit" fontSize="small" />
      </CloseIconContainer>
    );
  }
}

const CloseIconContainer = styled.div`
  color: ${({ theme }) => theme.palette.primary.light};
`;
