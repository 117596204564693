import { Column, Row } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import ReportGrid from 'components/ReportGrid';
import { useMemo } from 'react';
import { Cell as ExcelCell, Worksheet } from 'exceljs';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { summaryCalculator } from './helpers/summaryCalculator';
import {
  columns,
  dateColumnName,
  defaultGroupingColumn,
  groupSummaryItems,
  summaryItems,
  tableColumnExtensions,
} from './constants';
import { GroupIconComponent } from './GroupIconComponent';
import { SummaryItemComponent } from './SummaryItemComponent';
import { customFooterResult } from './helpers/customFooterResult';
import { CellComponent } from './CellComponent';
import { GroupSummaryItemComponent } from './GroupSummaryItemComponent';
import { customCellFormatter } from './helpers/customCellFormatter';
import { GroupCellContent } from './GroupCellContent';
import { customizeSummaryCell } from './helpers/customizeSummaryCell';
import { sortReportData } from './helpers/sortReportData';
import { SLAReportTableProps } from './types';
import { valuesConverter } from './helpers/valuesConverter';


export function SLAReportTable({
  reportData,
  isReportFetching,
  reportHeaderTagLine,
}: Readonly<SLAReportTableProps>) {
  //excel configuration
  const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
    customCellFormatter(cell, row, column, reportData ?? []);
  };

  const rows = useMemo<Row>(() => sortReportData(reportData), [reportData]);

  const customizeFooter = (worksheet: Worksheet) => {
    if (!rows) return null;
    customFooterResult(worksheet, rows);
  };

  return (
    <>
      {!isReportFetching && rows && rows?.length === 0 && (
        <NoReportDataMessage />
      )}
      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          disableFiltering
          columns={columns}
          isLoading={isReportFetching}
          disableDragAndDrop
          disabledTableSelection
          title="SLAPerformanceV3"
          summaryItems={summaryItems}
          dateColumnsName={dateColumnName}
          DateTypeProvider={DateTypeProvider}
          customizeCellExport={customizeCell}
          CustomCellComponent={CellComponent}
          groupCellContent={(props) => GroupCellContent(reportData, props)}
          groupSummaryItems={groupSummaryItems}
          summaryCalculator={summaryCalculator}
          SummaryItemComponent={SummaryItemComponent}
          customizeSummaryCell={customizeSummaryCell}
          CustomGroupIconComponent={GroupIconComponent}
          defaultGroupingColumn={defaultGroupingColumn}
          tableColumnExtensions={tableColumnExtensions}
          headerTitleForSheet="SLA Performance V3 Report"
          GroupSummaryItemComponent={GroupSummaryItemComponent}
          reportHeaderTagLine={reportHeaderTagLine}
          customizeFooter={customizeFooter}
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </>
  );
}

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
  .Content-columnTitle {
    strong {
      display: none !important;
    }
    white-space: pre-line;
  }
`;
