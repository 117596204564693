import { Column } from '@devexpress/dx-react-grid';

export const columns: Column[] = [
  { name: 'engineerName', title: 'Engineer Name' },
  { name: 'onsiteDTG', title: 'Onsite DTG' },
  { name: 'engineerDate', title: 'Engineer Date' },
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'postcode', title: 'Post Code' },
];

export const tableColumnExtensions = [
  { columnName: 'onsiteDTG', width: '10%', wordWrapEnabled: true },
  { columnName: 'engineerDate', width: '10%', wordWrapEnabled: true },
];

export const dateColumnsName = ['onsiteDTG', 'engineerDate'];