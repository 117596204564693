/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react';
import { Container } from 'components/Layout';
import { Subheading } from 'components/Typography';
import { MilestoneItem } from './MilestoneItem';
import { Milestone as MilestoneProps } from './helpers';
import { WithWrapper } from 'components/WithWrapper';
import Permits from 'components/Permits';
import { BasicMilestoneItem } from './BasicMilestoneItem';

interface Props {
  data: MilestoneProps[];
}

export function MilestoneList({ data }: Props) {
  const theme = useTheme();

  return (
    <Container direction="column">
      <Subheading
        css={css`
          margin-bottom: ${theme.margin.m};
        `}
      >
        Milestones
      </Subheading>
      <Container>
        {data.map((d, i) => (
          <WithWrapper
            key={i}
            condition={!!d?.permit}
            wrap={(children) => (
              <Permits permit={d.permit!}>{children}</Permits>
            )}
          >
            {d.status ? (
              <MilestoneItem
                name={d.name}
                date={d.date}
                status={d.status}
                additionalStatus={d.additionalStatus}
                additionalMessage={d.additionalMessage}
                key={i}
              />
            ) : (
              <BasicMilestoneItem
                name={d.name}
                date={d.date}
                additionalMessage={d.additionalMessage}
              />
            )}
          </WithWrapper>
        ))}
      </Container>
    </Container>
  );
}
