import { Row, Column } from '@devexpress/dx-react-grid';
import { Cell as ExcelCell } from 'exceljs';
import { currencyColumnsName, dateColumnNames } from './constants';
import { customizeDateColumns } from '../helpers';
import { formatDateTime } from 'utils/helpers';

export const customizeCurrencyCellType = (
  cell: ExcelCell,
  column: Column,
  currencyColumns: string[]
) => {
  if (currencyColumns?.includes(column.name)) {
    cell.numFmt = '£#,##0.00';
  }
};

export const customizeCell = (cell: ExcelCell, row: Row, column: Column) => {
  customizeDateColumns(cell, row, column, dateColumnNames);
  customizeCurrencyCellType(cell, column, currencyColumnsName);
};

export const valuesConverter = (data: Row[]) => {
  return data.map((row) => {
    const formattedRow = { ...row };
    dateColumnNames.forEach((columnName) => {
      if (row[columnName]) {
        formattedRow[columnName] = formatDateTime(row[columnName]);
      }
    });
    return formattedRow;
  });
};
