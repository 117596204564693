import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import { Modal } from 'components/Modal';
import { MainModalContainer, ModalButton } from 'components/ModalLayout';
import { GridItem } from 'components/Layout';
import { Text } from 'components/Typography';
import { useState } from 'react';

interface ReportTitleInfoProps {
  children?: React.ReactNode;
  message?: string;
  title?: string;
}

export function ReportTitleInfo(props: ReportTitleInfoProps) {
  const { message, title } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div>
        <Icon onClick={() => setShowModal(true)} />
      </div>
      <Modal
        isOpen={showModal}
        title={title ?? ''}
        onClose={() => setShowModal(false)}
      >
        <MainModalContainer direction="column">
          <GridItem className="text">
            <Text>
              {message ?? 'Apologies, there are no information available.'}
            </Text>
          </GridItem>
          <GridItem>
            <ModalButton onClick={() => setShowModal(false)}>Close</ModalButton>
          </GridItem>
        </MainModalContainer>
      </Modal>
    </div>
  );
}

const Icon = styled(InfoIcon)`
  margin-top: 2px;
  margin-left: 5px;
  cursor: pointer;
`;
