import { RegistrationNo } from './fields/RegistrationNo';
import { ModifiedDisplay } from './fields/Modified';
import { EcdDisplay } from './fields/Ecd';
import { FormalStatusField } from './fields/FormalStatus';
import { DiaryDisplay } from './fields/Diary';
import { CpdDisplay } from './fields/Cpd';
import { WorkingInProgress } from './fields/WorkingInProgress';
import { ReplacementVehicleIcon } from '../ReplacementVehicleIcon';
import { CompletedDisplay } from "./fields/Completed";
import { DeliveredDisplay } from "./fields/Delivered";
import { formatDateTime } from "utils/helpers";
import { convertMinToDayHourMin } from "pages/ReportsDetails/SLAPerformanceV3/helpers/convertMinToDayHourMin";
import { Repair } from "api/resources/models/AutoGenerated";


export function renderTableItem(row: Repair, id: keyof Repair) {
    const value = row[id];
    if (id === 'vehicleReg') {
      return <RegistrationNo number={row.vehicleReg} />;
    } else if (id === 'dtgUpdated') {
      const { dtgUpdated, dtgDelivered, formalStatusDescription } = row;
      if (!dtgUpdated) return '';
  
      return (
        <ModifiedDisplay
          modified={new Date(dtgUpdated)}
          delivered={dtgDelivered ? new Date(dtgDelivered) : undefined}
          formalStatus={formalStatusDescription || ''}
        />
      );
    } else if (id === 'dtgEcd') {
      const { dtgEcd, dtgCompleted } = row;
  
      return (
        <EcdDisplay
          ecd={dtgEcd ? new Date(dtgEcd) : undefined}
          completed={dtgCompleted ? new Date(dtgCompleted) : undefined}
        />
      );
    } else if (id === 'dtgCpd') {
      const { dtgCpd, dtgDelivered } = row;
  
      return (
        <CpdDisplay
          cpd={dtgCpd ? new Date(dtgCpd) : undefined}
          completed={dtgDelivered ? new Date(dtgDelivered) : undefined}
        />
      );
    } else if (id === 'dtgCompleted') {
      const { dtgEcd, dtgCompleted } = row;
  
      return (
        <CompletedDisplay
          ecd={dtgEcd ? new Date(dtgEcd) : undefined}
          completed={dtgCompleted ? new Date(dtgCompleted) : undefined}
        />
      );
    } else if (id === 'dtgDelivered') {
      const { dtgCpd, dtgDelivered } = row;
  
      return (
        <DeliveredDisplay
          cpd={dtgCpd ? new Date(dtgCpd) : undefined}
          delivered={dtgDelivered ? new Date(dtgDelivered) : undefined}
        />
      );
    } else if (id === 'diaryDate') {
      if (!row.diaryDate) return '';
  
      return <DiaryDisplay diaryDate={new Date(row.diaryDate)} />;
    } else if (id === 'replacementVehicleStatus') {
      if (!row.replacementVehicleStatus) return '';
  
      return <ReplacementVehicleIcon status={row.replacementVehicleStatus} />;
    } else if (id === 'formalStatus') {
      if (!row.formalStatus || !row.formalStatusDescription) return '';
  
      return (
        <FormalStatusField
          status={row.formalStatus}
          dtgOnSite={row.dtgOnSite ? new Date(row.dtgOnSite) : null}
          statusDescription={row.formalStatusDescription}
        />
      );
    } else if (id === 'workInProgressCode') {
      return <WorkingInProgress status={row.workInProgressCode} />;
    } else if (id === 'dtgCreated') {
      return (
        <div style={{ width: '83px' }}>
          {formatDateTime(value as unknown as Date)}
        </div>
      );
    } else if (id === 'k2KDuration' || id === 'totalDuration') {
      if (value === 0) return value;

      return convertMinToDayHourMin(value as number, '');
    } else return value;
  }