import { DtlItem } from 'api/resources/models/AutoGenerated';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import { UpperInfoContainer } from 'components/PageLayout';
import { SortObject } from 'components/types';
import { Heading } from 'components/Typography';
import { logger } from 'core/logger';
import { createDtlUrl, ROUTES, TOTAL_LOSS } from 'core/routes';
import { useDtlList } from 'pages/hooks';
import { MainContainer } from 'pages/VehiclesSearch';

import { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { DEFINITE_TOTAL_LOSS, dtlTableHeaders, NO_DTL_DATA_MESSAGE } from './constants';
import { sortDtlList, useDtlListParams } from './helpers';
import { renderCustomCell } from './renderCustomCell';

export function DtlSearchPage() {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useDtlListParams(location.search);
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? '',
    sortOrder: urlParams.sortOrder ?? undefined,
  });

  const { dtlList, isLoading } = useDtlList();

  const [sortedDtlList, setSortedDtlList] = useState(dtlList);

  useEffect(() => {
    setSortedDtlList(sortDtlList(sortObject, dtlList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtlList]);

  useEffect(() => {
    navigateToAccessDataProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  return (
    <MainContainer isLoading={isLoading} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <Heading>{DEFINITE_TOTAL_LOSS}</Heading>
      </UpperInfoContainer>

      {!isLoading && dtlList.length === 0 && (
        <InfoText>{NO_DTL_DATA_MESSAGE}</InfoText>
      )}

      <DataGrid
        headers={dtlTableHeaders}
        data={sortedDtlList}
        isLoading={isLoading}
        onRowClick={handleVehicleClick}
        onSort={sort}
        sortObject={sortObject}
        customCellRender={renderCustomCell}
      />
    </MainContainer>
  );

  function handleVehicleClick(row: DtlItem) {
    if (!row.repairCode)
      return logger?.error(
        'Unable to navigate to Vehicle details from DTL Search',
        {
          repairId: row.repairId,
        }
      );

    const route = generatePath(ROUTES.vehicleDetails, {
      repairId: row.repairId,
      repairCode: row.repairCode,
      search: TOTAL_LOSS,
    });
    history.push(route);
  }

  function sort(sortValue: keyof DtlItem) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedDtlList(sortDtlList(sortObj, dtlList));
      return sortObj;
    });
  }

  function navigateToAccessDataProfiles() {
    history.replace(
      createDtlUrl(sortObject.sortString ?? undefined, sortObject.sortOrder)
    );
  }
}
