import { Row } from '@devexpress/dx-react-grid';

export const GOODWILL_REPORT = 'DL Proposed Goodwill Report';

export const GOODWILL_REPORT_MESSAGE =
  'Provides the proposed goodwill figures for DL jobs within the chosen date range.  Report to be used as part of the process for goodwill payments.';

export const GOODWILL_REPORT_GRID_TITLE = 'dlProposedGoodwillReport';

export const dateColumnsName = ['dtgCalculated'];

export const tableColumnExtensions = [
  { columnName: 'pinetreeId', wordWrapEnabled: true },
  { columnName: 'regMark', wordWrapEnabled: true },
  { columnName: 'dtgCalculated', wordWrapEnabled: true },
  { columnName: 'claimReference', wordWrapEnabled: true },
  { columnName: 'schemeCode', wordWrapEnabled: true },
  { columnName: 'ownerName', wordWrapEnabled: true },
  { columnName: 'ownerPostCode', wordWrapEnabled: true },
  { columnName: 'isValid', wordWrapEnabled: true },
  { columnName: 'goodwillDays', wordWrapEnabled: true },
  { columnName: 'goodwillAmount', wordWrapEnabled: true },
  { columnName: 'comments', wordWrapEnabled: true },
];

export const columns = [
  { name: 'pinetreeId', title: 'Pine Tree Id' },
  { name: 'regMark', title: 'Region Mark' },
  { name: 'dtgCalculated', title: 'DTG' },
  { name: 'claimReference', title: 'Claim Reference' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'ownerName', title: 'Owner Name' },
  { name: 'ownerPostCode', title: 'Owner Post Code' },
  {
    name: 'isValid',
    title: 'Valid',
    getCellValue: (row: Row) => (row.isValid == 0 ? 'No' : 'Yes'),
  },
  { name: 'goodwillDays', title: 'Good Will Days' },
  {
    name: 'goodwillAmount',
    title: 'Proposed Good Will Amount',
    getCellValue: (row: Row) => `£${row.goodwillAmount}`,
  },
  { name: 'comments', title: 'Comments' },
];
