/** @jsxImportSource @emotion/react */

import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Container, GridItem } from 'components/Layout';
import { Heading } from 'components/Typography';
import { useRepairClaimSummary, useRepairDetails } from 'pages/hooks';
import { ROUTES, TOTAL_LOSS, VehicleDetailsRouteParams } from 'core/routes';
import { BackButton, SecondaryButton } from 'components/Button';
import { QuickDetails } from './QuickDetails';
import { MilestoneList } from './MilestoneList';
import { InfoList } from './InfoList';
import {
  mapQuickDetails,
  mapMilestoneDetails,
  mapMobilityInformation,
  mapClaimInformation,
  mapActivity,
  mapSlas,
} from './helpers';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ActivityList } from './ActivityList';
import { ReactComponent as ContactRepairerSvg } from 'assets/ContactRepairer.svg';
import { ReactComponent as AssignEngineerSvg } from 'assets/AssignEngineer.svg';
import { ReactComponent as Edit } from 'assets/Edit.svg';
import { useState } from 'react';
import { ContactRepairerModal } from './ContactRepairerModal';
import { ContactOwnerModal } from './ContactOwnerModal';
import { ServiceLegal } from './ServiceLegal';
import { NavigationHeader } from './NavigationHeader';
import Permits from 'components/Permits';
import { AddActivityModal } from './ActivityList/AddActivityModal';
import { FormalStatusField } from 'components/VehiclesTable/fields/FormalStatus';
import PersonIcon from '@mui/icons-material/Person';
import dayjs from 'dayjs';
import AssignEngineerModal from './AssignEngineerModal';
import { Permit } from 'api/resources/models/AutoGenerated';
import { VehicleDetailsLoader } from './VehicleDetailsLoader';
import { useInMobile } from 'core/Theming/Device';

const useDetailsParams = () => {
  const {
    repairId: rawRepairId,
    repairCode,
    search,
  } = useParams<VehicleDetailsRouteParams>();
  const repairId = Number(rawRepairId);

  return {
    repairId: isNaN(repairId) ? 'RepairID must be a number' : repairId,
    repairCode,
    search,
  };
};
export function VehicleDetails() {
  const isMobile = useInMobile();
  const history = useHistory();
  const { repairId, repairCode, search } = useDetailsParams();
  if (typeof repairId === 'string') throw new Error(repairId);

  const {
    repairSummary,
    isRepairSummaryLoading,
    refetchClaimSummary,
    isRepairSummaryFetching,
  } = useRepairClaimSummary(repairId, repairCode);
  const { repairDetails, isRepairDetailsLoading, isRepairDetailsFetching } =
    useRepairDetails(repairId);

  const [isContactRepairerOpen, setIsContactRepairerOpen] = useState(false);
  const [isContactOwnerOpen, setIsContactOwnerOpen] = useState(false);
  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [isAssignEngineerOpen, setIsAssignEngineerOpen] = useState(false);

  const isLoading =
    isRepairSummaryLoading ||
    isRepairDetailsLoading ||
    isRepairDetailsFetching ||
    isRepairSummaryFetching;

  const activities = repairSummary?.activities
    ? repairSummary?.activities.map(mapActivity)
    : [];

  const slas = repairSummary?.serviceLevelAgreements
    ? repairSummary?.serviceLevelAgreements.map(mapSlas)
    : [];

  const isTotalLossRoute = search === TOTAL_LOSS;
  return (
    <VehicleContainer>
      {isLoading ? (
        <VehicleDetailsLoader />
      ) : (
        <MainContainer direction="column">
          {repairSummary && repairDetails ? (
            <>
              {isMobile ? <NavigationHeader /> : null}
              <Container className="upper-info">
                <Container id="Details" direction="column">
                  <GridItem>
                    <Container>
                      <BackButton text="Back to List" />
                    </Container>
                  </GridItem>
                  <StyledContainer>
                    <GridItem xs={6} sm={6} md="auto">
                      <HeaderContainer>
                        <Heading>
                          {repairSummary?.vehicleRegistration ?? ''}
                        </Heading>
                      </HeaderContainer>
                    </GridItem>
                    <GridItem xs={6} sm={6} md="auto" className="status">
                      {!repairDetails.formalStatus ||
                      !repairDetails.formalStatusDescription ? (
                        ''
                      ) : (
                        <ItemContainer>
                          <FormalStatusField
                            status={repairDetails.formalStatus}
                            dtgOnSite={dayjs(repairDetails.dtgOnSite).toDate()}
                            statusDescription={
                              repairDetails.formalStatusDescription
                            }
                          />
                        </ItemContainer>
                      )}
                    </GridItem>
                    <div className="divider"></div>
                    <Permits permit={Permit.ALLOW_MANUAL_UPDATES}>
                      {repairSummary.repairer?.requiresManualRepairUpdate && (
                        <GridItem xs={12} sm={'auto'} className="buttons-item">
                          <ManualUpdateButton onClick={navigateToManualUpdate}>
                            <Edit className="button-image" />
                            Manual Update
                          </ManualUpdateButton>
                        </GridItem>
                      )}
                    </Permits>
                    {isTotalLossRoute && (
                      <Permits permit={Permit.CORE_TOTALLOSS}>
                        <GridItem xs={12} sm={'auto'} className="buttons-item">
                          <AssignEngineerButton
                            onClick={() => setIsAssignEngineerOpen(true)}
                          >
                            <AssignEngineerSvg className="button-image" />
                            Assign Engineer
                          </AssignEngineerButton>
                        </GridItem>
                      </Permits>
                    )}
                    <Permits permit={Permit.CONTACT_REPAIRER}>
                      <GridItem xs={12} sm={'auto'} className="buttons-item">
                        <ContactButton
                          onClick={() => setIsContactRepairerOpen(true)}
                        >
                          <ContactRepairerSvg className="button-image" />
                          Contact Repairer
                        </ContactButton>
                      </GridItem>
                    </Permits>
                    <Permits permit={Permit.CONTACT_OWNER}>
                      <GridItem xs={12} sm={'auto'} className="buttons-item">
                        <ContactButton
                          onClick={() => setIsContactOwnerOpen(true)}
                        >
                          <PersonIcon className="button-image" />
                          Contact Owner
                        </ContactButton>
                      </GridItem>
                    </Permits>
                  </StyledContainer>
                  <GridItem className="quick-details">
                    <QuickDetails
                      data={mapQuickDetails(repairSummary)}
                      dtgUpdated={repairDetails?.dtgUpdated}
                    />
                  </GridItem>
                </Container>
                <GridItem id="Milestones" className="milestones">
                  <MilestoneList
                    data={mapMilestoneDetails(repairSummary, repairDetails)}
                  />
                </GridItem>
              </Container>
              <Container className="bottom-info">
                <GridItem
                  md={3}
                  xs={12}
                  css={css`
                    padding-right: 12px;
                  `}
                >
                  <InfoList
                    id="Claim"
                    data={mapClaimInformation(repairSummary, repairDetails)}
                    title="Claim Information"
                  />
                  <InfoList
                    data={mapMobilityInformation(repairSummary, repairDetails)}
                    title="Mobility"
                  />
                </GridItem>
                {slas.length > 0 && (
                  <SLAGridItem id="SLAs" md={4} xs={12}>
                    <ServiceLegal data={slas} />
                  </SLAGridItem>
                )}
                <ActivitiesGridItem
                  id="Activities"
                  md={slas.length > 0 ? 5 : 9}
                  xs={12}
                >
                  <ActivityList
                    data={activities}
                    onAddClick={() => setIsAddActivityOpen(true)}
                  />
                </ActivitiesGridItem>
              </Container>
            </>
          ) : null}
          <ContactRepairerModal
            isOpen={isContactRepairerOpen}
            repairId={repairId}
            onClose={() => {
              setIsContactRepairerOpen(false);
              refetchClaimSummary();
            }}
          />
          <ContactOwnerModal
            isOpen={isContactOwnerOpen}
            repairId={repairId}
            onClose={() => {
              setIsContactOwnerOpen(false);
              refetchClaimSummary();
            }}
          />
          {isAssignEngineerOpen && (
            <AssignEngineerModal
              repairId={repairId}
              isOpen={isAssignEngineerOpen}
              onClose={() => setIsAssignEngineerOpen(false)}
              onAdded={() => {
                setIsAssignEngineerOpen(false);
                refetchClaimSummary();
              }}
            />
          )}
          {repairSummary?.repairCode && (
            <AddActivityModal
              isOpen={isAddActivityOpen}
              repairCode={repairSummary?.repairCode}
              onClose={() => setIsAddActivityOpen(false)}
              onAdded={() => {
                setIsAddActivityOpen(false);
                refetchClaimSummary();
              }}
            />
          )}
        </MainContainer>
      )}
    </VehicleContainer>
  );

  function navigateToManualUpdate() {
    const route = generatePath(ROUTES.manualUpdate, {
      repairId,
      repairCode,
      search,
    });
    history.push(route);
  }
}

const VehicleContainer = styled.div`
  flex: 1;
`;

const ContactButton = styled(SecondaryButton)`
  width: 159px;
  height: 40px;
  margin-right: 8px;
  background: #f1f8fc;
  border: 1px solid #f1f8fc;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.border.radius};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.hover};
  }
  :active {
    background-color: ${({ theme }) => theme.palette.primary.active};
    border-color: ${({ theme }) => theme.palette.primary.activeBorder};
    color: ${({ theme }) => theme.palette.primary.activeText};
    path,
    circle {
      fill: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
`;
const ManualUpdateButton = ContactButton;
const AssignEngineerButton = ContactButton;

const MainContainer = styled(Container)`
  & .upper-info {
    background: ${({ theme }) => theme.palette.background.default};
    padding: ${({ theme }) => `0  ${theme.padding.xl}`};
  }

  & .bottom-info {
    padding: ${({ theme }) => theme.padding.xl};
    background-color: ${({ theme }) => theme.palette.contact.primary};
  }

  & .divider {
    display: flex;
    flex: 1;
  }

  & .buttons-item {
    display: flex;
    flex-shrink: 0;

    @media (max-width: ${({ theme }) =>
        `${theme.breakpoints.values.sm - 1}px`}) {
      margin-top: 8px;
    }

    & .button-image {
      margin-right: 8px;
    }
  }

  & .status {
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding-left: ${({ theme }) => theme.margin.s};
    margin-top: ${({ theme }) => theme.margin.s};
    padding-right: ${({ theme }) => theme.margin.s};
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      padding-right: 0px;
    }
  }

  & .quick-details {
    margin-top: ${({ theme }) => theme.margin.xl};
  }

  & .milestones {
    margin-top: ${({ theme }) => theme.margin.s};
    margin-bottom: ${({ theme }) => theme.margin.l};
  }
`;

const HeaderContainer = styled('div')`
  margin-top: 6px;
`;

const ItemContainer = styled(Container)`
  justify-content: flex-end;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
`;

const SLAGridItem = styled(GridItem)`
  padding: 0 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0px;
  }
`;

const ActivitiesGridItem = styled(GridItem)`
  padding-left: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0px;
  }
`;
