import styled from '@emotion/styled';
import { FormHelperText, InputAdornment } from '@mui/material';
import { PrimaryDateTimePicker } from 'components/DatePicker';
import InputSelect, { SelectOption } from 'components/InputSelect';
import SearchIcon from '@mui/icons-material/Search';
import { Modal } from 'components/Modal';
import { Label, RequiredLabel } from 'components/PageLayout';
import { TextArea } from 'components/TextArea';
import { TextField } from 'components/TextField';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  MainModalContainer,
  ModalButton,
  ModalInputContainer,
} from 'components/ModalLayout';
import { useEngineers, useUpdateEngineer } from 'pages/hooks';
import { GrayBackdrop, Loader } from 'components/Loader';
import { useState } from 'react';
import { Engineer } from 'api/resources/models/AutoGenerated';
import PrimaryToast from 'components/PrimaryToast';
import { dateToString } from 'pages/ManualUpdate/form';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';

const validationSchema = z.object({
  engineerInspectionDTG: z.date({
    required_error: 'Inspection time is required',
    invalid_type_error: 'Inspection time is required',
  }),
  engineerName: z.string({ required_error: 'Engineer is required' }),
  notes: z.string().max(2000, { message: 'Must be max 2 000 characters long' }).optional(),
});

interface Props {
  repairId: number;
  isOpen: boolean;
  onClose: () => void;
  onAdded: () => void;
}

export default function AssignEngineerModal({
  isOpen,
  onClose,
  onAdded,
  repairId,
}: Props) {
  const { engineers, isEngineersLoading } = useEngineers({});
  const { updateEngineer, isUpdating } = useUpdateEngineer();
  const isBusy = isEngineersLoading || isUpdating;
  const [toastMessage, setToastMessage] = useState('');
  const [isOpenToast, setIsOpenToast] = useState(false);
  const engineerOptions = engineers.map((engineer, index) => ({
    id: index,
    text: `${engineer.firstName} ${engineer.surname}`,
    value: engineer.userName ?? '',
  }));

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Engineer>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      engineerInspectionDTG: undefined,
      engineerName: undefined,
      notes: undefined,
    },
  });

  const onSubmit = handleSubmit((data) => {
    updateEngineer({
      ...data,
      repairId: repairId,
      engineerInspectionDTG: dateToString(new Date(data.engineerInspectionDTG)),
    })
      .then(() => {
        onAdded();
        reset();
      })
      .catch(() => {
        setToastMessage(errorMessages.assignEngineerFailed);
        setIsOpenToast(true);
      });
  });

  return (
    <Modal isOpen={isOpen} title="Assign Engineer" onClose={onClose}>
      <form onSubmit={onSubmit}>
        <MainModalContainer direction="column">
          <GrayBackdrop open={isBusy}>
            <Loader />
          </GrayBackdrop>
          <RequiredLabel>Engineer</RequiredLabel>
          <ModalInputContainer>
            <Controller
              render={({ field: { onChange, value } }) => (
                <InputSelect
                  value={engineerOptions.find(
                    (engineer) => engineer.value === value
                  )}
                  onChange={(engineer: SelectOption | null) =>
                    onChange(engineer?.value)
                  }
                  options={engineerOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Search"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
              name="engineerName"
              control={control}
            />
            {!!errors.engineerName && (
              <SelectHelperText>{errors.engineerName.message}</SelectHelperText>
            )}
          </ModalInputContainer>
          <ModalInputContainer direction="column">
            <RequiredLabel>Inspection Time</RequiredLabel>
            <Controller
              render={({ field: { value, onChange } }) => (
                <PrimaryDateTimePicker
                  selected={value ? new Date(value) : null}
                  onChange={onChange}
                />
              )}
              name="engineerInspectionDTG"
              control={control}
            />
            {!!errors.engineerInspectionDTG && (
              <SelectHelperText>
                {errors.engineerInspectionDTG.message}
              </SelectHelperText>
            )}
          </ModalInputContainer>
          <ModalInputContainer>
            <Label>Note</Label>
            <Controller
              render={({ field }) => (
                <StyledTextArea {...field} value={field.value ?? undefined} />
              )}
              name="notes"
              control={control}
            />
             {!!errors.notes && (
              <SelectHelperText>
                {errors.notes.message}
              </SelectHelperText>
            )}
          </ModalInputContainer>
          <ModalButton type="submit">Submit</ModalButton>
        </MainModalContainer>
      </form>
      <PrimaryToast
        message={toastMessage}
        isOpen={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        severity="error"
      />
    </Modal>
  );
}

const StyledTextArea = styled(TextArea)`
  width: 100%;
  height: 84px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  box-sizing: unset;
`;

const SelectHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.messages.error};
`;
