export const errorMessages = {
  accountProfileCreated: 'Account profile creation failed',
  accountProfileUpdated: 'Account profile update failed',
  companyCreated: 'Company creation failed',
  companyUpdated: 'Company update failed',
  dataAccessCodes: 'Unable to find any profiles with the search parameters',
  repairDetailsUpdate: 'Failed to save repair details.',
  saveChanges: 'Please save your changes',
  userCreation: `User creation failed`,
  userUpdate: 'User account update failed',
  workProviderAdded: 'Adding Work Provider failed!',
  workProviderRemoved: 'Removing Work Provider failed!',
  assignEngineerFailed: 'Failed to assign engineer. Something went wrong',
  addActivity: 'Failed to add activity. Something went wrong',
  serviceAccounts: 'Unable to fetch any service accounts',
  repairersList: 'Unable to fetch any Repairer Group',
  streamsList: 'Unable to fetch any Streams',
  schemeCodes: 'Unable to fetch any Scheme Code',
  repairSites: 'Unable to fetch any Repair Sites',
  siteCodes: 'Unable to fetch any Sites',
  reports: (reportName: string) =>
    `Unable to fetch the ${reportName}, please try again!`,
  default: 'Something went wrong',
  fnolFirstActivityDateError: 'First activity date is empty, please create an activity before saving the Repair',
};
