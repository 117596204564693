import { Row } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import {
  useGetNPSDetailV4Report,
  useGetDelimitedRepairers,
  useGetNPSschemecodes,
  convertToDate,
} from 'pages/hooks';
import { FormEvent, useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';

import { ReportsLoader } from 'components/ReportsLoader';
import { SelectOption } from 'components/InputSelect';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import { NPSDetailForm } from 'components/ReportForms/NPSDetailForm';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { NPS_DETAIL_REPORT, NPS_DETAIL_REPORT_MESSAGE, columns, dateColumnsName, nPSScoreOptions, tableColumnExtensions } from './constants';
import { customizeCell, valuesConverter } from './helpers';
import { reportLogger } from '../reportLogger';

export function NPSDetailV4({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const urlParams = {
    startDate: urlSearchParams.get('startDate'),
    endDate: urlSearchParams.get('endDate'),
    nPSScore: urlSearchParams.get('nPSScore'),
    repairSites: urlSearchParams.get('repairSiteList'),
    schemeCodes: urlSearchParams.get('schemaCodeList'),
  };
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );
  const [nPSScore, setNPSScore] = useState<string>(urlParams.nPSScore ?? '');
  const [repairSites, setRepairSites] = useState<SelectOption[]>([]);
  const [schemeCodes, setSchemeCodes] = useState<SelectOption[]>([]);

  const queryEnabled =
    !!urlParams.startDate &&
    !!urlParams.endDate &&
    !!urlParams.repairSites &&
    !!urlParams.schemeCodes &&
    !!urlParams.nPSScore;

  const { delimitedRepairersList, isLoading } = useGetDelimitedRepairers(
    (error) => handleError(error, 'isRepairersList')
  );
  const { NPSschemeCodes, isFetching } = useGetNPSschemecodes((error) =>
    handleError(error, 'isSchemeCodes')
  );

  const { nPSDetailV4ReportData, refetchReport, isReportFetching } =
    useGetNPSDetailV4Report(
      {
        startDate: urlParams.startDate,
        endDate: urlParams.endDate,
        repairSites: urlParams.repairSites,
        schemeCodes: urlParams?.schemeCodes,
        nPSScore: urlParams?.nPSScore,
        groupingValue: '',
        reportGrouping: 'ignore',
      },
      queryEnabled,
      (error) => handleError(error, 'isReport')
    );

  const rows = useMemo<Row>(
    () => nPSDetailV4ReportData,
    [nPSDetailV4ReportData]
  );

  const repairerSitesOptions = delimitedRepairersList?.map((item, index) => {
    return {
      id: index,
      value: item.siteCode,
      text: item.repairerName,
    };
  });

  const schemeCodesOptions = NPSschemeCodes?.map((item, index) => {
    return {
      id: index,
      value: item.schemeCode,
      text: item.schemeCode,
    };
  });

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryEnabled]);

  const isOptionsLoading = isLoading || isFetching;

  useEffect(() => {
    if (urlParams.repairSites != repairSites?.map((e) => e.value)?.join(','))
      setRepairSites(
        repairerSitesOptions?.filter((e) =>
          urlParams.repairSites?.split(',').includes(e.value)
        ) || []
      );
    if (urlParams.schemeCodes != schemeCodes?.map((e) => e.value)?.join(','))
      setSchemeCodes(
        schemeCodesOptions?.filter((e) =>
          urlParams.schemeCodes?.split(',').includes(e.value)
        ) || []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading]);

  return (
    <MainPageContainer
      isLoading={isReportFetching}
      direction="column"
      wrap="nowrap"
    >
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={NPS_DETAIL_REPORT}
          infoMessage={NPS_DETAIL_REPORT_MESSAGE}
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
            <NPSDetailForm
              handleSubmit={handleSubmit}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isDisabled={isReportFetching}
              nPSScore={nPSScore}
              setNPSScore={setNPSScore}
              nPSScoreOptions={nPSScoreOptions}
              repairSites={repairSites}
              setRepairSites={setRepairSites}
              repairerSitesOptions={repairerSitesOptions}
              schemeCodes={schemeCodes}
              setSchemeCodes={setSchemeCodes}
              schemeCodesOptions={schemeCodesOptions}
            />
        )}
      </UpperInfoContainer>
      {!isReportFetching && rows && rows?.length === 0 && (
        <NoReportDataMessage />
      )}
      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          isLoading={isReportFetching}
          columns={columns || []}
          title="NPSDetailReport04"
          DateTypeProvider={DateTypeProvider}
          customizeCellExport={customizeCell}
          headerTitleForSheet="NPS Detail Report V4"
          tableColumnExtensions={tableColumnExtensions}
          dateColumnsName={dateColumnsName}
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          pdfOrientation='l'
          showAdditionalExportOptions
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParams.startDate == dateToString(startDate) &&
      urlParams.endDate == dateToString(endDate) &&
      urlParams.schemeCodes == schemeCodes?.map((e) => e.value)?.join(',') &&
      urlParams.repairSites == repairSites?.map((e) => e.value)?.join(',') &&
      urlParams.nPSScore == nPSScore
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (
      !!startDate &&
      !!endDate &&
      !!nPSScore &&
      schemeCodes.length > 0 &&
      repairSites.length > 0
    ) {
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            schemaCodeList: schemeCodes?.map((e) => e.value)?.join(','),
            repairSiteList: repairSites?.map((e) => e.value)?.join(','),
            nPSScore: nPSScore,
          },
          reportPath
        )
      );
    }
  }

  function handleError(
    error: AxiosError,
    type: 'isReport' | 'isRepairersList' | 'isSchemeCodes'
  ) {
    reportLogger(error);
    setErrorMessage(
      type == 'isRepairersList'
        ? errorMessages.repairSites
        : type == 'isSchemeCodes'
        ? errorMessages.schemeCodes
        : errorMessages.reports('NPS Detail Report V4')
    );
    setToast(true);
  }
}

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  margin-bottom: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
