import { Row } from '@devexpress/dx-react-grid';
import { MainPageContainer, UpperInfoContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { convertToDate, useNargControlSheetV5, useSchemeCodes } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { dateToString, formatDate } from 'utils/helpers';
import { WorkProviderScheme } from 'api/resources/models/AutoGenerated';
import { ReportProps } from '../hooks';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { AxiosError } from 'axios';
import { ReportsLoader } from 'components/ReportsLoader';
import { SelectOption } from 'components/InputSelect';
import { NoReportDataMessage } from 'components/ReportsComponents/NoReportDataMessage';
import { ReportGridContainer } from 'components/ReportGridContainer';
import { ReportHeader } from 'components/ReportsComponents/ReportHeader';
import {
  dateColumnsName,
  reportColumns,
  tableColumnExtensions,
} from './constants';
import { customizeCell, valuesConverter } from './helpers';
import { ReportBackButton } from 'components/ReportsComponents/ReportBackButton';
import { NargSearchForm } from 'components/ReportForms/NargSearchForm';
import { DateTypeProvider } from 'components/ReportsComponents/DateTypeProvider';
import { reportLogger } from '../reportLogger';

export function NargControlSheetV5({
  reportPath,
  setErrorMessage,
  setToast,
}: ReportProps) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    endDate: urlParams.get('endDate'),
    startDate: urlParams.get('startDate'),
    schemaCodeList: urlParams.get('schemaCodeList'),
  };

  const queryEnabled =
    !!urlParamsValue.startDate &&
    !!urlParamsValue.endDate &&
    !!urlParamsValue.schemaCodeList;

  const { isSchemeCodesLoading, schemeCodes } = useSchemeCodes((error) =>
    handleError(error, 'isSchemeCodes')
  );
  const { reportData, refetchReport, isReportFetching } = useNargControlSheetV5(
    {
      endDate: urlParamsValue.endDate,
      startDate: urlParamsValue.startDate,
      schemaCodeList: urlParamsValue.schemaCodeList,
    },
    queryEnabled,
    (error) => handleError(error, 'isReport')
  );

  const rows = useMemo<Row>(() => reportData, [reportData]);
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) || null
  );
  const [schemaCodeList, setSchemaCodeList] = useState<SelectOption[]>([]);
  const options = schemeCodes?.map((e: WorkProviderScheme, i: number) => {
    return { id: i, value: e.schemeCode || '', text: e.schemeCode || '' };
  });
  const isBusy = isReportFetching;
  const isOptionsLoading = isSchemeCodesLoading;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    urlParamsValue.schemaCodeList,
  ]);

  useEffect(() => {
    if (urlParamsValue.schemaCodeList?.length != schemaCodeList?.length)
      setSchemaCodeList(
        options?.filter((e) =>
          urlParamsValue.schemaCodeList?.split(',').includes(e.value)
        ) || []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsLoading]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <ReportBackButton />
        <ReportHeader
          reportName={'NARG Control Sheet V5 Report'}
          infoMessage={
            'Displays the NARG Control Sheet V5 report for the date range provided'
          }
        />
        {isOptionsLoading ? (
          <ReportsLoader loadingRowsCount={2} />
        ) : (
          <NargSearchForm
              handleSubmit={handleSubmit}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              schemaCodeList={schemaCodeList}
              setSchemaCodeList={setSchemaCodeList}
              isDisabled={isBusy}
              options={options}
          />
        )}
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && <NoReportDataMessage />}

      <ReportGridContainer direction="column">
        <ReportGrid
          rows={rows}
          columns={reportColumns}
          isLoading={isBusy}
          title="nargControlSheetV5"
          dateColumnsName={dateColumnsName}
          DateTypeProvider={DateTypeProvider}
          customizeCellExport={customizeCell}
          tableColumnExtensions={tableColumnExtensions}
          headerTitleForSheet="NARG Control Sheet V5 Report"
          reportHeaderTagLine={`Report produced on ${formatDate(
            new Date()
          )} for period ${formatDate(startDate)} to ${formatDate(endDate)}`}
          valuesConverter={valuesConverter}
        />
      </ReportGridContainer>
    </MainPageContainer>
  );

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (
      urlParamsValue.startDate == dateToString(startDate) &&
      urlParamsValue.endDate == dateToString(endDate) &&
      urlParamsValue.schemaCodeList ==
      schemaCodeList?.map((e) => e.value)?.join(',')
    )
      refetchReport();
    else navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate && schemaCodeList?.length > 0)
      history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            schemaCodeList: schemaCodeList?.map((e) => e.value)?.join(','),
          },
          reportPath
        )
      );
    else return;
  }

  function handleError(error: AxiosError, type: 'isReport' | 'isSchemeCodes') {
    reportLogger(error);
    setErrorMessage(
      type == 'isReport'
        ? errorMessages.reports('NARG Control Sheet V5 Report')
        : errorMessages.schemeCodes
    );
    setToast(true);
  }
}
