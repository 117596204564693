import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
    Label,
} from 'components/PageLayout';
import { useInMobile } from 'core/Theming/Device';
import { Select } from 'components/Select';
import NumberField from 'components/NumberField';
import { MultiSelectNew } from 'components/MultiSelectNew';
import { CustomForm } from 'components/ReportsComponents/CustomForm';
import { NPSSummaryFormProps } from './types';
import { renderSearchButtonText, renderSearchIcon } from 'pages/ReportsDetails/helpers';

export function NPSSummarySearchForm({
    handleSubmit,
    endDate,
    setEndDate,
    repairSites,
    setRepairSites,
    schemeCodes,
    setSchemeCodes,
    noOfDateGroups,
    setNoOfDateGroups,
    reportGroupingOptions,
    repairSiteOptions,
    siteCodeOptions,
    reportingGroup,
    setReportingGroup,
    isDisabled,
}: NPSSummaryFormProps) {
    const isMobile = useInMobile();

    return (
        <CustomForm onSubmit={handleSubmit}>
            <CustomGridContainer direction="row" alignItems="center">
                <InputButton md={3} sm={3} xs={12}>
                    <Label>End Date</Label>
                    <DatePickerField
                        value={endDate}
                        isDisabled={isDisabled}
                        onChange={(date) => setEndDate(date)}
                    />
                </InputButton>
                <InputButton lg={3} md={3} sm={4} xs={12}>
                    <Label>No. of Weeks/Months/Years (numbers only)</Label>
                    <NumberField
                        disabled={isDisabled}
                        value={noOfDateGroups}
                        onChange={(data) => setNoOfDateGroups(Number(data))}
                    />
                </InputButton>
                <InputButton lg={3} md={3} sm={4} xs={12}>
                    <Label>Report Grouping</Label>
                    <Select
                        disabled={isDisabled}
                        selectedId={
                            reportGroupingOptions.find(
                                (status) => status.value === reportingGroup
                            )?.id
                        }
                        onChange={(status) => setReportingGroup(status.value)}
                        options={reportGroupingOptions}
                    />
                </InputButton>
                <GridItem md={1} sm={1} xs={2}>
                    <Button
                        type="submit"
                        disabled={
                            isDisabled ||
                            !endDate ||
                            !noOfDateGroups ||
                            !reportingGroup ||
                            repairSites?.length == 0 ||
                            schemeCodes?.length == 0
                        }
                    >
                        {renderSearchIcon(isMobile)}
                        {renderSearchButtonText(isMobile)}
                    </Button>
                </GridItem>
            </CustomGridContainer>
            <CustomGridContainer direction="row" alignItems="center">
                <InputButton md={3} sm={3} xs={12}>
                    <Label>Repair Sites</Label>
                    <MultiSelectNew
                        disabled={isDisabled}
                        options={repairSiteOptions}
                        selectedOptions={repairSites}
                        onChange={(value) => setRepairSites(value || [])}
                    />
                </InputButton>
                <InputButton md={3} sm={3} xs={12}>
                    <Label>Scheme Codes</Label>
                    <MultiSelectNew
                        disabled={isDisabled}
                        options={siteCodeOptions}
                        selectedOptions={schemeCodes}
                        onChange={(value) => setSchemeCodes(value || [])}
                    />
                </InputButton>
            </CustomGridContainer>
        </CustomForm>
    );
}

const InputButton = styled(GridItem)`
    padding-right: ${({ theme }) => theme.margin.s};
    max-width: 330px !important;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        margin - bottom: 6px;
    }
    & svg {
        width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
    }
  `;

const CustomGridContainer = styled(Container)`
    margin-bottom: ${({ theme }) => theme.margin.m};
  `;

const Button = styled(PrimaryButton)`
    min-width: 40px;
    font-size: ${({ theme }) => theme.fontSize.s};
    height: 40px;
    padding: 12px;
    margin-top: 20.5px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      width: 100%;
    }
  `;


