import { SortOrder } from 'api/resources/models/AutoGenerated';
import { SortObject } from 'components/types';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';

dayjs.extend(UTC);

export function getActivity(date: Date | null | undefined) {
  const activityDate =
    date && dayjs(date).isAfter(Date.now()) ? Date.now() : date;

  const activityDateDiff = date ? dayjs().diff(dayjs(date), 'd') : 0;

  return {
    activity: activityDate ? dayjs(activityDate).fromNow(true) : null,
    isOlder: activityDateDiff > 3,
  };
}

export const formatDateTime = (date?: Date | null | string) => {
  return date ? dayjs(date).format('DD/MM/YYYY HH:mm:ss') : '';
};
export const formatDate = (date?: Date | null | string) => {
  return date ? dayjs(date).format('DD/MM/YYYY') : '';
};
export const formatCurrency = (amount: number | null) => {
  const formattedAmount = amount?.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
  return formattedAmount;
};
export const formatDateCustomFormat = (
  date?: Date | null | string,
  format = 'DD/MM/YYYY HH:mm:ss'
) => {
  return date ? dayjs(date).format(format) : '';
};
export const reportAPIEndDate = (date: string | null) => {
  return dayjs(date).format('YYYY-MM-DDT23:59:00');
};

export const formatAPIStartDate = (date: string | null) => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const formatAPIEndDate = (date: string | null) => {
  return dayjs(date).endOf('month').format('YYYY-MM-DDT23:59:00');
};

export const dateToString = (date: Date | null) =>
  dayjs(date).format('YYYY-MM-DD');

export const isValidDate = (date: Date | null) => dayjs(date).isValid();

export const hasOnlySpaces = (value: string) => value.trim() === '';

export const getSortObject = (
  prevValue: SortObject,
  sortValue: string
): SortObject => {
  return {
    sortString:
      prevValue.sortString !== sortValue ? sortValue : prevValue.sortString,
    sortOrder:
      prevValue.sortString !== sortValue
        ? SortOrder.Ascending
        : prevValue.sortOrder !== SortOrder.Ascending
        ? SortOrder.Ascending
        : SortOrder.Descending,
  };
};

export function isSortOrder(value: string): value is SortOrder {
  return Object.values(SortOrder).includes(value as SortOrder);
}

/**
 *
 * setRecursiveTimeout should be used instead of standard setTimeout
 * if delay number is too big (higher than 2,147,483,647 ms (about 24.8 days)).
 * https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
 * Function takes delay parameter compares it with maximum 32-bit integer value-1 (maxDelay)
 * and if higher then runs standard setTimeout function with callback
 * which runs setRecursiveTimeout function with delay value minus maxDelay value
 * this will be repeated untill delay value becomes less than maxDelay
 * after that standard setTimeout will be called with original functionHandler to execute
 *
 */
export function setRecursiveTimeout(
  functionHandler: () => void,
  delay: number
) {
  //Here we calculate max 32-bit integer value with -1
  //https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
  const maxDelay = Math.pow(2, 31) - 1;

  if (delay > maxDelay) {
    const _delay = delay - maxDelay;
    return window.setTimeout(function () {
      setRecursiveTimeout.apply(undefined, [functionHandler, _delay]);
    }, maxDelay);
  }

  return window.setTimeout(functionHandler, delay);
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
