import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CycleTimeTable } from './CycleTimeTable';
import { CycleTimeBreadcrumbs } from './CycleTimeBreadcrumbs';
import { useCycleTimeSummaryDetails } from 'pages/hooks';
import { cycleTimeHeaders, headerButtonsOptions, summaryTypeMapToName } from './constants';
import { Button } from '@mui/material';
import { SortOrder } from 'api/resources/models/AutoGenerated';
import { formatAPIEndDate, formatAPIStartDate } from 'utils/helpers';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import { SortObject } from 'components/types';
import { getSortObject } from 'components/DataGrid';
import { orderBy } from 'lodash';
import { convertCycleTimeData } from './helpers';
import { CycleTimeResponse, TableProps } from './types';
import { createCycleTimeUrl } from 'core/routes';


export function RepairerSummary({ handleError }: TableProps) {
    const location = useLocation();
    const history = useHistory();
    const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

    const urlParamsValue = useMemo(() => {
        const urlParams = new URLSearchParams(location.search);

        return {
            startDate: urlParams.get('startDate'),
            endDate: urlParams.get('endDate'),
            summaryType: urlParams.get('summaryType'),
            siteCode: urlParams.get('siteCode'),
            stream: urlParams.get('stream'),
            manufacturer: urlParams.get('manufacturer'),
            prevSummary: urlParams.get('prevSummary'),
            page: urlParams.get('page'),
            sort: urlParams.get('sort'),
            sortOrder: urlParams.get('sortOrder'),
            breadcrumbs: urlParams.get('breadcrumbs'),
        }

    }, [location.search])

    const [data, setData] = useState<CycleTimeResponse[] | undefined>();
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
    const [headers, setHeaders] = useState<{ id: keyof CycleTimeResponse, label: string }[]>([]);
    const [page, setPage] = useState(Number(urlParamsValue.page));

    const [sortObject, setSortObject] = useState<SortObject>({
        sortString: urlParamsValue.sort ?? '',
        sortOrder: SortOrder.Descending,
    });

    useEffect(() => {
        const updatedBreadcrumbs: string[] = [];
        const previousSummaryType = urlParamsValue.prevSummary ? urlParamsValue.prevSummary.split(",") : [];
        const breadcrumbsFromUrl = urlParamsValue.breadcrumbs ? urlParamsValue.breadcrumbs.split(",") : [];

        if (breadcrumbsFromUrl.length !== 0) {
            breadcrumbsFromUrl.forEach(item => {
                if (item in summaryTypeMapToName) {
                    updatedBreadcrumbs.push(summaryTypeMapToName[item]);
                } else {
                    updatedBreadcrumbs.push(item)
                }
            })
        } else {
            urlParamsValue.summaryType && updatedBreadcrumbs.push(summaryTypeMapToName[urlParamsValue.summaryType]);
        }

        setBreadcrumbs(updatedBreadcrumbs);

        const actionButtons = headerButtonsOptions
            .filter(option => option.value !== urlParamsValue.summaryType)
            .filter(option => {
                if (!previousSummaryType.includes(option.value)) return option
            })
            .map(({ id, label }) => ({ id, label }));

        const fullNameTitle = (urlParamsValue?.summaryType && summaryTypeMapToName[urlParamsValue.summaryType]) ?? 'Repairer';
        const updatedHeaders = [...actionButtons, ...cycleTimeHeaders(true, fullNameTitle !== 'Repairer' ? fullNameTitle : 'Repairer Name')]

        setHeaders(updatedHeaders as { id: keyof CycleTimeResponse, label: string }[])

    }, [urlParamsValue.breadcrumbs, urlParamsValue.prevSummary, urlParamsValue.summaryType]);

    const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate;

    const params = useMemo(() => {
        return {
            siteCode: urlParamsValue.siteCode ?? '%',
            manufacturer: urlParamsValue.manufacturer ?? '%',
            stream: urlParamsValue.stream ?? '%',
            startDate: formatAPIStartDate(urlParamsValue.startDate),
            endDate: formatAPIEndDate(urlParamsValue.endDate),
            summaryType: Number(urlParamsValue.summaryType),
            dataAccessProfileId: null,
        }

    }, [urlParamsValue])

    const { summaryDetailsData, isDataFetching } = useCycleTimeSummaryDetails(
        params,
        queryEnabled,
        handleError
    );

    const sortSummaryDetails = useCallback(
        (sortObj: SortObject) => {
            if (summaryDetailsData) {
                const currentListed = page * itemsPerPage;
                const orderedList = orderBy(
                    convertCycleTimeData(summaryDetailsData),
                    sortObj.sortString,
                    sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
                );

                return orderedList?.slice(currentListed, currentListed + itemsPerPage);
            } else return [];
        }, [itemsPerPage, page, summaryDetailsData]);

    const sort = (sortValue: string) => {
        setSortObject((prevValue) => {
            const sortObj = getSortObject(sortValue, prevValue);
            const sortedData = sortSummaryDetails(sortObj);
            setData(sortedData)
            return sortObj;
        });
    }

    useEffect(() => {
        if (summaryDetailsData) {
            const sortedData = sortSummaryDetails(sortObject);
            return setData(sortedData)
        }
    }, [sortObject, sortSummaryDetails, summaryDetailsData]);


    function handleSummaryTypeChange(shortName: string, fullName: string, summaryType: string | number | null) {
        let updatedSiteCode = urlParamsValue.siteCode ?? '%';
        let updatedManufacturer = urlParamsValue.manufacturer ?? '%';
        let updatedStream = urlParamsValue.stream ?? '%';

        switch (urlParamsValue.summaryType) {
            case '1':
                updatedSiteCode = shortName;
                break;
            case '2':
                updatedManufacturer = shortName;
                break;
            case '3':
                updatedStream = shortName;
                break;
        }

        const prevSummary = urlParamsValue.prevSummary ? `${urlParamsValue.prevSummary},${urlParamsValue.summaryType}` : urlParamsValue.summaryType;
        const urlBreadcrumbs = urlParamsValue.breadcrumbs ? [urlParamsValue.breadcrumbs, fullName] : [urlParamsValue.summaryType, fullName];

        const newRoute = createCycleTimeUrl(
            urlParamsValue.startDate,
            urlParamsValue.endDate,
            summaryType,
            updatedSiteCode,
            updatedManufacturer,
            updatedStream,
            prevSummary,
            urlBreadcrumbs.toString()
        )

        history.push(newRoute);
        setPage(0);
    }

    function renderCustomCell({
        baseRender,
        headerId,
        row,
        value,
    }: {
        baseRender: () => JSX.Element;
            headerId: keyof CycleTimeResponse;
            row: CycleTimeResponse;
            value: string | number | null;
    }) {
        const fetchDataHeaders = ['repairer', 'manufacturer', 'stream', 'details']
        if (fetchDataHeaders.includes(headerId)) {
            return (
                <Button variant="contained" color="primary" onClick={() => handleSummaryTypeChange(row.shortName, row.fullName, value)} style={{ maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px' }}>
                    +
                </Button>
            );
        }

        return baseRender();
    }

    const totalCount = useMemo(() => summaryDetailsData?.length, [summaryDetailsData?.length]);

    return (
        <>
            {queryEnabled ?
                (<CycleTimeTable
                    title={'Summary Details'}
                    noDataMessage={`Sorry, we couldn't find any Repairer Summary data.`}
                    headers={headers}
                    data={data}
                    isLoading={isDataFetching}
                    customCellRender={renderCustomCell}
                    currentPage={page}
                    onPageChange={setPage}
                    totalCount={totalCount}
                    onItemsPerPageChange={setItemsPerPage}
                    onSort={sort}
                    sortObject={sortObject}
                >
                    <CycleTimeBreadcrumbs breadcrumbs={breadcrumbs} />
                </CycleTimeTable>
                )
                : null
            }
        </>
    );
}