import { InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { Container, GridItem } from 'components/Layout';
import { TextField } from 'components/TextField';
import { Text } from 'components/Typography';
import {
  ISiteCodeOnSuccess,
  useAddAllSiteCodeForFormalCode,
  useAddSiteCodeFormalCodeAssign,
  useGetALLSiteCodeFormalCode,
  useRemoveSiteCodeFormalCodeAsign,
  useSearchWorkProviderFormalCodes,
  useSiteCodeFormalCodePairSearch,
  useSiteCodes,
} from 'pages/hooks';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import PrimaryToast from 'components/PrimaryToast';
import { SearchResult } from './SearchResult';
import { DataAccessReport } from './DataAccessReport';
import { AxiosError } from 'axios';
import { warningMessages } from 'api/resources/responseMessages/warningMessages';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import Skeleton from 'components/Skeleton';

export function DataAccessDetails({
  dataAccessProfileId,
}: {
  dataAccessProfileId: number;
}) {
  const [siteCode, setSiteCode] = useState<SelectOption | null>(null);
  const [formalCode, setFormalCode] = useState<SelectOption | null>(null);
  const [inputFormalCode, setInputFormalCode] = useState<string | null>(null);

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'warning' | 'success' | 'error';
  }>();

  const isPairSearchEnabled = !!siteCode && !!formalCode;
  const searchConfig = !isPairSearchEnabled
    ? 'disabled'
    : {
        dataAccessProfileId,
        siteCode: siteCode?.value ?? '',
        formalCode: formalCode?.value ?? '',
      };

  const { isSiteCodesLoading } = useSiteCodes();
  const { searchWorkProviderFormalCodes, refetchFormalCodes } =
    useSearchWorkProviderFormalCodes(inputFormalCode);

  useEffect(() => {
    if (inputFormalCode) {
      refetchFormalCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFormalCode]);

  const { searchResult, search, isSearching } = useSiteCodeFormalCodePairSearch(
    searchConfig,
    handleError
  );
  const { removeSiteCodeFormalCodeAssign, isRemoving } =
    useRemoveSiteCodeFormalCodeAsign({ onSuccess: onSuccessResponse });
  const { addSiteCodeFormalCodeAssign, isAdding } =
    useAddSiteCodeFormalCodeAssign();
  const { refetchAllSiteCodeFormalCode, isAllSiteCodeLoading } =
    useGetALLSiteCodeFormalCode(dataAccessProfileId);
  const { siteCodeForFormalCode, siteCodeLoading, siteCoderRefetch } =
    useAddAllSiteCodeForFormalCode(formalCode?.value ?? '');

  const isBusy =
    isRemoving ||
    isAdding ||
    isSiteCodesLoading ||
    isSearching ||
    isAllSiteCodeLoading ||
    siteCodeLoading;

  useEffect(() => {
    if (formalCode) siteCoderRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formalCode]);

  return (
    <>
      <Container>
        <UpperNote>
          <b>
            Note: Data access detail assignments take up to 24 hour to take
            effect.
          </b>
        </UpperNote>
      </Container>
      <MainContainer md={12}>
        <Header>
          <Text fontSize="m">Add Data Access Detail</Text>
        </Header>
        {isBusy ? (
          <Container>
            <SkeletonContainer lg={12} wrap={'wrap'}>
              <SkeletonLoader
                variant="rectangular"
                height={56}
                width={323}
                startingPoint={'start'}
              />
              <SkeletonLoader
                variant="rectangular"
                height={56}
                width={323}
                startingPoint={'end'}
              />
            </SkeletonContainer>
          </Container>
        ) : (
          <Container alignItems="flex-end">
            <Item md={4} xs={12}>
              <Label noWrap fontSize="xs">
                Formal/Scheme Code
              </Label>
              <InputSelect
                  options={inputFormalCode ? searchWorkProviderFormalCodes?.map((c, index) => ({
                  id: index,
                  text: c.formalCode ?? '',
                  value: c.formalCode ?? undefined,
                  })) : []}
                value={formalCode}
                onChange={(data: SelectOption | null) => {
                  setFormalCode(data);
                  setSiteCode(null);
                }}
                  onInputChange={(e) => setInputFormalCode(e)}
                  noOptionsText={
                    inputFormalCode ? 'No options' : 'Type to Search...'
                  }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Item>
            <Item md={4} xs={12}>
              <Label fontSize="xs">Site Code</Label>
              <InputSelect
                options={siteCodeForFormalCode?.map(
                  (data: SelectOption | null, index: number) => ({
                    id: index,
                    value: data,
                    text: data,
                  })
                )}
                value={siteCode}
                onChange={setSiteCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Item>
            <Item>
              <Button disabled={!isPairSearchEnabled} onClick={onSearch}>
                Search
              </Button>
            </Item>
          </Container>
        )}
        {searchResult && siteCode?.value && formalCode?.value && (
          <SearchResult
            searchResult={searchResult}
            siteCode={siteCode.value}
            formalCode={formalCode.value}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        )}
      </MainContainer>
      <DataAccessReport />
      {toastState && (
        <PrimaryToast
          message={toastState.message}
          isOpen={toastState.isOpen}
          severity={toastState.severity}
          onClose={() => setToastState({ ...toastState, isOpen: false })}
        />
      )}
    </>
  );

  function onRemove() {
    if (siteCode?.value && formalCode?.value)
      removeSiteCodeFormalCodeAssign({
        dataAccessProfileId,
        siteCode: siteCode?.value,
        formalCode: formalCode?.value,
      });
  }

  function onAdd() {
    if (siteCode?.value && formalCode?.value)
      addSiteCodeFormalCodeAssign({
        dataAccessProfileId,
        siteCode: siteCode?.value,
        formalCode: formalCode?.value,
      }).then((response) => {
        response.status !== 204
          ? setToastState({
              isOpen: true,
              message: warningMessages.addDataAccessCodes(
                siteCode.value || '',
                formalCode.value || ''
              ),
              severity: 'warning',
            })
          : setToastState({
              isOpen: true,
              message: successMessages.addDataAccessCodes(
                siteCode.value || '',
                formalCode.value || ''
              ),
              severity: 'success',
            });
        refetchAllSiteCodeFormalCode();
      });
  }

  function onSuccessResponse({
    response,
    siteCode: siteCodeOnSuccess,
    formalCode: formalCodeOnSuccess,
  }: ISiteCodeOnSuccess) {
    response?.status !== 204
      ? setToastState({
          isOpen: true,
          message: warningMessages.removeDataAccessCodes(
            siteCodeOnSuccess,
            formalCodeOnSuccess
          ),
          severity: 'warning',
        })
      : setToastState({
          isOpen: true,
          message: successMessages.removeDataAccessCodes(
            siteCodeOnSuccess,
            formalCodeOnSuccess
          ),
          severity: 'success',
        });
    refetchAllSiteCodeFormalCode();
  }

  function onSearch() {
    search();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleError(_error: AxiosError) {
    setToastState({
      message: errorMessages.dataAccessCodes,
      isOpen: true,
      severity: 'error',
    });
  }
}

const MainContainer = styled(Container)`
  background-color: ${({ theme }) => theme.palette.primary.skeleton};
  padding: ${({ theme }) => theme.padding.m};
  border-radius: ${({ theme }) => theme.border.radius};
`;

const Header = styled(GridItem)`
  margin-bottom: 12px;
`;

const Label = styled(Text)`
  margin-bottom: ${({ theme }) => theme.padding.xs};
`;

const Button = styled(PrimaryButton)`
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
`;

const Item = styled(GridItem)`
  padding-right: ${({ theme }) => theme.padding.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-bottom: ${({ theme }) => theme.padding.s};
    padding-right: 0px;
  }
`;
const UpperNote = styled(Container)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin: ${({ theme }) => theme.padding.m};
`;

const SkeletonContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SkeletonLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-bottom: ${({ theme }) => `${theme.margin.m}`};
  margin-right: ${({ theme }) => `${theme.margin.m}`};
`;
