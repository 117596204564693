import dayjs, { Dayjs } from 'dayjs';
import { GridDateStatusIcon } from './DateStatusIcon';
import { DateStatus } from './types';
import { SlaFieldContainer } from './SlaFieldContainer';
import { formatDateTime } from 'utils/helpers';

interface Props {
  ecd?: Date;
  completed?: Date;
}

export function EcdDisplay({ ecd, completed }: Props) {
  const now = dayjs();
  const ecdDate: Dayjs | undefined = ecd ? dayjs(ecd) : undefined;
  const dtgCompletedDate: Dayjs | undefined = completed
    ? dayjs(completed)
    : undefined;

  const status = getEcdGridStatus(now, ecdDate, dtgCompletedDate);

  return (
    <SlaFieldContainer>
      <GridDateStatusIcon status={status} />
      {formatDateTime(ecd)}
    </SlaFieldContainer>
  );
}

export function getEcdGridStatus(
  now: Dayjs,
  ecd?: Dayjs,
  completed?: Dayjs
): DateStatus {
  if (!ecd && !completed) return 'grey';
  if (ecd) {
    if (ecd > now && !completed) return 'grey';
    if (ecd < now && !completed) return 'amber';
  }
  if (!ecd && completed) return 'amber';
  if (ecd && completed) {
    const isSameDate = dayjs(ecd).isSame(dayjs(completed), 'date');
    if (isSameDate) return 'grey';
    if (!isSameDate) return 'grey';
  }
  return 'unknown';
}

export function getEcdDetailsStatus(
  now: Dayjs,
  ecd?: Dayjs,
  completed?: Dayjs
): DateStatus {
  if (!ecd && !completed) return 'grey';
  if (ecd) {
    if (ecd > now && !completed) return 'green';
    if (ecd < now && !completed) return 'amber';
  }
  if (!ecd && completed) return 'amber';
  if (ecd && completed) {
    const isSameDate = dayjs(ecd).isSame(dayjs(completed), 'date');
    if (isSameDate) return 'green';
    if (!isSameDate) return 'green';
  }
  return 'unknown';
}
