import { MainPageContainer } from 'components/PageLayout';
import styled from '@emotion/styled';
import { NetworkAverage } from './NetworkAverage';
import { CycleTimeSearchForm } from './CycleTimeSearchForm';
import { RepairerSummary } from './RepairerSummary';
import PrimaryToast from 'components/PrimaryToast';
import { AxiosError } from 'axios';
import { logger } from 'core/logger';
import { errorMessages } from 'api/resources/responseMessages/errorMessages';
import { useState } from 'react';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';


export function CycleTime() {

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);

  function handleError(error: AxiosError) {
    logger?.error(error);
    appInsights?.trackException({ error, severityLevel: SeverityLevel.Error });
    setErrorMessage(errorMessages.default);
    setIsOpenToast(true);
  }

  return (
    <MainContainer direction="column" wrap="nowrap">
      <CycleTimeSearchForm />
      <NetworkAverage handleError={handleError} />
      <RepairerSummary handleError={handleError} />
      <PrimaryToast
        message={errorMessage}
        isOpen={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        severity="error"
      />
    </MainContainer>

  );
}

export const MainContainer = styled(MainPageContainer)`
  padding-top: ${({ theme }) => ` ${theme.padding.m}`};
`;
