import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';


export function ReportBackButton() {
    return (
        <GridItem>
            <Container>
                <BackButton text="Back to Reports" />
            </Container>
        </GridItem>
    );
}
