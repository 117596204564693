import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from 'components/TextField';
import { DataAccessProfile } from 'api/resources/models/AutoGenerated';
import {
  useCompanies,
  useCreateDataAccessProfile,
  useUpdateDataAccessProfile,
} from 'pages/hooks';
import { useState } from 'react';
import { FormHelperText } from '@mui/material';
import { generatePath, useHistory } from 'react-router';
import { ROUTES } from 'core/routes';
import PrimaryToast from 'components/PrimaryToast';
import { Select } from 'components/Select';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  InputContainer,
  InputFirst,
  InputSecond,
  Label,
  SaveButton,
  SaveIcon,
} from 'components/PageLayout';
import { successMessages } from 'api/resources/responseMessages/successMessages';
import Skeleton from 'components/Skeleton';

interface Props {
  dataAccessProfile?: DataAccessProfile;
}

const formSchema = z.object({
  name: z
    .string()
    .nonempty({ message: 'Profile Name is required' })
    .min(3, { message: 'Must be 3 or more characters long' })
    .max(50, { message: 'Must be max 50 characters long' }),
  wpIdMask: z
    .string({ required_error: 'Mask is required' })
    .max(50, { message: 'Must be max 50 characters long' }),
  companyId: z.number({ required_error: 'Company is required' }),
});

export function DataAccessGeneralDetails({ dataAccessProfile }: Props) {
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<DataAccessProfile>({
    defaultValues: {
      name: dataAccessProfile?.name ?? '',
      companyId: dataAccessProfile?.companyId ?? undefined,
    },
    resolver: zodResolver(formSchema),
  });

  const history = useHistory();
  const { companies, isCompaniesLoading } = useCompanies({});
  const { update, isUpdating } = useUpdateDataAccessProfile();
  const { create, isCreating } = useCreateDataAccessProfile();
  const isBusy = isUpdating || isCreating || isCompaniesLoading;

  const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
  const [createdDataAccessProfileId, setCreatedDataAccessProfileId] =
    useState<number>();
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>();

  return isBusy ? (
    <>
      <SkeletonContainer>
        <SkeletonLoader
          variant="rectangular"
          height={56}
          width={200}
          startingPoint={'end'}
        />
      </SkeletonContainer>
      <SkeletonFullWidth direction="column">
        <SkeletonLoader
          variant="rectangular"
          height={145}
          startingPoint={'start'}
        />
        <Separator />
      </SkeletonFullWidth>
    </>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container direction="column">
        <GridItem>
          <StyledSaveButton type="submit">
            <SaveIcon />
            Save Changes
          </StyledSaveButton>
        </GridItem>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Data Access Profile Name</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.name}
                  fullWidth
                  helperText={errors.name?.message}
                  onChange={(e) => {
                    field.onChange(e.target.value.trimStart())
                  }}
                />
              )}
              name="name"
              control={control}
              defaultValue={dataAccessProfile?.name}
            />
          </InputFirst>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Mask</Label>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.wpIdMask}
                  helperText={errors.wpIdMask ? errors.wpIdMask.message : null}
                  fullWidth
                  defaultValue={dataAccessProfile?.wpIdMask}
                  disabled={!!dataAccessProfile}
                />
              )}
              name="wpIdMask"
              control={control}
              defaultValue={dataAccessProfile?.wpIdMask}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Company Name</Label>
            {dataAccessProfile ? (
              <TextField
                error={!!errors.companyName}
                helperText={
                  errors.companyName ? errors.companyName.message : null
                }
                fullWidth
                defaultValue={dataAccessProfile?.companyName}
                disabled={!!dataAccessProfile}
              />
            ) : (
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    error={!!errors.companyId}
                    options={companies.map((c) => ({
                      id: c.id,
                      value: c.name,
                      text: c.name ?? '',
                    }))}
                    selectedId={selectedCompanyId}
                    onChange={(e) => {
                      setValue('companyId', e.id);
                      setSelectedCompanyId(e.id);
                      clearErrors('companyId');
                    }}
                    variant="outlined"
                  />
                )}
                name="companyId"
                control={control}
              />
            )}
            {!!errors.companyId && (
              <SelectHelperText>{errors.companyId.message}</SelectHelperText>
            )}
          </InputSecond>
        </InputContainer>
      </Container>
      {toastState && (
        <PrimaryToast
          message={toastState.message}
          isOpen={toastState.isOpen}
          severity={toastState.severity}
          onClose={() => {
            setToastState({ ...toastState, isOpen: false });
            if (createdDataAccessProfileId)
              history.replace(
                generatePath(ROUTES.dataAccessProfileEdit, {
                  id: createdDataAccessProfileId,
                })
              );
          }}
        />
      )}
    </form>
  );

  function onSubmit(data: DataAccessProfile) {
    const profile = { ...dataAccessProfile, ...data };
    if (dataAccessProfile)
      update(profile).then(() =>
        setToastState({
          isOpen: true,
          message: successMessages.dataAccessProfilesUpdate,
          severity: 'success',
        })
      );
    else
      create(profile).then((response) => {
        setCreatedDataAccessProfileId(response.id);
        setToastState({
          isOpen: true,
          message: successMessages.dataAccessProfilesCreate,
          severity: 'success',
        });
      });
  }
}

const StyledSaveButton = styled(SaveButton)`
  margin-top: ${({ theme }) => theme.margin.l};
`;

const SelectHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.messages.error};
`;

const SkeletonContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-top: ${({ theme }) => `${theme.margin.s}`};
`;

const SkeletonFullWidth = styled(Container)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  width: 100%;
`;

const SkeletonLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-bottom: ${({ theme }) => `${theme.margin.m}`};
`;

const Separator = styled.div`
  margin-top: ${({ theme }) => `${theme.margin.m}`};
`;
