import dayjs, { Dayjs } from 'dayjs';
import { formatDateTime } from 'utils/helpers';
import { DateStatusIcon } from './DateStatusIcon';
import { SlaFieldContainer } from './SlaFieldContainer';
import { DateStatus } from './types';

interface Props {
  diaryDate: Date;
}

export function DiaryDisplay({ diaryDate }: Props) {
  const now = dayjs();
  const plusOneDay = now.add(1, 'day');

  const status = getDiaryDisplayStatus(dayjs(diaryDate), plusOneDay, now);

  return (
    <SlaFieldContainer>
      <DateStatusIcon status={status} />
      {formatDateTime(diaryDate)}
    </SlaFieldContainer>
  );
}

function getDiaryDisplayStatus(
  initialField: Dayjs,
  plusOneDay: Dayjs,
  now: Dayjs
): DateStatus {
  if (initialField > plusOneDay) return 'green';

  if (initialField > now && initialField < plusOneDay) return 'amber';

  if (initialField < now) return 'red';

  return 'unknown';
}
