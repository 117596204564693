import { Fragment, ReactElement, useEffect, ReactNode } from 'react';
import { User, UserManager } from 'oidc-client';
import store from 'core/store';
import { EnhancedStore } from '@reduxjs/toolkit';
import { userLoggedin } from 'core/store/auth';
import {
  signout,
  switchUserProfile,
  clearLocalStorage,
  localStorageSelectedProfileKey,
} from './userService';
import { logger } from 'core/logger';
import { appInsights } from 'core/logger/ApplicationInsightsService';

interface AuthProviderProps {
  userManager: UserManager;
  children: ReactNode;
  store: EnhancedStore;
}

export default function AuthProvider({
  userManager,
  children,
}: AuthProviderProps): ReactElement {
  useEffect(() => {
    const onUserLoaded = (user: User) => {
      userManager.startSilentRenew();
      
      logger?.debug(`UserManager -> user loaded`);
      appInsights?.trackEvent({ name: 'UserManager -> user loaded' });

      store.dispatch(
        userLoggedin({
          accessToken: user.access_token,
          name: user.profile.name,
          email: user.profile.email,
        })
      );
      const profileId = localStorage.getItem(
        localStorageSelectedProfileKey
      );
      if (profileId) {
        switchUserProfile(Number(profileId));
      }
    };

    const onUserUnloaded = () => {
      const infoMessage = 'UserManager -> user unloaded';
      logger?.debug(infoMessage);
      appInsights?.trackEvent({ name: infoMessage })
    };

    const onAccessTokenExpiring = () => {
      userManager.signinSilent();
      const infoMessage = 'UserManager -> user token expiring';
      logger?.debug(infoMessage);
      appInsights?.trackEvent({ name: infoMessage })
    };

    const onAccessTokenExpired = () => {
      const infoMessage = 'UserManager -> user token expired'
      logger?.debug(infoMessage);
      appInsights?.trackEvent({ name: infoMessage })
      userManager.stopSilentRenew();
      clearLocalStorage();
      signout();
    };

    const onUserSignedOut = () => {
      const infoMessage = 'UserManager -> user signed out';
      logger?.debug(infoMessage);
      appInsights?.trackEvent({ name: infoMessage })
    };

    // events for user
    userManager.events.addUserLoaded(onUserLoaded);
    userManager.events.addUserUnloaded(onUserUnloaded);
    userManager.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.events.addUserSignedOut(onUserSignedOut);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.events.removeUserLoaded(onUserLoaded);
      userManager.events.removeUserUnloaded(onUserUnloaded);
      userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring);
      userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager.events.removeUserSignedOut(onUserSignedOut);
    };
  }, [userManager]);

  return <Fragment>{children}</Fragment>;
}
