import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { useParams } from 'react-router-dom';
import { Heading } from 'components/Typography';
import { useState } from 'react';
import { DataAccessDetails } from './DataAccessDetails';
import { DataAccessGeneralDetails } from './DataAccessGeneralDetails';
import { useDataAccessProfile } from 'pages/hooks';
import { AdminDataAccessProfileParams } from 'core/routes';
import { DataAccessProfile } from 'api/resources/models/AutoGenerated';
import { TabPanel, PageTab, PageTabItem } from 'components/Tab';
import { PageHeader, UpperInfoContainer } from 'components/PageLayout';
import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';

const useAdminDataAccessProfileParams = () => {
  const { id } = useParams<AdminDataAccessProfileParams>();
  const dataAccessProfileId = Number(id);
  return isNaN(dataAccessProfileId)
    ? 'Data access profile id must be a number'
    : dataAccessProfileId;
};

export function DataAccessProfileEdit() {
  const dataAccessProfileId = useAdminDataAccessProfileParams();
  if (typeof dataAccessProfileId === 'string') throw Error(dataAccessProfileId);
  const { dataAccessProfile, isDataAccessProfilesLoading } =
    useDataAccessProfile(dataAccessProfileId);
  return (
    <DataAccessProfilesDetails
      dataAccessProfile={dataAccessProfile}
      isBusy={isDataAccessProfilesLoading}
    />
  );
}

export function DataAccessProfileCreate() {
  return <DataAccessProfilesDetails />;
}

interface Props {
  dataAccessProfile?: DataAccessProfile;
  isBusy?: boolean;
}
export function DataAccessProfilesDetails({
  dataAccessProfile,
  isBusy = false,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<string>('details');

  return isBusy ? (
    <>
      <SkeletonContainer>
        <SkeletonLoader
          variant="rectangular"
          height={56}
          width={500}
          startingPoint={'start'}
        />
        <SkeletonLoader
          variant="rectangular"
          height={56}
          width={500}
          startingPoint={'end'}
        />
      </SkeletonContainer>
      <SkeletonFullWidth direction="column">
        <SkeletonLoader
          variant="rectangular"
          height={145}
          startingPoint={'start'}
        />
        <Separator />
        <SkeletonLoader
          variant="rectangular"
          height={145}
          startingPoint={'middle'}
        />
        <Separator />
      </SkeletonFullWidth>
    </>
  ) : (
    <UpperInfoContainer>
      <Container direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Data Access Profiles" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            {dataAccessProfile
              ? `Edit Data Access Profile "${dataAccessProfile?.name}"`
              : `Create Data Access Profile`}
          </Heading>
        </PageHeader>
      </Container>
      <Container direction="row">
        <Container>
          <GridItem md={10} sm={12} xs={12}>
            <PageTab
              value={selectedTab}
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <PageTabItem label="Details" value="details" />
              <PageTabItem
                label="Data Access Detail"
                value="dataAccessDetail"
                disabled={!dataAccessProfile}
              />
            </PageTab>

            <TabPanel value={selectedTab} index="details">
              <DataAccessGeneralDetails dataAccessProfile={dataAccessProfile} />
            </TabPanel>

            <TabPanel value={selectedTab} index="dataAccessDetail">
              {dataAccessProfile && (
                <DataAccessDetails dataAccessProfileId={dataAccessProfile.id} />
              )}
            </TabPanel>
          </GridItem>
        </Container>
      </Container>
    </UpperInfoContainer>
  );
}

const SkeletonContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-top: ${({ theme }) => `${theme.margin.s}`};
`;

const SkeletonFullWidth = styled(Container)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  width: 100%;
`;

const SkeletonLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-bottom: ${({ theme }) => `${theme.margin.m}`};
`;

const Separator = styled.div`
  margin-top: ${({ theme }) => `${theme.margin.m}`};
`;
