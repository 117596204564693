import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import { Label } from 'components/PageLayout';
import { Select } from 'components/Select';
import { useInMobile } from 'core/Theming/Device';
import {
  renderSearchButtonText,
  renderSearchIcon,
} from 'pages/ReportsDetails/helpers';
import { ReportFormB2bProps } from './types';

export const ReportFormB2B = ({
  onChangeStartDate,
  selectedAccountId,
  onChangeEndDate,
  onChangeSelect,
  handleSubmit,
  startDate,
  options,
  endDate,
  isBusy,
}: ReportFormB2bProps) => {
  const isMobile = useInMobile();
  return (
    <CustomForm onSubmit={handleSubmit}>
      <Container direction="row">
        <InputButton md={3} sm={4} xs={12}>
          <Label>Start Date</Label>
          <DatePickerField
            isDisabled={isBusy}
            onChange={(date) => onChangeStartDate(date)}
            value={startDate}
            maxDate={endDate}
          />
        </InputButton>
        <InputButton md={3} sm={4} xs={12}>
          <Label>End Date</Label>
          <DatePickerField
            isDisabled={isBusy}
            onChange={(date) => onChangeEndDate(date)}
            value={endDate}
            minDate={startDate}
          />
        </InputButton>
        <InputButton md={3} sm={4} xs={12}>
          <Label>Service Account</Label>
          <Select
            disabled={isBusy}
            options={options}
            onChange={(status) => onChangeSelect(status.value)}
            selectedId={
              options?.find(
                (e: { value: string | null }) => e.value == selectedAccountId
              )?.id
            }
          />
        </InputButton>
        <GridItem md={1} sm={1} xs={2}>
          <Button
            type="submit"
            disabled={!startDate || !endDate || !selectedAccountId || isBusy}
          >
            {renderSearchIcon(isMobile)}
            {renderSearchButtonText(isMobile)}
          </Button>
        </GridItem>
      </Container>
    </CustomForm>
  );
};

const InputButton = styled(GridItem)`
          padding-right: ${({ theme }) => theme.margin.s};
          @media (max-width: ${({ theme }) =>
            `${theme.breakpoints.values.sm}px`}) {
              margin - bottom: 6px;
          }
          & svg {
              width: 20px;
          height: 20px;
          color: ${({ theme }) => theme.palette.secondary.main};
          }
      `;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
