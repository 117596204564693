import darkLogo from 'assets/darkLogo.png';
import logo from 'assets/Logofull.png';
import styled from '@emotion/styled/macro';
import { UserMenuContainer } from 'components/UserMenu';
import { useAppSelector } from 'core/store';
import { ReactComponent as BurgerIcon } from 'assets/BurgerIcon.svg';
import { GridItem } from 'components/Layout';
import { MobileMenu } from 'components/Menu/MobileMenu';
import { UserPermit } from 'api/resources/models/AutoGenerated';
import { useContext, useEffect } from 'react';
import { ScrollContext } from 'core/ScrollContext';
import { Text } from './Typography';
import { ImpersonatingButton } from './Button';
import { removeImpersonateUser } from 'core/auth/userService';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';

interface Props {
  userPermits: UserPermit[];
  isOpen: boolean;
  setOpen: () => void;
  currentUserFullName: string | null;
}

export function MainHeader({
  isOpen,
  setOpen,
  userPermits,
  currentUserFullName,
}: Props) {
  const history = useHistory();
  const isMobile = useInMobile();
  const scrollPosition = useContext(ScrollContext);
  const isHeaderFixed = scrollPosition !== undefined;
  const { impersonatedUser, user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isOpen && isMobile) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isOpen, isMobile]);

  return (
    <>
      {isHeaderFixed ? (
        <MainContainer>
          <FixedHeader
            style={{ transform: `translateY(${-scrollPosition!}px)` }}
            isOpen={isOpen}
          >
            {renderHeaderMenu()}
          </FixedHeader>
        </MainContainer>
      ) : (
        <>
          <RelativeHeader isOpen={isOpen}>{renderHeaderMenu()}</RelativeHeader>
          {impersonatedUser && (
            <ImpersonatingMobileContainer>
              <ImpersonateView />
            </ImpersonatingMobileContainer>
          )}
        </>
      )}
    </>
  );

  function renderHeaderMenu() {
    return (
      <>
        <MenuItem isOpen={isOpen} onClick={setOpen}>
          <BurgerIcon />
          Menu
        </MenuItem>
        <div className="logo">
          <img className="img" src={isOpen && isMobile ? logo : darkLogo} />
          {process.env.REACT_APP_LOGGING_ENVIRONMENT === 'dev' && (<text style={{color:"red", fontSize:"x-large"}}> - DEVELOPMENT </text>)}
          {process.env.REACT_APP_LOGGING_ENVIRONMENT === 'stg' && (<text style={{color:"red", fontSize:"x-large"}}> - STAGING </text>)}
        </div>
        {impersonatedUser && (
          <ImpersonatingDesktopContainer>
            <ImpersonateView />
          </ImpersonatingDesktopContainer>
        )}
        {user ? <UserMenuContainer /> : null}
        {isOpen ? (
          <MobileMenu userPermits={userPermits} setOpen={setOpen} />
        ) : null}
      </>
    );
  }

  function ImpersonateView() {
    return (
      <>
        <Text fontSize="l">
          You are impersonating user account &quot;{currentUserFullName} -{' '}
          {impersonatedUser?.name}&quot;
        </Text>
        <ImpersonatingButton
          color="error"
          onClick={() => {
            removeImpersonateUser();
            history.push(ROUTES.dashboard);
            history.replace(ROUTES.redirectHandler);
          }}
        >
          STOP
        </ImpersonatingButton>
      </>
    );
  }
}

const ImpersonatingDesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgb(241, 248, 252);
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  max-width: 50%;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    display: none;
  }
`;

const ImpersonatingMobileContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    display: flex;
    flex-direction: row;
    background-color: rgb(241, 248, 252);
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
  }
`;

const MenuItem = styled(GridItem)<{ isOpen: boolean }>`
  display: none;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xs};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    display: flex;
    & svg {
      margin-right: 5px;
      path {
        fill: ${({ theme, isOpen }) =>
          isOpen
            ? theme.palette.primary.activeText
            : theme.palette.primary.main};
      }
    }

    color: ${({ theme, isOpen }) =>
      isOpen ? theme.palette.primary.activeText : theme.palette.primary.main};
  }
`;

const Header = styled('header')<{ isOpen: boolean }>`
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  padding-right: ${({ theme }) => theme.margin.l};
  padding-left: ${({ theme }) => `${88 - theme.palette.drawer.width}px`};
  & .logo {
    margin-left: 20px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      margin-left: 6px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: ${({ theme }) => `0 ${theme.margin.m}`};

    color: ${({ theme, isOpen }) =>
      isOpen
        ? theme.palette.background.default
        : theme.palette.background.dark};

    background-color: ${({ theme, isOpen }) =>
      isOpen
        ? theme.palette.background.dark
        : theme.palette.background.default};
  }
`;

const FixedHeader = styled(Header)`
  position: fixed;
  width: 100%;
  right: 0;
  padding-left: 88px;
`;

const RelativeHeader = styled(Header)`
  position: relative;
`;

const MainContainer = styled('div')`
  margin-bottom: 56px;
`;
